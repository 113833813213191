import React from "react";
import Authentication from './Authentication';
import Logo from './Logo';
import './Overlay.css';

const Overlay = () => {
    return (
        <div className="overlay">

            {/* <Logo/> */}
            <Authentication/>

        </div>
    )
}

export default Overlay