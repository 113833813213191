import * as React from 'react';
import axios from 'axios';
import  {CircleSpinner}  from "../utils/GuiComponents/CircleSpinner";
import ReactDOM from 'react-dom';
import { ExtensionsError, ExtensionsInfo, ExtensionUtil, PLAYER_ExtensionText } from '../utils/utils';
import { Client, ContentParameter, FileType, IDownloadInformation } from '../../../client';
import { HtmlUtils } from '../utils/HtmlUtils';
import ViewerToolkit, { IPropertiesResult } from '../utils/ViewerToolkit';
import PlayerGUIPanel from './PlayerGUIPanel';
import { SeverityType } from '../utils/GuiComponents/ModalDialogs/BaseSnackbar';
import { lastIndexOf } from 'underscore';
import FileSaver from 'file-saver';
import Axios from 'axios';

interface IRevAndRepoInfo 
{
    libId:string; 
    revisionId:string;
    repositoryId:string;
}

export default class DownloadRevitFile
{
    //=================================================================================================================
    private   _apiClient:Client = null;    
    //=================================================================================================================

    constructor(apiClient:Client)
    {
        this._apiClient    = apiClient;
    }

    //=================================================================================================================

    async processDownloadSignedUrlForSelectedItems(viewer:any,selectedRows:any[],apiClient:Client, spinnerCaptionId:string,guiPanel:PlayerGUIPanel) 
    {
        let   serverError     = false;
        let   successfullDownloaded=0;
        let   warningCounter  = 0;
        let   index:number    = 0;
        const maxIndex:number = selectedRows.length;
        const modelTree       = viewer.model.getInstanceTree();

        for (let currRow of selectedRows)
        {
            if (serverError)
                continue;

            let revId         = null;
            let repoId        = null; 
            let signedUrl     = null;
            let dbId:number   = parseInt(currRow.getData().DBID,10);
            let name_dbId     = null; 
            let itemNumber         = currRow.getData().ItemNumber;
            let productDescription = currRow.getData().Product;
            let externalDbId  = null;


            let info_name_property  = "name-property: ";
            let info_lib_content    = "library-content: ";
            let info_signed_url     = "signed-url: ";


            console.log("try to load ... for  " + dbId.toString());

            const infoText = "( " + (index+1) + " / " + maxIndex +    "  )  " + "trying to download revit-file for item-number: " + itemNumber;

            HtmlUtils.showHtmlElement(  spinnerCaptionId,  true, true);
            HtmlUtils.setText(          spinnerCaptionId,  infoText);
            //==========================================================================

            name_dbId = modelTree.nodeAccess.name(dbId);

            const name_full = name_dbId;

            if (name_dbId === null)
            {
                info_name_property  += "could not be found";
            }
            else
            {
                // e.g. Hilti_FS_collar CFS-C [286902]"
                // [286902] cut off !!!

                const index_1 = name_dbId.lastIndexOf("[");
                const index_2 = name_dbId.lastIndexOf("]");

                if (index_1 > 0 && index_2 > 0 && index_1 < index_2)
                {
                    name_dbId = name_dbId.substring(0,index_1);
                    name_dbId = name_dbId.trim();
                }
                info_name_property  += name_dbId;
            }

            //==========================================================================

            if (name_dbId !== null)
            {
                await this.getLibraryContentByName(name_dbId).then(function( result:IRevAndRepoInfo ) {

                    if (result !== null)
                    {
                        revId  = result.revisionId;
                        repoId = result.repositoryId;
                        info_lib_content += "revisionId: " +  revId + "  repositoryId: " + repoId;
                    }
                    else
                    {
                        info_lib_content += "could not be found";
                    }
                })
                .catch((error) => { 
       
                    serverError = true;
                    const info = ExtensionUtil.getErrorText(ExtensionsError.ERROR_CONNECT_WEB_SERVER);
                    guiPanel.showAlert( info,  SeverityType.ERROR );

                });    
            }
            
            //==========================================================================
            
            if (revId !== null && repoId != null)
            {
                await this.getDownloadInfoByRevIdAndRepoId(revId,repoId).then(async function( retval_signedUrl:string ) {

                    if (retval_signedUrl !== null)
                    {
                        try
                        {
                            
                            apiClient.download(retval_signedUrl, name_dbId).then(res => {
                                FileSaver.saveAs(res.data, name_dbId);
                            })
                        }
                        catch(e)
                        {
                            alert("error: " + e);
                        }

                       signedUrl = retval_signedUrl;

                        // let errInfo = "Item-number: " + itemNumber  +  "\r\n" +  "\r\n"; 
                        // errInfo += "Name-stripped: "       + name_dbId + "\r\n";
                        // errInfo += "Name-full: "           + name_full + "\r\n";
                        // errInfo += "Product description: " + productDescription + "\r\n" + "\r\n";
                        // errInfo += "RevisionId: " + revId + "\r\n" +  "\r\n";
                        // errInfo += "RepositoryId: " + repoId + "\r\n" +  "\r\n";
                        // errInfo += "Signed-URL:";
                        // errInfo += "\r\n" +  "\r\n";
                        // errInfo += retval_signedUrl;
                        // console.log( errInfo );
                        // that.saveRevitInfoToFile(errInfo,itemNumber);
                    
                        info_signed_url  += retval_signedUrl;
                         ++successfullDownloaded;
                    }
                    else
                    {
                        info_signed_url  += "could not be found";
                    }
                })
                .catch((error) => { 
       
                    serverError = true;
                    const info = ExtensionUtil.getErrorText(ExtensionsError.ERROR_CONNECT_WEB_SERVER);
                    guiPanel.showAlert( info,  SeverityType.ERROR );
                });  
            }

            //==========================================================================
            if (!serverError)
            {
                if (name_dbId === null || name_dbId === null || revId === null || repoId === null || signedUrl == null)
                {
                    ++warningCounter;
                    let errInfo = "[Warning]: " + ExtensionUtil.getErrorText(ExtensionsError.WARNING_DATA_NOT_COMPLETED);    
                    errInfo += "\r\n";

                    errInfo = "item-number: " + itemNumber + "  dbId: " + dbId.toString(); 
                    errInfo += "\r\n";

                    errInfo += info_name_property;
                    errInfo += "\r\n";

                    errInfo += info_lib_content;
                    errInfo += "\r\n";

                    errInfo += info_signed_url;
                    //console.log( errInfo );
                    this.saveErrorInfoToFile(errInfo,itemNumber);
                }

                if ( (++index) == selectedRows.length && warningCounter > 0)
                {
                    let  info  = ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_WARNING_REVIT_DOWNLOAD_01);
                        info  = info.replace("%", warningCounter.toString() );
                    guiPanel.showAlert( info, SeverityType.WARNING );
                }
            }
        }

        if (successfullDownloaded > 0)
        {
            const infoId = (successfullDownloaded == 1) ? PLAYER_ExtensionText.CAPTION_SUCCESS_REVIT_DOWNLOAD_01 : PLAYER_ExtensionText.CAPTION_SUCCESS_REVIT_DOWNLOAD_02;
            let  info  = ExtensionUtil.getPlayerExtesionCaptionText(  infoId );
            info  = info.replace("%", successfullDownloaded.toString() );
            guiPanel.showAlert( info, SeverityType.SUCCESS );
        }
    }
    
    //=================================================================================================================

    private saveErrorInfoToFile(errInfo:string,itemNumber:string)
    {
        const now_as_number  = Date.now();
        const uniquefileName =  now_as_number +  "-" + itemNumber + "_basket_errinfo.err";
         HtmlUtils.saveTextFile(uniquefileName,errInfo);
    }

    private saveRevitInfoToFile(errInfo:string,itemNumber:string)
    {
        const now_as_number  = Date.now();
        const uniquefileName =  now_as_number +  "-" + itemNumber + "_revit_urlinfo.err";
         HtmlUtils.saveTextFile(uniquefileName,errInfo);
    }
     
 
    //=================================================================================================================

    async getNamePropertyByDbId(viewer:any,dbId:number) : Promise<string> 
    {
        let name:string = null;

        await ViewerToolkit.getProperty02(viewer.model, dbId, "Name").then(function(result) {

            if (result !== null)
            {
                const result_objProperty = result as IPropertiesResult;
    
                const index = result_objProperty.propertyValue.search("[");
    
                if (index > 0)
                {
                    name = result_objProperty.propertyValue.substring(0,index-1);
                    name = name.trim();
                }
            }
            else
            {
               // alert("no data found for given dbId and property-name");                
            }
        })
        .catch((error) => { 
       
            //alert( "Error: " + dbId );  

        });    
        return name;
    }

    //=================================================================================================================

    private async getLibraryContentByName( name:string) : Promise<IRevAndRepoInfo> 
    {
        let revRepoInfo:IRevAndRepoInfo = null;

        await this._apiClient.getLibraryContentByName( name ).then ( (resultArray:ContentParameter[]) =>  {

            if (ExtensionUtil.isValidContent( resultArray ))   // must have length
            {
                revRepoInfo = { libId: "" , revisionId:  resultArray[0].currentRevisionId, 
                                                         repositoryId: resultArray[0].repositoryFileId };
            }
            else
            {
               // alert("no lib content for given name");
            }
        });
        // .catch((error) => { 
       
        //  //   ExtensionUtil.getErrorText(ExtensionsError.ERROR_CONNECT_WEB_SERVER);
        //  //   alert( "Error: 1100" );  

        // });    
        return revRepoInfo;
    }

    //=================================================================================================================

 
     private async getDownloadInfoByRevIdAndRepoId(revId:string,repFileId:string) : Promise<string>
     {
         let   signedUrl:string = null;
 
         await this._apiClient.reps(revId,repFileId).then( (contents:IDownloadInformation[]) =>  {
 
             if (ExtensionUtil.isValidContent(contents))
             {
                 signedUrl = contents[0].signedUrl;
             }  
         })
         .catch((error) => { 
         
             const errInfo = "[Error]: RevisionId: " + revId + "  RepositoryId: " + repFileId + "  " + 
                                        ExtensionUtil.getErrorText(ExtensionsError.ERROR_PLAYER_DOWNLOADINFO_ERR);    
             console.log( errInfo );
             //errInfoData.push( errInfo);
             //this.saveErrorInfoToFile(errInfo,revId,repFileId);
             return null;
       
         });
         return signedUrl;
     }
 
     //=================================================================================================================
   
     private async downloadRevitFamilyFile(fileUrl:string)
     {
         if (ExtensionUtil.isValidContent(fileUrl))        
         {
           // const datex = date.toString('YYYY-MM-dd'); 'Tue Feb 10 2015 15:42:50'
             const link = document.createElement('a');
             link.href  = fileUrl;
             link.setAttribute('download', 'file.rfa'); 
             await link.click();
         }
     }

     //=================================================================================================================
 }