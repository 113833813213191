import './css/ScreenShotExtension.css';


/*=====================================================================================================================
DESCRIPTION:
=======================================================================================================================
ScreenshotExtension

This Extension does not have any GUI-Elements. By clicking the Toolbar-Button, this Extension will take
a screenshot of the current content of the Forge-Viewer and save this content in a imagefile (in png-format) in the
download-folder of the user webbrowser. 
=====================================================================================================================*/

export class ScreenShotExtension extends Autodesk.Viewing.Extension {

  constructor(viewer:any, options:any) {
    super(viewer, options);
    Autodesk.Viewing.Extension.call(this, viewer, options);
   }


  load() : boolean {
    if(this.viewer.toolbar){
      // Toolbar is already available, create the UI
      this.createUI();
    }
    else {
      // Toolbar hasn't been created yet, wait until we get notification of its creation
    }
    return true;
  }

  onToolbarCreated(){
    this.createUI();
  }

  createUI() : void {
  

    //prepare to execute the button action
    var hiltiScreenshotToolbarButton = new Autodesk.Viewing.UI.Button('Capture Screenshot');

    hiltiScreenshotToolbarButton.onClick = () => {
        this.makeScreenshot();
    }

  hiltiScreenshotToolbarButton.addClass('screenShotToolbarButton');
  hiltiScreenshotToolbarButton.setToolTip('Capture a Screenshot');

  // SubToolbar

  var subToolbar : Autodesk.Viewing.UI.ControlGroup = (this.viewer.toolbar.getControl("hiltiScreenshotToolbar") ? 
      this.viewer.toolbar.getControl("hiltiScreenshotToolbar") as Autodesk.Viewing.UI.ControlGroup :
      new Autodesk.Viewing.UI.ControlGroup('hiltiScreenshotToolbar'));
  subToolbar.addControl(hiltiScreenshotToolbarButton);

  this.viewer.toolbar.addControl(subToolbar);

  }

  unload() : boolean {
    //alert("Hilti Screenshot extension unloaded");
    return true;
  }

  download(blobUrl : string) : void {
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = blobUrl;
      a.download = "hilti-screenshot.png";
      a.click();
  }

  makeScreenshot() : void {
      this.viewer.getScreenShot(this.viewer.container.clientWidth, this.viewer.container.clientHeight, (blobUrl : string) => {
          this.download(blobUrl);
      });
  }

  public static loadExtension(viewer:any)
  {
      viewer.loadExtension('Hilti.ScreenshotExtension', {} );
  }

}
