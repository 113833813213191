import { BIM360DataManagerExtensionPanel } from "./BIM360DataManagerExtensionPanel";
import './css/BIM360DataManagerExtension.css';
import Config from '../../../Config';

export class BIM360DataManagerExtension extends Autodesk.Viewing.Extension {
    
    //=================================================================================================================
    _panel      :any = null;
    _subToolbar :any = null;
    _options    :any = null;
    _viewer     :any = null;
    //=================================================================================================================

    public constructor(viewer:any, options:any) {
        super(viewer, options);
        Autodesk.Viewing.Extension.call(this, viewer, options);
        this._options = options;
        this._viewer = viewer;
    }
    
    //===================================================================================================================

    public load() : boolean {

        if (this._viewer.toolbar)  // Toolbar is already available, create the UI
        {
            this.createUI(); 
        }
        return true;
    }

    //===================================================================================================================

    public onToolbarCreated() : void {

        this.createUI();
    }

    //===================================================================================================================

    private createUI() : void {

        if (this._subToolbar == null)
        {
            // button to show the docking panel
            const actionToolbarButton = new Autodesk.Viewing.UI.Button('BIM360DataManagerButton');

            actionToolbarButton.addClass('BIM360DataManagerToolbarButton');
            actionToolbarButton.setToolTip('BIM360DataManager');

            actionToolbarButton.onClick = (e) => {  this.onButtonClicked(e);  }
            
            this._subToolbar = new Autodesk.Viewing.UI.ControlGroup('BIM360DataManagerToolbar');
                
            this._subToolbar.addControl(actionToolbarButton);
            this._viewer.toolbar.addControl(this._subToolbar);
        }
    } 

    //===================================================================================================================

    public unload() : boolean 
    {
        this._viewer.toolbar.removeControl(this._subToolbar);
        return true;
    }
 
    //===================================================================================================================

    private onButtonClicked(e:any) : void {

        if (this._panel == null) 
        {
            this._panel = new BIM360DataManagerExtensionPanel(this._viewer, this._viewer.container, 'BIM360DataManagerExtensionPanel', 'BIM360DataManager', this.options);
        }                
        this._panel.setVisible(!this._panel.isVisible()); // toogle (show/hide) docking panel
    }

    //===================================================================================================================

    public static loadExtension(viewer:any)
    {
        const baseUrl      = Config.BaseUrl;
        const spinnerColor = Config.SpinnerColor;
        // const spinnerSize  = Config.SpinnerSize;
        // const spinnerSizeValue = parseInt(spinnerSize,10);

        const init_options = {
            PANEL_INITIAL_POS:    "20,90,520,840",     // initial x1Pos,y1Pos,Width,Height of the panel
            SPINNER_COLOR:        spinnerColor,         // color of spinner bars
            SPINNER_SIZE:         10,     // size of spinner 90 -> very big 60 -> middle size  30 -> small
            BASE_URL:             baseUrl              // web-server base-url for ( Axios ) 
        }
          viewer.loadExtension('Hilti.BIM360DataManagerExtension', {  init_options  });
    }  
  
    //===================================================================================================================
}