import { ChartExtensionPanel } from "./ChartExtensionPanel";
import './css/ChartExtension.css';


/*=====================================================================================================================
DESCRIPTION:
=======================================================================================================================
ChartExtension

The ChartExtension shows, based on collections of  certain data (datasets), different Chart-types
like Doughnut and Piecharts, LineChart, BarChart and so on. By clicking on the download-Button, 
the user can download certain resource-data from a certain WebServer-URL.

Parameter:

PANEL_INITIAL_POS:      initial x1 and y1 ( left-upper corner ) width and height of the ExtensionPanel
default-Value:          x1 = 30  y1 = 90  width = 326 pixel  height = 520 pixel
=====================================================================================================================*/

export class ChartExtension extends Autodesk.Viewing.Extension  {

    //=================================================================================================================
    panel      :any = null;
    subToolbar :any = null;
    //=================================================================================================================

    public constructor(viewer:any, options:any) {
        super(viewer, options);
        Autodesk.Viewing.Extension.call(this, viewer, options);
    }

    //===================================================================================================================

    public load() : boolean {

        if(this.viewer.toolbar)
        {
            // Toolbar is already available, create the UI
            this.createUI();
        }
        else 
        {
            // Toolbar hasn't been created yet, wait until we get notification of its creation
        }
        return true;
    }

    //===================================================================================================================

    public onToolbarCreated() : void {

        this.createUI();
    }
    
    //===================================================================================================================

    private createUI() : void {

        if (this.subToolbar == null)
        {
            // button to show the docking panel
            const actionToolbarButton = new Autodesk.Viewing.UI.Button('showChartExtensionPanel');

            actionToolbarButton.addClass('chartExtensionToolbarButton');
            actionToolbarButton.setToolTip('Charts');
        
            actionToolbarButton.onClick = (e) => {  this.onChartToolbarButtonClicked(e);  }
        
            // SubToolbar
            this.subToolbar = new Autodesk.Viewing.UI.ControlGroup('ChartExtensionToolbar');
                    
            this.subToolbar.addControl(actionToolbarButton);
            this.viewer.toolbar.addControl(this.subToolbar);
        }
    } 

    //===================================================================================================================

    public unload() : boolean {

        if (this.subToolbar != null)
            this.viewer.toolbar.removeControl(this.subToolbar);
        return true;
    }

    //===================================================================================================================

    private onChartToolbarButtonClicked(e:any) : void {

        if (this.panel == null) 
        {
            this.panel = new ChartExtensionPanel(this.viewer, this.viewer.container, "ChartExtensionPanel", "Charts", this.options);
        }

        this.panel.setVisible(!this.panel.isVisible());
    }

    //===================================================================================================================

    public static loadExtension(viewer:any)
    {
        const init_options = {
    
            PANEL_INITIAL_POS:    "30,90,384,582",       // initial x1Pos,y1Pos,Width,Height of the panel
        }
        viewer.loadExtension('Hilti.ChartExtension',  {  init_options  });
    }  
    
    //===================================================================================================================
}