import {ExtensionsNames,Hilti_SearchMode, ExtensionsError,ExtensionInfoLevel,ExtensionUtil,ExtensionsInfo} from '../utils/utils'
import  DbInitExtensionPanel  from './DbInitExtensionPanel';
import  DbInitGUIPanel  from './components/DbInitGUIPanel';
import { Client, UpsertAssemblyDto } from '../../../client';
import ViewerCore from '../utils/ViewerCore';
import Config from '../../../Config'

export default class DbInitExtensionBusinessLogic 
{
    //=================================================================================================================
    protected _isDBInitialized:  boolean        = false;
    protected _viewer:           any            = null;
    private   _dispValueSeparator:string  ="/";
    private   _modelIdent = null;
    private   _parentPanel = null;
    //=================================================================================================================

    protected p_options = {

        INFO_LEVEL:           ExtensionInfoLevel.DETAILED,
        HILTI_SEARCH_MODE:    Hilti_SearchMode.LOCATION_CODE,
        POST_ASSEMBLIES_URL:  null,
        GET_ASSEMBLIES_URL:   null,
        errCode:              ExtensionsError.NO_ERROR
    }

    //=================================================================================================================

    constructor(parent:DbInitExtensionPanel,viewer:any, options:any)
    {
        this._viewer              = viewer;
        this._parentPanel         = parent;

    //    this.getModelIdentification = this.getModelIdentification.bind(this)

        if (ExtensionUtil.isValidContent(options))
        {
            if (ExtensionUtil.isContentNotNull(options.init_options.HILTI_SEARCH_MODE))
                this.p_options.HILTI_SEARCH_MODE = options.init_options.HILTI_SEARCH_MODE;

            if (ExtensionUtil.isContentNotNull(options.init_options.POST_ASSEMBLIES_URL))    
                this.p_options.POST_ASSEMBLIES_URL = options.init_options.POST_ASSEMBLIES_URL;

            if (ExtensionUtil.isContentNotNull(options.init_options.GET_ASSEMBLIES_URL))
                this.p_options.GET_ASSEMBLIES_URL = options.init_options.GET_ASSEMBLIES_URL;

            if (ExtensionUtil.isContentNotNull(options.init_options.INFO_LEVEL))    
                this.p_options.INFO_LEVEL = options.init_options.INFO_LEVEL;

            if (ExtensionUtil.isContentNotNull(options.init_options.HILTI_DISPVALUE_SEPARATOR))
                this._dispValueSeparator = options.init_options.HILTI_DISPVALUE_SEPARATOR;

        }
        if (this.p_options.POST_ASSEMBLIES_URL === null || this.p_options.POST_ASSEMBLIES_URL === undefined)
        {
            alert(ExtensionUtil.getErrorText(ExtensionsError.ERROR_BASE_URL_UNDEF));
        }        
    }

    //=================================================================================================================

    public getModelIdent() : string
    {
        return this._modelIdent;
    }

    //======================================================================================================================

    public saveModelIdentWithAssemblyCodes(modelIdent:string,assemblyCodes:string[],guiPanel:DbInitGUIPanel) : void 
    {
        guiPanel.startLongLastingProcess();

        this.doSaveModelIdentWithAssemblyCodes2(modelIdent, assemblyCodes).then(() => {

            let buttonText = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_INIT_DB);
            let infoText   = ExtensionUtil.getErrorText(ExtensionsError.NO_ERROR_INITDB_OK);

            this._isDBInitialized = true;

            // we have to change Button text init -> reinit..
            buttonText = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_RESET_INIT_DB);
            infoText += ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_DBREINIT_WARNING);
            
            //==============================
            guiPanel.finishLongLastingProcess(infoText, buttonText);

            let info  = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_ASSEMBLIES_SAVE_RECORDS_OK);
            const count = assemblyCodes.length;
            info   = info.replace("%", count.toString() );

            guiPanel.showWarning(info);

            // what we just have saved to db.. has to be the new content in the grid !!!
            guiPanel.doFetchData(modelIdent,false,true); 
            //=============================
        })
        .catch((error) => { 

            const detailedErrText =  "[  " + error + "  ]";
            let errInfo = ExtensionUtil.getErrorText(ExtensionsError.ERROR_FETCH_DATA_CAPTION);
            if (this.p_options.INFO_LEVEL === ExtensionInfoLevel.DETAILED)
                errInfo += "    " +  detailedErrText;
            errInfo += ExtensionUtil.getErrorText(ExtensionsError.ERROR_FETCH_DATA_INFO);
            guiPanel.finishLongLastingProcess( errInfo, null);
        });
    }

    //======================================================================================================================

    public getModelIdentWithAssemblyCodes(bInitDatabase:boolean,guiPanel:DbInitGUIPanel) : void 
    {
        this.p_options.errCode = ExtensionsError.NO_ERROR;
        let errInfoCode        = ExtensionsError.NO_ERROR; 

        ViewerCore.getModelIdentification(this._viewer.model,false).then((modelIdent:string) => { 
        
            if ( this._modelIdent !== null && this._modelIdent !== modelIdent )
            {
                const info = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_INITDB_PROJECT_CONFLICT);
                guiPanel.showWarning(info);
                guiPanel.showInitButton(false);
                guiPanel.showExportButton(false);
                return;
            }

            ViewerCore.getAssembliesCodes(this._viewer.model,this.p_options.HILTI_SEARCH_MODE,false,this._dispValueSeparator).then((assemblyCodes:string[]) => { 
         
                if (!ExtensionUtil.isValidContent(modelIdent)) 
                {       
                    errInfoCode = ExtensionsError.ERROR_INVALID_MODELIDENTIFICATION;  // should not be null or undefined or empty-array
                }
                else
                {
                    if (!ExtensionUtil.isValidContent(assemblyCodes)) {                // should not be null or undefined or empty-array

                        errInfoCode = ExtensionsError.ERROR_INVALID_ASSEMBLIECODES;  
                    }
                }

                if (errInfoCode === ExtensionsError.NO_ERROR)
                {
                    this._modelIdent = modelIdent;
                    guiPanel.showInitButton(true);

                    const s1          = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_ASSEMBLIES_EXTRACTED_1);
                    const assembInfo  = "<strong>" + assemblyCodes.length + "</strong>" + " " + s1;
                    const projectInfo = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_PROJECT) + " " +  "<strong>" + modelIdent + "</strong>";

                    guiPanel.setProjectInfo(projectInfo,assembInfo);

                    if (this._isDBInitialized)
                        guiPanel.setErrorInfo(ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_DBREINIT_WARNING));

                    if (bInitDatabase)
                    {
                        this.saveModelIdentWithAssemblyCodes(modelIdent,assemblyCodes,guiPanel); 
                    }
                    else
                    {
                        //  current viewermodel/project has valid modelIdent and has assemblyCodes
                        //  the user just opened the extension ( no initDB !!!) and did not used init-db yet
                        //  now we check the db if there are already data for the given model-ident
                        //  if yes, we show thse data in the datagrid
                        guiPanel.doFetchData(this._modelIdent,true,true);   //processLoadData(true);  
                        guiPanel.setErrorInfo(ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_DBREINIT_WARNING));
                    }
                }
                else
                {
                    const info = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_INITDB_PROJECT_WRONG_TYPE);
                    //guiPanel.setErrorInfo( info  );
                    guiPanel.showWarning(info);
                    guiPanel.showInitButton(false);
                    guiPanel.showExportButton(false);
                }
            });
        });
    }

    //======================================================================================================================

    private async doSaveModelIdentWithAssemblyCodes2(modelIdent:string,assemblyCodes:string[]) 
    {
        const data = [];
        
        const states: string = Config.States !== undefined && Config.States !== null ? Config.States : "PreAssembled";
        
        const assemblyDtoArray:UpsertAssemblyDto[] = [];

        assemblyCodes.forEach((assemblyCode) => {
            console.log(assemblyCode);
            var assembly = {
                identification:         assemblyCode,
                modelIdentification:    modelIdent,
                state:                  states,
            };
            data.push(assembly);

            assemblyDtoArray.push( assembly as UpsertAssemblyDto );

        });

        const apiClient:Client = this._parentPanel.getApiClient();

        await apiClient.upsertMany(assemblyDtoArray).then( result =>  {

            if (result != null)
            {
              
            }

        })
        .catch((error) => { 
       
            //alert("err-2323");
            //alert( "Fatal Error-22: " + errInfo ); 
            //let info = ExtensionUtil.getErrorText(ExtensionsError.ERROR_CONNECT_WEB_SERVER);
            //this.setState( {showAlert: true, alertType:  SeverityType.ERROR,  alertDuration: 8000,  alertMessage: info });
        });
    }

    //======================================================================================================================
} 
