import { RecentActorsOutlined } from '@material-ui/icons';
import { ExtensionUtil, NavigationType, PLAYER_ExtensionText } from '../utils/utils';


export interface BasketDataStruct
{
    DBID         :number,
    ItemNumber   :string;
    Product      :string;
}


export default class BasketGUIPanel
{

    //=================================================================================================================
    public _BasketItemsGrid=null;
    //=================================================================================================================

    constructor()  
    {
       
    }
    //=================================================================================================================

    public removeSelectedRows() : number
    {
        let retval         = 0;
        let selectionCount = 0;

        if ( (selectionCount = this.getBasketSelectionCount())  > 0 )
        {
            const selectedRows = this._BasketItemsGrid.getSelectedRows(); 

            if (selectedRows !== null && selectedRows.length > 0)
            {
                if (selectionCount == 1)
                {
                    let   rowToSelect = null;
                    const rowToDelete = selectedRows[0];

                    rowToSelect = rowToDelete.getNextRow();

                    if (rowToSelect == false)
                    {
                        rowToSelect = rowToDelete.getPrevRow();
                    }
                    this._BasketItemsGrid.deleteRow( rowToDelete.getData().DBID);

                    if (rowToSelect != false)
                    {
                        this._BasketItemsGrid.selectRow( rowToSelect.getData().DBID);

                        retval = rowToSelect.getData().DBID;
                    }
                }
                else
                {
                    for (let currSelectedRow of selectedRows)
                    {
                        this._BasketItemsGrid.deleteRow( currSelectedRow.getData().DBID);
                    }
                    retval = -1;
                }
            }
        }
        return retval;
    }

    //=================================================================================================================

    public getBasketSelectionCount()
    {
        let count = 0;
        const selectedData = this._BasketItemsGrid.getSelectedRows(); 
        if (selectedData !== null)
        {
            count = selectedData.length;
        }
        return count;
    }

    //=================================================================================================================

    public getSelectedRows()
    {
        return this._BasketItemsGrid.getSelectedRows();         
    }

    //==================================================================================================================

    public addItem(newItem:BasketDataStruct,counter:number) : boolean
    {
        const allRows     = this._BasketItemsGrid.getRows('active'); //true);
        let index:number  = 0;
        let bExists       = false;
        let bShowInfo     = false;

        if (ExtensionUtil.isValidContent( allRows ))
        {
            for ( let currRow of allRows)
            {
                if (allRows[index++].getData().ItemNumber == newItem.ItemNumber)
                {
                    bExists = true;
                    break;
                }      
            }
        }
        if (bExists)
        {
            if (counter==0)
            {
                //const  infoText  = ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_ADD_ITEM_NUMBER_ALREADY_EXIST);
                //const  newInfo   = infoText.replace("%", newItem.ItemNumber.toString() );
                //alert( newInfo );  
                
                bShowInfo = true;
            }
        }
        else
        {
            if (this._BasketItemsGrid !== null && newItem !== null)
            {
                this._BasketItemsGrid.addRow( newItem );
                this._BasketItemsGrid.deselectRow();               // delete all selected rows  
                this._BasketItemsGrid.selectRow(newItem.DBID);   // only selected new added
             }
        }
        return bShowInfo;
    }

    //==================================================================================================================

    public getBasketRowCount()
    {
        return   (this._BasketItemsGrid != null)  ?  this._BasketItemsGrid.getDataCount() : 0;         
    }

    //==================================================================================================================

    public doNavigate( naviType:NavigationType, jumpStep:number ) : number
    {
        let dbid_to_select = 0;

        const allRows      = this._BasketItemsGrid.getRows('active'); //true);
        const rowCount     = allRows.length;

        if (rowCount > 0)
        {
            let canSelect      = true;
            const selectedRows = this._BasketItemsGrid.getSelectedRows();
            let cursel_dbId    = 0;


            if (selectedRows !== null)
            {
                if (selectedRows.length == 1)
                {
                    cursel_dbId = selectedRows[0].getData().DBID;
                }
                else
                {
                    canSelect = false;  // more as one row is sleected
                }
            }
            if ( canSelect )
            {
                let rowIndex = 0;

                switch(naviType as NavigationType)
                {
                    case NavigationType.FIRST:
                        dbid_to_select = allRows[0].getData().DBID; 
                        break;

                    case NavigationType.LAST:
                        dbid_to_select = allRows[rowCount-1].getData().DBID;  
                        break;

                    case NavigationType.NEXT:                            
                    case NavigationType.PREV:

                        for (let currRow of allRows)
                        {
                            if (currRow.getData().DBID == cursel_dbId)
                            {
                                let row_to_select = 0;                                    

                                if (naviType == NavigationType.PREV)
                                    row_to_select = ( (rowIndex - 1) >= 0) ? rowIndex-1 : rowCount-1;
                                else    
                                    row_to_select = ( (rowIndex + 1) < rowCount) ? rowIndex+1 : 0;

                                dbid_to_select      = allRows[ row_to_select ].getData().DBID; 
                                break;
                            }
                            ++rowIndex;
                        }
                        break;
                        
                    case NavigationType.JUMP_FORWARD:
                    case NavigationType.JUMP_BACKWARD:

                        for (let currRow of allRows)
                        {
                            if (currRow.getData().DBID == cursel_dbId)
                            {
                                let row_to_select = 0;  
                                if (naviType == NavigationType.JUMP_BACKWARD)
                                    row_to_select = ( (rowIndex - jumpStep) >= 0) ? rowIndex-jumpStep : rowCount-1;
                                else    
                                    row_to_select = ( (rowIndex + jumpStep) < rowCount) ? rowIndex+jumpStep : 0;

                                dbid_to_select = allRows[ row_to_select ].getData().DBID; 
                                break;
                            }
                            ++rowIndex;
                        }
                        break;

                        break;
                        
                    default:
                        break;    

                }
                if (cursel_dbId)    // deselect current selected
                {
                    this._BasketItemsGrid.deselectRow( cursel_dbId );
                    //   this.props.parentPanel.basketSelectionChanged( cursel_dbId, null);  // deselect 
                }

                if (dbid_to_select > 0)    
                {
                    this._BasketItemsGrid.selectRow(  dbid_to_select );  // select new row 
                 //   this.onBasketSelectionChanged(dbid_to_select,null); // select
                }
            }
        }
        return dbid_to_select;
    }

    //==================================================================================================================

    public selectRowByViewerSelection(currentViewerSelection:number[])
    {
        // try to find selected item-number in the basket-list
        // if only 1 dbid is selected in the model
        // if only 1 row is selected in the basket
      
        if ( currentViewerSelection.length > 0) 
        {
           // if (this.getBasketValidSelectionCount() <= 1)
            {
                if (ExtensionUtil.isValidContent( currentViewerSelection ))
                {
                    const first_dbId_in_selection = currentViewerSelection[0];

                    if (first_dbId_in_selection > 0)
                    {
                      //  this._basketSelectedItems.clear();

                        this._BasketItemsGrid.deselectRow(); // ..if you leave the argument blank you will deselect all rows.

                        this._BasketItemsGrid.selectRow( first_dbId_in_selection );
                    }
                }
            }
        }
        else
        {
            if ( currentViewerSelection.length == 0) 
            {
                const selectedData = this._BasketItemsGrid.getSelectedRows(); //get array of currently selected data.

                if (selectedData !== null)
                {
                    if (selectedData.length > 0)
                    {
                        this._BasketItemsGrid.deselectRow(); // ..if you leave the argument blank you will deselect all rows.
                    }
                }    
            }
        }
    }

    //==================================================================================================================

}