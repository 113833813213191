import Config from "../../../Config";
import "./css/ProductExplorerExtension.css";
import ProductExplorerExtensionPanel from "./ProductExplorerExtensionPanel";
import "@fortawesome/fontawesome-free/css/all.min.css";

export class ProductExplorerExtension extends Autodesk.Viewing.Extension {
  //=================================================================================================================

  private _panel: any = null;
  private _subToolbar: any = null;
  private _options: any = null;
  private _viewer: any = null;
  private _allItems = null;
  private _extensionName = "Product Explorer";

  //=================================================================================================================

  public constructor(viewer: any, options: any) {
    super(viewer, options);
    this._options = options;
    this._viewer = viewer;
    this.onObjectTreeCreated = this.onObjectTreeCreated.bind(this);
  }

  //===================================================================================================================

  public load(): boolean {
    if (this.viewer.toolbar) {
      // Toolbar is already available, create the UI
      this._viewer.addEventListener(
        Autodesk.Viewing.SELECTION_CHANGED_EVENT,
        this.onObjectSelectionChanged
      );
      this._viewer.addEventListener(
        Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
        this.onObjectTreeCreated
      );
      this.createUI();
    }
    return true;
  }

  //===================================================================================================================

  private createUI(): void {
    if (this._subToolbar == null) {
      // button to show the docking panel
      const actionToolbarButton = new Autodesk.Viewing.UI.Button(
        "ProductExplorerButton"
      );
      const iconSpan = document.createElement('span');
      actionToolbarButton.setToolTip(this._extensionName);
      iconSpan.className = 'fa-solid fa-cube';
      iconSpan.style.fontSize = '24px'; // Legen Sie die Größe des Icons entsprechend Ihren Anforderungen fest.
      actionToolbarButton.icon.style.display = 'flex';
      actionToolbarButton.icon.style.alignItems = 'center';
      actionToolbarButton.icon.style.justifyContent = 'center';
      actionToolbarButton.icon.appendChild(iconSpan);

      actionToolbarButton.onClick = (e) => {
        this.onToolbarButtonClicked(e);
      };

      this._subToolbar = new Autodesk.Viewing.UI.ControlGroup(
        "ProductExplorerExtensionToolbar"
      );

      this._subToolbar.addControl(actionToolbarButton);
      this.viewer.toolbar.addControl(this._subToolbar);
    }
  }
  
  

  //===================================================================================================================

  private onToolbarButtonClicked(e: any): void {
    if (this._panel == null) {
      this._panel = new ProductExplorerExtensionPanel(
        this.viewer,
        this.viewer.container,
        "ProductExplorerExtensionPanel",
        this._extensionName,
        this.options
      );
      //==============================================================================
    }

    this._panel.setVisible(!this._panel.isVisible()); // toogle (show/hide) docking panel
  }

  public onToolbarCreated(): void {
    this.createUI();
  }
  //===================================================================================================================

  public onObjectTreeCreated(event: any): void {}

  //==================================================================================================================

  public onObjectSelectionChanged = (event: any) => {};

  //=================================================================================================================

  public static loadExtension(viewer: any, modelURN: string) {
    const baseUrl: string | undefined = Config.KimUrl;
    const init_options = {
      PANEL_INITIAL_POS:
        window.innerWidth / 8 +
        ",10," +
        window.innerWidth * 0.75 +
        "," +
        window.innerHeight * 0.8, // initial x1Pos,y1Pos,Width,Height of the panel1
      WRITE_LOG_FILE: 1,
      BASE_URL: baseUrl, // web-server base-url for ( Axios )
      // MODEL_URN: modelURN,
    };

    viewer.loadExtension("Hilti.ProductExplorerExtension", { init_options });
  }
}
