
import "../utils/GuiComponents/base.css";
import './css/PlayerExtension.css';
import '../global_css/switch.css';

import * as React from 'react';
import { ExtensionsError, ExtensionUtil, NavigationType, PLAYER_ExtensionText } from '../utils/utils';
import Tabulator from 'tabulator-tables'; 
import {  createMuiTheme, FormControl, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, Slider, Snackbar, TextField, ThemeProvider, Typography } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircleSpinner } from '../utils/GuiComponents/CircleSpinner';
import ReactDOM from 'react-dom';
import { HtmlUtils } from '../utils/HtmlUtils';
import { Alert } from '@material-ui/lab';
import  {SeverityType,VerticalOrigin,HorizontalOrigin, DialogUtil, g_darkThemeDialog } from "../utils/GuiComponents/ModalDialogs/BaseSnackbar";
import SpinnerCore from '../utils/GuiComponents/SpinnerCore';


const muiTheme100 = createMuiTheme({
    
    overrides:
    {
        MuiSlider: 
        {
            root:
            {
                color: '#52af77',
                height: 8,
            },
            thumb:
            {
                height: 24,
                width: 24,
                color: "#C1C1C1",
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
                '&:focus, &:hover, &$active': { boxShadow: 'inherit', },
            },    
            track: 
            {
                color:        '#9E9E9E',
                height:       8,
                borderRadius: 4,
            },
            rail:
            {
                color:        '#9E9E9E',
                height:       8,
                borderRadius: 4,
            },
            active: 
            {
                color:        "#D2051E",
            },
            valueLabel: { left: 'calc(-50% + 4px)', },
        }
    }
});
  

const themeLabel01 = createMuiTheme({
    typography: {
      fontSize: 8,
      htmlFontSize: 10,
    },
});


const darkTheme = createMuiTheme({

    palette: 
    {
        type: 'dark',
  
        primary: 
        {
           main: '#FFFFFF',
        },
    },
});



interface PlayerGUIPanelProps 
{
    readonly parentPanel;
}

interface PlayerGUIPanelState
{
    stepAnimSpeed:number;
    rotAnimSpeed:number;
    canBasketNavigate:boolean;
    isBasketVisible:boolean;
    canBasketRemove:boolean;
    canBasketAdd:boolean;
    isolationON:boolean;
    randomON:boolean;
    autoZoomON:boolean;
    getDataFromPropertySearch:boolean;
    itemNumbers:string[];
    loadingItemNumbers:boolean;


    showAlert:      boolean;
    alertMessage:   string;
    alertVOrigin:   VerticalOrigin;
    alertHOrigin:   HorizontalOrigin;
    alertType:      SeverityType;
    alertTransition: any;
    alertDuration:number;
}

export default class PlayerGUIPanel extends React.Component<PlayerGUIPanelProps,PlayerGUIPanelState>
{
    //=================================================================================================================
    private static readonly VALUE_STOP_ANIMATION:number=0;
    _searchItemNumber:string ="";
    //=================================================================================================================
    private   _spinner:SpinnerCore = null;
    //=================================================================================================================

    constructor(prop:any)
    {
        super(prop);  
      
        this.state = { isolationON: false, randomON: false, 
                       autoZoomON: false, getDataFromPropertySearch: true, 
                       canBasketAdd: false, canBasketRemove: false, canBasketNavigate:false, isBasketVisible: false,
                       rotAnimSpeed:0, stepAnimSpeed:0 , itemNumbers: [], loadingItemNumbers:true,
                       showAlert: false, alertMessage: "", alertVOrigin: VerticalOrigin.TOP, alertHOrigin: HorizontalOrigin.CENTER,
                       alertType:  SeverityType.INFO, alertTransition: undefined, alertDuration: 6000};

        this._searchItemNumber = "";

        this.props.parentPanel.setGUIPanel(this);

        this._spinner = new SpinnerCore();

    }  

    //=================================================================================================================

    componentDidMount = () => {

        this.onGuiLoaded();

    }

    //=================================================================================================================
  
    componentWillUnmount = () => { 

       // window.removeEventListener('load', this.onGuiLoaded);  
    }

    //=================================================================================================================

    private onGuiLoaded = () => {

        let buttonElement = document.getElementById("pd_start-button");
        if (buttonElement != null)
            buttonElement.setAttribute("title", ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_START));

        buttonElement = document.getElementById("pd_next-button");
        if (buttonElement != null)
            buttonElement.setAttribute("title", ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_NEXT));

        buttonElement = document.getElementById("pd_prev-button");
        if (buttonElement != null)
            buttonElement.setAttribute("title", ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_PREV));

        buttonElement = document.getElementById("pd_add-basketitem-button");
        if (buttonElement != null)
            buttonElement.setAttribute("title", ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_ADD_BASKET_ITEM));

        buttonElement = document.getElementById("pd_remove-basketitem-button");
        if (buttonElement != null)
            buttonElement.setAttribute("title", ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_REMOVE_BASKET_ITEM));

        buttonElement = document.getElementById("pd_show-basket-button");
        if (buttonElement != null)
            buttonElement.setAttribute("title", ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_SHOW_BASKET_LIST));
    
        buttonElement = document.getElementById("pd_zoom-selected-button");
        if (buttonElement != null)
            buttonElement.setAttribute("title", ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_ZOOM_SELECTED_DBID));
    
        buttonElement = document.getElementById("pd_jump-next-button");
        if (buttonElement != null)
        {
            let infoText    = ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_JUMP_FORWARD);
            const stepWidth = this.props.parentPanel.getJumpStep();
            const newInfo   = infoText.replace("%", stepWidth.toString() );
            buttonElement.setAttribute("title", newInfo);
        }
       
        buttonElement = document.getElementById("pd_jump-prev-button");
        if (buttonElement != null)
        {
            let infoText    = ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_JUMP_BACKWARD);
            const stepWidth = this.props.parentPanel.getJumpStep();
            const newInfo   = infoText.replace("%", stepWidth.toString() );
            buttonElement.setAttribute("title", newInfo);
        }
    
        buttonElement = document.getElementById("pd_last-button");
        if (buttonElement != null)
            buttonElement.setAttribute("title", ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_GOTO_LAST));
                
        buttonElement = document.getElementById("pd_first-button");
        if (buttonElement != null)
            buttonElement.setAttribute("title", ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_GOTO_FIRST));
   

        this._spinner.init("id-player-spinnerContainer","id-player-spinnerPanel","id-player-spinnerCaptionPanel");
        this._spinner.initColor( this.props.parentPanel.getSpinnerColor() );
        this._spinner.initSize ( this.props.parentPanel.getSpinnerSize() );

        this.setupBasketItemsGrid( [] );
    }

    //=================================================================================================================

    private onNext = (event:any) =>  {

        this.preDoNavigate( NavigationType.NEXT, event);
    }

    //=================================================================================================================

    private onPrev = (event:any)  => {
    
        this.preDoNavigate( NavigationType.PREV, event);
    }

    //=================================================================================================================

    private onJumpForward = (event:any) =>  {

        this.preDoNavigate( NavigationType.JUMP_FORWARD, event);
    }

    //=================================================================================================================

    private onJumpBackward = (event:any) =>  {

        this.preDoNavigate( NavigationType.JUMP_BACKWARD, event);
    }

    //=================================================================================================================

    private onFirst = (event:any)  => {

        this.preDoNavigate( NavigationType.FIRST, event);
    }

    //=================================================================================================================

    private onLast = (event:any)  => {

        this.preDoNavigate( NavigationType.LAST, event);
    }

    //=================================================================================================================

    private preDoNavigate(navigationType:NavigationType,event:any)
    {
        if (this.props.parentPanel.isBasketVisible())
        {
            this.props.parentPanel.doNavigate(navigationType);
        }
        else
        {
            if (this.state.stepAnimSpeed !== PlayerGUIPanel.VALUE_STOP_ANIMATION)
            {
                this.stopStepAnim();     
                // step-anim has been stopped, set slider position
                this.setState({ stepAnimSpeed: PlayerGUIPanel.VALUE_STOP_ANIMATION });
            }

            if (navigationType == NavigationType.LAST)
                this.props.parentPanel.onGotoLast(event);

            else if (navigationType == NavigationType.FIRST)
                this.props.parentPanel.onGotoFirst(event);

            else if (navigationType == NavigationType.JUMP_FORWARD)
                this.props.parentPanel.onJumpForward(event);

            else if (navigationType == NavigationType.JUMP_BACKWARD)
                this.props.parentPanel.onJumpBackward(event);

            else if (navigationType == NavigationType.NEXT)
                this.props.parentPanel.onNext(event);

            else if (navigationType == NavigationType.PREV)
                this.props.parentPanel.onPrev(event);
        }
    }

    //=================================================================================================================
 
    private onShowBasketPanel =  (event:any)  => {

        this.props.parentPanel.basketShow();

        this.setState({ isBasketVisible:  this.props.parentPanel.isBasketVisible() });    

        this.init(null);
       
    };

    //=================================================================================================================

    public onClosePanel()
    {

    }

    //=================================================================================================================

    public init(uniqueListOfItemNumbers:string[])
    {
        if (uniqueListOfItemNumbers !== null)
        {
            this.setState({ itemNumbers:  uniqueListOfItemNumbers, loadingItemNumbers: false });     
        }

        if (this.props.parentPanel.isBasketVisible())
        {
            this.setBasketButtonsState();
        }
        else
        {
            const canNavigate = (this.props.parentPanel.getDbIdsArrayLength() > 0 ) ? true : false; 
            
            this.setState({ canBasketAdd:    false });
            this.setState({ canBasketRemove: false });
            this.setState({ canBasketNavigate: canNavigate });     
        }
    }

    //=================================================================================================================

    private onBasketAdd =  (event:any)  => {

        let itemNumbers_already_exist:string = null;
        if ( (itemNumbers_already_exist = this.props.parentPanel.basketAddSelectedObjects()) != null)
        {
            let  info  = ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_ADD_ITEM_NUMBER_ALREADY_EXIST);
                 info  = info.replace("%", itemNumbers_already_exist );
            this.setState( {showAlert: true, alertType:  SeverityType.WARNING, alertMessage: info });

        }
        this.setBasketButtonsState();
    }

    //=================================================================================================================

    private onBasketSelectionChanged(dbid, item_number)
    {
        this.props.parentPanel.basketSelectionChanged(dbid,item_number);
        this.setBasketButtonsState();
    }

    //=================================================================================================================

    private onBasketRemove =  (event:any)  => {

        this.props.parentPanel.basketRemoveSelectedRows(); 

        this.setBasketButtonsState();
    }

    //==================================================================================================================

    private setBasketButtonsState()
    {
        const canAdd             = this.props.parentPanel.hasViewerValidSelection();
        const basketRows         = this.props.parentPanel.getBasketRowCount();
        const basketRowsSelected = this.props.parentPanel.getBasketSelectionCount();  
        
       // we only can remove if at least 1 row is selected
        const canRemove = (basketRowsSelected > 0) ? true : false;
        
        // we only can Navigate if ONLY 1 row is Selected (!!!) and Basket list has more as 1 row !!
        // we cannot navigate of more as 1 row is selected !!
        const canNavigate = ( basketRows > 1 && basketRowsSelected == 1) ? true : false;

        this.setState({ canBasketAdd:      canAdd });     
        this.setState({ canBasketRemove:   canRemove });            
        this.setState({ canBasketNavigate: canNavigate });      

    }

    //==================================================================================================================

    public onViewerSelectionChanged = () => { 

        if (this.props.parentPanel.isBasketVisible())
        {
            this.setState({ canBasketAdd: this.props.parentPanel.hasViewerValidSelection() });
        }
    }

    //==================================================================================================================

    public onViewerModelRemoved = () => { 

        alert(ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_MODEL_HAS_BEEN_REMOVED));

        this.setupBasketItemsGrid( null );
    }

    //=================================================================================================================

    private onRandomChanged = (e) => {

        this.setState({ randomON: !this.state.randomON });
    }

    //=================================================================================================================

    private onStepAnimSpeedChanged = (event: any, newValue: number) => {

        this.setState({ stepAnimSpeed: newValue });

        this.props.parentPanel.stopStepAnimation();     
        
        this.props.parentPanel.stopRotateAnimation();
    };
       
    //=================================================================================================================
       
    private onStepAnimSpeedCommited = (event: any, newValue: number) => {
   
        this.setState({ stepAnimSpeed: newValue });

        this.props.parentPanel.startStepAnimation(newValue);

        this.props.parentPanel.startRotateAnimation(this.state.rotAnimSpeed);
    };

    //=================================================================================================================

    private stopStepAnim() : void
    {
        this.props.parentPanel.pauseStepAnimation();
    }

    //=================================================================================================================

    private onRotationAnimSpeedChanged = (event: any, newValue: number) => {

        this.setState({ rotAnimSpeed: newValue });

        this.props.parentPanel.stopRotateAnimation();  
      
        this.props.parentPanel.stopStepAnimation();
    };
       
    //=================================================================================================================
       
    private onRotationAnimSpeedCommited = (event: any, newValue: number) => {

        this.setState({ rotAnimSpeed: newValue });

        this.props.parentPanel.startRotateAnimation(newValue);

        this.props.parentPanel.startStepAnimation( this.state.stepAnimSpeed);
    };

    //=================================================================================================================

    private onItemNumberFieldChanged = (event, currentSelectedOptions) => {

        if (this.props.parentPanel.isBasketVisible())
        {
            this._searchItemNumber = currentSelectedOptions;
        }    
    };

    //=================================================================================================================

    private onSearchItemNumber = (event) => {

        if (this._searchItemNumber.length > 0)
        {
            this.props.parentPanel.searchItemNumberInModel( this._searchItemNumber );
        }
    };

    //=================================================================================================================

    private onProcessDownload = (event) => {
      
        this.props.parentPanel.basketProcessDownload(this._spinner);

    };

    //=================================================================================================================

    public onAutoZoomChanged = (e) => {

        this.props.parentPanel.onSetIsolationMode(  !this.state.autoZoomON );

        this.setState({ autoZoomON: !this.state.autoZoomON });

        // zoom is a toogle button
        const buttonElement = document.getElementById("pd_zoom-selected-button");
        if (buttonElement != null)
        {
            if ( this.state.autoZoomON )
            {
                buttonElement.setAttribute("class", "btn btn-secondary btn-circle btn-smx");
            }
            else
            {
                buttonElement.setAttribute("class", "btn btn-secondary btn-circle btn-smx-active");
            }
        }
    }

    //=================================================================================================================

    public getIsolationState()
    {
        return this.state.isolationON;
    }
    public getRandomState()
    {
        return this.state.randomON;
    }    

    public getAutoZoomState()
    {
        return this.state.autoZoomON;
    }  
   
    public getDataFromPropertySearch() 
    {
        return  this.state.getDataFromPropertySearch;
    }

    //=================================================================================================================

    public setIsolation(mode:boolean)
    {
        this.setState({ isolationON: mode });
    }
 
    //=================================================================================================================

    public setRandom(mode:boolean)
    {
        this.setState({ randomON: mode });
    }
 
    //=================================================================================================================

    public setLabelText(info:string)
    {
        const labelElement = document.getElementById("pd_infoLabel");
        if (labelElement != null)
            labelElement.innerHTML = info;
    }
     
    private onCloseAlertMessage = () => {

        this.setState( { showAlert: false } );
    };

    public showAlert(alertText:string,type:SeverityType)
    {
        this.setState( {showAlert: true, alertType:  type, alertMessage: alertText });
    }

    //=================================================================================================================

    public setupBasketItemsGrid(data) 
    {
        const that = this;

        this.props.parentPanel._basketPanel._BasketItemsGrid = new Tabulator("#basketItemsTable", {
                        
            index:              "DBID", 
            data:               data,                   //assign data to table
            //layout:           "fitColumns",           //fit columns to width of table (optional)
            layout:             "fitDataStretch",
            height:             "438px",
            selectable:         true,                 // ( true ) ..you can select as many as you want
            selectableRangeMode:"click",
         //   rowContextMenu:      headerMenu, //add context menu to rows
    
            columns: [
                { title: "dbid",         field: "DBID",       headerSort: true,   width:60  , visible: false},                
                { title: "Item",         field: "ItemNumber", headerSort: true,   width:80 },                
                { title: "Product",      field: "Product",    headerSort: true,   width:240 , tooltip: true}
            ],
            rowSelectionChanged: function (data, rows) {},
            rowClick: function (e, row) {
                that.onBasketSelectionChanged(row.getData().DBID,row.getData().ItemNumber);
            },       
        });  
    }

    //=================================================================================================================

private renderGUI() 
{
    let useSeverity:any         = DialogUtil.getSeverity(this.state.alertType);
    let useHorizontalOrigin:any = DialogUtil.getHorizontalOrigin(this.state.alertHOrigin);
    let useVerticalOrigin:any   = DialogUtil.getVerticalOrigin(this.state.alertVOrigin);

  return ( 

    <div>
  
        <div>     
            <ThemeProvider theme={g_darkThemeDialog}>
            <Snackbar 
                open                = { this.state.showAlert } 
                TransitionComponent = { this.state.alertTransition }
                autoHideDuration    = { this.state.alertDuration } 
                anchorOrigin={{
                    vertical:   useVerticalOrigin,
                    horizontal: useHorizontalOrigin,
                }}   
                onClose = { this.onCloseAlertMessage }>

                <Alert 
                    onClose  = { this.onCloseAlertMessage } 
                    severity = { useSeverity }>
                    { this.state.alertMessage }
                </Alert>

            </Snackbar>
            </ThemeProvider>
        </div>


        <div className=" container-fluid m-0" id="id-player-mainpanel01" >

            <div className="p-0 m-0 pt-2 row">
                <div className="col-xs-6-left">
                    <button onClick={ (e) => this.onShowBasketPanel(e)}       id="pd_show-basket-button"    type="button"  className="btn btn-secondary btn-circle btn-smx"><i className="fa fa-shopping-cart fa-sm" aria-hidden="true"></i></button>
                    <button onClick={ (e) => this.onAutoZoomChanged(e)}  disabled={this.state.isBasketVisible} id="pd_zoom-selected-button"  type="button"  className="btn btn-secondary btn-circle btn-smx"><i className="fas fa-search fa-sm" aria-hidden="true"></i></button>
                </div>
            </div>

            <div className="p-0 m-0 row">      
                <div className="col-xs-6-left">            
                    <button onClick={ (e) => this.onBasketAdd(e)}    disabled={!this.state.canBasketAdd}     id="pd_add-basketitem-button"     type="button"  className="btn  btn-secondary btn-circle btn-smx"><i className="fas fa-plus"   aria-hidden="true"></i></button>
                    <button onClick={ (e) => this.onBasketRemove(e)} disabled={!this.state.canBasketRemove}  id="pd_remove-basketitem-button"  type="button"  className="btn  btn-secondary btn-circle btn-smx"><i className="fas fa-minus"  aria-hidden="true"></i></button>
                </div>
            </div>    


            <div className="p-0 m-0 row">      
                <div className="col-xs-6-left">            
                    <button onClick={ (e) => this.onNext(e)}  id="pd_next-button"  disabled={!this.state.canBasketNavigate}   type="button"   className="btn btn-secondary btn-circle btn-smx"><i className="fas fa-caret-right fa-lg"   aria-hidden="true"></i></button>
                    <button onClick={ (e) => this.onPrev(e)}  id="pd_prev-button"  disabled={!this.state.canBasketNavigate}   type="button"   className="btn btn-secondary btn-circle btn-smx"><i className="fas fa-caret-left  fa-lg"   aria-hidden="true"></i></button>
                </div>
            </div>

            <div className="p-0 m-0 row">      
                <div className="col-xs-6-left">
                    <button  onClick={ (e) => this.onJumpForward(e)}   id="pd_jump-next-button" disabled={!this.state.canBasketNavigate}   type="button"   className="btn btn-secondary btn-circle btn-smx"><i className="fas fa-forward fa-sm"  aria-hidden="true"></i></button>          	
                    <button  onClick={ (e) => this.onJumpBackward(e)}  id="pd_jump-prev-button" disabled={!this.state.canBasketNavigate}   type="button"   className="btn btn-secondary btn-circle btn-smx"><i className="fas fa-backward fa-sm" aria-hidden="true"></i></button>
                </div>
            </div>


            <div className="p-0 m-0 row">      
                <div className="col-xs-6-left">
                    <button  onClick={ (e) => this.onLast(e)}   id="pd_last-button"    type="button"  disabled={!this.state.canBasketNavigate}   className="btn btn-secondary btn-circle btn-smx"><i className="fa fa-step-forward fa-sm"  aria-hidden="true"></i></button>          	
                    <button  onClick={ (e) => this.onFirst(e)}  id="pd_first-button"   type="button"  disabled={!this.state.canBasketNavigate}   className="btn btn-secondary btn-circle btn-smx"><i className="fa fa-step-backward fa-sm" aria-hidden="true"></i></button>
                </div>
            </div>
            
        </div>


        <div className="container-fluid" id="id-player-mainpanel02">
             
            <div className="myspacer2"></div>

            <div style={{ width: 120 , margin: 2 }}>

                <label htmlFor="id-label-slide-show" className="slider-label">slide-show</label>

                <ThemeProvider theme={muiTheme100}>
                <Slider 
                    disabled={this.state.isBasketVisible}
                    id="pd-step-anim-slider" 
                    value={this.state.stepAnimSpeed} 
                    onChange={this.onStepAnimSpeedChanged}  
                    onChangeCommitted={this.onStepAnimSpeedCommited} 
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={-100}
                    max={100}
                    aria-labelledby="continuous-slider" 
                />
                </ThemeProvider>                
            </div>

            <div style={{ width: 120 , margin: 6 }}>

                <label htmlFor="id-label-rotation" className="slider-label">rotation</label>
              
                <Slider 
                    disabled={this.state.isBasketVisible}
                    id="pd-rotate-anim-slider" 
                    value={this.state.rotAnimSpeed} 
                    onChange={this.onRotationAnimSpeedChanged}  
                    onChangeCommitted={this.onRotationAnimSpeedCommited}
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={-100}
                    max={100}                     
                    aria-labelledby="continuous-slider"
                />
            </div>


            <div className="myspacer2"></div>
 
            <div className="row m-2 p-0">
                <input  onClick={ (e) => this.onRandomChanged(e)}  type="checkbox"  checked={this.state.randomON} 
                 disabled={this.state.isBasketVisible}  id="id-name--3" name="set-name" className="switch-input"/>
                <label htmlFor="id-name--3" className="switch-label">random</label>
            </div>

            <div className="myspacer3"></div>
       

            <div className="row m-2 p-0">
                <div className="col-1-left">   
                    <label className="infolabel" id="pd_infoLabel" >0 / 0</label>
                </div>
            </div>
               
      </div>
 

            <div className="container-fluid m-0 p-0 grid-container" id="id-player-basketpanel01">

                <div className="p-0 m-0 pt-3 ml-3 mr-2 row">
                    <div className="col" id="basketItemsTable"/>
                </div>


                <div className="p-0 m-0 pt-3 ml-3 mr-2 row">

                    <div className="input-group mb-0">

                            <ThemeProvider theme={darkTheme}>

                            <Autocomplete
                                clearOnEscape
                                // openOnFocus
                                id="pd_item-number-search"
                                options = { this.state.itemNumbers}    
                                // disableCloseOnSelect
                                onChange={this.onItemNumberFieldChanged}
                                // getOptionLabel={(option) => option.label}
                                // onInputChange={this.onInputChange} 
                                style={{ width: 200 }}

                                renderInput={params => (

                                    <TextField
                                        {...params}
                                            type        ="number" 
                                            variant     ="outlined"
                                            size        ="small" 
                                            label       ="item-number"
                                            placeholder ="search item-number"
                                        />
                                    )}
                            />
                            </ThemeProvider>


                        <div className="col-1 ml-2 p-0 ">
                            <button 
                                type="button" 
                                onClick={ (e) => this.onSearchItemNumber(e)} 
                                className="btn btn-secondary  search-item-number-button">
                                    <i className="fa fa-search" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div className="col-1 ml-4 p-0">   
                            <button 
                                type="button"  
                                onClick={ (e) => this.onProcessDownload(e)} 
                                disabled={!this.state.canBasketRemove}  
                                className="btn  btn-secondary download-button2"> 
                                <i className="fas fa-cloud-download-alt" aria-hidden="true"></i>
                            </button>

                        </div>

                    </div>

                </div>

            </div>

    </div>

  );

}

//=================================================================================================================

  render() {

    let content_01 = this.renderGUI();

      return (

        <div>
        
            <div>
                {content_01}
            </div>

            <div className="container m-0 p-0  flex-column d-flex" id="id-player-spinnerContainer">
                <div id="id-player-spinnerCaptionPanel" />
                <div className="col-xs-6 col-centered-3" id="id-player-spinnerPanel" />
            </div>

        </div>
      );
  }

 //=================================================================================================================

}



