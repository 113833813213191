import "../../utils/GuiComponents/base.css";
import "../css/DbInitExtension.css";
import * as React from "react";
//import ReactDOM from 'react-dom';
import {
  ExtensionsError,
  ExtensionsInfo,
  ExtensionUtil,
} from "../../utils/utils";
import SpinnerCore from "../../utils/GuiComponents/SpinnerCore";
import Tabulator from "tabulator-tables"; //import Tabulator library
import { Snackbar, Typography } from "@material-ui/core";
import {
  SeverityType,
  VerticalOrigin,
  HorizontalOrigin,
  DialogUtil,
  g_darkThemeDialog,
} from "../../utils/GuiComponents/ModalDialogs/BaseSnackbar";
import { Alert } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { HtmlUtils } from "../../utils/HtmlUtils";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface GridDataRow {
  identification: string;
  updatedAt: string;
  stateDisplay: string;
}

interface DbInitGUIPanelProps {
  parentPanel;
}

interface DbInitGUIPanelState {
  showAlert: boolean;
  alertMessage: string;
  alertVOrigin: VerticalOrigin;
  alertHOrigin: HorizontalOrigin;
  alertType: SeverityType;
  alertTransition: any;
  alertDuration: number;

  showMessageBox: boolean;
  dlgTitle: string;
  dlgContentText: string;
}

export default class DbInitGUIPanel extends React.Component<
  DbInitGUIPanelProps,
  DbInitGUIPanelState
> {
  //============================================================
  private _guiElementesInitialized = false;
  private _spinner: SpinnerCore = null;
  private _dataTableAssemblies = null;
  private _GridData: GridDataRow[] = [];
  private _csvFileDelimiter = ";";
  private _csvFileDelimiterName = "semicolon";
  //=============================================================

  constructor(prop: any) {
    super(prop);

    this.state = {
      showAlert: false,
      alertMessage: "",
      alertHOrigin: HorizontalOrigin.CENTER,
      alertVOrigin: VerticalOrigin.TOP,
      alertType: SeverityType.INFO,
      alertTransition: undefined,
      alertDuration: 6000,
      showMessageBox: false,
      dlgTitle: "",
      dlgContentText: "",
    };

    this.props.parentPanel.setGUIPanel(this);

    this._spinner = new SpinnerCore();
  }

  //=================================================================================================================

  componentDidMount = () => {
    this.onGuiLoaded();
  };

  //=================================================================================================================

  componentWillUnmount = () => {};

  //=================================================================================================================

  private onGuiLoaded() {
    if (!this._guiElementesInitialized) {
      this._guiElementesInitialized = true;

      this._spinner.init(
        "id-spinnerContainer-dbinit",
        "id-spinnerPanel-dbinit",
        "id-spinnerCaptionPanel-dbinit"
      );
      this._spinner.initColor(this.props.parentPanel.getSpinnerColor());
      this._spinner.initSize(this.props.parentPanel.getSpinnerSize());

      this.clearGUI();

      const object = this.props.parentPanel.getDbInitBusinessLogicObect();

      object.getModelIdentWithAssemblyCodes(false, this);
    }
  }

  //=================================================================================================================

  public startLongLastingProcess() {
    HtmlUtils.showHtmlElement("dbexportWrapper", false);
    HtmlUtils.showHtmlElement("dbinitWrapper", false);

    this._spinner.show(true);
  }

  //=================================================================================================================

  public finishLongLastingProcess(stateInfo: string, buttonText: string) {
    this._spinner.show(false);

    HtmlUtils.showHtmlElement("dbexportWrapper", true);

    HtmlUtils.showHtmlElement("dbinitWrapper", true);

    if (stateInfo) HtmlUtils.setText("stateInfoArea", stateInfo);

    if (buttonText) HtmlUtils.setText("btn-init", buttonText);
  }

  //=================================================================================================================

  public showInitButton(bShow: boolean) {
    HtmlUtils.showHtmlElement("btn-init", bShow);
  }

  //=================================================================================================================

  public showExportButton(bShow: boolean) {
    HtmlUtils.showHtmlElement("btn-export", bShow);
  }

  //======================================================================================================================

  public setProjectInfo(projectIdentInfo: string, assembliesInfo: string) {
    HtmlUtils.setText("projectIdentification", projectIdentInfo);
    HtmlUtils.setText("assembliesInfoArea", assembliesInfo);
  }

  //======================================================================================================================

  public setErrorInfo(errInfo: string) {
    HtmlUtils.setText("stateInfoArea", errInfo);
  }

  //======================================================================================================================

  private clearGUI(): void {
    HtmlUtils.setText("stateInfoArea", "");
    HtmlUtils.setText("projectIdentification", "");
    HtmlUtils.setText("assembliesInfoArea", "");
  }

  //======================================================================================================================

  private fillAssemblyTable(data, groupStartOpenFlag) {
    this._dataTableAssemblies = new Tabulator("#dbinit-assembliesTable", {
      //dataTreeStartExpanded:true, //start with an expanded tree
      data: data, //assign data to table
      // layout: "fitDataStretch",
      layout: "fitColumns",   //fit columns to width of table (optional)
      height: "418px",
      selectable: 1,
      groupStartOpen: groupStartOpenFlag,
      groupToggleElement: "header",
      groupBy: function (data) {
        return data.stateDisplay;
      },
      columns: [
        {
          title: "Identification",
          field: "identification",
          headerSort: true,
          width:"50%", widthGrow: 1, widthShrink: 1, 
          headerFilter: true,
        },
        {
          title: "Updated At",
          field: "updatedAt",
          headerSort: true,
          width:"25%", widthGrow: 1, widthShrink: 1 ,
        },
        { title: "State", field: "stateDisplay", headerSort: true,  width:"25%", widthGrow: 1, widthShrink: 1  },
      ],
    });
  }

  //======================================================================================================================

  public doFetchData(
    modelIdent: string,
    setInfo: boolean,
    expandGridNodes: boolean
  ): void {
    const paramUrl =
      this.props.parentPanel.getAssembliesUrl() +
      "?" +
      new URLSearchParams({ model: modelIdent });

    this.startLongLastingProcess();

    this._GridData = [];

    let fetchPromise = fetch(paramUrl);

    fetchPromise
      .then((response) => {
        return response.json();
      })
      .then((assemblies) => {
        let errorInfo: string = null;
        let initButtonText: string = null;

        if (!ExtensionUtil.isValidContent(assemblies)) {
          errorInfo = ExtensionUtil.getErrorText(
            ExtensionsError.ERROR_FETCH_DATA_EMPTY_02
          );
          errorInfo = errorInfo.replace("%", modelIdent);
        }
        if (ExtensionUtil.isValidContent(assemblies.status)) {
          if (assemblies.status === 400) {
            errorInfo =
              ExtensionUtil.getErrorText(
                ExtensionsError.ERROR_FETCH_DATA_CAPTION
              ) +
              ExtensionUtil.getErrorText(ExtensionsError.ERROR_FETCH_DATA_INFO);
          }
        }
        if (errorInfo === null) {
          this.showExportButton(true);

          if (setInfo) {
            const s1 = ExtensionUtil.getInfoText(
              ExtensionsInfo.CAPTION_ASSEMBLIES_EXTRACTED_FROM_DB
            );
            const assembInfo =
              "<strong>" + assemblies.length + "</strong>" + " " + s1;
            const projectInfo =
              ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_PROJECT) +
              " " +
              "<strong>" +
              modelIdent +
              "</strong>";
            this.setProjectInfo(projectInfo, assembInfo);
          }

          // do some changes, if we have top show data in a more specific way

          for (let currAssembly of assemblies) {
            // "2020-06-03T00:00:00" remove time in this string
            const index = currAssembly.updatedAt.indexOf("T");
            if (index > 0) {
              currAssembly.updatedAt = currAssembly.updatedAt.substring(
                0,
                index
              );
            }
            const gridRow: GridDataRow = {
              identification: currAssembly.identification,
              updatedAt: currAssembly.updatedAt,
              stateDisplay: currAssembly.state.display,
            };
            this._GridData.push(gridRow);
          }
          this.fillAssemblyTable(this._GridData, expandGridNodes);

          this.setErrorInfo(
            ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_DBREINIT_WARNING)
          );

          initButtonText = ExtensionUtil.getInfoText(
            ExtensionsInfo.CAPTION_RESET_INIT_DB
          );
        } else {
          this.showExportButton(false);
        }

        this.finishLongLastingProcess(errorInfo, initButtonText);
      })
      .catch((error) => {
        const errInfo =
          ExtensionUtil.getErrorText(ExtensionsError.ERROR_FETCH_DATA_CAPTION) +
          ExtensionUtil.getErrorText(ExtensionsError.ERROR_FETCH_DATA_INFO);

        this.finishLongLastingProcess(errInfo, null);

        this.showInitButton(false);
        this.showExportButton(false);

      });
  }

  //======================================================================================================================

  public exportGridDataToCSV() {
    let bCheck = false;

    if (this._dataTableAssemblies != null) {
      const rows = this._dataTableAssemblies.getRows("active");

      if (ExtensionUtil.isValidContent(rows)) {
        // before we export the grid-data, we have to expand all nodes (groups )
        // because , collapsed nodes will not be exported !!!

        this._dataTableAssemblies.destroy();
        this.fillAssemblyTable(this._GridData, true); // we need to have an grid with all groups expanded

        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        const formatedDate = yyyy + mm + dd;

        const uniquefileName =
          this.props.parentPanel.getModelIdent() + "-" + formatedDate + ".csv";

        this._dataTableAssemblies.download("csv", uniquefileName, {
          delimiter: this._csvFileDelimiter,
        });

        bCheck = true;

        const infoRecordsSaved = ExtensionUtil.getInfoText(
          ExtensionsInfo.RECORDS_SUCCESSFUL_SAVED
        );
        const infoDelimiter = ExtensionUtil.getInfoText(
          ExtensionsInfo.CAPTION_DELIMITER
        );
        const infoFileName = ExtensionUtil.getInfoText(
          ExtensionsInfo.CAPTION_FILENAME
        );
        const info =
          " " +
          rows.length +
          " " +
          infoRecordsSaved +
          "\n" +
          infoFileName +
          ":  " +
          uniquefileName +
          "\n" +
          infoDelimiter +
          ":  " +
          this._csvFileDelimiterName;

        this.setState({
          showAlert: true,
          alertType: SeverityType.SUCCESS,
          alertDuration: 6000,
          alertMessage: info,
        });
      }
    }
    if (!bCheck) {
      const info = ExtensionUtil.getInfoText(ExtensionsInfo.NO_DATA_FOUND);
      this.setState({
        showAlert: true,
        alertType: SeverityType.SUCCESS,
        alertDuration: 6000,
        alertMessage: info,
      });
    }
  }

  //=================================================================================================================

  private onInitDatabase(e: any): void {
    if (this._GridData.length == 0) {
      // first time init db ..this is a init and NOT reinit !!!
      this.clearGUI();
      const obj = this.props.parentPanel.getDbInitBusinessLogicObect();
      obj.getModelIdentWithAssemblyCodes(true, this);
    } else {
      // ..this is a reinit !!! ..show dialog before reinit
      //  this.clearGUI();
      const title = ExtensionUtil.getInfoText(
        ExtensionsInfo.CAPTION_RESET_INIT_DB
      );
      const info = ExtensionUtil.getInfoText(
        ExtensionsInfo.CAPTION_DBREINIT_WARNING
      );
      this.setState({
        showMessageBox: true,
        dlgTitle: title,
        dlgContentText: info,
      });
    }
  }

  //=================================================================================================================

  private onExportGridData(e: any): void {
    this.exportGridDataToCSV();
  }

  //=================================================================================================================

  public showWarning(info: string) {
    this.setState({
      showAlert: true,
      alertType: SeverityType.WARNING,
      alertMessage: info,
    });
  }

  private onCloseAlertMessage = () => {
    this.setState({ showAlert: false });
  };

  private onCancelMessageBox = () => {
    this.setState({ showMessageBox: false });
  };

  private onOKMessageBox = () => {
    this.setState({ showMessageBox: false });
    this.clearGUI();
    const obj = this.props.parentPanel.getDbInitBusinessLogicObect();
    obj.getModelIdentWithAssemblyCodes(true, this);
  };

  //=================================================================================================================

  render() {
    let useSeverity: any = DialogUtil.getSeverity(this.state.alertType);
    let useHorizontalOrigin: any = DialogUtil.getHorizontalOrigin(
      this.state.alertHOrigin
    );
    let useVerticalOrigin: any = DialogUtil.getVerticalOrigin(
      this.state.alertVOrigin
    );

    return (
      <div className=" container-fluid m-0" id="id-dbinit-mainpanel01">
        <div>
          <div
            className="container m-0 p-0  flex-column d-flex"
            id="id-spinnerContainer-dbinit"
          >
            <div id="id-spinnerCaptionPanel-dbinit" />
            <div className="col-xs-6" id="id-spinnerPanel-dbinit" />
          </div>
        </div>

        <div>
          <ThemeProvider theme={g_darkThemeDialog}>
            <Snackbar
              open={this.state.showAlert}
              TransitionComponent={this.state.alertTransition}
              autoHideDuration={this.state.alertDuration}
              anchorOrigin={{
                vertical: useVerticalOrigin,
                horizontal: useHorizontalOrigin,
              }}
              onClose={this.onCloseAlertMessage}
            >
              <Alert onClose={this.onCloseAlertMessage} severity={useSeverity}>
                {this.state.alertMessage}
              </Alert>
            </Snackbar>
          </ThemeProvider>
        </div>

        <div>
          <ThemeProvider theme={g_darkThemeDialog}>
            <Dialog
              open={this.state.showMessageBox}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.onCancelMessageBox}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dlg-title-bim">
                {this.state.dlgTitle}
              </DialogTitle>

              <DialogContent>
                <DialogContentText id="alert-dlg-bim">
                  <Typography display="block" noWrap>
                    {this.state.dlgContentText}
                  </Typography>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={this.onOKMessageBox} color="primary">
                  Reinit
                </Button>
                <Button onClick={this.onCancelMessageBox} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </ThemeProvider>
        </div>

        <div id="dbexportWrapper">
          <div className="xxx">
            <div className="row mb-2 ml-1 mt-1">
              <div id="projectIdentification"></div>
            </div>
          </div>

          <div className="xxx">
            <div className="row mb-2 ml-1">
              <div id="assembliesInfoArea"></div>
            </div>
          </div>

          <div className="dbinit-grid-container">
            <div className="dbinit-grid-content">
              <div id="dbinit-assembliesTable" />
            </div>
          </div>

          <div className="row mt-3 p-0">
            <button
              className="btn btn-secondary dbexport-ini-btn"
              id="btn-export"
              type="button"
              onClick={(e) => this.onExportGridData(e)}
            >
              Export to CSV
            </button>
          </div>
        </div>

        <div className="dbinit-some-space"></div>

        <div id="dbinitWrapper">
          <div className="state-infoarea-container">
            <div className="row mb-2 ml-1 mr-4">
              <div id="stateInfoArea"></div>
            </div>
          </div>

          <div className="row mt-0 p-0">
            <button
              className="btn btn-danger dbinit-ini-btn"
              id="btn-init"
              type="button"
              onClick={(e) => this.onInitDatabase(e)}
            >
              Init Database
            </button>
          </div>
        </div>
      </div>
    );
  }

  //=================================================================================================================
}
