
export class HtmlUtils
{

    //======================================================================================================================
 
    public static showHtmlElement(idElement:string, show:boolean, centerElement:boolean=false)
    {
        const htmlElement = document.getElementById(  idElement  );
        if (htmlElement)
        {
            if (show)
            {
                if (centerElement)
                {
                    htmlElement.style.display='flex';
                    htmlElement.style.justifyContent='center';
                }
                if (htmlElement.style.display === 'none')
                    htmlElement.style.display = "block";           // show
            }
            else
            {
                htmlElement.style.display = "none";                // hide
            }
        }
    }

    //======================================================================================================================

    public static setText(idElement:string, captionText:string)
    {
        const htmlElement = document.getElementById(  idElement  );
        if (htmlElement)
        {
            htmlElement.innerHTML  = captionText;
        }
    }
  
    //=================================================================================================================

    public static setButtonTextAndTooltip(idButton:string,captionText:string,tooltipInfo:string) : void
    {
        const butElement = document.getElementById(  idButton );
        if (butElement)
        {
            if (captionText != null)
                butElement.innerHTML = captionText;
            butElement.setAttribute("title", tooltipInfo);
        }
    }

    //=================================================================================================================

    public static enableButton(idButton:string,bShow:boolean) : void
    {
        const htmlElement = document.getElementById(  idButton  );
        if (htmlElement)
        {
            if (bShow)
            {
                document.getElementById( idButton ).style.visibility = "visible"; 
            }
            else
            {
                document.getElementById( idButton ).style.visibility = "hidden"; 
            }
        }
    }

    //=================================================================================================================

    public static saveTextFile(fileName:string,content:string)
    {
        let   blobText  = content;
        const blob      = new Blob([ content ], { type: 'text/plain' });
        const anchor    = document.createElement('a');
        anchor.download = fileName;
        anchor.href     = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
        anchor.click();

    }

    //=================================================================================================================

}