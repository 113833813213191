import './css/DbInitExtension.css';
import  DbInitExtensionPanel  from "./DbInitExtensionPanel";
import Config from '../../../Config';
import { ExtensionsError, ExtensionUtil } from '../utils/utils';
import ViewerToolkit from '../utils/ViewerToolkit';

/*=====================================================================================================================
DESCRIPTION:
=======================================================================================================================
DbInitExtension

The DbInitExtension stores all Assemblies ( with their current state ) of the current Model
with its unique Model-Identification-Number.
The Assemblies will be searched by HILTI-LOCATION-CODE or by AVEVA-DESCRPTION

Parameter:

PANEL_INITIAL_POS:      initial x1 and y1 ( left-upper corner ) width amnd height of the ExtensionPanel
default-Value:          x1=60  y1=90  width=360pixel  height=360pixel

SPINNER_COLOR:          color of spinner bars
default-Value:          #FF0000 ( red )

SPINNER_SIZE:           size of spinner
default-Value:          60          30 small, 60 middle, 90 big

INFO_LEVEL:             1 = less detailed Error or warning-messages,  2 = more detailed Error or warning-messages
default-Value:          2    

HILTI_SEARCH_MODE:     defines how Assemblies will be searched. 1 = LOCATION_CODE, 2 = AVEVA_DESCRRIPTION  
default-Value:          1    

 POST_ASSEMBLIES_URL:  web-server url for POST transaction
 default-Value:        null  

===================================================================================================*/

export class DbInitExtension extends Autodesk.Viewing.Extension {

    //=================================================================================================================
    _panel      :any = null;
    _subToolbar :any = null;
    _viewer     :any = null;
    _options    :any = null;
    //=================================================================================================================
    _title:string    = "DbInit & DbExport";
    _viewerObjectTreeCreated:boolean = false;
    //=================================================================================================================

    public constructor(viewer:any, options:any) {

        super(viewer, options);

        this._viewer  = viewer;
        this._options = options;

        this._viewer.addEventListener( Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT ,   this.onViewerObjectTreeCreated.bind(this));
    }

    //===================================================================================================================

    public load() : boolean {

        if (this._viewer.toolbar) // Toolbar is already available, create the UI
        {
            this.createUI();
        }
        return true;
    }

    //===================================================================================================================

    public onToolbarCreated() : void {

        this.createUI();
    }

    //===================================================================================================================

    createUI() : void {

        if (this._subToolbar === null)
        {
            //prepare to execute the button action
            const actionToolbarButton = new Autodesk.Viewing.UI.Button(  this._title );
            actionToolbarButton.addClass('dbInitExtensionToolbarButton');
            actionToolbarButton.setToolTip( this._title );
            actionToolbarButton.onClick = () => {  this.onToolbarButtonClicked();  }

            this._subToolbar = new Autodesk.Viewing.UI.ControlGroup("DbInitExtensionToolbar");
            this._subToolbar.addControl(actionToolbarButton);
            this._viewer.toolbar.addControl(this._subToolbar);
        }
    } 

    //===================================================================================================================

    unload() : boolean {

        this._viewer.removeEventListener( Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.onViewerObjectTreeCreated);
        if (this._subToolbar !== null)
            this._viewer.toolbar.removeControl(this._subToolbar);
        return true;
    }
 
    //===================================================================================================================

    onToolbarButtonClicked() : void {

        if (this._panel == null) 
        {
            if (this._viewerObjectTreeCreated)
            {
                this._panel = new DbInitExtensionPanel(this._viewer, this._viewer.container, 'DbInitExtensionPanel', this._title, this._options);
            }
            else
            {
                alert(ExtensionUtil.getErrorText(ExtensionsError.ERROR_VIEWER_INSTANCE_TREE));
            }
        }                
        if (this._panel !== null) 
        {
            this._panel.setVisible(!this._panel.isVisible());
        }
    }

    //===================================================================================================================

    public static loadExtension(viewer:any){

        const spinnerColor : string | undefined = Config.SpinnerColor;
        const spinnerSize  : string | undefined = Config.SpinnerSize;
        const spinnerSizeValue                  = parseInt(spinnerSize,10);
        const baseUrl                           = Config.BaseUrl;
        const separator:string | undefined      = Config.DBInitExtDispValueSeparator;
        const postURL:string | undefined        = Config.DBInitExtPostAssembliesUrl;
        const getURL:string | undefined         = Config.LoadAssembExtGetAssemblyUrl;
        const hiltiSearchMode:string | undefined = Config.HiltiSearchMode;
        const hiltiSearchModeValue = parseInt(hiltiSearchMode,10);

        const init_options = {
          PANEL_INITIAL_POS:          "12,6,590,790",       //  initial x1Pos,y1Pos,Width,Height of the panel
          SPINNER_COLOR:              spinnerColor,         // color of spinner bars
          SPINNER_SIZE:               spinnerSizeValue,     // size of spinner 90 -> very big 60 -> middle size  30 -> small
          INFO_LEVEL:                 2,                    // 1 = more common Error or warning-text,  2= more detailed Error or Warning Text
          HILTI_SEARCH_MODE:          hiltiSearchModeValue, // LOCATION_CODE = 1,AVEVA_DESCRRIPTION = 2
          HILTI_DISPVALUE_SEPARATOR:  separator,
          POST_ASSEMBLIES_URL:        postURL,          // web-server url for POST transaction
          GET_ASSEMBLIES_URL:         getURL,           // web-server url for GET transaction
          BASE_URL:                   baseUrl           // web-server base-url for ( Axios ) 
        }
          viewer.loadExtension("Hilti.DbInitExtension", {  init_options  });
    } 

    //===================================================================================================================
   
    private onViewerObjectTreeCreated() : void 
    {
        this._viewerObjectTreeCreated  = ViewerToolkit.isViewerObjectTreeCreated(this._viewer);
    }
  
    //===================================================================================================================

}