import './css/SearchExtension.css';
import {ExtensionUtil} from '../utils/utils'
import Config from '../../../Config';

/*=====================================================================================================================
DESCRIPTION:
=======================================================================================================================
SearchExtension

This Extension does NOT have any GUI-Elements.
By clicking on the toolbar-button for this extension, the program will invoke a search-procedure and 
create a selection. The objects of this created selection will shown in a darker color while all other
objects of the model, will be shown in a more bright and more transparent color.

Paramter:

SEARCH_PROPERTY_NAME:      the name of the property, which will be used in the search-procedure to create an object-selection
default-value:             Manufacturer  

SEARCH_DISPLAY_VALUE:      the value of the property, which will be used in the search-procedure to create an object-selection 
default-value:             Hilti
=====================================================================================================================*/

export class SearchExtension extends Autodesk.Viewing.Extension {

  _searchPropertyName: string = "Manufacturer";
  _searchDisplayValue:string  = "Hilti";

  constructor(viewer:any, options:any) {
    super(viewer, options);
    Autodesk.Viewing.Extension.call(this, viewer, options);

    if (ExtensionUtil.isContentNotNull(options))
    { 
        if (ExtensionUtil.isContentNotNull(options.init_options))
        {
            if (ExtensionUtil.isValidContent(options.init_options.SEARCH_DISPLAY_VALUE))      
              this._searchDisplayValue = options.init_options.SEARCH_DISPLAY_VALUE;      

            if (ExtensionUtil.isValidContent(options.init_options.SEARCH_PROPERTY_NAME)  )     
              this._searchPropertyName = options.init_options.SEARCH_PROPERTY_NAME;      
        }
      }
    }     

  load() : boolean {
    if(this.viewer.toolbar){
      // Toolbar is already available, create the UI
      this.createUI();
    }
    else {
      // Toolbar hasn't been created yet, wait until we get notification of its creation
    }
    return true;
  }

  onToolbarCreated(){
    this.createUI();
  }

  createUI() : void {
    var _this = this

    //prepare to execute the button action
    
    const buttonTitle = "Search for elements with " + 
                        "propertyname: " + this._searchPropertyName +
                        "  propertyvalue: " + this._searchDisplayValue;

    var hiltiSearchToolbarButton = new Autodesk.Viewing.UI.Button( buttonTitle );

    hiltiSearchToolbarButton.onClick = (e) => {
   
      this.doSearch( _this._searchPropertyName, _this._searchDisplayValue , (dbIds :number[]) => {  

        console.log("[#4] viewer.isolate gets an array of " + dbIds.length + " ids !!");

        if (ExtensionUtil.isValidContent(dbIds))  // can not be null or array with length 0
        {
            this.viewer.isolate(dbIds);
        }
      });
    };
    
      // HiltiSearchToolbarButton CSS class should be defined on your .css file
      // you may include icons, below is a sample class:

  hiltiSearchToolbarButton.addClass('searchExtensionToolbarButton');
  hiltiSearchToolbarButton.setToolTip('Search for hilti data');

  // SubToolbar

  var subToolbar : Autodesk.Viewing.UI.ControlGroup = (this.viewer.toolbar.getControl("hiltiSearchToolbar") ? 
      this.viewer.toolbar.getControl("hiltiSearchToolbar") as Autodesk.Viewing.UI.ControlGroup :
      new Autodesk.Viewing.UI.ControlGroup('hiltiSearchToolbar'));
  subToolbar.addControl(hiltiSearchToolbarButton);

  this.viewer.toolbar.addControl(subToolbar);

  }

  unload() : boolean {
   // alert("HiltiSearch extension unloaded");
    return true;
  }


  doSearch(propertyName : string, propertyDisplayValue : string, callback : Function)
  {
    console.log("[#1] " + propertyName + " = " + propertyDisplayValue);
 
    this.viewer.search('"' + propertyDisplayValue + '"', (dbIds : number[]) => {  

      console.log("[#2] viewer has found " + dbIds.length + " ids with the desired search-pattern :"  + propertyDisplayValue );

      this.getSubset(dbIds, propertyName, propertyDisplayValue, function (dbIds : number[]) {   

          console.log("[#3] viewer has found " + dbIds.length + " ids with the desired propertyname and search-pattern :" + propertyName + "  " + propertyDisplayValue );

          callback(dbIds);

      })
    }, function (error: any) {
          console.log(error);
    }, []);
  }

  getSubset(dbIds: number[], name: string, displayValue: string, callback: Function) {

    var newDbIds : number[] = [];   

    this.viewer.model.getBulkProperties(dbIds, undefined, function(data) {

        for (var key in data)
        {
            var item = data[key];

            if (item.properties[0].displayValue === displayValue)
            {
                newDbIds.push(item.dbId);
            }
        }
        callback(newDbIds);    // wjx added 21.11.20, this line was neccessary, before this method has returned always an empty-array

    }, function (error) {  console.log(error); } );
    callback(newDbIds);
  }

  //===================================================================================================================

  public static loadExtension(viewer:any)
  {
      const propName:string  | undefined =  Config.SearchExtPropertyName;
      const dispValue:string | undefined =  Config.SearchExtDisplayValue;
  
      const init_options = {   
        SEARCH_PROPERTY_NAME:  propName,         // wjx added 21.11.20 elements of this manujfacturer will be isolated
        SEARCH_DISPLAY_VALUE:  dispValue,        // wjx added 21.11.20 elements of this manujfacturer will be isolated
      }

      viewer.loadExtension('Hilti.SearchExtension', { init_options } ).then( function(externalExtension) {})

  }

  //===================================================================================================================

}
