// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/button-ico.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.unifiExtensionToolbarButton {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); \n    background-size: 24px;\n    background-repeat: no-repeat;\n    background-position: center;\n  }\n \n \n\n#multi-combo {\n\n   width: 560px;\n}\n\n#id-spinnerContainer-unifi\n{\n    align-items:     center;\n    justify-content: center;\n}\n\n\n#id-spinnerCaptionPanel-unifi\n{\n    color:             #a4b4a9;\n    margin-top:       12px;\n    display:          flex;\n    align-items:      center;\n    justify-content:  center;\n}\n\n#id-spinnerPanel-unifi\n{\n  color:  #FF0000;\n  height:           auto;\n  padding:          0px;\n  margin-top:       0px;\n\n  display:          flex;\n  align-items:      center;\n  justify-content: center;\n}\n\n.unifi-grid-container\n{\n    width: 100%;\n    margin-top: 0px;\n    padding-left: 32px;\n    padding-right:32px;    \n    box-sizing: border-box; \n}\n\n.unifi-box-container\n{\n\n    width: 100%;\n    margin-top: 24px;\n    padding-left: 24px;\n    padding-right:24px;    \n    background-color: #FF0000;\n\n    height: 140px;\n    box-sizing: border-box;\n}\n\n\n#id-button-dublicates\n{\n  margin:  0px 0px 0px 4px;               /* top right bottom left */\n}\n\n.btn-export\n{\n  margin:  0px 0px 0px 10px;           \n  background-color: var(--base_button_bkcolor);\n  height:    42px;\n  width:     24px;\n}\n\n.btn-getdata\n{\n  margin:  0px 0px 0px 10px;\n  background-color: var(--base_button_bkcolor);\t\n  height:    42px;\n  width:     24px;\n}\n\n.btn-reset\n{\n  margin:  0px 0px 0px 10px;            \n  background-color: var(--base_button_bkcolor);\t\n  height:    42px;\n  width:     24px;\n}\n\n\n.some-space\n{\n  height: 0px;\n}\n\n\n\n\n\n\n\n", ""]);
// Exports
module.exports = exports;
