// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/button_icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".modelConfiguratorExtensionToolbarButton {\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); \r\n    background-size: 24px;\r\n    background-repeat: no-repeat;\r\n    background-position: center;\r\n}\r\n\r\n/* \r\n#modelconfig-configurationTable {\r\n \r\n  background-color: #353535;\t\r\n\r\n  font-family:  roboto;\r\n  font-size:    16px;\r\n  font-weight:  340;  \r\n} */\r\n\r\n.btn-modelconfig\r\n{\r\n    width:        80px;\r\n    display:      inline-block;\r\n    margin:       5px;       \r\n    background-color:  var(--base_button_bkcolor);\t\r\n}\r\n\r\n\r\n.mconfig-grid-container\r\n{\r\n    width: 100%;\r\n    margin-top:   14px;\r\n    padding-left: 6px;\r\n    padding-right:36px;    \r\n    box-sizing: border-box; \r\n}\r\n\r\n\r\n.mconfig-button-panel\r\n{\r\n    width: 100%;\r\n    margin-top:   8px;\r\n    padding-left: 0px;\r\n    padding-right:24px;    \r\n\r\n    height: 40px;\r\n    box-sizing: border-box;\r\n\r\n    /* margin-left: 12px;\r\n    margin-right:24px;\r\n    background-color: #FF0000;\r\n  \r\n    box-sizing: border-box;\r\n    padding: 12px; */\r\n}\r\n\r\n", ""]);
// Exports
module.exports = exports;
