import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children } : any) => {
  const domain : string | undefined = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId : string | undefined = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  if(!(domain && clientId)){
      throw "Failed loading Environment..."
  }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience = {audience}
        >
            {children}
        </Auth0Provider>
        );
};

export default Auth0ProviderWithHistory;