import { useAuth0 } from '@auth0/auth0-react';
import React from "react";
import { Link } from 'react-router-dom';

const ToForgeViewer = () => {
    const { isAuthenticated } = useAuth0();

    function renderButton(){
        if(isAuthenticated){
            return (
                <Link to="/forgeviewer">
                    <button className="hiltibutton">
                        Forge Viewer
                    </button>
                </Link>
            )
        }

    }

    return (
        <div>
            {renderButton()}
        </div>
    )
}

export default ToForgeViewer