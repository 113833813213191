import {ExtensionUtil} from '../utils/utils'
import $ from 'jquery';
import * as _ from 'underscore';
import './css/PropertyExtension.css';
import Config from '../../../Config';
import { HtmlUtils } from '../utils/HtmlUtils';
import ViewerCore from '../utils/ViewerCore';


/*=====================================================================================================================
DESCRIPTION:
=======================================================================================================================
PropertyExtension

The PropertyExtension extends the Forge-PropertyExtension  by adding some 

Parameter:

 HILTI_FILTER:          can have 1 or many entries....
 default-value:         building name | hilti_delivery_adress  
=====================================================================================================================*/

export class PropertyExtension extends Autodesk.Viewing.Extension {
  private _viewer;
  private _propertyPanel;
  private _mode:string          = "all";  //"hiltiOnly";         // wjx
  private _searchForHiltOnly    = false;               // wjx
  private _hiltiFilter:string[] = [ "building name", "hilti_delivery_adress" ];

  public constructor(viewer: any, options: any) {
    super(viewer, options);
    this._viewer = viewer;

    if (ExtensionUtil.isValidContent(options))
    {
        if (ExtensionUtil.isContentNotNull(options.init_options))
        {
            if (ExtensionUtil.isContentNotNull(options.init_options.HILTI_FILTER))
            {
                this._hiltiFilter = [];
                const filter = options.init_options.HILTI_FILTER;
                this._hiltiFilter = filter.split("|");
            }
        }
    }

    viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, this.onExtensionLoaded);
    viewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreated);
    viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);

    this.onToolbarCreated = this.onToolbarCreated.bind(this);
    this.onExtensionLoaded = this.onExtensionLoaded.bind(this);
  }

  // ------------------------------------------------------------------
  //  Load the extension.
  // ------------------------------------------------------------------
  load(): boolean {
    if (this._viewer.toolbar) {
      // Toolbar is already available, create the UI
      console.log('PropertyExtension -> this.viewer.toolbar', this.viewer.toolbar);
    } else {
      // Toolbar hasn't been created yet, wait until we get notification of its creation
    }
    return true;
  }

  
  // ------------------------------------------------------------------
  //   Toolbar is created.
  // ------------------------------------------------------------------
  onToolbarCreated(event: any): void 
  {
      console.log('PropertyExtension -> event', event);
  }

  // ------------------------------------------------------------------
  //   Viewer selection changed..
  // ------------------------------------------------------------------
  onSelectionChanged = (AGGREGATE_SELECTION_CHANGED_EVENT: string, onSelectionChanged: any) => {
      this.setMode(this._propertyPanel, this._mode);
  };

  // ------------------------------------------------------------------
  //  Check if the extension is loaded.
  //  If so, add additional controls to the UI.
  // ------------------------------------------------------------------
  onExtensionLoaded = (event: { extensionId: string }) => {
    if (event.extensionId !== 'Autodesk.PropertiesManager') return;

    let extension = this._viewer.getExtension('Autodesk.PropertiesManager');
    this._propertyPanel = extension._panel;

    this.buildAdditionalControls(this._propertyPanel);

//    this.setProperties([]);    
    this.setMode(this._propertyPanel,this._mode);   // wjx

  };

  // ------------------------------------------------------------------
  //  Unload the extension. Clean up.
  // ------------------------------------------------------------------
  unload(): boolean 
  {
      if (this._propertyPanel != null) 
      {
          const extension = this._viewer.getExtension('Autodesk.PropertiesManager');
          extension.setDefaultPanel();
          this._propertyPanel = null;
      }
      return true;
  }

  // ------------------------------------------------------------------
  //  Add additional controls to the UI.
  //  Register events for the created controls.
  // ------------------------------------------------------------------
  buildAdditionalControls(panel: { container: { children: any[] } }) {

    var extensionsOwnToolbar = `    <div class="switch-div" id="switch-div"> 
                                        <input type="checkbox" id="id-propext-search-mode" name="propext-search-mode" class="switch-input" checked/>
                                        <label for="id-propext-search-mode" class="switch-label"><span class="toggle--on">All</span> 
                                        <span class="toggle--off">Hilti only</span>
                                        </label>
                                    </div>
                                `;

    let existingChild = panel.container.children[1];
    this.insertBefore(existingChild, extensionsOwnToolbar);

    const space_div = `<div class="switch-div" id="space-div"></div>`;

 //   let existingChild2 = panel.container.children[2];  // wjx
 //   this.insertBefore(existingChild2, space_div);      // wjx

    //============================================

    $('#id-propext-search-mode').on('click', () => {

      // toggle mode
      this._searchForHiltOnly = !this._searchForHiltOnly;

      if (this._searchForHiltOnly)
         this.setMode(panel, 'hiltiOnly');
      else
         this.setMode(panel, 'all');

    });

    //============================================

  }

  //=========================================================================
  //  Set properties. This properties are showed in the property panel.
  //=========================================================================
  setProperties(properties: any[]) 
  {
    let filteredProperties = properties;

    if (this._mode === 'hiltiOnly') 
    {
        filteredProperties = properties.filter((property) => {

            return _.contains(this._hiltiFilter, property.attributeName.toLowerCase());
        });
    }
    this._propertyPanel.setProperties(filteredProperties);
  }

  //===================================================================================================================
  //  Set working mode.
  //  This mode acts a filter.
  //===================================================================================================================
  setMode(panel: any, mode: string)
  {
      this._mode = mode;

      if (this._viewer.getSelection().length > 0) 
      {
       //   HtmlUtils.showHtmlElement("space-div", false);        // wjx
       //   HtmlUtils.showHtmlElement("switch-div",true);        // wjx

          var objSelected = this._viewer.getSelection()[0];

          this._viewer.getProperties(objSelected, (data: any) => {

            this.setProperties(data.properties);

          });
      }
      else 
      {
       //   HtmlUtils.showHtmlElement("switch-div",false);        // wjx
       //   HtmlUtils.showHtmlElement("space-div", true);        // wjx

          if (this._mode === 'hiltiOnly') 
          {
              let instanceTree = this._viewer.model.getData().instanceTree;

              if (ExtensionUtil.isValidContent(instanceTree))
              {
                  const that = this;
                  let rootId       = instanceTree.getRootId();
                  that._viewer.getProperties( rootId, function ( data ) {

                    if (data.properties) 
                    {
                        that.setProperties(data.properties);                
                    }
                });
              }
          }
          else
          {
            panel.showDefaultProperties();
          }
      }
  }

  // ------------------------------------------------------------------
  //  Helper method for html dom.
  // ------------------------------------------------------------------
  insertBefore = (htmlElement: HTMLElement, htmlString: string) => {
    return htmlElement.insertAdjacentHTML('beforebegin', htmlString);
  };

  //===================================================================================================================

  public static loadExtension(viewer:any)
  {
      const init_options = {   HILTI_FILTER:    Config.PropertyExtFilter }

      viewer.loadExtension('Hilti.PropertyExtension',  { init_options  });
  }  

  //===================================================================================================================

}
