import { ModelConfiguratorExtensionPanel } from "./ModelConfiguratorExtensionPanel";
import './css/ModelConfiguratorExtension.css';
import Config from '../../../Config';

export class ModelConfiguratorExtension extends Autodesk.Viewing.Extension {
    
    //=================================================================================================================
    _panel      :any = null;
    _subToolbar :any = null;
    _options    :any = null;
    _viewer     :any = null;
    //=================================================================================================================

    public constructor(viewer:any, options:any) {
        super(viewer, options);

        this._options = options;
        this._viewer = viewer;

    }
    
    //===================================================================================================================

    public load() : boolean {

        if (this.viewer.toolbar)  // Toolbar is already available, create the UI
        {
            this.createUI(); 
        }
        return true;
    }

    //===================================================================================================================

    public onToolbarCreated() : void {

        this.createUI();
    }

    //===================================================================================================================

    private createUI() : void {

        if (this._subToolbar == null)
        {
            // button to show the docking panel
            const actionToolbarButton = new Autodesk.Viewing.UI.Button('modelConfiguratorExtensionPanel');

            actionToolbarButton.addClass('modelConfiguratorExtensionToolbarButton');
            actionToolbarButton.setToolTip('ModelConfigurator');

            actionToolbarButton.onClick = (e) => {  this.onDefaultToolbarButtonClicked(e);  }
            
            this._subToolbar = new Autodesk.Viewing.UI.ControlGroup('ModelConfiguratorExtensionToolbar');
                
            this._subToolbar.addControl(actionToolbarButton);
            this.viewer.toolbar.addControl(this._subToolbar);

        }
    } 

    //===================================================================================================================

    public unload() : boolean 
    {
        this._viewer.toolbar.removeControl(this._subToolbar);
        return true;
    }
 
    //===================================================================================================================

    private onDefaultToolbarButtonClicked(e:any) : void {

        if (this._panel == null) 
        {
            this._panel = new ModelConfiguratorExtensionPanel(this.viewer, this.viewer.container, 'ModelConfiguratorExtensionPanel', 'ModelConfigurator', this.options);
        }                
        this._panel.setVisible(!this._panel.isVisible()); // toogle (show/hide) docking panel
    }

   //==================================================================================================================

    public static loadExtension(viewer:any)
    {
        const baseUrl:string      = Config.BaseUrl;
        const spinnerColor:string = Config.SpinnerColor;
        const spinnerSize:string  = Config.SpinnerSize;
        const spinnerSizeValue    = parseInt(spinnerSize,10);

        const init_options = {   
            PANEL_INITIAL_POS:       "12,14, 1240,668",      // initial x1Pos,y1Pos,Width,Height of the panel
            SPINNER_COLOR:           spinnerColor,           // color of spinner bars 
            SPINNER_SIZE:            spinnerSizeValue,       // spinner size default = 30 
            WRITE_LOG_FILE:           1,                     // 1 => write to console.log  .., 0 => dont write    
            BASE_URL:                 baseUrl,               // web-server base-url for ( Axios ) 
        }
        viewer.loadExtension('Hilti.ModelConfiguratorExtension', { init_options } );
   }  
   
   //==================================================================================================================
}