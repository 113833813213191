import { escapeLeadingUnderscores } from "typescript";
import Config from "../../../Config";

export class FilterTypes {
  public static readonly NONE = 0;
  public static readonly STARTSWITH_INCLUDE = 1;
  public static readonly STARTSWITH_EXCLUDE = 2;
  public static readonly ENDSWITH_INCLUDE = 4;
  public static readonly ENDSWITH_EXCLUDE = 8;
}

export enum NavigationType {
  FIRST = 1,
  LAST = 2,
  NEXT = 3,
  PREV = 4,
  JUMP_FORWARD = 5,
  JUMP_BACKWARD = 6,
}

export enum PLAYER_SelectMode {
  SELECT_BY_ON_MODEL_CLICK = 0,
  SELECT_BY_PROPERTY_NAME_AND_VALUE = 1,
}

export enum PLAYER_ShowMode {
  SHOW_ISOLATE_GHOST_ISOLATE = 0,
  SHOW_ISOLATE_FULL_ISOLATE = 1,
}

export enum AVEA_DescFilter {
  GET_NAMES = 1,
  GET_ELEMENTS = 2,
}

export enum ExtensionInfoLevel {
  COMMON = 1,
  DETAILED = 2,
}

export enum Hilti_SearchMode {
  LOCATION_CODE = 1,
  AVEVA_DESCRRIPTION = 2,
}

export enum ExtensionsError {
  NO_ERROR,
  NO_ERROR_INITDB_OK,
  ERROR_INVALID_ROOTID,
  WARNING_INVALID_MODE,
  ERROR_INVALID_BULKPROPERTIES,
  ERROR_INVALID_MODELIDENTIFICATION,
  ERROR_INVALID_ASSEMBLIECODES,
  ERROR_GLOBAL_INVALID_DATA,
  ERROR_SAVEDATA,
  ERROR_INVALID_HILTI_SEARCH_MODE,
  ERROR_ROOTID_WITHOUT_ELEMENTS,
  ERROR_INVALID_INSTANCETREE,
  ERROR_FETCH_DATA_CAPTION,
  ERROR_FETCH_DATA_INFO,
  ERROR_FETCH_DATA_EMPTY,
  ERROR_FETCH_DATA_EMPTY_02,
  ERROR_LOADMODEL_INVALID_MODELURL,
  ERROR_LOADMODEL_FAILED,
  ERROR_LOADMODEL_NO_DEFAULTMODEL,
  ERROR_LOADMODEL_INVALID_CONTAINER_ELEMENT,
  ERROR_LOADMODEL_INVALID_DOCUMENT_ID,
  ERROR_LOADMODEL_INVALID_ACCESS_TOKEN,
  ERROR_CONNECT_DB_SERVER,
  ERROR_READ_UNIFI_LIB,
  ERROR_READ_UNIFI_LIB_02,
  ERROR_PLAYER_DOWNLOADINFO_ERR,
  ERROR_READ_CONTENT_PARAMETER,
  WARNING_READ_CONTENT_PARAMETER,
  ERROR_CONNECT_WEB_SERVER,
  WARNING_DATA_NOT_COMPLETED,
  ERROR_CONNECT_BIM360,
  WARNING_NO_DATA_FOR_URN,
  ERROR_BASE_URL_UNDEF,
  ERROR_VIEWER_INSTANCE_TREE,
  ERROR_RECORDS_COULD_NOT_BE_SAVED,
  ERROR_READ_ITEM_NUMBER_INFORMATION,
  ERROR_INVALID_BIMCAD_FILE,
  ERROR_CANNOT_FIND_BIMCAD_FILE,
  ERROR_INVALID_PORTFOLIO_FILE,
  ERROR_CANNOT_FIND_PORTFOLIO_FILE,
}

export enum ExtensionsInfo {
  CAPTION_INIT_DB,
  CAPTION_RESET_INIT_DB,
  CAPTION_ASSEMBLIES_EXTRACTED_1,
  CAPTION_ASSEMBLIES_EXTRACTED_FROM_DB,
  CAPTION_PROJECT,
  CAPTION_ASSEMBLIES_EXTRACTED_3,
  CAPTION_DBREINIT_WARNING,
  RECORDS_SUCCESSFUL_SAVED,
  NO_DATA_FOUND,
  NO_CATEGORYDATA_FOUND,
  CAPTION_DELIMITER,
  CAPTION_FILENAME,
  CAPTION_EXPORT_RECORDS,
  CAPTION_GET_UNIFI_LIBCONTENT,
  CAPTION_GET_UNIFI_LIBCONTENT_INFO,
  CAPTION_GET_UNIFI_LIBCONTENT_INFO_2,
  CAPTION_UNIFI_EXPORT_RECORDS_INFO,
  CAPTION_RESET_GRID_COLOUMS_INFO,
  CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_INFO,
  CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_WARNING,
  CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_ERROR,
  CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_OK,
  CAPTION_ITEMNUMBER_CROWLER_GET_RECORD_WARNING,
  CAPTION_ITEMNUMBER_CROWLER_GET_RECORD_WARNING2,
  CAPTION_DATA_SUCCESSFULLY_EXPORTED,
  CAPTION_CONTENT_STORAGE_LIBS_FOUND,
  CAPTION_SOME_WARNINGS_OCCURED,
  CAPTION_SOME_ERRORS_OCCURED,
  CAPTION_WARNING,
  CAPTION_UNIFI_LIBS_FOUND,
  CAPTION_ERROR,
  CAPTION_SUCCESS,
  CAPTION_BIM360_GET_FILENAME,
  CAPTION_COPY_CLIPBOARD_SUCCESSFUL,
  CAPTION_COPY_CLIPBOARD_ERROR,
  CAPTION_INITDB_PROJECT_CONFLICT,
  CAPTION_INITDB_PROJECT_WRONG_TYPE,
  CAPTION_LOADMODEL_SUCCESSFUL,
  CAPTION_MODEL_CONFIG_FOUND,
  CAPTION_ITEMNUMBER_RECORDS_LOADED_OK,
  CAPTION_ITEMNUMBER_RECORDS_SAVED_OK,
  CAPTION_ASSEMBLIES_SAVE_RECORDS_OK,
}

export class ExtensionsNames {
  public static readonly AVEVA = "AVEVA";
  public static readonly HILTI_LOCATION_CODE = "Hilti_location_code";
  public static readonly PROJECT_NUMBER = "Project Number";
  public static readonly DESCRIPTION = "DESCRIPTION";
}

export enum PLAYER_ExtensionText {
  CAPTION_START,
  CAPTION_NEXT,
  CAPTION_PREV,
  CAPTION_PAUSE_ANIM,
  CAPTION_CLEAR_SELECTION,
  CAPTION_ROTATE_ANIM,
  CAPTION_FIRST,
  CAPTION_LAST,
  CAPTION_READ_DATA_FILTER,
  CAPTION_ADD_BASKET_ITEM,
  CAPTION_REMOVE_BASKET_ITEM,
  CAPTION_SHOW_BASKET_LIST,
  CAPTION_ZOOM_SELECTED_DBID,
  CAPTION_JUMP_FORWARD,
  CAPTION_JUMP_BACKWARD,
  CAPTION_GOTO_FIRST,
  CAPTION_GOTO_LAST,
  CAPTION_ADD_ITEM_NUMBER_ALREADY_EXIST,
  CAPTION_MODEL_HAS_BEEN_REMOVED,
  CAPTION_WARNING_REVIT_DOWNLOAD_01,
  CAPTION_SUCCESS_REVIT_DOWNLOAD_01,
  CAPTION_SUCCESS_REVIT_DOWNLOAD_02,
}

export class ExtensionUtil {
  //======================================================================================================================
  private static _rvtFileExtension = ".rvt";
  private static _svfFileExtension = ".svf";
  private static _supportedFileFormats: string | undefined =
    Config.SupportedAutodeskForgeFileFormats;

  public static getAllSupportedAutoDeskForgeFormats() {
    if (ExtensionUtil._supportedFileFormats) {
      return ExtensionUtil._supportedFileFormats.split(";");
    } else {
      return ExtensionUtil._rvtFileExtension.split(";");
    }
  }

  public static getRvtFileExtension() {
    return ExtensionUtil._rvtFileExtension;
  }

  public static getSvfFileExtension() {
    return ExtensionUtil._svfFileExtension;
  }

  public static limitText(text: string, maxLength: number): string {
    const trimmedString =
      text.length > maxLength ? text.substring(0, maxLength - 3) + "..." : text;
    return trimmedString;
  }

  public static getInfoText(infoCode: ExtensionsInfo): string {
    let infoText: string = "";

    switch (infoCode) {
      case ExtensionsInfo.CAPTION_PROJECT:
        infoText = "Project: ";
        break;

      case ExtensionsInfo.CAPTION_ASSEMBLIES_EXTRACTED_1:
        infoText = "assemblies have been extracted from this project";
        break;

      case ExtensionsInfo.CAPTION_ASSEMBLIES_EXTRACTED_FROM_DB:
        infoText = "assemblies have been extracted from database";
        break;

      case ExtensionsInfo.CAPTION_DBREINIT_WARNING:
        infoText =
          "Warning: Reinit database can cause a lost of existing data !";
        break;

      case ExtensionsInfo.CAPTION_ASSEMBLIES_EXTRACTED_3:
        infoText = "assemblies from this project.";
        break;

      case ExtensionsInfo.CAPTION_INIT_DB:
        infoText = "Init database";
        break;

      case ExtensionsInfo.CAPTION_RESET_INIT_DB:
        infoText = "Reinit Database";
        break;

      case ExtensionsInfo.NO_DATA_FOUND:
        infoText = "no data found";
        break;

      case ExtensionsInfo.NO_CATEGORYDATA_FOUND:
        infoText = "no categories found";
        break;

      case ExtensionsInfo.RECORDS_SUCCESSFUL_SAVED:
        infoText = "Records have been successfully saved !";
        break;

      case ExtensionsInfo.CAPTION_DELIMITER:
        infoText = "Delimiter";
        break;

      case ExtensionsInfo.CAPTION_FILENAME:
        infoText = "Filename";
        break;

      case ExtensionsInfo.CAPTION_EXPORT_RECORDS:
        infoText = "export % records";
        break;

      case ExtensionsInfo.CAPTION_GET_UNIFI_LIBCONTENT:
        infoText = "get item numbers";
        break;

      case ExtensionsInfo.CAPTION_GET_UNIFI_LIBCONTENT_INFO:
        infoText =
          "gets content-parameters for selected libraries from database";
        break;

      case ExtensionsInfo.CAPTION_GET_UNIFI_LIBCONTENT_INFO_2:
        infoText = "gets content of selected Unifi-Libraries";
        break;

      case ExtensionsInfo.CAPTION_UNIFI_EXPORT_RECORDS_INFO:
        infoText = "creates csv excel-file of % datagrid-rows";
        break;

      case ExtensionsInfo.CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_INFO:
        infoText = "stores item-number-information rows in the database";
        break;

      case ExtensionsInfo.CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_WARNING:
        infoText =
          "The following process will delete all current records in the database";
        break;

      case ExtensionsInfo.CAPTION_ITEMNUMBER_CROWLER_GET_RECORD_WARNING:
        infoText = "item-number % could not be found in the database";
        break;

      case ExtensionsInfo.CAPTION_ITEMNUMBER_CROWLER_GET_RECORD_WARNING2:
        infoText = "the download url for item-number % is null";
        break;

      case ExtensionsInfo.CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_ERROR:
        infoText =
          "An database-server error occured: item-number information could not be saved in the database";
        break;

      case ExtensionsInfo.CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_OK:
        infoText =
          "Item-number-informations have been successfully saved in the database";
        break;

      case ExtensionsInfo.CAPTION_RESET_GRID_COLOUMS_INFO:
        infoText = "shows all columns again";
        break;

      case ExtensionsInfo.CAPTION_DATA_SUCCESSFULLY_EXPORTED:
        infoText = "Data successfully exported";
        break;

      case ExtensionsInfo.CAPTION_CONTENT_STORAGE_LIBS_FOUND:
        infoText = "% content storage libraries have been found";
        break;

      case ExtensionsInfo.CAPTION_UNIFI_LIBS_FOUND:
        infoText = "% Unifi libraries have been found";
        break;

      case ExtensionsInfo.CAPTION_MODEL_CONFIG_FOUND:
        infoText = "% Model-Configurations have been found";
        break;

      case ExtensionsInfo.CAPTION_SOME_WARNINGS_OCCURED:
        infoText = "Some warnings occured !";
        break;

      case ExtensionsInfo.CAPTION_SOME_ERRORS_OCCURED:
        infoText = "Some errors occured !";
        break;

      case ExtensionsInfo.CAPTION_WARNING:
        infoText = "Warning !";
        break;

      case ExtensionsInfo.CAPTION_ERROR:
        infoText = "Error";
        break;

      case ExtensionsInfo.CAPTION_SUCCESS:
        infoText = "Sucess";
        break;

      case ExtensionsInfo.CAPTION_BIM360_GET_FILENAME:
        infoText = "What do you want to do with the urn of the model % ?";
        break;

      case ExtensionsInfo.CAPTION_COPY_CLIPBOARD_SUCCESSFUL:
        infoText = "Copying to clipboard was successful";
        break;

      case ExtensionsInfo.CAPTION_COPY_CLIPBOARD_ERROR:
        infoText = "Copying to clipboard failed";
        break;

      case ExtensionsInfo.CAPTION_INITDB_PROJECT_CONFLICT:
        infoText =
          "Model-Identification conflict, the current model/project in the viewer does not match to the initialized data in your datagrid";
        break;

      case ExtensionsInfo.CAPTION_INITDB_PROJECT_WRONG_TYPE:
        infoText =
          "The current Project ( model ) does not have assemblies and cannot be used by this Extension, please choose another Project";
        break;

      case ExtensionsInfo.CAPTION_ITEMNUMBER_RECORDS_LOADED_OK:
        infoText = "% item-numbers have been successfully imported from file";
        break;

      case ExtensionsInfo.CAPTION_ITEMNUMBER_RECORDS_SAVED_OK:
        infoText = "% item-numbers have been successfully saved !";
        break;

      case ExtensionsInfo.CAPTION_ASSEMBLIES_SAVE_RECORDS_OK:
        infoText = "% assemblies have been successfully saved in the database";
        break;

      default:
        break;
    }
    return infoText;
  }

  //======================================================================================================================

  public static getErrorText(ErrCode: ExtensionsError): string {
    let errText: string = "";

    switch (ErrCode) {
      case ExtensionsError.ERROR_INVALID_ROOTID:
        errText = "Root-Id cannot be blank !";
        break;

      case ExtensionsError.ERROR_INVALID_BULKPROPERTIES:
        errText = "No Bulk-properties and assemblies found !";
        break;
      case ExtensionsError.ERROR_INVALID_MODELIDENTIFICATION:
        errText = "Invalid model identification data !";
        break;
      case ExtensionsError.ERROR_SAVEDATA:
        errText =
          "An <b>An error has occurred:<b><p> which you can correct it,<br> contact Support.</p>";
        break;

      case ExtensionsError.ERROR_GLOBAL_INVALID_DATA:
        errText = "<b>Warning:</b><p>No valid data found !";
        break;

      case ExtensionsError.NO_ERROR_INITDB_OK:
        errText =
          "The database initialization has been completed successfully.";
        break;

      case ExtensionsError.ERROR_INVALID_HILTI_SEARCH_MODE:
        errText = "Unknown seach mode";
        break;

      case ExtensionsError.ERROR_ROOTID_WITHOUT_ELEMENTS:
        errText = "Root-Id without elements";
        break;

      case ExtensionsError.ERROR_INVALID_INSTANCETREE:
        errText = "Invalid model-instance-tree";
        break;

      case ExtensionsError.ERROR_FETCH_DATA_CAPTION:
        errText = "<b>Error has occured:</b>";
        break;

      case ExtensionsError.ERROR_FETCH_DATA_INFO:
        errText =
          "<p>Please check the server connection or contact your administrator or support line.</p>";
        break;

      case ExtensionsError.ERROR_FETCH_DATA_EMPTY:
        errText = "<p>No data found for the given project number.</p>";
        break;

      case ExtensionsError.ERROR_FETCH_DATA_EMPTY_02:
        errText =
          "The database still does not have data for the current project %, use init-database to initialize this project in the database.";
        break;

      case ExtensionsError.ERROR_LOADMODEL_INVALID_MODELURL:
        errText = "model-url is empty.";
        break;

      case ExtensionsError.ERROR_LOADMODEL_FAILED:
        errText = "[failure] The model could not be loaded.";
        break;

      case ExtensionsError.ERROR_LOADMODEL_NO_DEFAULTMODEL:
        errText = "[failure] The model does not have defaultgeometry";
        break;

      case ExtensionsError.ERROR_LOADMODEL_INVALID_DOCUMENT_ID:
        errText = "Model document-id invalid";
        break;
      case ExtensionsError.ERROR_LOADMODEL_INVALID_ACCESS_TOKEN:
        errText = "Access-Token invalid";
        break;

      case ExtensionsError.ERROR_LOADMODEL_INVALID_CONTAINER_ELEMENT:
        errText = "Div-container could not be found";
        break;

      case ExtensionsError.ERROR_CONNECT_WEB_SERVER:
        errText = "Cannot connect to web-server";
        break;

      case ExtensionsError.ERROR_CONNECT_DB_SERVER:
        errText = "Cannot connect to database-server";
        break;

      case ExtensionsError.ERROR_READ_UNIFI_LIB:
        errText = "Library could not be read";
        break;

      case ExtensionsError.ERROR_READ_UNIFI_LIB_02:
        errText =
          "% Unifi Library file(s) caused server-error, for further information read generated err-data file(s) in your download folder";
        break;

      case ExtensionsError.ERROR_PLAYER_DOWNLOADINFO_ERR:
        errText = "Downloadinformation could not be read";
        break;

      case ExtensionsError.ERROR_READ_CONTENT_PARAMETER:
        errText =
          "Content Parameter for the given Library could not be read from database-server";
        break;

      case ExtensionsError.ERROR_READ_ITEM_NUMBER_INFORMATION:
        errText =
          "Item-number Informations could not be read from database-server";
        break;

      case ExtensionsError.WARNING_READ_CONTENT_PARAMETER:
        errText = "Warning for Directory %: no records found in the database !";
        break;

      case ExtensionsError.WARNING_DATA_NOT_COMPLETED:
        errText = "Not all data could be found";
        break;

      case ExtensionsError.ERROR_CONNECT_BIM360:
        errText = "Cannot connect to Bim360";
        break;

      case ExtensionsError.WARNING_NO_DATA_FOR_URN:
        errText = "No data found for the given urn";
        break;

      case ExtensionsError.ERROR_BASE_URL_UNDEF:
        errText = "Base-Url is undefined";
        break;

      case ExtensionsError.ERROR_VIEWER_INSTANCE_TREE:
        errText = "Viewer instanceTree could not be created";
        break;

      case ExtensionsError.ERROR_RECORDS_COULD_NOT_BE_SAVED:
        errText = "records could not be saved !";
        break;

      case ExtensionsError.ERROR_INVALID_BIMCAD_FILE:
        errText = "Invalid BimCad-File";
        break;

      case ExtensionsError.ERROR_CANNOT_FIND_BIMCAD_FILE:
        errText = "Could NOT find BimCad-File";
        break;

      case ExtensionsError.ERROR_INVALID_PORTFOLIO_FILE:
        errText = "Invalid Portfolio-ItemNumbers-File";
        break;

      case ExtensionsError.ERROR_CANNOT_FIND_PORTFOLIO_FILE:
        errText = "Could NOT find Portfolio-ItemNumbers-File";
        break;

      default:
        break;
    }
    return errText;
  }

  //======================================================================================================================

  public static isValidContent(content: any): Boolean {
    // content can be string , Array, number, undefinend, null

    let bHasValidContent: Boolean = true;

    if (typeof content == "undefined") {
      return false;
    }
    if (content == null) {
      return false;
    }

    if (typeof content == "string") {
      if (content.length === 0) {
        // empty-string is NOT a valid content
        bHasValidContent = false;
      }
    } else if (typeof content == "number") {
      if (content === 0) {
        // number => 0  is NOT a valid content
        bHasValidContent = false;
      }
    } else {
      var contentArray = <Array<any>>content;
      if (contentArray.length === 0) {
        // empty Array ... is NOT a valid content
        bHasValidContent = false;
      }
    }
    return bHasValidContent;
  }

  //======================================================================================================================

  public static isContentNotNull(content: any): Boolean {
    return typeof content == "undefined" || content == null ? false : true;
  }

  //======================================================================================================================

  public static getPlayerExtesionCaptionText(
    captionId: PLAYER_ExtensionText
  ): string {
    let captionText: string = "";
    switch (captionId) {
      case PLAYER_ExtensionText.CAPTION_START:
        captionText =
          "Start step-animation: shows each object of the current selection for a certain time before automaticly navigate to the next Object";
        break;
      case PLAYER_ExtensionText.CAPTION_NEXT:
        captionText =
          "Goto next: shows the NEXT object within the current selection";
        break;
      case PLAYER_ExtensionText.CAPTION_PREV:
        captionText =
          "Goto previous: shows the PREVIOUS object within the current selection";
        break;

      case PLAYER_ExtensionText.CAPTION_GOTO_FIRST:
        captionText =
          "Goto first: shows the first object in the current selection";
        break;

      case PLAYER_ExtensionText.CAPTION_GOTO_LAST:
        captionText =
          "Goto last: shows the last object in the current selection";
        break;

      case PLAYER_ExtensionText.CAPTION_JUMP_FORWARD:
        captionText =
          "Jump-forward: jumps % steps forward and shows the object on that position";
        break;

      case PLAYER_ExtensionText.CAPTION_JUMP_BACKWARD:
        captionText =
          "Jump-backward: jumps % steps backward and shows the object on that position";
        break;

      case PLAYER_ExtensionText.CAPTION_PAUSE_ANIM:
        captionText =
          "Pause animation: stops or restarts ( toggles ) step-animation and rotate-animation )";
        break;
      case PLAYER_ExtensionText.CAPTION_CLEAR_SELECTION:
        captionText =
          "Clear selection: removes the selection, buttons ( except start-button ) dont have any functionality any more";
        break;
      case PLAYER_ExtensionText.CAPTION_ROTATE_ANIM:
        captionText =
          "Rotate-animation: starts or stops ( toggles ) rotate-animation for the current selected object";
        break;

      case PLAYER_ExtensionText.CAPTION_READ_DATA_FILTER:
        captionText =
          "Reads model-dbids, filtered by given property and value, otherwise from current models leaf-nodes";
        break;

      case PLAYER_ExtensionText.CAPTION_ADD_BASKET_ITEM:
        captionText =
          "Adds all selected fragment(s) in the viewer to the basket-list";
        break;

      case PLAYER_ExtensionText.CAPTION_REMOVE_BASKET_ITEM:
        captionText = "Removes all selected rows in the basket-list";
        break;

      case PLAYER_ExtensionText.CAPTION_SHOW_BASKET_LIST:
        captionText = "Expands the Player-Panel with the basket-list";
        break;

      case PLAYER_ExtensionText.CAPTION_ZOOM_SELECTED_DBID:
        captionText = "Zooms in the current selected fragment";
        break;

      case PLAYER_ExtensionText.CAPTION_ADD_ITEM_NUMBER_ALREADY_EXIST:
        captionText =
          "The item-number % already exists in the list and could not be added !";
        break;

      case PLAYER_ExtensionText.CAPTION_MODEL_HAS_BEEN_REMOVED:
        captionText =
          "Player-Extension Warning: The model in the Viewer has been removed, all data are not vaild anymore, please restart the Player-Extension !";
        break;

      case PLAYER_ExtensionText.CAPTION_WARNING_REVIT_DOWNLOAD_01:
        captionText =
          "Could not get the download url for % item-numbers, for further information read generated file(s) in your download folder";
        break;

      case PLAYER_ExtensionText.CAPTION_SUCCESS_REVIT_DOWNLOAD_01:
        captionText = "% Revit family file has been successfully downloaded";
        break;
      case PLAYER_ExtensionText.CAPTION_SUCCESS_REVIT_DOWNLOAD_02:
        captionText = "% Revit family files have been successfully downloaded";
        break;

      default:
        break;
    }
    return captionText;
  }
}
