import { setTimeout } from 'timers';


export enum ANIMTIMER_State
{
    ANIM_STATE_UNDEF   = 0,
    ANIM_STATE_CREATED = 1,
    ANIM_STATE_PAUSED  = 2,
    ANIM_STATE_RUNNING = 3,
  //  ANIM_STATE_CLOSED  = 4,
};

export default class BaseAnimationTimer extends EventTarget {

    private _state:ANIMTIMER_State  = ANIMTIMER_State.ANIM_STATE_UNDEF;
    private _stepDuration:number = 0;
    private _initialDuration:number = 0;
    private _currentStep:number  = 0;
    private _maxSteps:number     = 0;
    private _theTimer            = null;
    private _complete:Event      = new Event('complete')

    constructor(initialDuration:number,stepDuration:number,maxSteps:number)
    {
        super();

        this._state = ANIMTIMER_State.ANIM_STATE_CREATED;

        if (stepDuration < 1000)
            stepDuration = 1000;
        this._initialDuration = initialDuration;
        this._stepDuration = stepDuration;
        this._maxSteps     = maxSteps;
        this._currentStep  = 0;
    }

    public start(stepDuration:number): void 
    {
      //  alert("in start mit timeout =" +  this._initialDuration);
        if (stepDuration < 1000)
            stepDuration = 1000;
        this._stepDuration = stepDuration;
        this._theTimer = setTimeout(() => {
            this.dispatchEvent(this._complete)
        }, this._initialDuration );
        this._state = ANIMTIMER_State.ANIM_STATE_RUNNING;
    }

    public startX(): void 
    {
        this._theTimer = setTimeout(() => {
            this.dispatchEvent(this._complete)
        }, this._stepDuration );
        this._state = ANIMTIMER_State.ANIM_STATE_RUNNING;
    }


    public reStart(restartpos:number,duration:number): void 
    {
        this._currentStep  = restartpos;
        this._stepDuration = duration;
        clearTimeout( this._theTimer );
        this.startX();
    }

    public close(): void 
    {
       // alert("anim close");
        this._currentStep  = 0;
        this._state = ANIMTIMER_State.ANIM_STATE_CREATED;
        clearTimeout( this._theTimer );
    }

    public pause(): void 
    {
        this._state = ANIMTIMER_State.ANIM_STATE_PAUSED;
        clearTimeout( this._theTimer );
    }

    public getState(): ANIMTIMER_State {
        return this._state;
    }

    public setStepIndex(stepIndex:number) : void {
        this._currentStep = stepIndex;
    }

    public getStepIndex() : number {
        return this._currentStep % this._maxSteps;
    };
} 

