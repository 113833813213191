import { BimCadExtensionPanel } from "./BimCadExtensionPanel";
import './css/BimCadExtension.css';
import Config from '../../../Config';

export class BimCadExtension extends Autodesk.Viewing.Extension {
    
    
    //=================================================================================================================
    private  _panel      :any = null;
    private  _subToolbar :any = null;
    private  _options    :any = null;
    private  _viewer     :any = null;
    private  _titel      :string = "ItemNumberUploader";
    //=================================================================================================================

    public constructor(viewer:any, options:any) {

        super(viewer, options);

        this._options = options;
        this._viewer  = viewer;
    }
    
    //===================================================================================================================

    public load() : boolean {

        if (this.viewer.toolbar)  // Toolbar is already available, create the UI
        {
            this.createUI(); 
        }
        return true;
    }

    //===================================================================================================================

    public onToolbarCreated() : void {

        this.createUI();
    }

    //===================================================================================================================

    private createUI() : void {

        if (this._subToolbar == null)
        {
            // button to show the docking panel
            const actionToolbarButton = new Autodesk.Viewing.UI.Button(  this._titel + "Button" );

            actionToolbarButton.addClass(  "BimCadExtensionToolbarButton" );
            actionToolbarButton.setToolTip( this._titel );

            actionToolbarButton.onClick = (e) => {  this.onButtonClicked(e);  }
            
            this._subToolbar = new Autodesk.Viewing.UI.ControlGroup( this._titel + 'Toolbar' );
                
            this._subToolbar.addControl(actionToolbarButton);
            this.viewer.toolbar.addControl(this._subToolbar);
        }
    } 

    //===================================================================================================================

    public unload() : boolean 
    {
        this._viewer.toolbar.removeControl(this._subToolbar);
        return true;
    }
 
    //===================================================================================================================

    private onButtonClicked(e:any) : void {

        if (this._panel == null) 
        {
            this._panel = new BimCadExtensionPanel(this.viewer, this.viewer.container, this._titel + 'ExtensionPanel', this._titel, this.options);
        }                
        this._panel.setVisible(!this._panel.isVisible()); // toogle (show/hide) docking panel
    }

   //==================================================================================================================

    public static loadExtension(viewer:any)
    {
        const baseUrl          = Config.BaseUrl;
        const spinnerColor     = Config.SpinnerColor;
        const spinnerSize      = Config.SpinnerSize;
        const spinnerSizeValue = parseInt(spinnerSize,10);

        const init_options = {   
            PANEL_INITIAL_POS:       "10,10, 554,816",      // initial x1Pos,y1Pos,Width,Height of the panel
            WRITE_LOG_FILE:           1,                    // 1 => write to console.log  .., 0 => dont write    
            SPINNER_COLOR:           spinnerColor,        // color of spinner bars 
            SPINNER_SIZE:            spinnerSizeValue,    // spinner size default = 30 
            BASE_URL:                baseUrl              // web-server base-url for ( Axios ) 
        }
   
        viewer.loadExtension('Hilti.BimCadExtension', { init_options } );
   }  
   
   //==================================================================================================================
}