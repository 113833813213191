import "../utils/GuiComponents/base.css";
import './css/ChartExtension.css';
import {ExtensionUtil} from '../utils/utils'
import { Chart } from 'chart.js'

export class ChartExtensionPanel extends Autodesk.Viewing.UI.DockingPanel {

      //=================================================================================================================
    protected _initialXPos   = "80";
    protected _initialYPos   = "140";
    protected _initialWidth  = "500";
    protected _initialHeight = "400";
    //=================================================================================================================
  
    constructor(viewer:any, container:any, id:any, title:any, options:any) 
    {
        super(viewer.container,id, title, options);

        Autodesk.Viewing.UI.DockingPanel.call(this, container, id, title, options);

        //==================================================================

        if (ExtensionUtil.isContentNotNull(options.init_options.PANEL_INITIAL_POS))
        {
            const posString = options.init_options.PANEL_INITIAL_POS;
            const xyCoords = posString.split(',');
            if (xyCoords.length === 4)
            {
              this._initialXPos   = xyCoords[0];
              this._initialYPos   = xyCoords[1];
              this._initialWidth  = xyCoords[2];
              this._initialHeight = xyCoords[3];
            }
        }   

       this.container.classList.add("docking-panel-container-solid-color-a");
       this.container.style.width       =  this._initialWidth +"px";  
       this.container.style.height      =  this._initialHeight +"px"; 
       this.container.style.minWidth    = "200px";
       this.container.style.minHeight   = "50px";
       this.container.style.resize      = "none";
       this.container.style.position    = "absolute";
       this.container.style.left        = this._initialXPos + "px";
       this.container.style.top         = this._initialYPos + "px";

        //=================================================================================

        const chartWrapper = document.createElement("div");
        chartWrapper.setAttribute("class", "chartWrapper flex-column d-flex p-2");

        //=================================================================================

        const pieChartElement = document.createElement("canvas");
        pieChartElement.setAttribute("id", "pieChart");
        pieChartElement.style.marginTop = "10px";
        chartWrapper.appendChild(pieChartElement);
     
        //=================================================================================

        const doughnutChartElement = document.createElement("canvas");
        doughnutChartElement.setAttribute("id", "doughnutChart");
        doughnutChartElement.style.marginTop = "30px";
        chartWrapper.appendChild(doughnutChartElement);

        //=================================================================================

        const button = document.createElement("a");
        const blobStorageUrl = "https://hiltidatr.blob.core.windows.net/hiltidatrcontainer/";
        const filename = "roof_example_01.rvt";
        const href = blobStorageUrl + filename;

        button.setAttribute("href", href);
        button.setAttribute("role", "button");
        button.setAttribute("class", "btn btn-secondary btn-chart");  
        button.innerHTML = "Download" + " " + filename;

        const buttonWrapper = document.createElement("div");
        buttonWrapper.setAttribute("class", "buttonWrapper flex-column d-flex"); // p-2 m-5");
        buttonWrapper.style.margin = "30px 15px 12px 15px";  // top right bottom left
        buttonWrapper.appendChild(button);

        //=================================================================================

        chartWrapper.appendChild(buttonWrapper); 
   
        this.container.appendChild(chartWrapper);

        //==========================================================

        let pieData = {
          datasets: [
            {
              data: [10, 20, 30],
              backgroundColor: ["red", "yellow", "green"],
              label: "Pie dataset",
            },
          ],
          labels: ["Red", "Yellow", "Green"],
        };
      
        const pieCtx = document.getElementById("pieChart");
                     
          new Chart( pieCtx as HTMLCanvasElement , {
            type:     "pie",
            data:     pieData,
            options:  options,
          });                        

        //=====================================================
        
        let doughnutData = {
          datasets: [
            {
              data: [60, 40, 80],
              backgroundColor: ["blue", "orange", "gray"],
              label: "Pie dataset",
            },
          ],
          labels: ["Bacon", "Pork", "Meatball"],
        };

        const doughnutCtx = document.getElementById("doughnutChart");

        new Chart(doughnutCtx as HTMLCanvasElement, {
          type:     "doughnut",
          data:     doughnutData,
          options:  options,
        });
    }
  
    //=================================================================================================================

    public initialize() : void {

      this.title = this.createTitleBar(this.titleLabel || this.container.id);
      this.container.appendChild(this.title);

      this.initializeMoveHandlers(this.container);

      this.closer = this.createCloseButton();
      this.container.appendChild(this.closer);
  
      this.footer = this.createFooter();
      this.container.appendChild(this.footer);

    }

    //=================================================================================================================

}


