import React from "react";
import hiltilogo from './hiltilogo.svg';
import './Logo.css';

const Logo = () => {
    return (
        <img src={hiltilogo} className="app-logo" alt="logo" />
    )
}

export default Logo