import "../../utils/GuiComponents/base.css";
import "../css/ModelConfiguratorExtension.css";
import * as React from "react";
import {
  ExtensionsError,
  ExtensionsInfo,
  ExtensionUtil,
} from "../../utils/utils";
import SpinnerCore from "../../utils/GuiComponents/SpinnerCore";
import Tabulator from "tabulator-tables"; //import Tabulator library
import { Snackbar } from "@material-ui/core";
import {
  SeverityType,
  VerticalOrigin,
  HorizontalOrigin,
  DialogUtil,
  g_darkThemeDialog,
} from "../../utils/GuiComponents/ModalDialogs/BaseSnackbar";
import { Alert } from "@material-ui/lab";
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
import { ThemeProvider } from "@material-ui/core/styles";
import { HtmlUtils } from "../../utils/HtmlUtils";
import { Category, Client, IModelDto, ModelDto } from "../../../../client";

export interface ConfigurationDataRow
{
    Id:number;
    ModelName:string;
    ModelUrn:string;
    Category:string;
}

interface CategoryShort
{
    Id:number;
    Name:string;  
}

interface ModelConfiguratorGUIPanelProps {
  parentPanel;
}

interface ModelConfiguratorGUIPanelState {
  showAlert: boolean;
  alertTransition: any;
  alertMessage: string;
  alertType: SeverityType;
  alertDuration: number;
  alertVOrigin:   VerticalOrigin;
  alertHOrigin:   HorizontalOrigin;

  showMessageBox: boolean;
  dlgTitle: string;
  dlgContentText: string;
}

export default class ModelConfiguratorGUIPanel extends React.Component<
  ModelConfiguratorGUIPanelProps,
  ModelConfiguratorGUIPanelState
> {
  //============================================================
  private _guiElementesInitialized = false;
  private _spinner: SpinnerCore = null;
  private _dataTableModelConfiguration = null;
  private _GridData: ConfigurationDataRow[] = [];
  private _allCategories:CategoryShort[] = [];
  //=============================================================

  constructor(prop: any) {
    super(prop);

    this.state = {
      alertTransition: undefined,
      showAlert: false,
      alertMessage: "",
      alertType: SeverityType.INFO,
      alertDuration: 6000,
      alertVOrigin: VerticalOrigin.TOP, alertHOrigin: HorizontalOrigin.CENTER,
      showMessageBox: false,
      dlgTitle: "",
      dlgContentText: "",
    };

    this.props.parentPanel.setGUIPanel(this);

    this._spinner = new SpinnerCore();
  }

  //=================================================================================================================

  componentDidMount = () => {
    this.onGuiLoaded();
    this.getConfiguration();
  };

  //=================================================================================================================

  componentWillUnmount = () => {};

  //=================================================================================================================

  private onGuiLoaded() {

    if (!this._guiElementesInitialized) 
    {
        this._guiElementesInitialized = true;

        this._spinner.init("id-spinnerContainer-modelconfig","id-spinnerPanel-modelconfig","id-spinnerCaptionPanel-modelconfig");
        this._spinner.initColor( this.props.parentPanel.getSpinnerColor() );
        this._spinner.initSize ( this.props.parentPanel.getSpinnerSize() );
    }
  }

  //=================================================================================================================

    public startLongLastingProcess()
    {
        HtmlUtils.showHtmlElement("mconfig-grid-container",false);
        HtmlUtils.showHtmlElement(" modelconfig-buttonwrapper",false);

        this._spinner.show(true);
    }

    //=================================================================================================================

    public finishLongLastingProcess()
    {
      this._spinner.show(false);
      HtmlUtils.showHtmlElement("mconfig-grid-container",true);
      HtmlUtils.showHtmlElement("modelconfig-buttonwrapper",true);

      let info  = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_MODEL_CONFIG_FOUND);
          info  = info.replace("%", this._GridData.length.toString() );
      this.setState( {showAlert: true, alertType:   SeverityType.INFO,  alertMessage: info });
      this.fillConfigurationTable(this._GridData,true)
    }

    //======================================================================================================================

    async getConfiguration()
    {
        this.startLongLastingProcess();

        this._allCategories = [];
        this._GridData = [];

        const apiClient:Client = this.props.parentPanel.getApiClient();

        await apiClient.getCategories().then( (return_data:Category[]) =>  {

            if (return_data !== null)
            {
                for ( let currCategory of return_data)
                {
                    const categorieShort:CategoryShort = { Id: currCategory.id, Name: currCategory.name }; 
                    this._allCategories.push(categorieShort);
                  
                    if (currCategory.models !== null)
                    {
                        for ( let currModelInfo of currCategory.models)
                        {
                            const d1:ConfigurationDataRow = { Id: currModelInfo.id, ModelName: currModelInfo.modelName, 
                                                              ModelUrn: currModelInfo.modelUrn, Category: currCategory.name };    
                            this._GridData.push(d1);
                        }
                    }  
                    else
                    {

                    }
                }  
            }
            this.finishLongLastingProcess();
        });    
    }

    //======================================================================================================================

    private onSaveItems = (event) => {

        const allModelInfos:ModelDto[] = [];

        const allRows = this._dataTableModelConfiguration.getRows('active'); //true);

        if (ExtensionUtil.isValidContent( allRows ))
        {
            let index:number = 0;

            for ( let currRow of allRows)
            { 
                const modelName  = currRow.getData().ModelName;
                const modelUrn   = currRow.getData().ModelUrn;
                const categoryId = this.getCategoryIdByName(currRow.getData().Category);

                const currRowModelDto:IModelDto = { modelName: modelName, modelUrn: modelUrn,  categoryId: categoryId };

                allModelInfos.push( currRowModelDto as ModelDto);
            } 
            
            const apiClient:Client = this.props.parentPanel.getApiClient();    
            
            apiClient.saveModels(allModelInfos).then( result_retval =>  {

              if ( result_retval == true )
              {
                  let info  = ExtensionUtil.getInfoText(ExtensionsInfo.RECORDS_SUCCESSFUL_SAVED);
                  this.setState( {showAlert: true, alertType:   SeverityType.SUCCESS,  alertMessage: info });
              }
              else
              {
                  let info  = ExtensionUtil.getErrorText(ExtensionsError.ERROR_RECORDS_COULD_NOT_BE_SAVED);
                  this.setState( {showAlert: true, alertType:   SeverityType.ERROR,  alertMessage: info });
              }
            }); 
        }
    }

    private getCategoryIdByName(categoryName:string) : number
    {
        let categoryId:number = 0;  
        for ( let currCategory of this._allCategories)      
        {
            if (currCategory.Name == categoryName)
            {
                categoryId = currCategory.Id;
                break;
            }
        }
        return categoryId;
    }

    //======================================================================================================================

    private fillConfigurationTable(data,groupStartOpenFlag) 
    {
        this._dataTableModelConfiguration = new Tabulator("#modelconfig-configurationTable", {
        
            //dataTreeStartExpanded:true, //start with an expanded tree
            index:              "id", 
            data:               data,     //assign data to table
            layout:             "fitColumns",   //fit columns to width of table (optional)
            height:             "520px",
            selectable:         1,
            groupStartOpen:     groupStartOpenFlag,
            groupToggleElement: "header",
            columns:[                 

                { title: "#",                 field: "Id",          visible: false},     
                { title: "Modelname",         field: "ModelName",   editor: true, headerSort: true,   width:"25%",  widthGrow: 1, widthShrink: 1  },
                { title: "Model-urn",         field: "ModelUrn",    editor: true, headerSort: true ,  width:"60%",  widthGrow: 1, widthShrink: 1  },
                { title:"Category",           field:"Category",     width:"15%",  widthGrow: 1, widthShrink: 1 ,  editor:"select", editorParams:{
                    values:{
                        "Firestop":        "Firestop",
                        "Modular Support": "Modular Support",
                        "Fasterners":      "Fasterners",
                    }
                }}

                // { title:"SearchMode",         field:"SearchMode",        editor:"select", editorParams:{
                //     values:{
                //         "none":                "none",
                //         "location_code":       "LOCATION_CODE",
                //         "aveva_description":   "AVEVA_DESCRRIPTION",
                //     }
                // }},
            ],
        });
    //   });
    }

  //======================================================================================================================

    private onReload = (event) => {
  
      this._GridData = [];

      this._dataTableModelConfiguration.destroy();

      this.getConfiguration();
  };    

//======================================================================================================================



    private onAddItem = (event) => {
      
        // get the highest id
        let highestId = -1;
        for (let currItem of this._GridData)
        {
            if (currItem.Id > highestId)
            highestId = currItem.Id;
        }
        const d1:ConfigurationDataRow = { Id: highestId+1, 
                                          ModelName:       "newname",
                                          ModelUrn:        "newurn",  
                                          Category:        "Firestop",  };    
        this._GridData.push(d1);
        this.fillConfigurationTable(this._GridData,true);
    };    

    //======================================================================================================================

    private onDeleteItem = (event) => {

        const selectedRows = this._dataTableModelConfiguration.getSelectedRows();
        if (selectedRows !== null)
        {
            let idDelete = 0;
            if (selectedRows.length == 1)
            {
                idDelete = selectedRows[0].getData().Id;
            }
            if (idDelete > 0)
            {
                const newGridData:ConfigurationDataRow[] = [];
                for (let currItem of this._GridData)
                {
                    if (currItem.Id !== idDelete)
                    {
                        newGridData.push(currItem);
                    }
                }
                this._GridData = newGridData;
                this.fillConfigurationTable(this._GridData,true)
            }
        }
    };    

  //======================================================================================================================

  private onTestModel = (event) => {

    this.setState( {showAlert: true, alertType:   SeverityType.INFO,  alertMessage: "not implemented yet" });

  };    

  //======================================================================================================================


  private onCloseAlertMessage = () => {
    this.setState({ showAlert: false });
  };

  private onCancelMessageBox = () => {
    this.setState({ showMessageBox: false });
  };

  private onOKMessageBox = () => {
    this.setState({ showMessageBox: false });
  };

  //=================================================================================================================

  render() {

    let useSeverity:any         = DialogUtil.getSeverity(this.state.alertType);
    let useHorizontalOrigin:any = DialogUtil.getHorizontalOrigin(this.state.alertHOrigin);
    let useVerticalOrigin:any   = DialogUtil.getVerticalOrigin(this.state.alertVOrigin);

    return (
      <div className=" container-fluid m-0" id="id-modelconfig-mainpanel01">
        <div>     
            <ThemeProvider theme={g_darkThemeDialog}>
            <Snackbar 
                open                = { this.state.showAlert } 
                TransitionComponent = { this.state.alertTransition }
                autoHideDuration    = { this.state.alertDuration } 
                anchorOrigin={{
                    vertical:   useVerticalOrigin,
                    horizontal: useHorizontalOrigin,
                }}   
                onClose = { this.onCloseAlertMessage }>
                <Alert 
                    onClose  = { this.onCloseAlertMessage } 
                    severity = { useSeverity }>
                    { this.state.alertMessage }
                </Alert>
            </Snackbar>
            </ThemeProvider>
        </div>     


        <div className="container m-0 p-0  flex-column d-flex" id="id-spinnerContainer-modelconfig">
            <div id="id-spinnerCaptionPanel-modelconfig"/>
                <div className="col-xs-6" id="id-spinnerPanel-modelconfig"/>
        </div>

        <div className="mconfig-grid-container">
          <div className="mconfig-grid-content">
            <div id="modelconfig-configurationTable"/>
          </div>
        </div>


        <div className="mconfig-button-panel" id="modelconfig-buttonwrapper">

          <div className="row ml-1 p0">

            <div className="xxx">

                <button className="btn btn-secondary btn-modelconfig" onClick={(e) => this.onSaveItems(e)} type="button">Save</button>

                <button className="btn btn-secondary  btn-modelconfig" onClick={(e) => this.onAddItem(e)} type="button">Add</button>

                <button className="btn btn-secondary  btn-modelconfig" onClick={(e) => this.onDeleteItem(e)} type="button" >Delete</button>

                <button className="btn btn-secondary btn-modelconfig" onClick={(e) => this.onReload(e)} type="button">Reload</button>

                {/* <button className="btn btn-secondary  btn-modelconfig" onClick={(e) => this.onTestModel(e)} type="button" >Test urn</button> */}

            </div>

          </div>
        
        </div>

      </div>
    );
  }

  //=================================================================================================================
}
