import { DefaultExtensionPanel } from "./DefaultExtensionPanel";
import './css/DefaultExtension.css';

export class DefaultExtension extends Autodesk.Viewing.Extension {
    
    //=================================================================================================================
    _panel      :any = null;
    _subToolbar :any = null;
    _options    :any = null;
    _viewer     :any = null;
    _allItems = null;
    //=================================================================================================================

    public constructor(viewer:any, options:any) {
        super(viewer, options);
        Autodesk.Viewing.Extension.call(this, viewer, options);

        this._options = options;
        this._viewer = viewer;

        this.onObjectTreeCreated = this.onObjectTreeCreated.bind(this)
    }
    
    //===================================================================================================================

    public load() : boolean {

        if (this.viewer.toolbar)  // Toolbar is already available, create the UI
        {
            this._viewer.addEventListener( Autodesk.Viewing.SELECTION_CHANGED_EVENT,   this.onObjectSelectionChanged);
            this._viewer.addEventListener( Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.onObjectTreeCreated);
   
            this.createUI(); 
        }
        return true;
    }

    //===================================================================================================================

    public onToolbarCreated() : void {

        this.createUI();
    }

    //===================================================================================================================

    private createUI() : void {

        if (this._subToolbar == null)
        {
            // button to show the docking panel
            const actionToolbarButton = new Autodesk.Viewing.UI.Button('showDefaultPanel');

            actionToolbarButton.addClass('defaultExtensionToolbarButton');
            actionToolbarButton.setToolTip('Default');

            actionToolbarButton.onClick = (e) => {  this.onDefaultToolbarButtonClicked(e);  }
            
            this._subToolbar = new Autodesk.Viewing.UI.ControlGroup('DefaultExtensionToolbar');
                
            this._subToolbar.addControl(actionToolbarButton);
            this.viewer.toolbar.addControl(this._subToolbar);

        }
    } 

    //===================================================================================================================

    public unload() : boolean 
    {
        this._viewer.removeEventListener( Autodesk.Viewing.SELECTION_CHANGED_EVENT,   this.onObjectSelectionChanged);
        this._viewer.removeEventListener( Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.onObjectTreeCreated);
        this._viewer.toolbar.removeControl(this._subToolbar);
        return true;
    }
 
    //===================================================================================================================

    private onDefaultToolbarButtonClicked(e:any) : void {

       // alert("DefaultPanel-Extension has been clicked");
        if (this._panel == null) 
        {
            this._panel = new DefaultExtensionPanel(this.viewer, this.viewer.container, 'DefaultExtensionPanel', 'Default', this.options);
        }                
        this._panel.setVisible(!this._panel.isVisible()); // toogle (show/hide) docking panel
    }

   //==================================================================================================================

   public onObjectSelectionChanged = (event:any) => { 

        // if (event.dbIdArray.length) 
        // {
        //     ViewerModelToolkit.setColorMaterial(this._viewer,"temperary-colored-overlay",event.dbIdArray,0x0000FF);
        // }

        // if (event.dbIdArray.length) 
        // {
        //     ViewerModelToolkit.setThemingColor(this._viewer, event.dbIdArray[0], new THREE.Vector4(1, 0, 0,1));
        // }
    }

    //=================================================================================================================

    public onObjectTreeCreated(event:any) : void 
    {
        // const that = this;

        // ViewerToolkit.getNodes(this._viewer).then(function(result) {
        //      that._allItems = [...result];
        //  });

        // ViewerToolkit.getFragments(this._viewer).then(function(result) {
        //     that._allItems = [...result];
        // });

        // ViewerToolkit.getLeafNodes02(this._viewer).then(function(result) {
        //     that._allItems = [...result];
        // });       

    }

    //===================================================================================================================

    public static loadExtension(viewer:any)
    {
        const init_options = {
            PANEL_INITIAL_POS:    "20,90,140,300",       // initial x1Pos,y1Pos,Width,Height of the panel
        }
  
        viewer.loadExtension('Hilti.DefaultExtension',  {  init_options  });
    }  
  
    //===================================================================================================================
}