import {AVEA_DescFilter,ExtensionsNames,Hilti_SearchMode, ExtensionsError,ExtensionInfoLevel,ExtensionUtil,ExtensionsInfo} from '../utils/utils'
import { Chart } from 'chart.js'
import * as _ from 'underscore';
import Tabulator from 'tabulator-tables'; //import Tabulator library
import './css/AssemblyExtension.css';
import QRious from "qrious";
import colorToVec4 from 'color-to-vec4'
import  AssemblyExtensionPanel  from './AssemblyExtensionPanel';
import ViewerCore from "../utils/ViewerCore";
import { HtmlUtils } from '../utils/HtmlUtils';

declare var THREE: any;

// wjx added 13.11.20
// yarn add tabulator-tables
// yarn add @types/tabulator-tables
// yarn add underscore
// yarn add @types/underscore
// yarn add qrious
// yarn add color-to-vec4    (from unpkg.com)

// test x

export default class AssemblyExtensionBusinessLogic 
{
    //=================================================================================================================
    private   _dataTableAssemblies = null;
    private   _tableData:any  = null;
    protected _assembliesTable:Tabulator = null;
    protected  _barChartData   = null;
    protected  _barChart:Chart = null;
    protected _parentPanel:AssemblyExtensionPanel = null;
    protected _viewer:any   = null;
    protected _hasDataLoaded:boolean = false;
    private   _dispValueSeparator:string  = "/";
    //=================================================================================================================
    protected _aweaElements:any[] = [];
    //=================================================================================================================
    protected _errCode:ExtensionsError =  ExtensionsError.NO_ERROR;
    //=================================================================================================================

    protected p_options = {

        INFO_LEVEL:           ExtensionInfoLevel.DETAILED,
        HILTI_SEARCH_MODE:    Hilti_SearchMode.LOCATION_CODE,
        GET_ASSEMBLIES_URL:   null,
        WRITE_LOG_FILE:       false,
    }

    //=================================================================================================================

    constructor(parent:AssemblyExtensionPanel,viewer:any, options:any)
    {
        this._viewer      = viewer;
        this._parentPanel = parent;

        if (ExtensionUtil.isValidContent(options))
        {
            // override default option values ... if necessary

            if (ExtensionUtil.isContentNotNull(options.init_options.INFO_LEVEL))
                this.p_options.INFO_LEVEL = options.init_options.INFO_LEVEL;

            if (ExtensionUtil.isContentNotNull(options.init_options.GET_ASSEMBLIES_URL))
                this.p_options.GET_ASSEMBLIES_URL = options.init_options.GET_ASSEMBLIES_URL;

            if (ExtensionUtil.isContentNotNull(options.init_options.WRITE_LOG_FILE))
            {
                const value = parseInt(options.init_options.WRITE_LOG_FILE); 
                if (value > 0)
                    this.p_options.WRITE_LOG_FILE = true;
            }

            if (ExtensionUtil.isContentNotNull(options.init_options.HILTI_SEARCH_MODE))
                this.p_options.HILTI_SEARCH_MODE = options.init_options.HILTI_SEARCH_MODE;

            if (ExtensionUtil.isContentNotNull(options.init_options.HILTI_DISPVALUE_SEPARATOR))
                this._dispValueSeparator = options.init_options.HILTI_DISPVALUE_SEPARATOR;
        }

        if (this.p_options.GET_ASSEMBLIES_URL === null || this.p_options.GET_ASSEMBLIES_URL === undefined)
        {
            alert(ExtensionUtil.getErrorText(ExtensionsError.ERROR_BASE_URL_UNDEF));
        }
    }

 
    //===================================================================================================================
    // Gets Awea Group elements as Array of Ids
    //===================================================================================================================
     
    private getAweaGroupElements(groupId:any)
    {
        let groupElements:any = [];

        if ( ExtensionUtil.isContentNotNull(groupId) && ExtensionUtil.isValidContent(this._aweaElements) )
        { 
            for (let currentElement of this._aweaElements) 
            {
                if (ExtensionUtil.isValidContent(currentElement.properties))
                {
                    for (let currentProp of currentElement.properties) 
                    {
                        if(currentProp.displayValue.includes(groupId))
                        {
                            groupElements.push(currentElement)
                        }
                    }
                } 
            }
        }
        else {  this._errCode = ExtensionsError.ERROR_INVALID_BULKPROPERTIES;  }

        return groupElements;
    }
          
    //=================================================================================================================
    // Loads Awea Elements so we dont need to do it again.
    //=================================================================================================================
 
    public loadAweaElements()
    {
        const instanceTree = this._viewer.model.getData().instanceTree;
        const dispValueSeparator = this._dispValueSeparator;

        if (ExtensionUtil.isContentNotNull(instanceTree))
        {
            const rootId = instanceTree.getRootId();    
            
            if (ExtensionUtil.isValidContent(rootId))
            {
                const dbIds = ViewerCore.getElements(instanceTree, rootId, this.p_options.WRITE_LOG_FILE);

                if (ExtensionUtil.isValidContent(dbIds))
                {
                    this._viewer.model.getBulkProperties(dbIds, [ ExtensionsNames.DESCRIPTION ], (elements) => {

                        this._aweaElements = ViewerCore.filterByAVEADesc(elements,AVEA_DescFilter.GET_ELEMENTS, dispValueSeparator);
                    });
                }
                else {  this._errCode = ExtensionsError.ERROR_ROOTID_WITHOUT_ELEMENTS;  }
            }
            else   { this._errCode = ExtensionsError.ERROR_INVALID_ROOTID; }
        }
        else {  this._errCode = ExtensionsError.ERROR_INVALID_INSTANCETREE; }
    }

    //=================================================================================================================

    private fillTableAndDrawChart(assemblies,barChartCanvas) : void
    {
        ViewerCore.writeToLogFile(assemblies,this.p_options.WRITE_LOG_FILE);

        this._dataTableAssemblies = assemblies;

        this.fillAssemblyTable(assemblies);

        this.drawBarChart(assemblies, barChartCanvas);

        this._viewer.clearThemingColors(this._viewer.model); // Clear all colors.

        this.setViewerThemingColor(assemblies);
      
    }
  
    //===================================================================================================================

    private setViewerThemingColor(assemblies)
    {
        const that = this;

        if (this.p_options.HILTI_SEARCH_MODE === Hilti_SearchMode.LOCATION_CODE )
        {
            ViewerCore.search(this._viewer,ExtensionsNames.HILTI_LOCATION_CODE,"",this.p_options.WRITE_LOG_FILE,function(assembliesData) 
            {
                if (ExtensionUtil.isValidContent(assembliesData))
                {
                    for (let assemblyItem of assembliesData) 
                    {
                        const displayValue = assemblyItem.properties[0].displayValue;
        
                        // Underscore.js | _.find() Function
                        // The _.find() function looks at each element of the list and returns the 
                        // first occurrence of the element that satisfy the condition. If any element
                        // of list is not satisfy the condition then it returns the undefined value.
        
                        let assembly = _.find(assemblies, function (assmbl) { return assmbl.identification === displayValue;  });
        
                        if (ExtensionUtil.isValidContent(assembly))
                        {
                            const color_no_blanks = assembly.state.defaultColor.trim();
                            const clr = colorToVec4( color_no_blanks );
                            var stateColor = new THREE.Vector4(clr[0], clr[1], clr[2], clr[3]);  
                            that._viewer.setThemingColor(assemblyItem.dbId, stateColor, null, true);
                        }
                    }
                }
            });  
        }
        else if (this.p_options.HILTI_SEARCH_MODE === Hilti_SearchMode.AVEVA_DESCRRIPTION )
        {
            const dispValueSeparator = this._dispValueSeparator;
            var instanceTree = this._viewer.model.getData().instanceTree;
            var rootId      = instanceTree.getRootId();
            var dbIds       = ViewerCore.getElements(instanceTree, rootId, this.p_options.WRITE_LOG_FILE);

            this._viewer.model.getBulkProperties(dbIds,  [  ExtensionsNames.DESCRIPTION ], (elements) => {

                for (var element of elements) 
                {
                    var names = ViewerCore.getNamesFromAVEAElement(element, dispValueSeparator);
                    for (let name of names) 
                    {
                        var assembly = _.find(assemblies, function (assmbl) {
                            return assmbl.identification === name;
                        });
                
                        if (assembly) 
                        {
                            var ids = ViewerCore.getDbIdFromAVEAElement(element);

                            for (let id of ids) 
                            {
                                const color_no_blanks = assembly.state.defaultColor.trim();
                                const clr = colorToVec4( color_no_blanks );
                                var stateColor = new THREE.Vector4(clr[0], clr[1], clr[2], clr[3]);
                                this._viewer.setThemingColor(id, stateColor, null, true);
                            }
                        }
                    }
                }
                return;
            });
        }


    }

    //===================================================================================================================

    private drawBarChart(assemblies, canvas) 
    {
        //==================================================================

        let sortedAssemblies = _.sortBy(assemblies, function (assembly)    // sort by  state.workflowSequence
        {
            return [assembly.state.workflowSequence];
        });

        //=================================================================    
        // uniq => Produces a duplicate-free version of the array  !!!!
        //=================================================================

        const lbls    = _.uniq(sortedAssemblies.map((a) => a.state.display));

        const colors  = _.uniq(sortedAssemblies.map((a) => a.state.defaultColor));

        // _.countyBy()
        // Sorts a list into groups and returns a count for the number of objects in each group. 
        // Similar to groupBy, but instead of returning a list of values, returns a count for the
        // number of values in that group.        

        const assemblyData = _.countBy(sortedAssemblies, function (a) { return a.state.display; });

        var assemblyCounts = [];

        _.forEach(assemblyData, function (e, k) 
        {
            assemblyCounts.push(e);
        });

        //===================================================================================

        this._barChartData = 
        {

            type: 'bar',
            data: 
            {
                labels: lbls,
                datasets: [
                {
                    scaleFontColor: "#FFFFFF",
                    pointLabelFontColor : "#FFFFFF",
                    label:          'Assemblies',
                    backgroundColor: colors,
                    data:            assemblyCounts,
                }]
            },
            options: 
            {

                chartArea: { backgroundColor: 'rgba(251, 85, 85, 0.4)' },
                responsive:     true,
                
                legend: 
                { 
                    labels: {
                        fontColor: 'red'
                    },
                    display: false 
                },

                title:  {  display: true, text: "Assemblies statistic",  },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            fontColor: '#CCCCCC'
                        },
                            gridLines: 
                            { 
                                color: '#444444',
                                zeroLineColor: '#AAAAAA' 
                            },
                        },
                    ],

                    xAxes: [{
                        ticks: {
                            fontColor: '#CCCCCC'
                        },
                        gridLines: 
                        { 
                            color: '#444444',
                            zeroLineColor: '#AAAAAA' 
                        }
                    }]


                },
            },
        };

        //======================================================

        if (this._barChart != null) 
        {
            this._barChart.destroy();
        }
    
        this._barChart = new Chart( canvas, this._barChartData);
        this._barChart.update();

        //=======================================================

    }

    //======================================================================================================================

    private fillAssemblyTable(assemblies) 
    {
        const that = this;

        this._assembliesTable = new Tabulator("#assembliesTable", {
          
            //dataTreeStartExpanded:true, //start with an expanded tree
            data:               assemblies,     //assign data to table
            layout:             "fitColumns",   //fit columns to width of table (optional)
            height:             "250px",
            selectable:         1,
            groupStartOpen:     false,
            groupToggleElement: "header",
            groupBy: function (assembly) { return assembly.state.display; } ,
            columns: [
              { title: "Identification",  field: "identification",          headerSort: false, headerFilter: true },
              { title: "State",           field: "state.display",           headerSort: false },
              { title: "StateNumber",     field: "state.workflowSequence",  visible: false },
            ],
            rowSelectionChanged: function (data, rows) {
  
                var label = document.getElementById("assemblyIdentification");
  
                if (data[0]) 
                {
                    label.innerHTML = data[0].identification;
  
                      new QRious({
                      element:          document.getElementById("qrCode"),
                      value:            data[0].identification,
                      foregroundAlpha:  0.8,
                      level:            "H",
                      padding:          5,
                  });
  
                }
            },                            
            rowClick: function (e, row) {
  
                //alert("Assembly " + row.getData().identification + " clicked!!!!");
                that.processIsolateItems(row.getData().identification);
            },       
  
  
        });           // end of new Tabulator 

        this._assembliesTable.setSort([ { column: "state.workflowSequence", dir: "asc" },  ]);

    }
 
    //======================================================================================================================

    private processIsolateItems = (identification:string) => { 

        if (ExtensionUtil.isValidContent(identification))
        {
            let ids = null;
            const that = this;

            if (this.p_options.HILTI_SEARCH_MODE === Hilti_SearchMode.LOCATION_CODE )
            {
                ViewerCore.search( this._viewer, ExtensionsNames.HILTI_LOCATION_CODE , identification, false, function (items) 
                {
                    if (ExtensionUtil.isValidContent(items))
                    {
                        ids = _.uniq(items.map((a) => a.dbId));
                        that._viewer.isolate(ids);
                        that._viewer.fitToView(ids);
                    }
                });
            }
            else if (this.p_options.HILTI_SEARCH_MODE === Hilti_SearchMode.AVEVA_DESCRRIPTION )
            {
                const items = this.getAweaGroupElements(identification);

                if (ExtensionUtil.isValidContent(items))
                {
                    const ids = _.uniq(items.map((a) => a.dbId));
                    this._viewer.isolate(ids);
                    this._viewer.fitToView(ids);
                }
            }
            else
            {
                this._errCode = ExtensionsError.ERROR_INVALID_HILTI_SEARCH_MODE;
            }
        }
    };

    //======================================================================================================================

    public processLoadData(bLoadData:boolean,barChartCanvas:HTMLCanvasElement) : void
    {
        this._errCode          = ExtensionsError.NO_ERROR;
        let   errInfoCode      = ExtensionsError.NO_ERROR; 
        const hiltiMode        = this.p_options.HILTI_SEARCH_MODE;
        const writeLog         = this.p_options.WRITE_LOG_FILE;
        const dispSeparator    = this._dispValueSeparator;

        ViewerCore.getModelIdentification(this._viewer.model,writeLog).then((modelIdent:string) => { 
        
            ViewerCore.getAssembliesCodes(this._viewer.model,hiltiMode,writeLog,dispSeparator).then((assembliesCodes:string[]) => { 

                if (!ExtensionUtil.isValidContent(modelIdent)) 
                {       
                    errInfoCode = ExtensionsError.ERROR_INVALID_MODELIDENTIFICATION;  // should not be null or undefined or empty-array
                }
                else
                {
                    if (!ExtensionUtil.isValidContent(assembliesCodes)) {                         // should not be null or undefined or empty-array

                        errInfoCode = ExtensionsError.ERROR_INVALID_ASSEMBLIECODES;  
                    }
                }

                if (errInfoCode === ExtensionsError.NO_ERROR)
                {
                    const s1 = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_ASSEMBLIES_EXTRACTED_1);
                    const assembInfo  = "<strong>" + assembliesCodes.length + "</strong>" + " " + s1;
                    const projectInfo = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_PROJECT) + " " +  "<strong>" + modelIdent + "</strong>";

                    this._parentPanel.setProjectInfo(projectInfo,assembInfo);

                    if (bLoadData)
                    {
                        // user want to load data again
                        this.doFetchData(modelIdent,barChartCanvas);
                    }
                    else
                    {
                        // user did NOT want to load data ... so , if we have filled the table already
                        // then....show these data ... again...by refreshing the Table

                        if (this._assembliesTable != null && this._dataTableAssemblies != null)
                        {
                            // draw table with already existing data .... redraw did not work unfortunatly !!!

                            this._assembliesTable.destroy();

                            this.fillAssemblyTable(this._dataTableAssemblies);
                        }

                        if (this._barChart != null && this._barChartData != null)
                        {
                            // draw chart with already existing data

                            if (this._barChart != null) 
                            {
                                this._barChart.destroy();
                            }
                        
                            this._barChart = new Chart( barChartCanvas, this._barChartData);
                            this._barChart.update();
                 

                            //this.drawBarChart(assemblies, barChartCanvas);
                        }
                    }
                }
                else
                {
                    let detailedInfo = "";

                    this._parentPanel.showGUIElements(false);
                    this._parentPanel.showButton(false);

                    // if ( (this.p_options.INFO_LEVEL === ExtensionInfoLevel.DETAILED)  && (this._errCode !== ExtensionsError.NO_ERROR) )
                    // {
                    //     detailedInfo = "<p>" + ExtensionUtil.getErrorText( this._errCode  ) + "</p>";
                    // }
                    // this._parentPanel.setErrorInfo( ExtensionUtil.getErrorText(ExtensionsError.ERROR_GLOBAL_INVALID_DATA) + detailedInfo );

                    const info = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_INITDB_PROJECT_WRONG_TYPE);
                    this._parentPanel.setErrorInfo(info);
                }
               
            })
            .catch((error_id) => {       // reject in getAssembliesCodes   liefert eine id von ExtensionsError

                this._parentPanel.showGUIElements(false);
                this._parentPanel.showButton(false);
                const info1 = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_INITDB_PROJECT_WRONG_TYPE);
                const info2 = ExtensionUtil.getErrorText( ExtensionsError.ERROR_INVALID_BULKPROPERTIES );
                this._parentPanel.setErrorInfo( info1 + "    [ Error: " + info2  + " ]");
            });
        })
        .catch((error_id) => {       // reject in getModelIdentification   liefert eine id von ExtensionsError

            this._parentPanel.showGUIElements(false);
            this._parentPanel.showButton(false);
            const info1 = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_INITDB_PROJECT_WRONG_TYPE);
            const info2 = ExtensionUtil.getErrorText( ExtensionsError.ERROR_INVALID_BULKPROPERTIES );
            this._parentPanel.setErrorInfo( info1 + "    [ Error: " + info2  + " ]");
        });        
    }

    //======================================================================================================================

    private doFetchData(modelIdent:string,barChartCanvas:HTMLCanvasElement) : void
    {
        const paramUrl = this.p_options.GET_ASSEMBLIES_URL + "?" + new URLSearchParams({ model: modelIdent,  });

        this._parentPanel.onNotifyStartLoadData();
  
        let  fetchPromise = fetch(paramUrl);

        fetchPromise.then((response) => { return response.json(); }).then((assemblies) => {  

            //====================================================================

            let errorInfo:string = null;

            if ( !ExtensionUtil.isValidContent( assemblies ) )
            {
                errorInfo = ExtensionUtil.getErrorText(ExtensionsError.ERROR_FETCH_DATA_EMPTY); 
            }
            if ( ExtensionUtil.isValidContent( assemblies.status ) )
            {
                if (assemblies.status === 400)
                {
                    errorInfo = ExtensionUtil.getErrorText(ExtensionsError.ERROR_FETCH_DATA_CAPTION) + 
                               ExtensionUtil.getErrorText(ExtensionsError.ERROR_FETCH_DATA_INFO);
                }
            }

            if (errorInfo != null)
            {
                this._hasDataLoaded = false; 
                this._parentPanel.onNotifyEndLoadData(errorInfo,null);
            }
            else
            {
                this._hasDataLoaded = true; 
                this._parentPanel.onNotifyEndLoadData(null,null);
                this.fillTableAndDrawChart(assemblies,barChartCanvas)
            }
        })
        .catch((error) => {

            this._hasDataLoaded = false; 
            const errInfo = ExtensionUtil.getErrorText(ExtensionsError.ERROR_FETCH_DATA_CAPTION) + 
                            ExtensionUtil.getErrorText(ExtensionsError.ERROR_FETCH_DATA_INFO);

            this._parentPanel.onNotifyEndLoadData(errInfo,null);

            this._parentPanel.showButton(false);
        });

    }

    //======================================================================================================================

    public hasDataLoaded() : boolean
    {
        return this._hasDataLoaded;
    }

    //======================================================================================================================

}


