import './css/PlayerExtension.css';
import  PlayerExtensionPanel  from "./PlayerExtensionPanel";
import ViewerToolkit from '../utils/ViewerToolkit'
import {ModelDataTypes} from '../utils/ViewerToolkit'
import { ExtensionsError, ExtensionUtil, PLAYER_ExtensionText } from '../utils/utils';
import Config from '../../../Config';

declare var THREE: any;

/*=====================================================================================================================
DESCRIPTION:
=======================================================================================================================
PlayerExtension

This Extension loads a 3D-Model, by clicking on start button, this extension will search 
within model for all objects with a certain propertyname and a certain value for this propertname,
then it will create a selection of these items and show them in the Viewer.
The Buttons of this Extension-Panel do have the following functionality:

Button:         start step-animation: 
                Shows each object of the current selection for a certain time before 
                automaticly navigate to the next Object.

Button:         Next: 
                Shows the NEXT object of the current selection"

Button:         Previous
                Shows the PREVIOUS object of the current selection"

Button:         Pause
                Stops or restarts ( toggles ) the step-animation and rotate-animation

Button:         Clear selection
                Removes the current selection and shows again the model with all its 
                objects. All buttons on this Extension-Panel ( except the start-button ) 
                dont have any functionality any more.

Button:         Rotate Animation
                Starts or stops ( toggles ) the rotate-animation for the current selected object.

Parameter:

SEARCH_PROPERTY_NAME:           "Manufacturer",      the name of the property, which will be used in the search-procedure to create an object-selection
SEARCH_DISPLAY_VALUE:           "Hilti",             the value of the property, which will be used in the search-procedure to create an object-selection 
PANEL_INITIAL_POS:              "20,90,82,364",      initial x1/y1 ( left-upper-corner) position, Panelwidth and Panelheight
WRITE_LOG_FILE:                 1,                   only for developer: 1 => writes internal resultm values and states to console.log,  0 => dont write    
ROTANIM_360_DEGREES_ROTATION:   14000,               duration (in milliseconds) for one full 360 degrees rotation for the rotate-animation 
STEPANIM_INITIAL_DURATION:      8000,                step-animation initial time in millisecond (!!)
STEPANIM_STEP_DURATION:         15000                the time-span (in milliseconds) for how long the current object will be
                                                     shown before the program automaticly will show the next object
=====================================================================================================================*/
export class PlayerExtension extends Autodesk.Viewing.Extension {
    
    //=================================================================================================================
    _panel      :any = null;
    _subToolbar :any = null;
    _options    :any = null;
    _viewer     :any = null;
    _allNodes = null;
    _viewerObjectTreeCreated:boolean = false;
    //=================================================================================================================

    
    public constructor(viewer:any, options:any) {

        super(viewer, options);

        this._options = options;
        this._viewer = viewer;


    //    this._viewer.setQualityLevel(true,true);  // useSAO ==> True or false to enable screen space ambient occlusion., useFXAA = True or false to enable fast approximate anti-aliasing.

       this._viewer.addEventListener( Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.onObjectTreeCreated.bind(this));
       this._viewer.addEventListener( Autodesk.Viewing.SELECTION_CHANGED_EVENT,   this.onViewerSelectionChanged);
       this._viewer.addEventListener( Autodesk.Viewing.MODEL_REMOVED_EVENT,       this.onViewerModelRemoved.bind(this));
    }
        
    //===================================================================================================================

    public load() : boolean {

        if (this.viewer.toolbar)  // Toolbar is already available, create the UI
        {
           this.createUI(); 
        }
        return true;
    }

    //===================================================================================================================

    public onToolbarCreated() : void {

        this.createUI();
    }

    //===================================================================================================================

    private createUI() : void {

        if (this._subToolbar == null)
        {
            // button to show the docking panel
            const actionToolbarButton = new Autodesk.Viewing.UI.Button('showPlayerPanel');

            actionToolbarButton.addClass('playerExtensionToolbarButton');
            actionToolbarButton.setToolTip('Model Player');

            actionToolbarButton.onClick = (e) => {  this.onToolbarButtonClicked(e);  }
            
            this._subToolbar = new Autodesk.Viewing.UI.ControlGroup('PlayerExtensionToolbar');
                
            this._subToolbar.addControl(actionToolbarButton);
            this.viewer.toolbar.addControl(this._subToolbar);
        }
    } 

    //===================================================================================================================

    public unload() : boolean 
    {
        this._viewer.removeEventListener( Autodesk.Viewing.SELECTION_CHANGED_EVENT,   this.onViewerSelectionChanged);
        this._viewer.removeEventListener( Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.onObjectTreeCreated);
        this._viewer.removeEventListener( Autodesk.Viewing.MODEL_REMOVED_EVENT,       this.onViewerModelRemoved);
        this._viewer.toolbar.removeControl(this._subToolbar);
        return true;
    }
 
    //===================================================================================================================

    private onToolbarButtonClicked(e:any) : void {

        if (this._panel == null) 
        {
            if (this._viewerObjectTreeCreated)
            {
                this._panel = new PlayerExtensionPanel(this._viewer, this._viewer.container, 'PlayerExtensionPanel', 'Model Player', this._options,this);
            }
            else
            {
                alert(ExtensionUtil.getErrorText(ExtensionsError.ERROR_VIEWER_INSTANCE_TREE));
            }
        }                
        if (this._panel !== null) 
            this._panel.setVisible(!this._panel.isVisible()); // toogle (show/hide) docking panel
    }

    //==================================================================================================================

    public onViewerSelectionChanged = (event:any) => { 

        if (this._panel !== null)   
        {
            if (this._panel.isVisible())
            {
                this._panel.onViewerSelectionChanged();
            }
        }
    }

    //=================================================================================================================

    public onViewerModelRemoved(event:any) : void 
    {
        if (this._panel !== null && this._panel != undefined)
        {
            if (this._panel.isVisible())
            {
                this._panel.onViewerModelRemoved();
            }
        }
    }

    //===================================================================================================================

    public onObjectTreeCreated(event:any) : void 
    {
        this._viewerObjectTreeCreated = true;
    }

    //===================================================================================================================

    public loadModelDataTypes(gettype:ModelDataTypes,parentDbId:number) : void
    {
        const that = this;

        if (gettype === ModelDataTypes.ALL_NODES)
        {
            ViewerToolkit.getNodes(this._viewer).then(function(result) {

                that._allNodes = [...result];   
                alert("got leafnodes " + that._allNodes.length)  
            });
        }

        if (gettype === ModelDataTypes.ALL_NODES_BY_PARENT_DBID)
        {
            ViewerToolkit.getNodes(this._viewer, parentDbId ).then(function(result) {

                that._allNodes = [...result];   
                alert("got leafnodes " + that._allNodes.length)  
            });
        }
    
        if (gettype === ModelDataTypes.ALL_FRAGMENTS)
        {
            ViewerToolkit.getFragments(this._viewer).then(function(result) {

                that._allNodes = [...result];
            });
        }

        if (gettype === ModelDataTypes.ALL_FRAGMENTS_BY_PARENT_DBID)
        {
            ViewerToolkit.getFragments(this._viewer,parentDbId).then(function(result) {

                that._allNodes = [...result];
            });
        }

        if (gettype === ModelDataTypes.ALL_LEAF_NODES)
        {
            ViewerToolkit.getLeafNodes02(this._viewer).then(function(result) 
            {
                 that._allNodes = [...result];  
                 alert("got leafnodes " + that._allNodes.length)  
            });       
        }

        if (gettype === ModelDataTypes.ALL_LEAF_NODES_BY_PARENT_DBID)
        {
            ViewerToolkit.getLeafNodes02(this._viewer,parentDbId).then(function(result) 
            {
                 that._allNodes = [...result];  
                 alert("got leafnodes " + that._allNodes.length)  
            });       
        }

        if (gettype === ModelDataTypes.ALL_LEAF_NODES_2)
        {
            ViewerToolkit.getAllLeafComponents(this._viewer, function ( dbids ) {

                that._allNodes = [...dbids ];   

            });
        }        
    }

    //===================================================================================================================
 
    public getModelDataTypes()
    {
        return this._allNodes;
    }
 
    //=================================================================================================================

    public static loadExtension(viewer:any)
    {
        const baseUrl      : string | undefined = Config.BaseUrl;
        const spinnerColor : string | undefined = Config.SpinnerColor;
        const spinnerSize  : string | undefined = Config.SpinnerSize;
        const spinnerSizeValue = parseInt(spinnerSize,10);

        const searchPropName:string | undefined   = Config.PlayerExtSearchPropName;
        const searchPropValue:string | undefined   = Config.PlayerExtSeachDispValue;
  
        const jumpStep:string  | undefined   = Config.PlayerExtJumpStepValue;
        const jumpStepValue     = parseInt(jumpStep,10);
  
        const rotAnim360Max:string | undefined  = Config.PlayerExtRotAnimMaxValue;
        const rotAnim360MaxValue = parseInt(rotAnim360Max,10);
  
        const rotAnim360Min:string | undefined  = Config.PlayerExtRotAnimMinValue;
        const rotAnim360MinValue = parseInt(rotAnim360Min,10);
  
        const rotAnimMaxFPS:string | undefined    =  Config.PlayerExtRotAnimMaxFPS;
        const rotAnimMaxFPSValue = parseInt(rotAnimMaxFPS,10);
  
        const stepAnimMin:string | undefined = Config.PlayerExtStepAnimMinValue;
        const stepAnimMinValue = parseInt(stepAnimMin,10);
  
        const stepAnimMax:string | undefined = Config.PlayerExtStepAnimMaxValue;
        const stepAnimMaxValue = parseInt(stepAnimMax,10);
  
        const init_options = {    
          
          SPINNER_COLOR:          spinnerColor,        // color of spinner bars 
          SPINNER_SIZE:           spinnerSizeValue,    // spinner size default = 30 
          SHOW_LABEL_WITH_DBID:   0,                    // new property !!  1 --> shows the infoLabel 
                                                        // about the current index position within the step-animation
                                                        // ALSO the dbid of the current activ element
  
          SEARCH_PROPERTY_NAME:      searchPropName,      // bei Anwendung von firestop model
          SEARCH_DISPLAY_VALUE:      searchPropValue,     // 
  
          //SEARCH_PROPERTY_NAME:  "Manufacturer",        // for test only
          //SEARCH_DISPLAY_VALUE:  "Hilti",               // for test only 
  
          PANEL_INITIAL_POS:       "4,4,164,602",         // (20,90) initial x1Pos,y1Pos,Width,Height of the panel
          BASKET_GRID_SIZE:        "494,300",
          BASKET_GRID_JUMP_STEP:   jumpStepValue,         // with Basket Grid button-jumps, jumps n-rows forward or backward  
  
          WRITE_LOG_FILE:           1,                    // 1 => write to console.log  .., 0 => dont write    
  
          ROTANIM_360_DEGREES_ROTATION_FORWARD:   rotAnim360MaxValue,    // max-rotate-animation duration for forward-anim and for one 360 degrees rotation  
          ROTANIM_360_DEGREES_ROTATION_BACKWARD:  rotAnim360MaxValue,    // max-rotate-animation duration for backward-anim and for one 360 degrees rotation  
          ROTANIM_360_DEGREES_MIN_ROTATION:       rotAnim360MinValue,    // min-rotate-animation duration for back and forward-anim and one 360 degrees rotation  
  
          STEPANIM_INITIAL_DURATION:    2000,                    // step-animation initial time in millisecond (!!)
  
          STEP_ANIM_MIN_DURATION:            stepAnimMinValue,   // shortest step-animation duration time in millisecond (!!)
  
          STEP_ANIM_MAX_DURATION_FORWARD:    stepAnimMaxValue,   // longest step-animation for forward-anim duration time in millisecond (!!)
          STEP_ANIM_MAX_DURATION_BACKWARD:   stepAnimMaxValue,   // longest step-animation for backward-anim duration time in millisecond (!!)
                                                           // for showing one single object of current seection 
          ROTANIM_MAX_FPS:                   rotAnimMaxFPSValue,  // max framerate for rotate-animation ... fast CPUs > 25  slow CPUs [15,20] d (!!)
          BASE_URL:                          baseUrl,             // web-server base-url for ( Axios ) 
        }

   
        viewer.loadExtension('Hilti.PlayerExtension', { init_options } );
    }
  
    //=================================================================================================================
}


