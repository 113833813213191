import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";
import {createMuiTheme, makeStyles, Theme} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Grow from "@material-ui/core/Grow";
import {TransitionProps} from "@material-ui/core/transitions";

export enum SeverityType {
	ERROR = 1,
	WARNING = 2,
	SUCCESS = 3,
	INFO = 4,
}

export enum VerticalOrigin {
	TOP = 1,
	BOTTOM = 2,
}

export enum HorizontalOrigin {
	LEFT = 1,
	CENTER = 2,
	RIGHT = 3,
}

export class DialogUtil {
	public static getHorizontalOrigin(hOriginType: HorizontalOrigin) {
		let hOrigin = "center";
		if (hOriginType == HorizontalOrigin.LEFT) hOrigin = "left";
		if (hOriginType == HorizontalOrigin.CENTER) hOrigin = "center";
		if (hOriginType == HorizontalOrigin.RIGHT) hOrigin = "right";
		return hOrigin;
	}

	public static getVerticalOrigin(vOriginType: VerticalOrigin) {
		let vOrigin = "top";
		if (vOriginType == VerticalOrigin.TOP) vOrigin = "top";
		if (vOriginType == VerticalOrigin.BOTTOM) vOrigin = "bottom";
		return vOrigin;
	}

	public static getSeverity(dlgType: SeverityType) {
		let severity = "info";
		if (dlgType == SeverityType.ERROR) severity = "error";
		if (dlgType == SeverityType.WARNING) severity = "warning";
		if (dlgType == SeverityType.SUCCESS) severity = "success";
		if (dlgType == SeverityType.INFO) severity = "info";
		return severity;
	}
}

function Alert(props: AlertProps) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		"& > * + *": {
			marginTop: theme.spacing(2),
		},
	},
}));

function SlideTransition(props: TransitionProps) {
	return <Slide {...props} direction="up" />;
}

function GrowTransition(props: TransitionProps) {
	return <Grow {...props} />;
}

export const g_darkThemeDialog = createMuiTheme({
	spacing: 14,

	palette: {
		type: "dark",

		common: {
			white: "#00FF00",
		},
		text: {
			primary: "#FFFFFF",
		},
		primary: {
			main: "#A3B1AC", //ff4400',
		},
		secondary: {
			light: "#0066ff",
			main: "#0044ff",
			contrastText: "#ffcc00",
		},
	},

	typography: {
		fontSize: 16,
		fontWeightRegular: 400,

		fontFamily: ["roboto", '"Segoe UI Symbol"'].join(","),
	},
});

//https://unosquare.github.io/2019/06/26/react-context-implementation.html

//https://codesandbox.io/s/unosquare-best-practices-react-context-2-0xy57?from-embed=&file=/src/componentC.tsx:239-349

// export default function ComponentC() {
// 	const { actions, isAuthenticated } = React.useContext(GlobalContext);

// const handleShowMessageClick = () =>
// actions.setSnackbarMessage("Message from component A");

export default function BaseSnackbar() {
	//=============================================================================================
	const _infoText = "data have been successfully saved";
	const _duration = 6000;
	const _vPosType = "top"; // top , bottom
	const _hPosType = "center"; // left, right, center
	const _severityType = "warning"; // warning, error, info, success
	const _transition = "slide"; // fade, grow, slide
	const _direction = "up"; // up, down

	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const [vertical, setVerticalPos] = React.useState("bottom");
	const [horizontal, setHorizontalPos] = React.useState("left");

	//=============================================================================================

	const onVerticalPosChange = (event) => {
		setVerticalPos(event.target.value);
	};

	//=============================================================================================

	const onHorizontalPosChange = (event) => {
		setHorizontalPos(event.target.value);
	};

	//=============================================================================================

	const handleClick = () => {
		setOpen(true);
	};

	//===============================================================================================

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	//===============================================================================================

	//===============================================================================================

	return (
		<div className={classes.root}>
			<Snackbar
				open={open}
				autoHideDuration={_duration}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				message={_infoText}
				action={
					<React.Fragment>
						<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</React.Fragment>
				}
			/>

			<Alert severity="error">This is an error message!</Alert>
			<Alert severity="warning">This is a warning message!</Alert>
			<Alert severity="info">This is an information message!</Alert>
			<Alert severity="success">This is a success message!</Alert>
		</div>
	);

	//===============================================================================================
}
