import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Typography from "@mui/material/Typography";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {Slider, TextField} from "@material-ui/core";

interface Props {
	onModelSelected: (setModelSelected: React.Dispatch<React.SetStateAction<boolean>>) => void;
	onRotate: (axis, value) => void;
	onMove: (axis, value) => void;
}

const TransformControls: React.FC<Props> = ({onModelSelected, onRotate, onMove}) => {
	const [modelSelected, setModelSelected] = useState(false);
	const [rotation, setRotation] = useState({x: 0, y: 0, z: 0});
	const [translation, setTranslation] = useState({x: 0, y: 0, z: 0});

	const handleRotationChange = (axis: string, value: number | number[]) => {
		setRotation((prev) => ({...prev, [axis]: value}));
		onRotate(axis, value);
	};

	const handleTranslationChange = (axis: string, value: number | number[]) => {
		setTranslation((prev) => ({...prev, [axis]: value}));
		onMove(axis, value);
	};

	useEffect(() => {
		onModelSelected(setModelSelected);
	}, [onModelSelected]);

	return (
		<div>
			<div style={{textAlign: "center"}}>
				<Typography variant="body1" style={{color: "#333"}}>
					{modelSelected ? "Ein Modell wurde ausgewählt." : "Bitte wählen Sie ein Modell aus."}
				</Typography>
			</div>
			<Grid container spacing={3}>
				{["x", "y", "z"].map((axis) => (
					<Grid item xs={12} key={axis}>
						<Typography variant="body2" gutterBottom style={{color: "#333"}}>
							Achse {axis.toUpperCase()}
						</Typography>
						<Typography variant="body2" style={{color: "#333"}}>
							Rotation
						</Typography>
						<Slider
							value={rotation[axis]}
							onChange={(event, value) => {
								handleRotationChange(axis, value);
							}}
							min={-360}
							max={360}
							track={false}
							valueLabelDisplay="auto"
							getAriaLabel={(value) => `${value} Grad`}
						/>
						<Typography variant="body2" style={{color: "#333"}}>
							Verschiebung
						</Typography>
						<Slider
							value={translation[axis]}
							onChange={(event, value) => {
								handleTranslationChange(axis, value);
							}}
							min={-5}
							max={5}
							track={false}
							valueLabelDisplay="auto"
							getAriaLabel={(value) => `${value} Einheiten`}
						/>
					</Grid>
				))}
			</Grid>
		</div>
	);
};

export default TransformControls;
