import JSZip from "jszip";
import {
	AssemblyChildObject,
	AssemblyObject,
	Client,
	CreateObject,
	FileObject,
	GenerateRequest,
	InstanceObject,
	ParameterObject,
	Product,
	ProductGroupChildResponse,
	ProductObject,
	ProductPerProductGroupResponse,
	ProductRequest,
	QuestAnswer,
	QuestAnswerResponse,
	SettingObject,
} from "../../../../clientKim";
import {SeverityType} from "../../utils/GuiComponents/ModalDialogs/BaseSnackbar";
import {FileFormat} from "../components/ProductExplorerConfig";
import {CreateNavigationTreeNode, updateNavigationTree} from "../components/ProductExplorerNavigationTree";

export interface IElement {
	id: string;
	name: string;
	icon: string;
	product: null | Product;
}

// Default values for element data
const _defaultDescriptionNodeName: string = "DESCRIPTION_NODE";
const _defaultImageNodeName: string = "INFOPIC";
const _defaultErrorNodeName: string = "UNKNOWN";

let storedId: null | string = null;

// Default values for product data
// const _defaultProductDataModelPrefix: string = "hilti\\";
const _defaultCacheExchangeXml = false;
const _defaultBooleanOp = "None";
// const _defaultLibrary = undefined;

export const GetGroupElements = async (client: Client, id: string, section: string, culture: string, snackbarCallback: Function, start: number = 0, limit: number = 0): Promise<IElement[]> => {
	const elements: IElement[] = [];

	await client
		.getChildGroups(id, section, culture, start, limit)
		.then((allElements: ProductGroupChildResponse) => {
			if (allElements.count <= 0) {
				return;
			}

			for (let element of allElements.children) {
				const nameNode = element.attributes.find((attribute) => attribute.name === _defaultDescriptionNodeName);
				const iconNode = element.attributes.find((attribute) => attribute.name === _defaultImageNodeName);

				const elementInfo: IElement = {
					id: element.id,
					name: nameNode ? nameNode.value : _defaultErrorNodeName,
					icon: iconNode ? iconNode.value : _defaultErrorNodeName,
					product: null,
				};
				elements.push(elementInfo);
			}
		})
		.catch((error) => {
			console.log(error);

			const errorMsg: string = "Something went wrong while getting children of product group" + id + ".\nFor more information read the console log.";
			snackbarCallback(SeverityType.ERROR, errorMsg, 5000);
		});
	return elements;
};

export const getProductsPerProductGroupResponse = async (client: Client, id: string, section: string, culture: string): Promise<ProductPerProductGroupResponse | null> => {
	let productPerProductGroup: ProductPerProductGroupResponse | null = null;
	const elementData = await client.getProductGroup(id, section, culture);
	if (elementData && elementData.hasProducts) {
		productPerProductGroup = await client.getChildProducts(elementData.id, section, culture, 0, 25, []);
	}
	return productPerProductGroup;
};

export const GetQAs = async (client: Client, id: string, section: string, culture: string, SnackbarCallback: Function, product: Product | null): Promise<QuestAnswer[]> => {
	const allQA: QuestAnswer[] = [];
	if (product) {
		const productDataRequest = BuildProductDataRequest(product, culture);
		await client
			.getProductData(productDataRequest)
			.then((productInformation: QuestAnswerResponse) => {
				productInformation.ccQuestAnswer.forEach((qa) => {
					allQA.push(qa);
				});
			})
			.catch((error) => {
				const errorMsg: string = "Something went wrong while getting product data for " + id + ".\nFor more information read the console log.";
				SnackbarCallback(SeverityType.ERROR, errorMsg, 5000);
			});
		return allQA;
	}
	// Get element data
	await client
		.getProductGroup(id, section, culture)
		.then(async (elementData) => {
			const productDataRequest = BuildProductDataRequest(elementData.product, culture);
			await client
				.getProductData(productDataRequest)
				.then((productInformation: QuestAnswerResponse) => {
					productInformation.ccQuestAnswer.forEach((qa) => {
						allQA.push(qa);
					});
				})
				.catch((error) => {
					const errorMsg: string = "Something went wrong while getting product data for " + id + ".\nFor more information read the console log.";
					SnackbarCallback(SeverityType.ERROR, errorMsg, 5000);
				});
		})
		.catch((error) => {
			const errorMsg: string = "Something went wrong while getting information about product group " + id + ".\nFor more information read the console log.";
			SnackbarCallback(SeverityType.ERROR, errorMsg, 5000);
		});

	return allQA;
};

const BuildProductDataRequest = (product: Product, culture: string): ProductRequest => {
	return new ProductRequest({
		cacheExchangeXml: _defaultCacheExchangeXml,
		model: product?.model,
		cultureId: culture,
		parametersListOrder: product?.parametersListOrder,
		parameters: product?.parameters as ParameterObject[],
	});
};

export const GetSectionFromCulture = (culture: string): string => {
	return culture.substring(culture.indexOf("-") + 1);
};

const BuildGltfRequest = (id: string, cultureId: string, questAnswers: QuestAnswer[], fileFormat: FileFormat, needsDownload: boolean): GenerateRequest => {
	const fileObject = new FileObject({
		format: fileFormat.format,
		name: "scene",
	});

	const updatedFileObject = addSettingsToFileObject(fileObject, fileFormat, needsDownload);

	const request = new GenerateRequest({
		cacheOutput: true,

		create: new CreateObject({
			files: [updatedFileObject],
			returnStreams: true,
			zipIfMultipleFiles: fileFormat.isZip,
		}),
		assembly: new AssemblyObject({
			cacheExchangeXml: false,
			instances: [
				new AssemblyChildObject({
					instance: new InstanceObject({
						attributes: [],
						id: id,
						product: new ProductObject({
							parametersListOrder: false,
							cultureId: cultureId,
							model: "main.cc3",
							parameters: questAnswers.map((qa) => {
								return new ParameterObject({
									question: qa.question.value,
									value: qa.answer.value,
								});
							}),
						}),
					}),
				}),
			],
		}),
	});

	return request;
};

const addSettingsToFileObject = (fileObject: FileObject, fileFormat: FileFormat, needsDownload: boolean): FileObject => {
	const updatedFileObject = new FileObject(fileObject.toJSON());
	updatedFileObject.settings = [
		new SettingObject({
			name: needsDownload ? "CREATE_" + fileFormat.description.toLocaleUpperCase() + "_FILE" : "CREATE_GLB_FILE",
			value: "true",
		}),
	];
	return updatedFileObject;
};

export const downloadProductFile = async (
	fileFormat: FileFormat,
	client: Client,
	viewer: Autodesk.Viewing.GuiViewer3D,
	id: string,
	cultureId: string,
	questAnswers: QuestAnswer[]
): Promise<boolean> => {
	try {
		const gltfRequest: GenerateRequest = BuildGltfRequest(id, cultureId, questAnswers, fileFormat, true);
		console.log(fileFormat);
		const data = await client.getGltf(gltfRequest);
		const glbBytes = Uint8Array.from(window.atob(data), (c) => c.charCodeAt(0));
		const blob = new Blob([glbBytes], {type: "model/gltf-binary"});
		const url = URL.createObjectURL(blob);
		const downloadLink = document.createElement("a");
		if (fileFormat.isZip) {
			const downloadLink = document.createElement("a");
			downloadLink.href = URL.createObjectURL(blob);
			downloadLink.download = "Product.zip";
			downloadLink.style.display = "none";
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink); // Reinigung nach dem Download
			return true;
		}
		downloadLink.href = url;
		downloadLink.download = "Product." + fileFormat.fileType; // Setzen Sie hier den gewünschten Dateinamen
		downloadLink.style.display = "none";
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const GetGltf = async (client: Client, viewer: Autodesk.Viewing.GuiViewer3D, id: string, cultureId: string, questAnswers: QuestAnswer[]): Promise<boolean> => {
	try {
		const gltfRequest: GenerateRequest = BuildGltfRequest(id, cultureId, questAnswers, {isZip: false, format: "glTF", description: "", fileType: ""}, false);
		const data = await client.getGltf(gltfRequest);
		await viewer.loadExtension("Autodesk.glTF");
		const glbBytes = Uint8Array.from(window.atob(data), (c) => c.charCodeAt(0));
		const blob = new Blob([glbBytes], {type: "model/gltf-binary"});
		const url = URL.createObjectURL(blob);
		const camera = viewer.navigation.getCamera();
		const savedPosition = new THREE.Vector3().copy(camera.position);
		const savedQuaternion = new THREE.Quaternion().copy(camera.quaternion);
		const savedFov = camera.fov;
		viewer.loadModel(url, {fileLoader: (Autodesk.Viewing as any).FileLoaderManager.getFileLoaderForExtension("glb")});
		if (storedId === id) {
			viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, function restoreCamera() {
				viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, restoreCamera);
				const camera = viewer.navigation.getCamera();
				camera.position.copy(savedPosition);
				camera.quaternion.copy(savedQuaternion);
				camera.fov = savedFov;
				camera.updateProjectionMatrix();

				viewer.impl.invalidate(true);
			});
		}
		viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, async function restoreCameraAndAdjustLights(event) {
			disableReflections(viewer);
			viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, restoreCameraAndAdjustLights);
			viewer.setLightPreset(1);
			viewer.setQualityLevel(true, true)
			viewer.impl.invalidate(true);
		});
		storedId = id;
		viewer.unloadModel(viewer.model);
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
};

function disableReflections(viewer: any) {
    const listOfMaterials = viewer.impl.matman()._materials;
    Object.keys(listOfMaterials).map(materialName => { 
        const material = listOfMaterials[materialName];
        material.reflectivity = 0;
        material.shininess = 30; 
        material.specular.set(0x000000);
        material.envMap = null;
        material.emissive.setHex(0x333333); 
        material.needsUpdate = true;
    });

}


