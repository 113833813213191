const Config = {
  Auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
  Auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  Auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  BaseUrl: process.env.REACT_APP_BASEURL,
  KimUrl: process.env.REACT_APP_KIMURL,
  SpinnerColor: process.env.REACT_APP_SPINNER_COLOR,
  SpinnerSize: process.env.REACT_APP_SPINNER_SIZE,
  SearchExtPropertyName: process.env.REACT_APP_SEARCH_EXT_PROPERTY_NAME,
  SearchExtDisplayValue: process.env.REACT_APP_SEARCH_EXT_DISPLAY_VALUE,
  PlayerExtSearchPropName:
    process.env.REACT_APP_PLAYER_EXT_SEARCH_PROPERTY_NAME,
  PlayerExtSeachDispValue:
    process.env.REACT_APP_PLAYER_EXT_SEARCH_DISPLAY_VALUE,
  PlayerExtJumpStepValue: process.env.REACT_APP_PLAYER_EXT_JUMP_STEP_VALUE,
  PlayerExtRotAnimMaxValue:
    process.env.REACT_APP_PLAYER_EXT_ROTANIM_360_MAX_VALUE,
  PlayerExtRotAnimMinValue:
    process.env.REACT_APP_PLAYER_EXT_ROTANIM_360_MIN_VALUE,
  PlayerExtRotAnimMaxFPS:
    process.env.REACT_APP_PLAYER_EXT_ROTANIM_MAX_FPS_VALUE,
  PlayerExtStepAnimMaxValue:
    process.env.REACT_APP_PLAYER_EXT_STEPANIM_MAX_VALUE,
  PlayerExtStepAnimMinValue:
    process.env.REACT_APP_PLAYER_EXT_STEPANIM_MIN_VALUE,
  DBInitExtDispValueSeparator:
    process.env.REACT_APP_DBINIT_EXT_DISPVALUE_SEPARATOR,
  DBInitExtPostAssembliesUrl:
    process.env.REACT_APP_DBINIT_EXT_POST_ASSEMBLIES_URL,
  LoadAssembExtGetAssemblyUrl:
    process.env.REACT_APP_LOADASSEMB_EXT_GET_ASSEMBLIES_URL,
  LoadAssembExtDispValueSeparator:
    process.env.REACT_APP_LOADASSEMB_EXT_DISPVALUE_SEPARATOR,
  PropertyExtFilter: process.env.REACT_APP_PROPERTY_EXT_FILTER,
  ModelPath: process.env.REACT_APP_MODEL_PATH,
  HiltiSearchMode: process.env.REACT_APP_HILTI_SEARCH_MODE,
  IsItAHDA: process.env.REACT_APP_ISITAHDA,
  States: process.env.REACT_APP_STATES,
  SupportedAutodeskForgeFileFormats:
    process.env.REACT_APP_AUTODESK_FORGE_FORMATS,
};

export default Config;
