import "./../css/ProductExplorerExtension.css";
import {InputAdornment, Snackbar, TextField, ThemeProvider} from "@material-ui/core";
import {Alert, Autocomplete, Color} from "@material-ui/lab";
import React from "react";
import {Client, Product, ProductPerProductGroupResponse, QuestAnswer} from "../../../../clientKim";
import ProductExplorerExtensionPanel from "../ProductExplorerExtensionPanel";
import ProductExplorerConfig from "./ProductExplorerConfig";
import {GetGroupElements, getProductsPerProductGroupResponse, GetQAs, GetSectionFromCulture, IElement} from "../services/ElementDataFetcher";
import ProductExplorerNavigationTree, {CreateNavigationTreeNode, INavigationTreeNode, updateNavigationTree} from "./ProductExplorerNavigationTree";
import {DialogUtil, g_darkThemeDialog, HorizontalOrigin, SeverityType, VerticalOrigin} from "../../utils/GuiComponents/ModalDialogs/BaseSnackbar";
//import CircularProgress from '@material-ui/core/CircularProgress';
import ReactCountryFlag from "react-country-flag";

	// Default values for element data
	const _defaultDescriptionNodeName: string = "DESCRIPTION_SHORT";
	const _defaultImageNodeName: string = "IMAGE_LARGE";
	const _defaultErrorNodeName: string = "UNKNOWN";

interface ProductExplorerComponentProps {
	parentPanel: ProductExplorerExtensionPanel;
	axiosApiClient: Client;
	cultures: string[];
	viewer: any;
	currentModel: string;
}

interface ProductExplorerComponentState {
	navigationTreeRoot: INavigationTreeNode;
	id: string;
	culture: string;
	section: string;
	groupElementsMap: Map<string, IElement>;
	currentGroupElements: IElement[];
	currentQuestAnswers: QuestAnswer[];

	isLoading: boolean;

	showConfig: boolean;
	snackbarState: ProductExplorerSnackbarState;
}

interface ProductExplorerSnackbarState {
	useSeverity: string;
	showAlert: boolean;
	alertMessage: string;
	alertDuration?: number;
}

//============================================ INIT ============================================

export default class ProductExplorereGUI extends React.Component<ProductExplorerComponentProps, ProductExplorerComponentState, ProductExplorerSnackbarState> {
	_useHorizontalOrigin: any = DialogUtil.getHorizontalOrigin(HorizontalOrigin.CENTER);
	_useVerticalOrigin: any = DialogUtil.getVerticalOrigin(VerticalOrigin.TOP);

	// Default values for root group
	_defaultRootId: string = "0";
	_defaultRootName: string = "Home";
	_defaultCultureId: string = "de-DE";

	// if need for testing
	// _defaultRootId: string = "100342_1";
	// _defaultRootName: string = "U_Joch";
	//_defaultRootId: string = "100343_1";
	//_defaultRootName: string = "L_Joch";

	constructor(props: any) {
		super(props);
		this.state = {
			navigationTreeRoot: CreateNavigationTreeNode(this._defaultRootId, this._defaultRootName, ""),

			id: this._defaultRootId,
			culture: this._defaultCultureId,
			section: GetSectionFromCulture(this._defaultCultureId),
			groupElementsMap: new Map<string, IElement>(),
			currentGroupElements: [],
			currentQuestAnswers: [],

			isLoading: false,
			showConfig: false,

			snackbarState: {
				useSeverity: DialogUtil.getSeverity(SeverityType.INFO),
				showAlert: false,
				alertMessage: "",
			},
		};
		this.props.parentPanel.setGUIPanel(this);
	}

	//============================================ SNACKBAR ============================================

	SetSnackBarState(alertType: SeverityType, alertMessage: string, showAlert: boolean, alertDuration?: number) {
		this.setState({
			snackbarState: {
				showAlert: showAlert,
				useSeverity: DialogUtil.getSeverity(alertType),
				alertMessage: alertMessage,
				alertDuration: alertDuration,
			},
		});
	}

	snackbarCallback = (alertType: SeverityType, alertMessage: string, alertDuration?: number) => this.SetSnackBarState(alertType, alertMessage, true, alertDuration);

	private onCloseAlertMessage = () => {
		this.SetSnackBarState(SeverityType.INFO, "", false);
	};

	setStateLoading() {
		this.setState({isLoading: true});
		this.SetSnackBarState(SeverityType.INFO, "Loading...", true);
	}

	setStateLoadingFinished() {
		this.setState({isLoading: false});
		this.SetSnackBarState(SeverityType.SUCCESS, "Finished loading...", false, 1000);
	}

	setStateLoadingError(errorMsg: string) {
		this.setState({isLoading: false});
		this.SetSnackBarState(SeverityType.ERROR, errorMsg, true, 5000);
	}

	toggleLoad = (loading: boolean) => {
		this.setState({isLoading: loading});
	};

	isSnackbarOpen(): boolean {
		return this.state.snackbarState.showAlert;
	}

	getSnackbarDuration(): number {
		return this.state.snackbarState.alertDuration;
	}

	getSnackbarSeverity(): string {
		return this.state.snackbarState.useSeverity;
	}

	getSnackbarMsg() {
		return this.state.snackbarState.alertMessage;
	}

	//============================================ GUI ============================================

	isLoading(): boolean {
		return this.state.isLoading;
	}

	async componentDidMount() {
		this.displayContent(this._defaultRootId);
	}

	async getContent(id: string): Promise<IElement[]> {
		if (this.isLoading()) {
			return null;
		}
		this.setStateLoading();
		const groupElements: IElement[] = await GetGroupElements(this.props.axiosApiClient, id, this.state.section, this.state.culture, this.snackbarCallback);
		return groupElements;
	}

	isContentGroup(groupElements: IElement[]): boolean {
		return groupElements.length > 0;
	}

	mapProductsPerProductGroupToIElements(products: ProductPerProductGroupResponse): IElement[] {
		const elements: IElement[] = [];
		if (products.children) {
			for (let product of products.children) {
				const nameNode = product.attributes?.find((attribute) => attribute.name === _defaultDescriptionNodeName);
				const iconNode = product.attributes?.find((attribute) => attribute.name === _defaultImageNodeName);
				const elementInfo: IElement = {
					id: product.id ? product.id : "",
					name: nameNode ? nameNode.value : _defaultErrorNodeName,
					icon: iconNode ? iconNode.value : _defaultErrorNodeName,
					product: product.product
				};
				elements.push(elementInfo);
			}
		}
		return elements;
	}

	async displayContent(id: string) {
		let content: IElement[] = await this.getContent(id);
		let isProduct = false
		if (content == null) {
			return;
		}
		if(content.length === 0){
			try {
				const productsPerProductGroup: ProductPerProductGroupResponse | null = await getProductsPerProductGroupResponse(this.props.axiosApiClient, id, this.state.section, this.state.culture)
				if(productsPerProductGroup){
					content = this.mapProductsPerProductGroupToIElements(productsPerProductGroup)
				}
			} catch(error: any){
				isProduct = true;
			}
		}
		if (this.isContentGroup(content) && !isProduct) {
			this.displayGroup(id, content);
		} else {
			const element = this.state.groupElementsMap.get(id);
			console.log(element, this.state.groupElementsMap)
			if (element !== undefined && element.product !== undefined) {
				await this.displayProduct(id, element.product);
			}
		}

		this.setStateLoadingFinished();
	}

	displayGroup(id: string, content: IElement[]) {
		updateNavigationTree(id, this.state.navigationTreeRoot, content);
		const newGroupElementsMap = new Map(this.state.groupElementsMap);
		content.forEach(element => {
			newGroupElementsMap.set(element.id, element);
		});
	
		this.setState({
			currentGroupElements: content,
			groupElementsMap: newGroupElementsMap,
			showConfig: false,
		});
	}
	

	async displayProduct(id: string, product: Product | null) {
		if (id === "0") {
			this.setStateLoadingError("Could not find any groups or products in root group");
			return;
		}
		const elementData = await GetQAs(this.props.axiosApiClient, id, this.state.section, this.state.culture, this.snackbarCallback, product);
		this.setState({showConfig: true, currentQuestAnswers: elementData});
	}

	LoadGroupElementsCallback = (id: string) => {
		this.setState({id: id});
		this.displayContent(id);
	};

	//=================================================================================================================
	render() {
		return (
			<div className="productExplorer-gui">
				{this.state.isLoading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							position: "absolute",
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundColor: "rgba(0, 0, 0, 0.5)",
							zIndex: 1000,
						}}>
						<p>Laden...</p>
					</div>
				) : (
					<div />
				)}
				<div id="SnackbarDiv" className="snackbar">
					<ThemeProvider theme={g_darkThemeDialog}>
						{
							<Snackbar
								open={this.isSnackbarOpen()}
								autoHideDuration={this.getSnackbarDuration()}
								anchorOrigin={{
									vertical: this._useVerticalOrigin,
									horizontal: this._useHorizontalOrigin,
								}}
								onClose={this.onCloseAlertMessage}>
								<Alert onClose={this.onCloseAlertMessage} severity={this.getSnackbarSeverity() as Color}>
									{this.getSnackbarMsg()}
								</Alert>
							</Snackbar>
						}
					</ThemeProvider>
				</div>
				<div className="productExplorer-container">
					<div className="top-div">
						<Autocomplete
							disablePortal
							options={this.props.cultures}
							renderInput={(params) => (
								<TextField
									{...params}
									InputProps={{
										...params.InputProps,
										startAdornment: (
											<>
												{params.InputProps.startAdornment}
												{this.state.culture && (
													<ReactCountryFlag
														countryCode={this.state.culture.replace(/\s/g, "").substring(3, 5)}
														svg
														cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
														cdnSuffix="svg"
														title={this.state.culture}
														style={{marginLeft: "0.5vw"}}
													/>
												)}
											</>
										),
									}}
								/>
							)}
							renderOption={(option) => (
								<>
									<ReactCountryFlag
										countryCode={option.replace(/\s/g, "").substring(3, 5)}
										svg
										cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
										cdnSuffix="svg"
										title={option}
									/>
									<p style={{marginTop: "auto", marginBottom: "auto", marginLeft: "0.5vw"}}>{option}</p>
								</>
							)}
							onChange={(event, value) => {
								if (!value) {
									value = this._defaultCultureId;
								}
								this.setState(
									{
										culture: value,
										section: GetSectionFromCulture(value),
										navigationTreeRoot: CreateNavigationTreeNode(this._defaultRootId, this._defaultRootName, ""),
									},
									() => this.getContent(this._defaultRootId)
								);
							}}
							style={{backgroundColor: "#fff"}}
							value={this.state.culture}
						/>
					</div>

					<div className="bottom-container">
						<div className="left-div">
							<ProductExplorerNavigationTree
								root={this.state.navigationTreeRoot}
								elements={this.state.currentGroupElements}
								handleClick={this.LoadGroupElementsCallback}></ProductExplorerNavigationTree>
						</div>
						<div className="right-div">
							<ProductExplorerConfig
								show={this.state.showConfig}
								questAnswers={this.state.currentQuestAnswers}
								viewer={this.props.viewer}
								id={this.state.id}
								cultureId={this.state.culture}
								client={this.props.axiosApiClient}
								setLoading={this.toggleLoad}></ProductExplorerConfig>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
