import { ViewerSettingsExtensionPanel } from "./ViewerSettingsExtensionPanel";
import '../global_css/switch.css';
import './css/ViewerSettingsExtension.css';

export class ViewerSettingsExtension extends Autodesk.Viewing.Extension {
    
    //=================================================================================================================
    _panel      :any = null;
    _subToolbar :any = null;
    _options    :any = null;
    _viewer     :any = null;
    _title      :string = "ViewerSettings";
    //=================================================================================================================

    public constructor(viewer:any, options:any) {
        super(viewer, options);
        Autodesk.Viewing.Extension.call(this, viewer, options);

        this._options = options;
        this._viewer = viewer;

      //  this.onObjectTreeCreated = this.onObjectTreeCreated.bind(this)
    }
    
    //===================================================================================================================

    public load() : boolean {

        if (this.viewer.toolbar)  // Toolbar is already available, create the UI
        {
       //     this._viewer.addEventListener( Autodesk.Viewing.SELECTION_CHANGED_EVENT,   this.onObjectSelectionChanged);
        //    this._viewer.addEventListener( Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.onObjectTreeCreated);
   
            this.createUI(); 
        }
        return true;
    }

    //===================================================================================================================

    public onToolbarCreated() : void {

        this.createUI();
    }

    //===================================================================================================================

    private createUI() : void {

        if (this._subToolbar == null)
        {
            // button to show the docking panel
            const actionToolbarButton = new Autodesk.Viewing.UI.Button('showViewerSettingsPanel');

            actionToolbarButton.addClass('viewerSettingsExtensionToolbarButton');
            actionToolbarButton.setToolTip(this._title);

            actionToolbarButton.onClick = (e) => {  this.onToolbarButtonClicked(e);  }
            
            this._subToolbar = new Autodesk.Viewing.UI.ControlGroup('viewerSerringsExtensionToolbar');
                
            this._subToolbar.addControl(actionToolbarButton);
            this.viewer.toolbar.addControl(this._subToolbar);

        }
    } 

    //===================================================================================================================

    public unload() : boolean 
    {
     //   this._viewer.removeEventListener( Autodesk.Viewing.SELECTION_CHANGED_EVENT,   this.onObjectSelectionChanged);
     //   this._viewer.removeEventListener( Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.onObjectTreeCreated);
        this._viewer.toolbar.removeControl(this._subToolbar);
        return true;
    }
 
    //===================================================================================================================

    private onToolbarButtonClicked(e:any) : void {

        if (this._panel == null) 
        {
            this._panel = new ViewerSettingsExtensionPanel(this.viewer, this.viewer.container, 'ViewerSettingsExtensionPanel', this._title, this.options);
        }                
        this._panel.setVisible(!this._panel.isVisible()); // toogle (show/hide) docking panel
    }

   //==================================================================================================================

   public onObjectSelectionChanged = (event:any) => { 

    }

    //=================================================================================================================

    public onObjectTreeCreated(event:any) : void 
    {
    }

    //===================================================================================================================

    public static loadExtension(viewer:any)
    {
        const init_options = {
            PANEL_INITIAL_POS:    "20,90,140,300",       // initial x1Pos,y1Pos,Width,Height of the panel
        }
        viewer.loadExtension('Hilti.ViewerSettingsExtension',  {  init_options  });
    }  
  
    //===================================================================================================================
}