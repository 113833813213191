//import '../css/ModelLoaderExtension.css';
import * as React from 'react';
import ModelLoaderExtensionPanel from '../ModelLoaderExtensionPanel';
import { ExtensionsError, ExtensionsInfo, ExtensionUtil } from '../../utils/utils';
import  {SeverityType,VerticalOrigin,HorizontalOrigin, DialogUtil, g_darkThemeDialog } from "../../utils/GuiComponents/ModalDialogs/BaseSnackbar";
import { Snackbar, ThemeProvider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ModelLoader from './ModelLoader';
import { Category, Client, ICategory, IModel } from '../../../../client';


export interface IModelCategory
{
    Id:number,
    Name:string,
    Description:string,
    Icon:string
    Models:IModel[];
}

interface CategorySelectionComponentProps 
{
    parentPanel:ModelLoaderExtensionPanel;
    axiosApiClient;
}

interface CategorySelectionComponentState
{
  categories:           IModelCategory[];
  modelInfos:           IModel[];

  loadingCategories:    boolean;
  loadingModelInfo:     boolean;
  currentLanguage:      number;
  currentCategoryName:  string;
  currentCategoryId:    string;
  rowCounter:number;

  showAlert:      boolean;
  alertMessage:   string;
  alertVOrigin:   VerticalOrigin;
  alertHOrigin:   HorizontalOrigin;
  alertType:      SeverityType;
  alertTransition: any;
  alertDuration:number;
}

export default class CategorySelectionComponent extends React.Component<CategorySelectionComponentProps,CategorySelectionComponentState>
{
    //=================================================================================================================
    private _curselModelIdentification:string      = "!";
    private _currentMouseOverCategoryName:string   = "";
    private _currentSelectedCategoryName:string    = "";
    private _maxRows    = 18;
    private _maxCols    = 1;
    private _maxChars   = 25;
    //=================================================================================================================

    constructor(prop:any)
    {
        super(prop);  
        this.state = { categories: [], modelInfos: [], loadingCategories: true, loadingModelInfo:true, 
                       currentLanguage: 1, currentCategoryName: "", rowCounter: 0 , currentCategoryId: "",
                       showAlert: false, alertMessage: "", alertVOrigin: VerticalOrigin.TOP, alertHOrigin: HorizontalOrigin.CENTER,
                       alertType:  SeverityType.INFO, alertTransition: undefined, alertDuration: 6000};

        this.props.parentPanel.setGUIPanel(this);                       
    }  

  //=================================================================================================================

  componentDidMount() 
  {
        this.getCategories();
  }

  //=================================================================================================================

  async getCategories()
  {
        await this.props.axiosApiClient.getCategories().then( (categories:Category[]) =>  {

            if (categories!== null)
            {
                if (ExtensionUtil.isValidContent(categories))
                {
                    const tmp_categories:IModelCategory[] = [];

                    for (let item of categories)
                    {
                        const modelsArray = item.models as IModel[];
                        const cx:IModelCategory = { Id: item.id, Name: item.name, Description: item.description, Icon: item.icon, Models: modelsArray };
                        tmp_categories.push(cx);
                    }
                    this.setState({ categories: tmp_categories, loadingCategories: false });
                }
                else
                {
                    const info = ExtensionUtil.getInfoText(ExtensionsInfo.NO_CATEGORYDATA_FOUND);
                    this.setState( {showAlert: true, alertType:  SeverityType.ERROR,  alertDuration: 6000,  alertMessage: info });
                }
            }
        })
        .catch((error) => { 
           
            let info = ExtensionUtil.getErrorText(ExtensionsError.ERROR_CONNECT_WEB_SERVER);
            this.setState( {showAlert: true, alertType:  SeverityType.ERROR,  alertDuration: 8000,  alertMessage: info });
        });
    }

    //=================================================================================================================
   
    private getModelInfosByCategory(curselCategory:any, categoryId:number, categoryName:string)
    {
        this._currentMouseOverCategoryName="";
        this._currentSelectedCategoryName = categoryName;

        const categoryModels:IModel[] = curselCategory.Models;

        for (let currCategoryModel of categoryModels)
        {
            if (currCategoryModel.modelUrn == this.props.parentPanel.getModelURN())
            {
                this._curselModelIdentification = currCategoryModel.id.toString();
                break;
            }
        }


    

        if (categoryModels.length > this._maxRows * this._maxCols)
        {
            // max 4 Columns 
            const we_need_cols = (categoryModels.length / this._maxRows) + 1.0;

            const new_maxCols = parseInt(we_need_cols.toString(),10);

            if ( new_maxCols < 5)
            {
                if ( this._maxCols != new_maxCols  && new_maxCols > this._maxCols )
                {
                    this._maxCols = new_maxCols;
                    this.props.parentPanel.enlargePanel( new_maxCols );
                }
            }
            else
            {
                alert("too many columns");
            }
        }

        this.setState({ modelInfos: categoryModels, loadingModelInfo: false });

        if (this.state.currentCategoryId.length > 0)
        {
            const element = document.getElementById( this.state.currentCategoryId );
            if (element != null)
            {
                element.setAttribute("class", "card-100");
            }            
        }    

        const categoryNumberString:string = "" + categoryId;    
        const element = document.getElementById( categoryNumberString );
        if (element != null)
        {
            element.setAttribute("class", "card-100-Active");
            this.setState( { currentCategoryId: categoryNumberString  }); 
        }
    }

    //=================================================================================================================
 
    onMouseOverCategory = (categoryName:string, categoryId:number)  => {

        if (categoryName === this.state.currentCategoryName)
        {
            this._currentMouseOverCategoryName = categoryName;
            return;
        }

        if (categoryName !== this._currentMouseOverCategoryName )
        {
            this._currentMouseOverCategoryName = categoryName;

            this.setState({ currentCategoryName: categoryName });
        }
    }

    //=================================================================================================================

    onMouseOutCategory = (categoryName:string, categoryId:number)  => {

        this._currentMouseOverCategoryName = "";

        if (categoryName !== this._currentSelectedCategoryName )
        {
            this.setState({ currentCategoryName: this._currentSelectedCategoryName });
        }
    }

    //=================================================================================================================

    private onLoadModel(modelInfoIdent:string,modelUrn:string) : void 
    {
        if (ExtensionUtil.isValidContent( modelUrn))
        {
            if (modelUrn.endsWith(ExtensionUtil.getSvfFileExtension()))
            {
                ModelLoader.loadModelLocal(  this.props.parentPanel.getViewer(), modelUrn);

                this._curselModelIdentification=modelInfoIdent;
                this.props.parentPanel.setModelURN( modelUrn );
                this.setState({ loadingModelInfo: false });
            }
            else
            {
                this.LoadModelFromWeb(modelUrn);
            }
        }
        else
        {
            const info = ExtensionUtil.getErrorText(ExtensionsError.ERROR_LOADMODEL_INVALID_MODELURL);
            this.setState( {showAlert: true, alertType:  SeverityType.ERROR,  alertDuration: 6000,  alertMessage: info });
        }
    }

    //=================================================================================================================

    async LoadModelFromWeb(modelUrn:string)
    {
        let token = await this.props.axiosApiClient.getForgeToken();
        ModelLoader.loadModelFromWeb( this.props.parentPanel.getViewer(), modelUrn,token.access_token);
    }

    //=================================================================================================================

    private onSetLanguage = () => {

        if (this.state.currentLanguage === 1)
            this.setState ({ currentLanguage: 2 });
        else
            this.setState ({ currentLanguage: 1 });
        
    }

    //=================================================================================================================

    incRowCounter = () => {

        const prev = this.state.rowCounter;
        this.setState( { rowCounter: prev + 1 });
       
      };

    //=================================================================================================================

    private onCloseAlertMessage = () => {

        this.setState( { showAlert: false } );
    };

    //=================================================================================================================

  private renderCategoriesWithSimpleCards(dataArray) {

    return (

        <div>
            <div className="category-container">
     
                <div className="category-grid">

                    {dataArray.map(item => 
    
                        <div className="category-grid-item">
                        
                            <div className="card-100 m-0 p-0"  id={item.Id}  key={item.Id}  >

                                <img className="card-img-bottom" 
                                    onMouseOver = { () => this.onMouseOverCategory( item.Name, item.Id ) }
                                    onMouseOut  = { () => this.onMouseOutCategory(  item.Name, item.Id ) }
                                    src= {item.Icon} alt="Card image"  
                                    onClick={ () => this.getModelInfosByCategory( item as ICategory, item.Id, item.Name  )}>
                                </img>
                                                            
                            </div>
                        </div>
                          
                   )}

                </div>
    
            </div>

            <div className="myspacer"></div>

            <div className="container m-0 p-0">


                <div className="row m-0 p-0">
                    <div className="col-xs-6 col-centered-3">{this.state.currentCategoryName}</div>
                </div>

                <div className="row m-0 p-0">
                    <div className="col-xs-6 col-centered-2">select model:</div>
                </div>

            </div>

        </div>
  
    );
  }

//=================================================================================================================

private renderModelInfos(dataArray) : JSX.Element
{
    let inx = 0;
    let useClass = "col-md-6 m-0 p-0";

    if (this._maxCols == 1)
        useClass = "col-md-12 m-0 p-0";
    if (this._maxCols == 2)
        useClass = "col-md-6 m-0 p-0";
    if (this._maxCols == 3)
        useClass = "col-md-4 m-0 p-0";
    if (this._maxCols == 4)
        useClass = "col-md-3 m-0 p-0";

    const modelInfosArray:IModel[] = Array( this._maxRows * this._maxCols );

    for (let currCol=0; currCol < this._maxCols; ++currCol)
    {
        for (let currRow=0; currRow < this._maxRows; ++currRow, ++inx)
        {
            const currIndex = (currRow * this._maxCols) + currCol;

            if ( inx >= dataArray.length)
            {
                const cx:IModel = { id: 0,   modelName: "",  modelUrn: "",   categoryId: 0 , category: null };
                modelInfosArray[currIndex] = cx;
            }
            else
            {
                modelInfosArray[currIndex] = dataArray[inx];
            }
        }
    }

    return ( 
    
            <div className="container m-3 pl-3">

                <div className="row">
            
             
                {modelInfosArray.map(item =>

                    <div className={useClass}>

                        { (this._currentMouseOverCategoryName.length === 0) ?

                            <div className="col-md-12" id="model-names-font" onClick={ () => this.onLoadModel(  item.id.toString(), item.modelUrn  )} >
                            {
                                (   (item.id !== 0) && (item.id.toString() === this._curselModelIdentification) ) ? <a href="#" title={item.modelName}><i className="fa fa-check"></i>&nbsp;{ExtensionUtil.limitText(item.modelName,this._maxChars)}</a> : <a href="#" title={item.modelName} >{ExtensionUtil.limitText(item.modelName,this._maxChars)}</a> 
                            }
                            </div>  : null
        
                         }
        
                    </div>
                )}

                </div>    

            </div>
    );
}

//=================================================================================================================

private renderFooter()
{
    const imgURL1 = "content/images/hilti-categories/flag_german_19.png";
    const imgURL2 = "content/images/hilti-categories/flag_german_19.png";
    const imgURL3 = "content/images/hilti-categories/flag_german_19.png";

    return ( 

            <div>
                {
                    (this.state.currentLanguage === 1) ? 
                    <div className="bottomleft"><img src= {imgURL1} alt="" width="19" height="11"  onClick={ () => this.onSetLanguage()}></img><label className="footerlabel">&nbsp;language</label></div> :
                    <div className="bottomleft"><img src= {imgURL2} alt="" width="19" height="11"  onClick={ () => this.onSetLanguage()}></img><label className="footerlabel">&nbsp;language</label></div>
                }
                <div className="bottomright"><img src= {imgURL3}  alt=""  width="19" height="11"></img><label className="footerlabel">&nbsp;market</label></div> 
            </div>
    );

}

//=================================================================================================================

  render() {

        let useSeverity:any         = DialogUtil.getSeverity(this.state.alertType);
        let useHorizontalOrigin:any = DialogUtil.getHorizontalOrigin(this.state.alertHOrigin);
        let useVerticalOrigin:any   = DialogUtil.getVerticalOrigin(this.state.alertVOrigin);

        let content_categories = this.state.loadingCategories ? <p><em>.</em></p> : this.renderCategoriesWithSimpleCards(this.state.categories);
        let content_modelInfos = this.state.loadingModelInfo ? <p><em>.</em></p> : this.renderModelInfos(this.state.modelInfos);
        let content_footer = this.renderFooter();

      return (

        <div>

            <div>     
                <ThemeProvider theme={g_darkThemeDialog}>
                <Snackbar 
                    open                = { this.state.showAlert } 
                    TransitionComponent = { this.state.alertTransition }
                    autoHideDuration    = { this.state.alertDuration } 
                    anchorOrigin={{
                        vertical:   useVerticalOrigin,
                        horizontal: useHorizontalOrigin,
                    }}   
                    onClose = { this.onCloseAlertMessage }>
                    <Alert 
                        onClose  = { this.onCloseAlertMessage } 
                        severity = { useSeverity }>
                        { this.state.alertMessage }
                    </Alert>
                </Snackbar>
                </ThemeProvider>
            </div>

          <div>
               {content_categories}
          </div>

          <div>
            {content_modelInfos}
          </div>
        
           <div>
                {content_footer}
           </div>

        </div>
      );
  }

 //=================================================================================================================

}



