import '../global_css/switch.css';
import './css/ViewerSettingsExtension.css';
import {ExtensionUtil} from '../utils/utils'
import ReactDOM from 'react-dom';
import React from 'react';
import ViewerSettingsGUIPanel from './components/ViewerSettingsGUIPanel';

export class ViewerSettingsExtensionPanel extends Autodesk.Viewing.UI.DockingPanel 
{
    //=================================================================================================================
    protected _initialXPos   = "80";
    protected _initialYPos   = "140";
    protected _initialWidth  = "500";
    protected _initialHeight = "400";
    private   _DOMContent:HTMLDivElement;
    private   _reactNode:any;
    private   _guiPanel:ViewerSettingsGUIPanel = null;
    public    _wrapper:HTMLDivElement;
    private   _viewer = null;
    private   _groundShadow = true;
    private   _lightPresets:string[] = [];
    private   _lightPresetIndex = -1;
    //=================================================================================================================

    constructor(viewer:any, container:any, id:any, title:any, options:any)  
    {
        super(viewer.container, id, title, options);

        this._viewer       = viewer;
        this._groundShadow = true;
        this._lightPresetIndex = -1;


        this._lightPresets.push("Simple Gray");
        this._lightPresets.push("Sharp Highlights");
        this._lightPresets.push("Dark Sky");
        this._lightPresets.push("Grey Room");
        this._lightPresets.push("Photo Booth");
        this._lightPresets.push("Tranquility");
        this._lightPresets.push("Infinity Pool");
        this._lightPresets.push("Simple White");
        this._lightPresets.push("Riverbank");
        this._lightPresets.push("Contrast");
        this._lightPresets.push("Rim Highlights"); 
        this._lightPresets.push("Cool Light"); 
        this._lightPresets.push("Warm Light"); 
        this._lightPresets.push("Soft Light");
        this._lightPresets.push("Grid Light");
        this._lightPresets.push("PLaza");
        this._lightPresets.push("Snow Field");


        if (ExtensionUtil.isContentNotNull(options.init_options.PANEL_INITIAL_POS))
        {
            const posString = options.init_options.PANEL_INITIAL_POS;
            const xyCoords = posString.split(',');
            if (xyCoords.length === 4)
            {
              this._initialXPos   = xyCoords[0];
              this._initialYPos   = xyCoords[1];
              this._initialWidth  = xyCoords[2];
              this._initialHeight = xyCoords[3];
            }
        }   

       this.container.classList.add("docking-panel-container-solid-color-a");  
       this.container.style.width       =  this._initialWidth +"px";  
       this.container.style.height      =  this._initialHeight +"px"; 
       this.container.style.minWidth    = "220px";
       this.container.style.minHeight   = "50px";
       this.container.style.resize      = "none";
       this.container.style.position    = "absolute";
       this.container.style.left        = this._initialXPos + "px";
       this.container.style.top         = this._initialYPos + "px";

        //=================================================================================
        this.container.classList.add('react-uni-docking-panel')
        //=================================================================================
        this._DOMContent = document.createElement('div')
        this._DOMContent.setAttribute("id", "default-dp");
        this._DOMContent.className = 'content'
        //=================================================================================
        this._wrapper = document.createElement('div');
        this._wrapper.setAttribute("class", "defaultcontainer flex-column d-flex p-0 m-0");
        this._wrapper.appendChild(this._DOMContent );
        //=================================================================================
        this.container.appendChild( this._wrapper ); 
        //=================================================================================
   
    }

    //===================================================================================================================

    public initialize() : void {

        this.title = this.createTitleBar(this.titleLabel || this.container.id);
        this.container.appendChild(this.title);
  
        this.initializeMoveHandlers(this.container);

        this.closer = this.createCloseButton();
        this.container.appendChild(this.closer);




        this.createScrollContainer({
            left: false,
            heightAdjustment: 45,
            marginTop:0
        });
        
        
        const button1   = document.createElement("button");
        const button2   = document.createElement("button");
        const button3   = document.createElement("button");
        const button4   = document.createElement("button");
        

        button1.setAttribute("class", "btn btn-secondary  btn-settings-button");
        button1.setAttribute("id", "viewer-settings-button");
        button1.innerHTML      = "set BackgroundColor";

        button2.setAttribute("class", "btn btn-secondary  btn-settings-button");
        button2.setAttribute("id", "viewer-settings-button");
        button2.innerHTML    = "set GroundShadow";

        button3.setAttribute("class", "btn btn-secondary  btn-settings-button");
        button3.setAttribute("id", "viewer-settings-button");
        button3.innerHTML    = " set LightMode ";

        button4.setAttribute("class", "btn btn-secondary btn-settings-button");
        button4.setAttribute("id", "viewer-settings-button");
        button4.innerHTML    = " set QualityLevel ";


        button1.onclick = (e) => {  this.onSetBackgroundColor(e);  }
        button2.onclick = (e) => {  this.onSetGroundShadow(e);  }
        button3.onclick = (e) => {  this.onSetLightMode(e);  }
        button4.onclick = (e) => {  this.onSetQualityLevel(e);  }

        this.scrollContainer.appendChild( button1 );
        this.scrollContainer.appendChild( button2 );
        this.scrollContainer.appendChild( button3 );
        this.scrollContainer.appendChild( button4 );
        
    
        this.footer = this.createFooter();
        this.container.appendChild(this.footer);
    }
  
    //=================================================================================================================

    public setGUIPanel(guiPanel:ViewerSettingsGUIPanel)
    {
        this._guiPanel = guiPanel;
    }

    //=================================================================================================================

    // setVisible (show) 
    // {
    //     if ( this._guiPanel)
    //     {
    //         //this._guiPanel.close();
    //     }

    //     super.setVisible(show)

    //     if (show) 
    //     {
    //         this._reactNode = ReactDOM.render(  <ViewerSettingsGUIPanel parentPanel = {this} />, this._DOMContent)
    //     }
    //     else if (this._reactNode)
    //     {
    //         ReactDOM.unmountComponentAtNode(this._DOMContent)
    //         this._reactNode = null  
    //     }
    // }
  
    //=================================================================================================================

    private onSetBackgroundColor(e:any) : void 
    {

        let red    = Math.random() * 255;
        let green  = Math.random() * 255;
        let blue   = Math.random() * 255;

        let red2     = red;
        let green2   = green;
        let blue2    = blue;

        red   = ((red    - 95) > 0 ) ? red   - 95 : red;
        green = ((green  - 95) > 0 ) ? green - 95 : green;
        blue  = ((blue   - 95) > 0 ) ? blue  - 95 : blue;

        red2   = ((red2   + 65) < 255 ) ? red2   + 65 : red2;
        green2 = ((green2 + 65) < 255 ) ? green2 + 65 : green2;
        blue2  = ((blue2  + 65) < 255 ) ? blue2  + 65 : blue2;

        this._viewer.setBackgroundColor( red,green,blue, red2,green2,blue2);
    }
  
    //=================================================================================================================

    private onSetGroundShadow(e:any) : void 
    {
        this._groundShadow = !this._groundShadow;

        this._viewer.setGroundShadow( this._groundShadow  );
    }
  
    //=================================================================================================================

    private onSetLightMode(e:any) : void 
    {

        if (this._lightPresetIndex +1 < this._lightPresets.length)
        {
            ++this._lightPresetIndex;      
        }
        else
        {
            this._lightPresetIndex = 0;
        }
        this._viewer.setLightPreset(this._lightPresetIndex);
    }
  
    //=================================================================================================================

    private onSetQualityLevel(e:any) : void 
    {
        //useSAO boolean True or false to enable screen space ambient occlusion.
        //useFXAA boolean True or false to enable fast approximate anti-aliasing.

        const useSAO  = true;
        const useFXAA = true;

        this._viewer.setQualityLevel( useSAO, useFXAA);

    }
  
    //=================================================================================================================



}



