import './css/UnifiExtension.css';
import { UnifiExtensionPanel } from "./UnifiExtensionPanel";
import Config from '../../../Config';

export class UnifiExtension extends Autodesk.Viewing.Extension {
    
    //=================================================================================================================
    private   _panel      :any = null;
    private   _subToolbar :any = null;
    private   _options    :any = null;
    private   _viewer     :any = null;
    private   _extensionTitle ="Data Export";

    //=================================================================================================================

    public constructor(viewer:any, options:any) {
        super(viewer, options);

        this._options = options;
        this._viewer = viewer;

      //  this.onObjectTreeCreated = this.onObjectTreeCreated.bind(this)
    }
    
    //===================================================================================================================

    public load() : boolean {

        return true;
    }

    //===================================================================================================================

    public onToolbarCreated() : void {

        this.createUI();
    }

    //===================================================================================================================

    private createUI() : void {

        if (this._subToolbar == null)
        {
            // button to show the docking panel
            const actionToolbarButton = new Autodesk.Viewing.UI.Button('showUnifiPanel');

            actionToolbarButton.addClass('unifiExtensionToolbarButton');
            actionToolbarButton.setToolTip( this._extensionTitle );
           
            this._subToolbar = new Autodesk.Viewing.UI.ControlGroup('UnifiExtensionToolbar');
                
            this._subToolbar.addControl(actionToolbarButton);
            this._viewer.toolbar.addControl(this._subToolbar);

            actionToolbarButton.onClick = (e) => {  this.onToolbarButtonClicked(e);  }
        }
    } 

    //===================================================================================================================

    public unload() : boolean 
    {
        // this._viewer.removeEventListener( Autodesk.Viewing.SELECTION_CHANGED_EVENT,   this.onObjectSelectionChanged);
        // this._viewer.removeEventListener( Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.onObjectTreeCreated);
        this._viewer.toolbar.removeControl(this._subToolbar);
        return true;
    }
 
    //===================================================================================================================

    private onToolbarButtonClicked(e:any) : void {

        if (this._panel == null) 
        {
            this._panel = new UnifiExtensionPanel(this._viewer, this._viewer.container, 'UnifiExtensionPanel', this._extensionTitle, this._options);
        }                
        this._panel.setVisible(!this._panel.isVisible()); // toogle (show/hide) docking panel
    }

   //==================================================================================================================

   public onObjectSelectionChanged = (event:any) => { 

    }

    //=================================================================================================================

    public onObjectTreeCreated(event:any) : void 
    {

    }

    //===================================================================================================================

    public static loadExtension(viewer:any)
    {
        const baseUrl      : string | undefined = Config.BaseUrl;
        const spinnerColor : string | undefined = Config.SpinnerColor;
        const spinnerSize  : string | undefined = Config.SpinnerSize;
        const spinnerSizeValue = parseInt(spinnerSize,10);
    
        const init_options = {   
          SPINNER_COLOR:           spinnerColor,        // color of spinner bars 
          SPINNER_SIZE:            spinnerSizeValue,    // spinner size default = 30 
          PANEL_INITIAL_POS:       "12,14, 1560,748",   // initial x1Pos,y1Pos,Width,Height of the panel
          WRITE_LOG_FILE:           1,                  // 1 => write to console.log  .., 0 => dont write    
          BASE_URL:                 baseUrl,            // web-server base-url for ( Axios ) 
        }
    
        viewer.loadExtension('Hilti.UnifiExtension', { init_options } );
    }  
    
    //===================================================================================================================

}