import React, {useState} from "react";
import {TreeItem, TreeView} from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {IElement} from "../services/ElementDataFetcher";
import Tooltip from '@material-ui/core/Tooltip';

export interface INavigationTreeNode {
	id: string;
	name: string;
	icon: string;
	children: INavigationTreeNode[];
}

export const CreateNavigationTreeNode = (id: string, name: string, icon: string): INavigationTreeNode => {
	return {
		id: id,
		name: name,
		icon: icon,
		children: [],
	};
};

export const GetNavigationTreeNode = (targetNodeId: string, startNode: INavigationTreeNode): INavigationTreeNode => {
	if (startNode.id === targetNodeId) {
		return startNode;
	}

	let result: INavigationTreeNode = null;
	for (let i = 0; result == null && i < startNode.children.length; i++) {
		result = GetNavigationTreeNode(targetNodeId, startNode.children[i]);
	}
	return result;
};

const _defaultImagePathPrefix: string = "https://labs.cadclick.com/ccDataService_HILTI/dataservice/PreviewImage/";
const _defaultGroupImageSearchString: string = "pg/";
const _defaultGroupImageReplaceString: string = "pg%2F";
const _defaultProductImageSearchString: string = "article/";
const _defaultProductImageReplaceString: string = "article%2F";
const _defaultErrorNodeName: string = "UNKNOWN";

const DisplayImage = (icon: string) => {
	if (icon === _defaultErrorNodeName) {
		return process.env.PUBLIC_URL + '/content/images/placeholder/icon_not_found.png';
	}

	let imgPath: string = icon.replace(_defaultGroupImageSearchString, _defaultGroupImageReplaceString);
	imgPath = imgPath.replace(_defaultProductImageSearchString, _defaultProductImageReplaceString);
	return _defaultImagePathPrefix + imgPath;
};

const BuildNavigationTree = (node: INavigationTreeNode, handleClick: Function, index: number) => {
	return (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      label={
        <div style={{display: "flex", alignItems: "center", gap: '10px'}}>
          <Tooltip title={<img src={DisplayImage(node.icon)} alt="" style={{maxWidth: '200px'}}/>}>
            <img 
              src={DisplayImage(node.icon)} 
              alt="" 
              style={{height: "1em", width: "1em"}}
            />
          </Tooltip>
		  <Tooltip title={node.name}>
        <div 
          style={{
            backgroundColor: index % 2 === 0 ? "#ffffff" : "#f2f2f2",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '200px' // adjust this as needed
          }}
        >
          {node.name}
        </div>
      </Tooltip>
        </div>
      }
      onLabelClick={(event) => {
        event.preventDefault();
        handleClick(node.id);
      }}
    >
      {node.children?.map((node, index) => BuildNavigationTree(node, handleClick, index))}
    </TreeItem>
	);
};

export const updateNavigationTree = (id: string, node: INavigationTreeNode, groupElements: IElement[]) => {
	const currentNavigationNode = GetNavigationTreeNode(id, node);
	if (currentNavigationNode == null) {
		return;
	}
	groupElements.forEach((element: IElement) => {
		if (!isNodeInTree(currentNavigationNode, element.id)) currentNavigationNode.children.push(CreateNavigationTreeNode(element.id, element.name, element.icon));
	});
};

const isNodeInTree = (currentNode: INavigationTreeNode, id: string): boolean => {
	return currentNode.children.find((child) => child.id === id) != undefined;
};

const ProductExplorerNavigationTree: React.FC<{
	root: INavigationTreeNode;
	elements: IElement[];
	handleClick: Function;
}> = ({root, elements, handleClick}) => {
	const [expanded, setExpanded] = useState<string[]>([]);

	const handleNodeToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
		setExpanded(nodeIds);
	};

	const handleLabelClick = (nodeId: string) => {
		const nodeIndex = expanded.indexOf(nodeId);
		if (nodeIndex === -1) {
			setExpanded([...expanded, nodeId]);
		} else {
			const newExpanded = [...expanded];
			newExpanded.splice(nodeIndex, 1);
			setExpanded(newExpanded);
		}
		handleClick(nodeId);
	};

	return Object.keys(root).length > 0 ? (
		<TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} expanded={expanded} onNodeToggle={handleNodeToggle}>
			{BuildNavigationTree(root, handleLabelClick, 0)}
		</TreeView>
	) : (
		<div></div>
	);
};

export default ProductExplorerNavigationTree;
