import './css/ShelfMakerExtension.css';

declare var THREE: any;


export class ShelfMakerExtension extends Autodesk.Viewing.Extension {

    //=================================================================================================================
    _button=null;
    _group=null;
    _ui=null;
    _panel      :any = null;
    _subToolbar :any = null;
    _viewer          =null;
    _sceneBuilder = null;
    _mesh = null;
    _modelBuilder = null;
    _shelves      = [];
    _panelGeometry      = null;
    _panelHeight        = 100; //default value
    _bottomPanelMesh    = null;
    _topPanelMesh       = null;
    _woodMaterial       = null;
    _refShelveMesh=null;
    _originalModelSize = 
        {
            "width": 210,
            "depth": 210,
            "height": 10
        };


    //=================================================================================================================

    public constructor(viewer:any, options:any) 
    {
        super(viewer, options);

        Autodesk.Viewing.Extension.call(this, viewer, options);

        this._viewer  = viewer;

        this.customize = this.customize.bind(this);

        this.addShelve              = this.addShelve.bind(this);
        this.removeShelve           = this.removeShelve.bind(this);
        this.toggleBottomPanel      = this.toggleBottomPanel.bind(this);
        this.toggleTopPanel         = this.toggleTopPanel.bind(this);
        this.updateTopPanelPosition = this.updateTopPanelPosition.bind(this);
        this.updateShelveHeight     = this.updateShelveHeight.bind(this);
    }

    //===================================================================================================================

    public load() : boolean {
        
     //   this._viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.customize);        
        return true;
    }

    //===================================================================================================================

    public xxxonToolbarCreated() {

        this.createUI();
    }

    //===================================================================================================================

    private createUI() : void {

        if (this._subToolbar == null)
        {

            this.configureUI();

            const actionToolbarButton = new Autodesk.Viewing.UI.Button('ShelfMaker');
            actionToolbarButton.onClick = () => {  this.onToolbarButtonClicked();  }
    
            actionToolbarButton.addClass('shelfMakerExtensionToolbarButton');
            actionToolbarButton.setToolTip('ShelfMaker');
    
            this._subToolbar = new Autodesk.Viewing.UI.ControlGroup('ShelfMakerExtensionToolbar');
         
            this._subToolbar.addControl(actionToolbarButton);
            this._viewer.toolbar.addControl(this._subToolbar);



        //==========================================================

        // let numShelves = document.getElementById("numShelves");

        // numShelves.onchange = (event) => {

        //    // if (event.target.value > this._shelves.length) {

        //         this.addShelve();
        //    // }
        //    // else
        //     {
        //      //   this.removeShelve()
        //     }

        //     this._viewer.fitToView();
        // }

        //==========================================================




        }
    } 

    //===================================================================================================================

    onToolbarCreated() 
    {
      // alert("aaaa");
        // Create a new toolbar group if it doesn't exist

        this._group = this.viewer.toolbar.getControl('ShelveToolbar');

        if (!this._group) 
        {
            this._group = new Autodesk.Viewing.UI.ControlGroup('ShelveToolbar');

            this._viewer.toolbar.addControl(this._group);
        }

        // Add a new button to the toolbar group

        this._button = new Autodesk.Viewing.UI.Button('ShelveConfiguration');

        this.configureUI();

        let panel = this._panel;

        if (panel == null) 
        {
            panel = new AKShelfMakerExtensionPanel(this._viewer, this._viewer.container,'shelfMakerPanel', 'ShelveMake Panel', {"innerDiv": this._ui});
        }


        this._button.onClick = (ev) => {

            const bCheck = panel.isVisible();

            panel.setVisible( !panel.isVisible());
        };

        this._button.setToolTip('ShelveConfiguration');

        this._button.addClass('shelfMakerExtensionToolbarButton');

        this._group.addControl(this._button);


        //==========================================================
        // link UI controls
        let bottomPanelCheckbox = document.getElementById("bottomPanel");

        bottomPanelCheckbox.onchange = (event) => {

            this.toggleBottomPanel();
            
            this.viewer.fitToView();
        }

        //==========================================================

        let topPanelCheckbox = document.getElementById("topPanel");

        topPanelCheckbox.onchange = (event) => {

            this.toggleTopPanel();

            this.viewer.fitToView();
        }

        //==========================================================

        let numShelves = document.getElementById("numShelves");

        numShelves.onchange = (event) => {

           // if (event.target.value > this._shelves.length) {

                this.addShelve();
           // }
           // else
            {
             //   this.removeShelve()
            }

            this.viewer.fitToView();
        }

        //==========================================================

        let shelveHeight = document.getElementById("shelveHeight");

        shelveHeight.onchange = (event) => {

        //    this.updateShelveHeight(parseInt(event.target.value));
            
            this.viewer.fitToView();
        }

    }

    //=================================================================================================================



    public unload() : boolean {
        this._viewer.toolbar.removeControl(this._subToolbar);
        return true;
    }
 
    //===================================================================================================================

    private onToolbarButtonClicked = () => { 
    
    
        if (this._panel == null) 
        {

            //this.customize();

            //this._panel = new TestExtensionPanel(this.viewer, this.viewer.container, 'TestExtensionPanel', 'Test', this.options);
            this._panel = new AKShelfMakerExtensionPanel(this._viewer, this._viewer.container,'shelfMakerPanel', 'ShelveMake Panel', {"innerDiv": this._ui});


        }                
        this._panel.setVisible(!this._panel.isVisible()); // toogle (show/hide) docking panel


    }

    //===================================================================================================================

    xcustomize()
    {
        alert("in customize");

        this._viewer.removeEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.xcustomize);

        this.viewer.loadExtension("Autodesk.Viewing.SceneBuilder").then(() => {

            this._sceneBuilder = this.viewer.getExtension("Autodesk.Viewing.SceneBuilder");

            this._sceneBuilder.addNewModel({})

                .then((modelBuilder) => {

                    this._modelBuilder = modelBuilder;

                    //window.modelBuilder = modelBuilder;

                    let geom = new THREE.BufferGeometry().fromGeometry(new THREE.BoxGeometry(5,5,5));

                    let phongMaterial = new THREE.MeshPhongMaterial({ color: new THREE.Color(1, 0, 0) });

                    phongMaterial = this._viewer.impl.matman()._materials["model:1|mat:0"];

                    this._mesh = new THREE.Mesh(geom, phongMaterial);

                    this._mesh.matrix = new THREE.Matrix4().compose(
                        new THREE.Vector3(0, 0, -20),
                        new THREE.Quaternion(0, 0, 0, 1),
                        new THREE.Vector3(1, 1, 1)
                    );

                    this._modelBuilder.addMesh(this._mesh);

            });

        })

    }

    //=================================================================================================================

    customize() 
    {
        const that = this;

     //   this._viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT,this.customize);

      //  this.viewer.setTheme("light-theme");

        this.viewer.loadExtension("Autodesk.Viewing.SceneBuilder").then(() => {
            
            that._sceneBuilder = this.viewer.getExtension("Autodesk.Viewing.SceneBuilder");

            that._sceneBuilder.addNewModel({}).then((modelBuilder) => {

                that._modelBuilder = modelBuilder;
                
                //window.modelBuilder = modelBuilder;

                that._panelGeometry = new THREE.BufferGeometry().fromGeometry(
                    new THREE.BoxGeometry(that._originalModelSize.width, that._panelHeight, that._originalModelSize.height));
                
                that._panelGeometry.name = "sidePanelGeom";

                // existing material can be found by calling `viewer.impl.matman()._materials`
                that._woodMaterial = that._viewer.impl.matman()._materials["model:1|mat:0"];

                that._refShelveMesh = that.getMeshFromFragment(0);

            });
        })
    }

    //=================================================================================================================


   getMeshFromFragment(fragmentId) 
   {

       let geom = new THREE.Geometry();

       let renderProxy = this.viewer.impl.getRenderProxy(this.viewer.model, fragmentId);

       let VE = Autodesk.Viewing.Private.VertexEnumerator;

       VE.enumMeshVertices(renderProxy.geometry, (v, i) => {

           geom.vertices.push(new THREE.Vector3(v.x, v.y, v.z));

       });

       VE.enumMeshIndices(renderProxy.geometry, (a, b, c) => {

           geom.faces.push(new THREE.Face3(a, b, c))

       });

       geom.computeFaceNormals();

       let mesh = new THREE.Mesh( new THREE.BufferGeometry().fromGeometry(geom), new THREE.MeshPhongMaterial({ color: new THREE.Color(1, 0, 0) }));

       mesh.matrix = renderProxy.matrixWorld.clone();

      // window.renderProxy = renderProxy;

       return mesh
   }

   //=================================================================================================================

   configureUI() 
   {
       this._ui = document.createElement("div");

       this._ui.id = "control_area";

       this._ui.classList.add("docking-panel-container-solid-color-a");

       this._ui.innerHTML = `
           <div id="controlsArea">
               <div><span>Number of shelves: </span><input type="number" min="1" max="10" value="1" id="numShelves"></div>
               <div><span>Inter shelve height: </span><input type="number" min="100" max="300" step="20" value="100" id="shelveHeight"></div>
               <div><span>Lower panel: </span><input type="checkbox" id="bottomPanel"></div>
               <div><span>Upper panel: </span><input type="checkbox" id="topPanel"></div>
           </div>
       `;

   }

   //=================================================================================================================

    addShelve() 
    {
        let shelveMesh = new THREE.Mesh(this._refShelveMesh.geometry, this._woodMaterial);

        shelveMesh.matrix = this._refShelveMesh.matrix.clone();

        shelveMesh.matrix.setPosition(new THREE.Vector3(  0, (this._panelHeight + this._originalModelSize.height) * (this._shelves.length + 1), 0));

        shelveMesh.dbId = 100 * this._shelves.length + 1; // any unused id



        const color = Math.floor(Math.random() * 16777215);
        const material = this.createColorMaterial(color);

        let sideMesh = new THREE.Mesh(this._panelGeometry, material ); //this._woodMaterial);

        // We want to alternate the position of the side panels: odd to right, even to left
        let position = (this._shelves.length % 2 == 0)
            ? new THREE.Vector3(
                0,
                (this._panelHeight + this._originalModelSize.height) / 2 * (this._shelves.length * 2 + 1),
                -100)
            : new THREE.Vector3(
                -(this._originalModelSize.width - this._originalModelSize.height) / 2,
                (this._panelHeight + this._originalModelSize.height) / 2 * (this._shelves.length * 2 + 1),
                0)

        let rotation = (this._shelves.length % 2 == 0)
            ? new THREE.Quaternion(0, 0, 0, 1)
            : new THREE.Quaternion(0, 0, 0, 1).setFromAxisAngle(new THREE.Vector3(0, 1, 0).normalize(), Math.PI / 2);



        sideMesh.matrix = new THREE.Matrix4().compose(position,rotation,new THREE.Vector3(1, 1, 1));

        sideMesh.dbId = 100*this._shelves.length + 2; // any unused id

        this._modelBuilder.addMesh(shelveMesh);

        this._modelBuilder.addMesh(sideMesh);

        this._shelves.push({ 
            "shelve":   shelveMesh,
            "side":     sideMesh
        });

        if (this._topPanelMesh) 
        {
            this.updateTopPanelPosition();
        }
    }



    createColorMaterial (color)
    {
           const newMaterial = new THREE.MeshPhongMaterial({
             specular:       new THREE.Color(color),
             side:           THREE.DoubleSide,
             reflectivity:   0.0, 
             color
         });
   
         const materials = this._viewer.impl.getMaterials();
   
         materials.addMaterial( color.toString(16), newMaterial, true);
   
         return newMaterial;
     }



  removeShelve() 
  {
      if (this._shelves.length > 0) 
      {
          let toRemove = this._shelves.pop();

          this._modelBuilder.removeMesh(toRemove.shelve);

          this._modelBuilder.removeMesh(toRemove.side);

          if (this._topPanelMesh)
          {
              this.updateTopPanelPosition();
          }
      }
  }

  //=================================================================================================================

  toggleBottomPanel()
  {
      if (this._bottomPanelMesh) 
      {
          this._modelBuilder.removeMesh(this._bottomPanelMesh);

          this._bottomPanelMesh = null;
      }
      else 
      {
          this._bottomPanelMesh = new THREE.Mesh(this._panelGeometry, this._woodMaterial);

          this._bottomPanelMesh.matrix = new THREE.Matrix4().compose(
              new THREE.Vector3(-(this._originalModelSize.width - this._originalModelSize.height) / 2,
                  -(this._panelHeight + this._originalModelSize.height) / 2,
                  0),
              new THREE.Quaternion(0, 0, 0, 1).setFromAxisAngle(new THREE.Vector3(0, 1, 0).normalize(), Math.PI / 2),
              new THREE.Vector3(1, 1, 1)
          );

          this._bottomPanelMesh.dbId = 3009; // any unused id
          this._modelBuilder.addMesh(this._bottomPanelMesh);
      }
  }

  //=================================================================================================================

  toggleTopPanel()
  {
      if (this._topPanelMesh) 
      {
          this._modelBuilder.removeMesh(this._topPanelMesh);
          this._topPanelMesh = null;
      } 
      else
      {
          this._topPanelMesh = new THREE.Mesh(this._panelGeometry, this._woodMaterial);

          // We want to alternate the position of the top panel
          let position = (this._shelves.length % 2 == 0)
              ? new THREE.Vector3(
                  0,
                  (this._panelHeight + this._originalModelSize.height) / 2 * (this._shelves.length * 2 + 1),
                  -100)
              : new THREE.Vector3(
                  -(this._originalModelSize.width - this._originalModelSize.height) / 2,
                  (this._panelHeight + this._originalModelSize.height) / 2 * (this._shelves.length * 2 + 1),
                  0)

          let rotation = (this._shelves.length % 2 == 0)
              ? new THREE.Quaternion(0, 0, 0, 1)
              : new THREE.Quaternion(0, 0, 0, 1).setFromAxisAngle(new THREE.Vector3(0, 1, 0).normalize(), Math.PI / 2);

          this._topPanelMesh.matrix = new THREE.Matrix4().compose(
              position,
              rotation,
              new THREE.Vector3(1, 1, 1)
          );

          this._modelBuilder.addMesh(this._topPanelMesh);
      }

  }

  //=================================================================================================================

  updateTopPanelPosition() 
  {
      if (this._topPanelMesh != null)
      { 
          let position = (this._shelves.length % 2 == 0) ? new THREE.Vector3(0,(this._panelHeight + this._originalModelSize.height) / 2 * (this._shelves.length * 2 + 1),-100)
              : new THREE.Vector3(-(this._originalModelSize.width - this._originalModelSize.height) / 2,(this._panelHeight + this._originalModelSize.height) / 2 * (this._shelves.length * 2 + 1), 0);

          let rotation = (this._shelves.length % 2 == 0) ? new THREE.Quaternion(0, 0, 0, 1) : new THREE.Quaternion(0, 0, 0, 1).setFromAxisAngle(new THREE.Vector3(0, 1, 0).normalize(), Math.PI / 2);

          this._topPanelMesh.matrix = new THREE.Matrix4().compose(position,rotation,new THREE.Vector3(1, 1, 1) );

          this._topPanelMesh.geometry = this._panelGeometry;

          this._modelBuilder.updateMesh(this._topPanelMesh);
      }
  }

  //=================================================================================================================

  updateShelveHeight(value) 
  {
      this._panelHeight = value;

      this._panelGeometry = new THREE.BufferGeometry().fromGeometry(new THREE.BoxGeometry(this._originalModelSize.width, this._panelHeight, this._originalModelSize.height));

      this._shelves.forEach((group, index) => {

          group.side.geometry = this._panelGeometry;
          group.side.geometry.computeBoundingBox();

          let sidePanelPosition = (index % 2 == 0)
              ? new THREE.Vector3(
                  0,
                  (this._panelHeight + this._originalModelSize.height) / 2 * (index * 2 + 1),
                  -100)
              : new THREE.Vector3(
                  -(this._originalModelSize.width - this._originalModelSize.height) / 2,
                  (this._panelHeight + this._originalModelSize.height) / 2 * (index * 2 + 1),
                  0);

          group.side.matrix.setPosition(sidePanelPosition);

          this._modelBuilder.updateMesh(group.side);


          let shelvePosition = new THREE.Vector3( 0, (this._panelHeight + this._originalModelSize.height) * (index + 1), 0);

          group.shelve.matrix.setPosition(shelvePosition);

          this._modelBuilder.updateMesh(group.shelve);

          this.updateTopPanelPosition();

          if ( this._bottomPanelMesh) 
          {
              this._bottomPanelMesh.geometry = this._panelGeometry;
              let bottomPanelPosition = new THREE.Vector3(
                  -(this._originalModelSize.width - this._originalModelSize.height) / 2,
                  -(this._panelHeight + this._originalModelSize.height) / 2,
                  0);

              this._bottomPanelMesh.matrix.setPosition(bottomPanelPosition);

              this._modelBuilder.updateMesh(this._bottomPanelMesh);
          }

      })
    }

}


// *******************************************
// Shelve Configuration Panel
// *******************************************
class AKShelfMakerExtensionPanel extends Autodesk.Viewing.UI.PropertyPanel 
{
    _viewer = null;

    constructor(viewer, container, id, title, options) 
    {
        super(container, id, title, options);

        this._viewer = viewer;

        Autodesk.Viewing.UI.DockingPanel.call(this, container, id, title, options);

        // the style of the docking panel
        // use this built-in style to support Themes on Viewer 4+

        this.container.classList.add('docking-panel-container-solid-color-a');

        this.container.id = "shelveConfigPanelContainer";

        this.container.appendChild(options.innerDiv);
    }
}