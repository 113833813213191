import '../css/DefaultExtension.css';
import * as React from 'react';
//import ReactDOM from 'react-dom';
import { ExtensionsError, ExtensionsInfo, ExtensionUtil } from '../../utils/utils';
import SpinnerCore from '../../utils/GuiComponents/SpinnerCore';

interface DefaultGUIPanelProps
{
   parentPanel;
}

interface DefaultGUIPanelState
{
}

export default class DefaultGUIPanel extends React.Component<DefaultGUIPanelProps,DefaultGUIPanelState>
{
    //============================================================
    private  _guiElementesInitialized=false;
    private   _spinner:SpinnerCore = null;
    //=============================================================

    constructor(prop:any)
    {
        super(prop);  

        // this.state = { selectedOption: null,    libraries: null, loadingLibraries: true,  
        //                curselLibrary: null,  loadingCurselLibrary: true, curselLibraryName: "",  
        //                showSpinner:true, canLoad: false, canExport: false, 
        //                showAlert:       false, 
        //                alertMessage:    "", 
        //                alertHOrigin:    HorizontalOrigin.CENTER, 
        //                alertVOrigin:    VerticalOrigin.TOP, 
        //                alertType:       SeverityType.INFO, 
        //                alertTransition: undefined, 
        //                alertDuration:   6000,
        //                showMessageBox:  false,
        //                dlgTitle:        "",
        //                dlgContentText:  "" };

        this.props.parentPanel.setGUIPanel(this);

        this._spinner = new SpinnerCore();
    }  

    //=================================================================================================================

    componentDidMount = () => {

        this.onGuiLoaded();
    }

    //=================================================================================================================
  
    componentWillUnmount = () => { 

    }

    //=================================================================================================================

    private onGuiLoaded()
    {
        if (!this._guiElementesInitialized)
        {
            this._guiElementesInitialized = true;

            // const toolTipInfo2   = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_UNIFI_EXPORT_RECORDS_INFO);
            // HtmlUtils.setButtonTextAndTooltip("id-button-export", null, toolTipInfo2 );

            this._spinner.init("id-spinnerContainer-default","id-spinnerPanel-default","id-spinnerCaptionPanel-default");
            this._spinner.initColor( "#56CF7D" ); //this.props.parentPanel.getSpinnerColor() );
            this._spinner.initSize ( 30 ); //this.props.parentPanel.getSpinnerSize() );

            this.before_longLastingCall();
        }
    }

    //=================================================================================================================

    private before_longLastingCall() : void
    {
      //  HtmlUtils.showHtmlElement("id-libraries-container",false);
        this._spinner.show(true);
    }

    //=================================================================================================================

    private after_longLastingCall() : void
    {
        this._spinner.show(false);
    }    
  
    //=================================================================================================================
    
    render() {


        return (
    
            <div>


                <div className="container m-0 p-0  flex-column d-flex" id="id-spinnerContainer-default">
                    <div id="id-spinnerCaptionPanel-default"/>
                    <div className="col-xs-6" id="id-spinnerPanel-default"/>
                </div>
                       
                <div className="container" id="grid-container">
                    <div className="row m-0 p-0">
                        <div className="col-12 p-0 m-0" id="currentLibContentTable"/>
                    </div>
                </div>


                <div className="container">

                    <div className="row">
                        <div className="col-sm">
                            One of three columns
                        </div>
                        <div className="col-sm">
                            One of three columns
                        </div>
                        <div className="col-sm">
                            One of three columns
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">

                        <div className="col-sm">
                            <button  id="def-button-1" type="button"  className="btn btn-danger btnx">Button-01</button>          	
                        </div>

                        <div className="col-sm">
                            <button  id="def-button-2" type="button"  className="btn btn-secondary">Button-2</button>
                        </div>

                        <div className="col-sm">
                            <button 
                                type="button"  
                                className="btn btn-outline-light download-button2"> 
                                <i className="fas fa-cloud-download-alt" aria-hidden="true"></i>
                            </button>
                        </div>

                    </div>
                </div>


                <div className="container">
                <div className="btn-group mybuttongroup">

                    <div className="row">

                        <div className="col-sm">
                            <button  id="def-button-1" type="button"  className="btn btn-danger">Button-01</button>          	
                        </div>

                        <div className="col-sm">
                            <button  id="def-button-2" type="button"  className="btn btn-secondary btnx">Button-2</button>
                        </div>
                      

                    </div>
                    </div>
                </div>




           </div>
        );
    }

    //=================================================================================================================
}



