import { createMuiTheme } from "@material-ui/core/styles";

const mui_darkTheme = createMuiTheme({

    palette: 
    {
        type: 'dark',
  
        primary: 
        {
           main: '#FFFFFF',
        },
    },
});

export { mui_darkTheme }

export class Material_UI_Themes
{

    //======================================================================================================================
    constructor()
    {
    }
 
    //======================================================================================================================
 
}