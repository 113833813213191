import "../../utils/GuiComponents/base.css";
import '../css/BimCadExtension.css';
import * as React from 'react';
import { ExtensionsError, ExtensionsInfo, ExtensionUtil } from '../../utils/utils';
import Tabulator from 'tabulator-tables'; //import Tabulator library
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { HtmlUtils } from '../../utils/HtmlUtils';
import ItemNumbersMainGUIPanel from './ItemNumbersMainGUIPanel';
import { g_darkThemeDialog } from "../../utils/GuiComponents/ModalDialogs/BaseSnackbar";
import { Typography } from "@material-ui/core";


const Transition = React.forwardRef(function Transition(
    props:  TransitionProps & { children?: React.ReactElement<any, any> },
    ref:    React.Ref<unknown>,
){
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface PortfolioGridDataRow
{
    ItemNumber:string;
}

interface PortfolioGUIPanelProps
{
   parentPanel;
}

interface PortfolioGUIPanelState
{
    canLoad:  boolean;
    canSave:  boolean;
    showMessageBox:  boolean;
    dlgTitle:        string;
    dlgContentText:  string;
}

export default class PortfolioGUIPanel extends React.Component<PortfolioGUIPanelProps,PortfolioGUIPanelState>
{
    //============================================================
    private  _portFolioGridControl     = null;
    private  _gridData:PortfolioGridDataRow[] = [];
    private  _storageIsEmpty = true;
    private _itemNumbersDataFileName = "Portfolio.txt";
    private _itemNumbersDataPath     = "content/data/";
    private _firstRowText            = "itemnr";
    private _FileNextLineChar  = "\n";
    private myInput_Portofolio;
    private upload;
    //=============================================================

    constructor(prop:any)
    {
        super(prop);  

        this.state = { 
            canLoad:  true,
            canSave:  false,
            showMessageBox:  false,
            dlgTitle:        "",
            dlgContentText:  "" };
    }  

    //=================================================================================================================

    componentDidMount = () => {

        const buttonElement = document.getElementById("btn_load_file_portofolio");
        if (buttonElement != null)
        {
            buttonElement.setAttribute("class", "btn btn-success btn-bimcad");
        }
        this.getPortfolioItemNumbersFromStorage();
    }
  
    //=================================================================================================================

    private async getPortfolioItemNumbersFromStorage()
    {
        this.startLongLastingProcess(true);

        let allItemNumbers:string[] = [];
        allItemNumbers = await this.props.parentPanel.props.parentPanel.getApiClient().getPortfolioItemNumbersFromStorage();
        if (allItemNumbers.length > 0)
        {
            let index = 0;
            for (let currItem of allItemNumbers)
            {
                const d1:PortfolioGridDataRow = { ItemNumber:  currItem };
                this._gridData.push(d1);
            }
        }
      
        this._storageIsEmpty = allItemNumbers.length > 0 ? false : true;
        this.finishLongLastingProcess(null,null,true);
    }

    //=================================================================================================================

    private async isPortfolioItemNumbersStorageEmpty() 
    {
        let allItemNumbers:string[] = [];
        allItemNumbers = await this.props.parentPanel.props.parentPanel.getApiClient().getPortfolioItemNumbersFromStorage();
        this._storageIsEmpty = allItemNumbers.length > 0 ? false : true;
    }

   //=================================================================================================================
   public startLongLastingProcess(clearGrid:boolean)
    {
        if (clearGrid)
            this._gridData = [];

        this.props.parentPanel.showAppBar(false);

        HtmlUtils.showHtmlElement("portfolio-grid-container",   false);
        HtmlUtils.showHtmlElement("portfolio-buttons-container",false);

        this.props.parentPanel.showSpinner(true);
    }

    //=================================================================================================================

    public finishLongLastingProcess(stateInfo:string,buttonText:string,setGridData:boolean)
    {
        this.props.parentPanel.showSpinner(false);

        this.props.parentPanel.showAppBar(true);

        HtmlUtils.showHtmlElement("portfolio-grid-container",   true);
        HtmlUtils.showHtmlElement("portfolio-buttons-container",true);

        this.fillPortFolioDataGrid(this._gridData,setGridData);
    }

    //======================================================================================================================

    private fillPortFolioDataGrid(data:PortfolioGridDataRow[],groupStartOpenFlag:boolean) 
    {
        this._portFolioGridControl = new Tabulator("#portfolio-datagrid", {
        
            //dataTreeStartExpanded:true, //start with an expanded tree
            data:               data,            // assign data to table
            layout:             "fitColumns",    // fit columns to width of table (optional)
            height:             "620px",
            selectable:         1,
            groupStartOpen:     groupStartOpenFlag,
            groupToggleElement: "header",
                   
            columns:[                 

                { title: "Portfolio-ItemNumber",  field: "ItemNumber",  headerFilter: true , headerSort: true,   width:"100%",  widthGrow: 1, widthShrink: 1  }

            ],
        });
    }

    //======================================================================================================================
    
    private async onSaveToDatabase(event:any) {

        await this.isPortfolioItemNumbersStorageEmpty();

        if ( this._storageIsEmpty == true)
        {
            await this.saveItemNumbers();
        }
        else
        {
            this.onOpenMessageBox();  // storage NOT EMPTY .., first ask, whether to save/overwrite data
        }
    }

    //======================================================================================================================

    private async saveItemNumbers()
    {
        const allItemNumbers:string[] = [];
        const allRows = this._portFolioGridControl.getRows('active'); 

        if (ExtensionUtil.isValidContent( allRows ))
        {
            this.startLongLastingProcess(false);
            for ( let currRow of allRows)
            { 
                const currItemNumber  = currRow.getData().ItemNumber;
                allItemNumbers.push( currItemNumber );
            } 
            let bRetval = await this.props.parentPanel.props.parentPanel.getApiClient().savePortfolioItemNumbersToStorage(allItemNumbers);
            if ( bRetval === true )
            {
                let info  = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_ITEMNUMBER_RECORDS_SAVED_OK);
                    info  = info.replace("%", allRows.length.toString() );
                    this.props.parentPanel.showSuccessMessage(info);
            }
            else
            {
                let info  = ExtensionUtil.getErrorText(ExtensionsError.ERROR_RECORDS_COULD_NOT_BE_SAVED);
                this.props.parentPanel.showErrorMessage(info);
            }
            this.finishLongLastingProcess(null,null,false);
        }
    }

    //======================================================================================================================

    private onClose = (event) => {

        this.props.parentPanel.onClose(event);
    }

    //======================================================================================================================

    onChangeFile(event) 
    {
        event.stopPropagation();
        event.preventDefault();

        var file = event.target.files[0];

        if (file === undefined)
        {
            // User closed OpenFileDialog with cancel !!
        }
        else
        {
            if (file !== null)
            {
                this.getItemNumbersFromFile2(file);
                console.log(file);
            }
            else
            {
                let info  = ExtensionUtil.getErrorText(ExtensionsError.ERROR_INVALID_PORTFOLIO_FILE);
                this.props.parentPanel.showErrorMessage(info);
            }
        }
    }

    //======================================================================================================================

    private async getItemNumbersFromFile2(file)
    {
        let isValidPortfolioFile  = false;
        const lineBreak_windows   = "\r\n";
        const lineBreakLinux      = "\n";

        this.startLongLastingProcess(true);

        const reader = new FileReader();

        reader.readAsText(file);

        reader.onload = (event:any) => {

            const data_stream = reader.result.toString();

            let dataArray:string[] = [];

            if (data_stream.search(lineBreak_windows) > -1) // windows-file
            {
                dataArray = data_stream.split( lineBreak_windows);
            }
            else if (data_stream.search( lineBreakLinux) > -1)  // linux - file
            {
                dataArray = data_stream.split( lineBreakLinux );
            }
       
            if (dataArray.length > 1)
            {
                let index = 0;
                let skip  = false;

                for (let currItem of dataArray)
                {
                    if (index++ == 0)
                    {
                        let  firstItemText = currItem.toLowerCase();

                        if (firstItemText.length > this._firstRowText.length)
                        {   // linux-stream-item
                            firstItemText = firstItemText.substring(0, this._firstRowText.length);
                        }

                        if ( firstItemText === this._firstRowText)
                        {
                            skip = true;
                            isValidPortfolioFile = true;
                        }
                        else
                        {
                            break;
                        }
                    }
                    if (!skip)
                    {
                       const d1:PortfolioGridDataRow = { ItemNumber:  currItem };
                       this._gridData.push(d1);
                    }
                    skip = false;
                }
            }

            if (isValidPortfolioFile===false)
            {
                let info  = ExtensionUtil.getErrorText(ExtensionsError.ERROR_INVALID_PORTFOLIO_FILE);
                this.setState( {canSave: false });
                this.props.parentPanel.showErrorMessage(info);
            }
            else
            {
                let info  = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_ITEMNUMBER_RECORDS_LOADED_OK);
                const item_number_count = dataArray.length - 1;
                info   = info.replace("%", item_number_count.toString() );
                this.props.parentPanel.showSuccessMessage(info);
                this.setState( {canSave: true });
            }
            this.finishLongLastingProcess(null,null,true);
        };
    }

    //=================================================================================================================

    private onCancelMessageBox = () => {

        this.setState( { showMessageBox: false } );
    };

    //=================================================================================================================

    private onOKMessageBox = () => {

        this.setState( { showMessageBox: false } );
        this.saveItemNumbers();
    };

    //=================================================================================================================
    
    private onCloseMessageBox = () => {

        this.setState( { showMessageBox: false } );
    };

    //=================================================================================================================

    private onOpenMessageBox()
    {
        let infoText = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_WARNING);
        const title  = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_WARNING);
        this.setState( { showMessageBox: true, dlgTitle: title, dlgContentText: infoText });
    }

    //=================================================================================================================


   render() {


    return (

        <div>
       

                <div>     
                    <ThemeProvider theme={g_darkThemeDialog}>
                    <Dialog
                        open   =  { this.state.showMessageBox }
                        TransitionComponent =   { Transition }
                        keepMounted
                        onClose             =   {  this.onCloseMessageBox }
                        aria-labelledby     =   "alert-dialog-slide-title"
                        aria-describedby    =   "alert-dialog-slide-description">

                        <DialogTitle id="alert-dlg-title-ic">{  this.state.dlgTitle }</DialogTitle>
                        
                        <DialogContent>
                            <DialogContentText id="alert-dlg-ic">
                                <Typography display="block">
                                    { this.state.dlgContentText }
                                </Typography>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={ this.onOKMessageBox }     color="primary">OK</Button>
                            <Button onClick={ this.onCancelMessageBox } color="primary">Cancel</Button>
                        </DialogActions>

                    </Dialog>
                    </ThemeProvider>
                </div>      

            <div className="portfolio-grid-container"  id="portfolio-grid-container">
                <div className="portfolio-grid-content">
                    <div id="portfolio-datagrid"/>
                </div>
            </div>     

            <div className="portfolio-buttons-container" id="portfolio-buttons-container">
                <div className="row ml-1 p0">
                    <div className="xxx">

                        <input id="myInput_Portofolio"
                            type="file"
                            ref={(ref) => this.upload = ref}
                            style={{display: 'none'}}
                            onChange={this.onChangeFile.bind(this)}
                        />
                        

                        <button 
                            className="btn btn-bimcad" 
                            id="btn_load_file_portofolio"
                            onClick={() => this.upload.click() }>Open File</button> 
                
                        <button className="btn  btn-secondary btn-bimcad"
                            onClick={ (e) => this.onSaveToDatabase(e)} 
                            disabled={!this.state.canSave}
                            type="button">Save</button>
                    
                        <button className="btn  btn-secondary btn-bimcad"
                            onClick={ (e) => this.onClose(e)} 
                            type="button">Close</button>
                    </div>
                </div>
            </div>
         
       </div>
    );
}

//=================================================================================================================
}

