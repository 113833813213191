// Library
import React from "react";
import MaterialJsonSchemaForm from "react-jsonschema-form-material-ui";
import {Paper, Typography, Button, Select, MenuItem, CircularProgress, Box} from "@material-ui/core";
import {CloudDownload} from "@material-ui/icons";
// CSS
import "./../css/ProductExplorerExtension.css";

// Internals
import uiSchema from "../Schemes/uiSchema.json";
import {QuestAnswer, Client} from "../../../../clientKim";

import {downloadProductFile, GetGltf} from "../services/ElementDataFetcher";

interface IFormData {
	[key: string]: any;
}

interface IFormField {
	key: string;
	value: string | number;
}

export interface FileFormat {
	isZip: boolean;
	format: string;
	description: string;
	fileType: string;
}

const fileFormats: FileFormat[] = [
	{isZip: false, format: "glTF", description: "GLB", fileType: "glb"},
	{isZip: true, format: "glTF", description: "GLTF", fileType: "glTF"},
	{isZip: false, format: "DWG", description: "DWG", fileType: "dwg"},
];

const ProductExplorerConfig: React.FC<{
	show: boolean;
	questAnswers: QuestAnswer[];
	viewer;
	id: string;
	cultureId: string;
	client: Client;
	setLoading: (loading: boolean) => void;
}> = ({show, questAnswers, viewer, id, cultureId, client, setLoading}) => {
	const [formData, setFormData] = React.useState<null | IFormData>(null);
	const [selectedFormat, setSelectedFormat] = React.useState<FileFormat>(fileFormats[0]);
	const [downloading, setDownloading] = React.useState(false);

	const IsExternalParameter = (qa: QuestAnswer | any): boolean => {
		if (!qa.isInternalParameter) {
			return false;
		}
		return qa.isInternalParameter === "false";
	};

	const BuildJSONSchema = (questAnswers: QuestAnswer[]) => {
		const jsonData = {
			title: "Config",
			type: "object",
			properties: {},
		};
		questAnswers.forEach((element) => {
			if (IsExternalParameter(element)) {
				const sanitizedValue = element.question.value.replace(/[.]/g, '_');
				if (element.type == "EDIT") {
					jsonData.properties[sanitizedValue] = {
						title: element.question.translation,
						type: "integer",
						minimum: +element.minMax?.min,
						maximum: +element.minMax?.max,
					};
				} else {
					jsonData.properties[sanitizedValue] = {
						type: "string",
						title: element.question.translation,
					};
	
					jsonData.properties[sanitizedValue].enum = element.answerItemList?.answerItem.map((a) => a.value);
				}
			}
		});
		return jsonData;
	};
	

	const BuildJSONFormSchema = (questAnswers: QuestAnswer[]): IFormData => {
		let jsonData: IFormData = {};
		questAnswers.forEach((element) => {
			if (IsExternalParameter(element)) {
				const sanitizedValue = element.question.value.replace(/[.]/g, '_');
				if (element.type == "EDIT") {
					jsonData[sanitizedValue] = parseInt(element.minMax.nom, 10);
				} else {
					jsonData[sanitizedValue] = element.answer.value;
				}
			}
		});
		return jsonData;
	};
	

	const onSubmit = async (submittedData: IFormData) => {
		setLoading(true);
		updateQuestAnswers(submittedData);
		await GetGltf(client, viewer, id, cultureId, questAnswers);
		setLoading(false);
	};

	const updateQuestAnswers = (submittedData: IFormData) => {
		console.log(submittedData)
		let i: number = 0;
		questAnswers.forEach((qa) => {
			if (IsExternalParameter(qa)) {
				const sanitizedValue = qa.question.value.replace(/[.]/g, '_');
				if (submittedData["formData"][sanitizedValue].toString() !== sanitizedValue) {
					questAnswers[i].answer.value = submittedData["formData"][sanitizedValue].toString();
				}
			}
			i++;
		});
	};

	const handleFormatChange = (event: React.ChangeEvent<{value: unknown}>) => {
		const selectedDescription = event.target.value as string;
		const selectedFormat = fileFormats.find((format) => format.description === selectedDescription);
		setSelectedFormat(selectedFormat);
	};

	const handleDownload = async () => {
		setDownloading(true);
		await downloadProductFile(selectedFormat, client, viewer, id, cultureId, questAnswers);
		setDownloading(false);
	};

	const onFormChanged = ({formData}: IFormData) => {
		setFormData(formData);
	};

	const onError = (errors) => {
		console.log("errors", errors);
	};

	const renderNonConfigurableProduct = () => (
		<Paper style={{padding: "20px", marginTop: "0vh", backgroundColor: "#fff", color: "#424242", borderRadius: "0px"}}>
			<Typography variant="h5" style={{marginBottom: "20px"}}>
				Das ausgewählte Produkt ist nicht konfigurierbar.
			</Typography>
			<Typography variant="body1" style={{marginBottom: "20px"}}>
				Sie können es dennoch im Viewer laden.
			</Typography>
			<Button variant="contained" color="primary" onClick={onSubmit}>
				Dieses Produkt laden
			</Button>
			<Typography variant="body1" style={{marginTop: "15px"}}>
				Dieses Produkt herunterladen:
			</Typography>
			<Box sx={{display: "flex", alignItems: "center"}}>
				<Select value={selectedFormat.description} onChange={handleFormatChange} style={{marginRight: "10px"}}>
					{fileFormats.map((fileFormat, index) => (
						<MenuItem key={index} value={fileFormat.description}>
							{fileFormat.description}
						</MenuItem>
					))}
				</Select>
				{downloading ? <CircularProgress /> : <CloudDownload color="primary" style={{cursor: "pointer"}} onClick={handleDownload} />}
			</Box>
		</Paper>
	);

	const renderConfigurableProduct = () => (
		<>
			<Paper style={{paddingLeft: "20px", paddingTop: "0", marginTop: "0vh", paddingBottom: "0", marginBottom: "0", backgroundColor: "#fff", color: "#424242", borderRadius: "0px"}}>
				<Typography variant="body1" style={{marginTop: "15px"}}>
					Dieses Produkt herunterladen:
				</Typography>
				<Box sx={{display: "flex", alignItems: "center"}}>
					<Select value={selectedFormat.description} onChange={handleFormatChange} style={{marginRight: "10px"}}>
						{fileFormats.map((fileFormat, index) => (
							<MenuItem key={index} value={fileFormat.description}>
								{fileFormat.description}
							</MenuItem>
						))}
					</Select>
					{downloading ? <CircularProgress /> : <CloudDownload color="primary" style={{cursor: "pointer"}} onClick={handleDownload} />}
				</Box>
			</Paper>
			<MaterialJsonSchemaForm
				schema={BuildJSONSchema(questAnswers)}
				uiSchema={uiSchema}
				formData={BuildJSONFormSchema(questAnswers)}
				onChange={onFormChanged}
				onSubmit={onSubmit}
				onError={onError}
			/>
		</>
	);

	const renderProduct = () => {
		if (questAnswers.length === 0 || Object.keys(BuildJSONSchema(questAnswers).properties).length === 0) {
			return renderNonConfigurableProduct();
		}
		return renderConfigurableProduct();
	};

	return show ? renderProduct() : <div />;
};

export default ProductExplorerConfig;
