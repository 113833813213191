
import './css/ItemNumberCrawlerExtension.css';
import '../UnifiExtension/css/UnifiExtension.css';
import { ItemNumberCrawlerExtensionPanel } from "./ItemNumberCrawlerExtensionPanel";
import Config from '../../../Config';

export class ItemNumberCrawlerExtension extends Autodesk.Viewing.Extension {
    
    //=================================================================================================================
    _panel      :any = null;
    _subToolbar :any = null;
    _options    :any = null;
    _viewer     :any = null;
    //=================================================================================================================

    public constructor(viewer:any, options:any) {
        super(viewer, options);
        Autodesk.Viewing.Extension.call(this, viewer, options);

        this._options = options;
        this._viewer = viewer;
    }
    
    //===================================================================================================================

    public load() : boolean {

        return true;
    }

    //===================================================================================================================

    public onToolbarCreated() : void {

        this.createUI();
    }

    //===================================================================================================================

    private createUI() : void {

        if (this._subToolbar == null)
        {
            // button to show the docking panel
            const actionToolbarButton = new Autodesk.Viewing.UI.Button('showItemNumberPanel');

            actionToolbarButton.addClass('itemNumberCrawlerExtensionToolbarButton');
            actionToolbarButton.setToolTip('ItemNumberCrawler');
           
            this._subToolbar = new Autodesk.Viewing.UI.ControlGroup('ItemNumberCrawlerExtensionToolbar');
                
            this._subToolbar.addControl(actionToolbarButton);
            this._viewer.toolbar.addControl(this._subToolbar);

            actionToolbarButton.onClick = (e) => {  this.onToolbarButtonClicked(e);  }


        }
    } 

    //===================================================================================================================

    public unload() : boolean 
    {
        this._viewer.toolbar.removeControl(this._subToolbar);
        return true;
    }
 
    //===================================================================================================================

    private onToolbarButtonClicked(e:any) : void {

        if (this._panel == null) 
        {
            this._panel = new ItemNumberCrawlerExtensionPanel(this._viewer, this._viewer.container, 'ItemNumberCrawlerPanel', 'ItemNumberCrawler', this._options);
        }                
        this._panel.setVisible(!this._panel.isVisible()); // toogle (show/hide) docking panel
    }

    //==================================================================================================================

    public static loadExtension(viewer:any)
    {
        const spinnerColor : string | undefined = Config.SpinnerColor;
        const spinnerSize  : string | undefined = Config.SpinnerSize;
        const spinnerSizeValue = parseInt(spinnerSize,10);
        const baseUrl: string | undefined = Config.BaseUrl;

        const init_options = {
            SPINNER_COLOR:           spinnerColor,       // color of spinner bars 
            SPINNER_SIZE:            spinnerSizeValue,   // size of spinner bars 
            PANEL_INITIAL_POS:       "20,90, 1480,748",  // initial x1Pos,y1Pos,Width,Height of the panel
            WRITE_LOG_FILE:           1,                 // 1 => write to console.log  .., 0 => dont write    
            BASE_URL:                baseUrl             // web-server base-url for ( Axios ) 
       }
 
       viewer.loadExtension('Hilti.ItemNumberCrawlerExtension', {  init_options  });
    }  
 
   //==================================================================================================================
}