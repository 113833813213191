import  ModelLoaderExtensionPanel  from "./ModelLoaderExtensionPanel";
import './css/ModelLoaderExtension.css';
import Config from '../../../Config';
import Axios from "axios";
import { Client } from "../../../client";
import ModelLoader from "./components/ModelLoader";

export class ModelLoaderExtension extends Autodesk.Viewing.Extension {
    
    //=================================================================================================================
    private _panel      :any = null;
    private _subToolbar :any = null;
    private _options    :any = null;
    private _viewer     :any = null;
    private _allItems = null;
    private  _extensionName = "Model Selection";
    //=================================================================================================================

    public constructor(viewer:any, options:any) {

        super(viewer, options);
        this._options = options;
        this._viewer = viewer;

        this.onObjectTreeCreated = this.onObjectTreeCreated.bind(this)
    }
    
    //===================================================================================================================

    public load() : boolean {

        if (this.viewer.toolbar)  // Toolbar is already available, create the UI
        {
            this._viewer.addEventListener( Autodesk.Viewing.SELECTION_CHANGED_EVENT,   this.onObjectSelectionChanged);
            this._viewer.addEventListener( Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.onObjectTreeCreated);
   
            this.createUI(); 
        }
        return true;
    }

    //===================================================================================================================

    public onToolbarCreated() : void {

        this.createUI();
    }

    //===================================================================================================================

    private createUI() : void {

        if (this._subToolbar == null)
        {
            // button to show the docking panel
            const actionToolbarButton = new Autodesk.Viewing.UI.Button('showModelLoaderPanel');

            actionToolbarButton.addClass('modelLoaderExtensionToolbarButton');
            actionToolbarButton.setToolTip( this._extensionName );

            actionToolbarButton.onClick = (e) => {  this.onToolbarButtonClicked(e);  }
            
            this._subToolbar = new Autodesk.Viewing.UI.ControlGroup('ModelLoaderExtensionToolbar');
                
            this._subToolbar.addControl(actionToolbarButton);
            this.viewer.toolbar.addControl(this._subToolbar);

        }
    } 

    //===================================================================================================================

    public unload() : boolean 
    {
        this._viewer.removeEventListener( Autodesk.Viewing.SELECTION_CHANGED_EVENT,   this.onObjectSelectionChanged);
        this._viewer.removeEventListener( Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.onObjectTreeCreated);
        this._viewer.toolbar.removeControl(this._subToolbar);
        return true;
    }
 
    //===================================================================================================================

    private onToolbarButtonClicked(e:any) : void {

        if (this._panel == null) 
        {

            this._panel = new ModelLoaderExtensionPanel(this.viewer, this.viewer.container, 'ModelLoaderExtensionPanel', this._extensionName, this.options);

            //==============================================================================
        }                
        this._panel.setVisible(!this._panel.isVisible()); // toogle (show/hide) docking panel
    }

   //==================================================================================================================

   public onObjectSelectionChanged = (event:any) => { 

    }

    //=================================================================================================================

    public onObjectTreeCreated(event:any) : void 
    {

    }

    //===================================================================================================================

    public static loadExtension(viewer:any, modelURN:string)
    {
        const baseUrl: string | undefined = Config.BaseUrl;

        const init_options = {   
          PANEL_INITIAL_POS:       "12,14, 334,760",     // initial x1Pos,y1Pos,Width,Height of the panel
          WRITE_LOG_FILE:           1,                   // 1 => write to console.log  .., 0 => dont write    
          BASE_URL:                 baseUrl,             // web-server base-url for ( Axios ) 
          MODEL_URN:                modelURN
        }
    
        viewer.loadExtension('Hilti.ModelLoaderExtension', { init_options } );
    }  
    
    //===================================================================================================================

    public static async loadModelByURN(viewer:any, modelURN:string, axiosBaseUrl:string)
    {
        let axiosInstance = null;
        let apiClient     = null;
    
        axiosInstance = Axios.create({  baseURL: axiosBaseUrl });
        if (axiosInstance != null)
        {
            apiClient = new Client( axiosBaseUrl, axiosInstance );

            let token = await apiClient.getForgeToken();
            ModelLoader.loadModelFromWeb(viewer,modelURN,token.access_token);
    
        }
    }      

    //===================================================================================================================
}
