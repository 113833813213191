import '../css/ItemNumberCrawlerExtension.css';
import '../../UnifiExtension/css/UnifiExtension.css';

import * as React from 'react';
import ReactDOM from 'react-dom';
import { ExtensionsError, ExtensionsInfo, ExtensionUtil } from '../../utils/utils';
import {Client, ContentDto, FileType, ItemNumberInformation, Library} from  '../../../../client';
import Tabulator from 'tabulator-tables'; 
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { createMuiTheme, FormControlLabel, Snackbar, ThemeProvider, Tooltip } from "@material-ui/core";
import { HtmlUtils } from '../../utils/HtmlUtils';
import { mui_darkTheme } from "../../utils/Material_UI_Themes";
import  {UnifiCore,StdIdWithNameStructure, ICLibDataExtStruct}  from "../../utils/UnifiCore";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Typography } from '@material-ui/core';

import  {SeverityType,VerticalOrigin,HorizontalOrigin, DialogUtil, g_darkThemeDialog } from "../../utils/GuiComponents/ModalDialogs/BaseSnackbar";
import { Alert } from '@material-ui/lab';
import SpinnerCore from '../../utils/GuiComponents/SpinnerCore';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


interface ItemNumberCrawlerGUIPanelProps 
{
    parentPanel;
}

interface ItemNumberCrawlerGUIPanelState
{
    selectedOption;

    loadingLibraries:     boolean;
    libraries:            StdIdWithNameStructure[];

    loadingCurselLibrary: boolean;
    curselLibrary:        ICLibDataExtStruct[];
    curselLibraryName:    string;

    showSpinner: boolean;

    canSave: boolean;
    canLoad: boolean;

    showMessageBox:  boolean;
    dlgTitle:        string;
    dlgContentText:  string;

    showAlert:      boolean;
    alertMessage:   string;
    alertVOrigin:   VerticalOrigin;
    alertHOrigin:   HorizontalOrigin;
    alertType:      SeverityType;
    alertTransition: any;
    alertDuration:number;
}

const Transition = React.forwardRef(function Transition(
    props:  TransitionProps & { children?: React.ReactElement<any, any> },
    ref:    React.Ref<unknown>,
){
    return <Slide direction="up" ref={ref} {...props} />;
});


export default class ItemNumberCrawlerGUIPanel extends React.Component<ItemNumberCrawlerGUIPanelProps,ItemNumberCrawlerGUIPanelState>
{
    //============================================================
    private   _LibraryContentGrid=null;
    private   _currentGridData:ICLibDataExtStruct[] = [];
    private   _hiddenColumns=[];
    private   _selectedOptions:StdIdWithNameStructure[] = [];
    private  _guiElementesInitialized=false;
    private  _captionAll = "All";
    private  _allData:ContentDto[] = [];
    private   _spinner:SpinnerCore = null;
    private  _itemNumberInfos:ItemNumberInformation[]=[];
    //=============================================================

    constructor(prop:any)
    {
        super(prop);  

        this.state = { selectedOption: null,    libraries: null, loadingLibraries: true,  
                       curselLibrary: null,  loadingCurselLibrary: true, curselLibraryName: "",  
                       showSpinner:true, canSave: false, canLoad: false, 
                       showMessageBox: false,  dlgTitle: "", dlgContentText: "",
                       showAlert: false, alertMessage: "", alertVOrigin: VerticalOrigin.TOP, alertHOrigin: HorizontalOrigin.CENTER,
                       alertType:  SeverityType.INFO, alertTransition: undefined, alertDuration: 6000};

        this.props.parentPanel.setGUIPanel(this);

        this._spinner = new SpinnerCore();
    }  

    //=================================================================================================================

    componentDidMount = () => {

        this._spinner.init("id-spinnerContainer-ic","id-spinnerPanel-ic","id-spinnerCaptionPanel-ic");
        this._spinner.initColor( this.props.parentPanel.getSpinnerColor() );
        this._spinner.initSize ( this.props.parentPanel.getSpinnerSize() );

        this._spinner.show(true);

        UnifiCore.getAllUnifiLibraries(this.props.parentPanel.getApiClient(),true).then( (libraries:StdIdWithNameStructure[]) =>  {  

            let available_Libraries:StdIdWithNameStructure[] = [];

            if (libraries.length > 0)
            {
                // add "Select All item"
                const cx1:StdIdWithNameStructure = {  id: "0" , name: this._captionAll   };
                const tmp_Array:StdIdWithNameStructure[] = [];
                tmp_Array.push(cx1);
                available_Libraries = [...tmp_Array, ...libraries];

          //    this.getItemNumbersInformations();
            }
            this._spinner.show(false);

            this.setState({ libraries: available_Libraries,  loadingLibraries: false });

            let info  = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_UNIFI_LIBS_FOUND);
                info  = info.replace("%", libraries.length.toString() );

            this.setState( {showAlert: true, alertType:  SeverityType.SUCCESS,  alertTransition: undefined,
                            alertVOrigin: VerticalOrigin.TOP, alertHOrigin: HorizontalOrigin.CENTER,
                            alertMessage: info });
        })
        .catch((error) => { 
            
            this._spinner.show(false); 
            let info = ExtensionUtil.getErrorText(ExtensionsError.ERROR_CONNECT_WEB_SERVER);
                info += "   [ " + error + " ]";    
            this.setState( {showAlert: true, alertType:  SeverityType.ERROR, alertMessage: info });
        });

    }

    //=================================================================================================================
  
    componentWillUnmount = () => { 

    }

    //=================================================================================================================

    private setButtonTooltips()
    {
        if (!this._guiElementesInitialized)
        {
            this._guiElementesInitialized = true;

            const toolTipInfo   = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_GET_UNIFI_LIBCONTENT_INFO_2);
            const buttonCaption = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_GET_UNIFI_LIBCONTENT);
            HtmlUtils.setButtonTextAndTooltip("id-button-getdata-ic", null, toolTipInfo );

            const toolTipInfo2   = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_INFO);
            HtmlUtils.setButtonTextAndTooltip("id-button-save-ic", null, toolTipInfo2 );

            const toolTipInfo3   = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_RESET_GRID_COLOUMS_INFO);
            HtmlUtils.setButtonTextAndTooltip("id-button-reset-ic", null, toolTipInfo3 );
        }
    }

    //=================================================================================================================

    private before_getLibraryByLibId() : void
    {
        HtmlUtils.showHtmlElement("id-libraries-container-ic",false);
        this._spinner.show(true);
        this._currentGridData = [];
    }

    //=================================================================================================================

    private after_getLibraryByLibId(libName:string) : void
    {
        this._spinner.show(false);
        this.setupCurrentLibraryContensGrid(this._currentGridData,false);
        this.setState({ curselLibrary: this._currentGridData,  loadingCurselLibrary: false,  curselLibraryName: libName });
        if (this._currentGridData.length)
            this.setState({ canSave: true });
        HtmlUtils.showHtmlElement( "id-libraries-container-ic",  true);
      //  HtmlUtils.enableButton("id-button-save-ic",  ((this._currentGridData.length > 0) ? true : false) );
        const info        = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_INFO);
      //  const tooltipInfo = info.replace("%", this._currentGridData.length.toString());
        HtmlUtils.setButtonTextAndTooltip( "id-button-save-ic", null , info);
    }    

    //=================================================================================================================

    private async loadSelectedLibraries()
    {
        let errInfoData:string[] = [];

        if (this._selectedOptions.length > 0)
        {
            const apiClient:Client = this.props.parentPanel.getApiClient();
            this._allData = [];

            if (this._LibraryContentGrid != null)
            {
                this._LibraryContentGrid.destroy();
            }

            this.before_getLibraryByLibId();

            if (this._selectedOptions[0].name === this._captionAll)
            {
                await UnifiCore.getSelectedLibraries(apiClient, this.state.libraries,errInfoData, 1, 
                                                     this._currentGridData, this._allData, "id-spinnerCaptionPanel-ic");               
            }
            else
            {
                await UnifiCore.getSelectedLibraries(apiClient, this._selectedOptions, errInfoData, 0, 
                                                     this._currentGridData, this._allData, "id-spinnerCaptionPanel-ic");
            }
            if (errInfoData.length > 0)
            {
                let infoText    = ExtensionUtil.getErrorText(ExtensionsError.ERROR_READ_UNIFI_LIB_02);
                const info      = infoText.replace("%", errInfoData.length.toString() );
           
                this.setState( {showAlert: true, alertType:  SeverityType.ERROR,  alertTransition: undefined,
                                alertVOrigin: VerticalOrigin.TOP, alertHOrigin: HorizontalOrigin.CENTER,
                                alertMessage: info,  alertDuration: 9000 });
            } 
            this.after_getLibraryByLibId("");
        }
    }
  
    //=================================================================================================================
  
    private setupCurrentLibraryContensGrid(data,groupStartOpenFlag) 
    {
        const that = this;

        var headerMenu = [
            {
                label:"<i class='fas fa-eye-slash'></i> Hide Column",
                action:function(e, column)
                {
                    that._hiddenColumns.push(column.getField());
                    column.hide();
                    HtmlUtils.enableButton("id-button-reset-ic", true);
                }
            },
        ];


        this._LibraryContentGrid = new Tabulator("#currentLibContentTable-ic", {
            
            data:               data,     //assign data to table
            layout:             "fitColumns",   //fit columns to width of table (optional)
            //layout:             "fitDataStretch",
            height:             "560px",
            selectable:         1,
            groupStartOpen:     groupStartOpenFlag,
            groupToggleElement: "header",
            groupBy:            "Title",
            rowContextMenu:      headerMenu, //add context menu to rows

            columns: [

             //  { title: "Typename",                    field: "Typename",     headerSort: true,  headerFilter: true , headerMenu:headerMenu,   width:"2%", widthGrow: 1, widthShrink: 1 },                   
                { title: "Title",                       field: "Title",        headerSort: true,  headerFilter: true , headerMenu:headerMenu,   width:"18%", widthGrow: 1, widthShrink: 1 },                   
                { title: "Filename",                    field: "Filename",     headerSort: true , headerFilter: true , headerMenu:headerMenu,   width:"18%", widthGrow: 1, widthShrink: 1 },   
                { title: "Product Description",         field: "ProdDesc",     headerSort: true,  headerFilter: true,  headerMenu:headerMenu,   width:"20%", widthGrow: 1, widthShrink: 1 }, 
                { title: "RSen_C_product Description",  field: "RSenCProdDesc",headerSort: true,  headerFilter: true,  headerMenu:headerMenu,   width:"20%", widthGrow: 1, widthShrink: 1 }, 
                { title: "Item-Number",                 field: "Value",        headerSort: true,  headerFilter: true,  headerMenu:headerMenu,   width:"14%", widthGrow: 1, widthShrink: 1 },
                { title: "Source",                      field: "Source",       headerSort: true,  headerFilter: true,  headerMenu:headerMenu,   width:"10%", widthGrow: 1, widthShrink: 1 }  
        ],

            rowSelectionChanged: function (data, rows) {},
            rowClick: function (e, row) {},       
        });  
    }

    //=================================================================================================================

    private onLoadSelectedLibraries = (e) => {

        this.loadSelectedLibraries();
    };    

    //=================================================================================================================

    private onSaveSelectedLibraries = (e) => {

        this.onOpenMessageBox();
    }

    //=================================================================================================================

    private onOpenMessageBox()
    {
        if (this._allData.length > 0)        
        {
            let infoText = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_WARNING);
            const title  = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_WARNING);
            this.setState( { showMessageBox: true, dlgTitle: title, dlgContentText: infoText });
        }
    }

    //=================================================================================================================

    async saveContentParamsFromSelectedLibraries()
    {
        if (this._allData.length > 0)        
        {
            HtmlUtils.showHtmlElement("id-libraries-container-ic",false);
            this._spinner.show(true);

            const apiClient:Client = this.props.parentPanel.getApiClient();

            await apiClient.saveContentParametersToStorage(this._allData).then( bRetval =>  {

                let info       = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_OK);
             //   let   infoText   = info.replace("%", this._currentGridData.length.toString());
                let   serverity  = SeverityType.SUCCESS;

                if (!bRetval)
                {
                    info   = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_OK);
                    serverity  = SeverityType.ERROR;
                }
            
                console.log( info );

                this._spinner.show(false);
                HtmlUtils.showHtmlElement("id-libraries-container-ic",true);

                let title  = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_UNIFI_LIBS_FOUND);

                this.setState( {showAlert: true, alertType:  serverity,  alertTransition: undefined,
                                alertVOrigin: VerticalOrigin.TOP, alertHOrigin: HorizontalOrigin.CENTER,
                                alertMessage: info });
            });    
        }
    }

    //=================================================================================================================
  
    private onLibrarySelectionChanged = (event, currentSelectedOptions) => {

        this._allData = [];
        this.setState({ canSave: false });

        let nSelectedLibs = 0;

        if (ExtensionUtil.isValidContent( currentSelectedOptions ))
        {
            this._selectedOptions = [...currentSelectedOptions];
            nSelectedLibs = this._selectedOptions.length;
        }
        else
        {
            this._selectedOptions = [];
        }
        
        if (!this._guiElementesInitialized)
        {
            this.setButtonTooltips();
        }
        this.setState({ canLoad:  ((nSelectedLibs > 0) ? true : false) } );
    }

    //=================================================================================================================

    private onResetGridData(e)
    {
        // show all columns again !
        if ( this._LibraryContentGrid != null)
        {
            if (ExtensionUtil.isValidContent(this._hiddenColumns))
            {
                for (let currrentColumnName of this._hiddenColumns)
                {
                    this._LibraryContentGrid.showColumn( currrentColumnName );         
                } 
                this._hiddenColumns = [];
                HtmlUtils.enableButton("id-button-reset",false);
            }
        }
    }

    //=================================================================================================================

    public close()
    {
        this._spinner.show(false);
    }

    private onOK = () => {

        this.setState( { showMessageBox: false } );
        this.saveContentParamsFromSelectedLibraries();
    }

    private onCancel = () => {

        this.setState( { showMessageBox: false } );
    }    

    private onCloseMessageBox = () => {

        this.setState( { showMessageBox: false } );
    };

    private onCloseAlertMessage = () => {

        this.setState( { showAlert: false } );
    };
  
    //=================================================================================================================
 
    private renderLibrariesComboBox() 
    {
        const preSelected = [];
        preSelected.push("_swTest");

        const xstyles = {
            
            control: base => ({
              ...base,
              fontFamily: "Roboto"
            }),
            menu: base => ({
              ...base,
              fontFamily: "Roboto"
            })
          };

        return ( 
   
            <div className="container-fluid m-0" id="id-libraries-container-ic">

                <div className="row m-2 p-2">

                    <div className="col-12-left">
                  
                        <div className="input-group">
                      
                            <ThemeProvider theme={mui_darkTheme}>

                            <Autocomplete
                                clearOnEscape
                                openOnFocus
                                multiple
                                id="id-ic-libraries"
                                options = {this.state.libraries}    
                                disableCloseOnSelect
                                onChange={this.onLibrarySelectionChanged}
                                getOptionLabel={(option) => option.name}
                                renderOption={(option, state) => {

                                    const selectItemIndex = this._selectedOptions.findIndex( item => item.name === this._captionAll  );

                                    if (selectItemIndex > -1)
                                    {
                                        state.selected = true;
                                    }

                                    return (

                                        <React.Fragment>

                                            <Checkbox
                                                color ="primary"
                                                icon            ={icon}
                                                checkedIcon     ={checkedIcon}
                                                style           ={{ marginRight: 8 }}
                                                checked         ={state.selected}
                                            />

                                            {option.name}
                                        </React.Fragment>
                                    );
                                }}

                                style={{ width: 970 }}

                                renderInput={params => (

                                    <TextField
                                        {...params}
                                            variant     ="outlined"
                                            label       ="Select Unifi-Library"
                                            // placeholder ="Favorites"
                                        />
                                    )}
                            />
                            </ThemeProvider>
                         
                            <span className="input-group-btn" id="id-button-goup-span">
                                <button id="id-button-getdata-ic"    type="button"  onClick={ (e) => this.onLoadSelectedLibraries(e)}   disabled={!this.state.canLoad}   className="btn btn-secondary btn-item-crawler"><i className="fa fa-play" aria-hidden="true"></i></button>
                                <button id="id-button-save-ic"       type="button"  onClick={ (e) => this.onSaveSelectedLibraries (e)}  disabled={!this.state.canSave}   className="btn btn-secondary btn-item-crawler"><i className="fas fa-database fa-lg" aria-hidden="true"></i></button>
                                <button id="id-button-reset-ic"    style={{visibility: "hidden"}}  type="button"  onClick={ (e) => this.onResetGridData(e)}   className="btn btn-secondary btn-item-crawler"><i className="far fa-dot-circle" aria-hidden="true"></i></button>
                            </span>

                        </div>        
                    </div>
                </div>
            </div>
        );

    }

    //=================================================================================================================
    
    render() {

        let useSeverity:any         = DialogUtil.getSeverity(this.state.alertType);
        let useHorizontalOrigin:any = DialogUtil.getHorizontalOrigin(this.state.alertHOrigin);
        let useVerticalOrigin:any   = DialogUtil.getVerticalOrigin(this.state.alertVOrigin);

        let content_libraries = this.state.loadingLibraries ? <p><em>.</em></p> : this.renderLibrariesComboBox();

        return (
    
            <div>
                    <div>     
                        <ThemeProvider theme={g_darkThemeDialog}>
                        <Snackbar 
                            open                = { this.state.showAlert } 
                            TransitionComponent = { this.state.alertTransition }
                            autoHideDuration    = { this.state.alertDuration } 
                            anchorOrigin={{
                                vertical:   useVerticalOrigin,
                                horizontal: useHorizontalOrigin,
                            }}   
                            onClose = { this.onCloseAlertMessage }>

                            <Alert 
                                onClose  = { this.onCloseAlertMessage } 
                                // variant  ="filled"
                                // variant  ="outlined"
                                severity = { useSeverity }>
                                { this.state.alertMessage }
                            </Alert>

                        </Snackbar>
                        </ThemeProvider>
                    </div>

                    <div>     
                        <ThemeProvider theme={g_darkThemeDialog}>
                        <Dialog
                            open   =  { this.state.showMessageBox }
                            TransitionComponent =   { Transition }
                            keepMounted
                            onClose             =   {  this.onCloseMessageBox }
                            aria-labelledby     =   "alert-dialog-slide-title"
                            aria-describedby    =   "alert-dialog-slide-description">

                            <DialogTitle id="alert-dlg-title-ic">{  this.state.dlgTitle }</DialogTitle>
                            
                            <DialogContent>
                                <DialogContentText id="alert-dlg-ic">
                                    <Typography display="block">
                                        { this.state.dlgContentText }
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions>

                                {/* <Button onClick={ this.onCloseMessageBox } color="primary">Disagree</Button>
                                <Button onClick={ this.onCloseMessageBox } color="primary">Agree</Button> */}

                                <Button onClick={ this.onOK }     color="primary">OK</Button>
                                <Button onClick={ this.onCancel } color="primary">Cancel</Button>

                                {/* <Button onClick={ this.onCloseMessageBox } color="primary">Close</Button> */}

                            </DialogActions>

                        </Dialog>
                        </ThemeProvider>
                    </div>      

  
                <div>{content_libraries}</div>
                
                <div className="some-space-ic"></div>      

                <div className="container m-0 p-0  flex-column d-flex" id="id-spinnerContainer-ic">
                    <div id="id-spinnerCaptionPanel-ic"/>
                    <div className="col-xs-6" id="id-spinnerPanel-ic"/>
                </div>

                <div className="itc-grid-container">
                    <div className="itc-grid-content">
                        <div id="currentLibContentTable-ic"/>
                     </div>
                </div>


                {/* <Notification notify={notify} setNotify={setNotify} /> */}

          </div>

        );
    }

    //=================================================================================================================
}



