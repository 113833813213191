import { useAuth0 } from '@auth0/auth0-react';
import React from "react";
import { Link } from 'react-router-dom';
import './Authentication.css';

const Authentication = () => {
    const { isAuthenticated } = useAuth0();
    if(isAuthenticated){
        return LogoutButton();
    }
    else{
        return LoginButton();
    }
}

function LoginButton() {
    const { loginWithRedirect } = useAuth0();
    return (
        <Link to="/forgeviewer">
            <button className="hiltibutton" 
                onClick={() => loginWithRedirect()}>
            Log In
            </button>
        </Link>
    )
}

function LogoutButton() {
    const { logout } = useAuth0();
    return (
        <button hidden  className="hiltibutton"
            onClick={() => logout({
                    returnTo: window.location.origin,
                })
            }>
            Log Out
        </button>
    )
}

export default Authentication