// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/default01.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".defaultExtensionToolbarButton {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 24px;\n    background-repeat: no-repeat;\n    background-position: center;\n}\n\n\n.btnx\n{\n  /* width:   80px; */\n  margin:  0px 10px 0px 10px;               /* top right bottom left */\n  background-color: #333333;\n  /* width:     100%; */\n  /* display:block; */\n}\n\n\n.mybuttongroup\n{\n  width:     100%;\n}\n\n\n\n\n#id-spinnerContainer-default\n{\n    align-items:     center;\n    justify-content: center;\n}\n\n#id-spinnerCaptionPanel-default\n{\n    color:             #a4b4a9;\n    margin-top:       12px;\n    display:          flex;\n    align-items:      center;\n    justify-content:  center;\n}\n\n#id-spinnerPanel-default\n{\n  height:           auto;\n  padding:          0px;\n  margin-top:       0px;\n\n  display:          flex;\n  align-items:      center;\n  justify-content: center;\n}\n\n\n\n", ""]);
// Exports
module.exports = exports;
