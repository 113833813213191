// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/chart02.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".chartExtensionToolbarButton {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 24px;\n    background-repeat: no-repeat;\n    background-position: center;\n}\n\n.chartWrapper \n{\n\n    height: auto;\n    width:  auto;\n    padding: 10px;\n    margin: 10px auto;\n}\n\n.buttonWrapper \n{\n    text-align: middle;\n}\n\n.btn-chart \n{\n    margin-top: 0px;\n    top: 50%;\n    display: inline-block;\n    font-size: 16px;\n    position: relative;\n    background-color:  var(--base_button_bkcolor);\t\n}", ""]);
// Exports
module.exports = exports;
