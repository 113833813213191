//import '../css/UnifiExtension.css';
import * as React from "react";
//import ReactDOM from 'react-dom';
import {
  ExtensionsError,
  ExtensionsInfo,
  ExtensionUtil,
} from "../../utils/utils";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import {
  Client,
  FileType,
  JsTreeNode,
  OutputType,
  Token,
} from "../../../../client";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Snackbar, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  SeverityType,
  VerticalOrigin,
  HorizontalOrigin,
  DialogUtil,
  g_darkThemeDialog,
} from "../../utils/GuiComponents/ModalDialogs/BaseSnackbar";
import { Alert } from "@material-ui/lab";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import ModelLoader from "../../ModelLoaderExtension/components/ModelLoader";
import SpinnerCore from "../../utils/GuiComponents/SpinnerCore";
import { parseCommandLine } from "typescript";
import { forEach } from "underscore";

interface TreeItemData {
  id: string;
  text: string;
  type: string;
  opcode: number;
  hasChildren: boolean;
  children?: TreeItemData[];
}

interface BIM360DataManagerGUIPanelProps {
  parentPanel;
}

interface BIM360DataManagerGUIPanelState {
  showSpinner: boolean;
  allTreeNodes: TreeItemData[];
  needsUpdate: boolean;

  showAlert: boolean;
  alertMessage: string;
  alertVOrigin: VerticalOrigin;
  alertHOrigin: HorizontalOrigin;
  alertType: SeverityType;
  alertTransition: any;
  alertDuration: number;

  showMessageBox: boolean;
  dlgTitle: string;
  dlgContentText: string;

  expanded: string[];
}

const darkTheme_Tree = createMuiTheme({
  spacing: 14,

  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 14,
        paddingBottom: 14,
        "&:last-child": {
          paddingRight: 5,
        },
      },
    },
  },

  palette: {
    type: "dark",

    common: {
      white: "#00FF00",
    },

    text: {
      primary: "#0000FF",
    },

    primary: {
      main: "#A3B1AC", //ff4400',
    },
    secondary: {
      light: "#0066ff",
      main: "#0044ff",
      contrastText: "#ffcc00",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },

  typography: {
    fontSize: 12,
    fontWeightRegular: 400,
    fontFamily: ["roboto", '"Segoe UI Symbol"'].join(","),
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class BIM360DataManagerGUIPanel extends React.Component<
  BIM360DataManagerGUIPanelProps,
  BIM360DataManagerGUIPanelState
> {
  //============================================================
  private _guiElementesInitialized = false;
  private _forgeAccessToken: string = null;
  private _firstPseudoNodeId = 10000;
  private PSEUDO_CHILD_NODE: number = 20;
  private MODEL_URN_NODE: number = 10;
  private _spinner: SpinnerCore = null;
  //=============================================================

  // const [expanded, setExpanded] = React.useState<string[]>([]);
  // const [selected, setSelected] = React.useState<string[]>([]);

  constructor(prop: any) {
    super(prop);

    this.state = {
      showSpinner: false,
      allTreeNodes: [],
      needsUpdate: false,
      showAlert: false,
      alertMessage: "",
      alertVOrigin: VerticalOrigin.TOP,
      alertHOrigin: HorizontalOrigin.CENTER,
      alertType: SeverityType.INFO,
      alertTransition: undefined,
      alertDuration: 6000,
      showMessageBox: false,
      dlgTitle: "",
      dlgContentText: "",
      expanded: null,
    };

    this._spinner = new SpinnerCore();
  }

  //=================================================================================================================

  componentDidMount = () => {
    this._spinner.init(
      "id-spinnerContainer-bim360",
      "id-spinnerPanel-bim360",
      "id-spinnerCaptionPanel-bim360"
    );
    this._spinner.initColor(this.props.parentPanel.getSpinnerColor());
    this._spinner.initSize(this.props.parentPanel.getSpinnerSize());

    //     this._spinner.show(true);

    this.getChildrenFromUnifi("#").then((rootNodes: Array<TreeItemData>) => {
      //  this._spinner.show(false);

      if (rootNodes && rootNodes.length > 0) {
        for (let currNode of rootNodes) {
          currNode.children = this.getPseudoNodeArray(
            ++this._firstPseudoNodeId
          );
          currNode.opcode = this.PSEUDO_CHILD_NODE;
        }
        let nodes: TreeItemData[] = [...rootNodes];
        this.setState({ allTreeNodes: nodes, needsUpdate: true });
      } else {
        const info = ExtensionUtil.getInfoText(ExtensionsInfo.NO_DATA_FOUND);
        this.setState({
          showAlert: true,
          alertType: SeverityType.WARNING,
          alertMessage: info,
        });
        this.setState({ allTreeNodes: null, needsUpdate: false });
      }
    });
  };

  //=================================================================================================================

  componentWillUnmount = () => {};

  //=================================================================================================================
  // event:   The event source of the callback.
  // nodeIds: The id(s) of the expanded nodes.
  //=================================================================================================================

  handleNodeToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {};

  //=================================================================================================================

  handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    let selectedId = Array.isArray(nodeIds) ? nodeIds[0] : [nodeIds];

    const selectedNodeId = selectedId as string;

    const selectedNode: TreeItemData = this.getTreeNodeById(
      selectedNodeId,
      this.state.allTreeNodes
    );

    if (selectedNode !== null) {
      if (selectedNode.opcode == this.MODEL_URN_NODE) {
        // selectedNode.type == "versions"
        this.onOpenMessageBox(selectedNode);
        return;
      }

      let parendNode_has_supported_file = false;
      ExtensionUtil.getAllSupportedAutoDeskForgeFormats().forEach(
        (fileFormat: string) => {
          if (selectedNode.text.endsWith(fileFormat.toLocaleLowerCase())) {
            parendNode_has_supported_file = true;
          }
        }
      );

      if (selectedNode.hasChildren) {
        if (selectedNode.opcode == this.PSEUDO_CHILD_NODE) {
          //  (selectedNode.children === undefined || selectedNode.children.length == 0 ) ) //&&  is_rvt_file == false ) // || selectedNode.opcode=="2")
          // the selected Node HAS (!!) childrenNodes and these children-nodes and DID-NOT-GOT from the server yet
          // soo, get the children-nodes now and.. attach them to the children property of the selectedNode ( parentNode ) !!!

          selectedNode.opcode = 0; // all PSEUDO_CHILD_NODES only get Data from database once !!!

          this.getChildrenFromUnifi(selectedNodeId).then(
            (childrenNodes: Array<TreeItemData>) => {
              if (childrenNodes && childrenNodes.length > 0) {
                selectedNode.children = [...childrenNodes];

                for (let currNode of selectedNode.children) {
                  if (parendNode_has_supported_file) {
                    currNode.opcode = this.MODEL_URN_NODE; // is model-URN-Node
                  } // this NodeType will NOT have children
                  else {
                    currNode.children = this.getPseudoNodeArray(
                      ++this._firstPseudoNodeId
                    );
                    currNode.opcode = this.PSEUDO_CHILD_NODE;
                  }
                }
                this.setState({ needsUpdate: true });
              }
            }
          );
        } else {
          this.setState({ needsUpdate: true });
        }
      }
    } else {
      this.setState({
        showAlert: true,
        alertType: SeverityType.ERROR,
        alertMessage: "selected node not found",
      });
    }
  };

  //=================================================================================================================

  async getChildrenFromUnifi(nodeId: string): Promise<Array<TreeItemData>> {
    this._spinner.show(true);

    const childrenOfNodeId: Array<TreeItemData> = [];

    const apiClient: Client = this.props.parentPanel.getApiClient();

    await apiClient
      .datamanagement(nodeId)
      .then((childrenNodes) => {
        for (let currChildNode of childrenNodes) {
          const id = currChildNode.id !== undefined ? currChildNode.id : "0";
          const text =
            currChildNode.text !== undefined ? currChildNode.text : "";
          const type =
            currChildNode.type !== undefined ? currChildNode.type : "";
          const hasChildren =
            currChildNode.children !== undefined
              ? currChildNode.children
              : false;

          const cx: TreeItemData = {
            id: id,
            text: text,
            type: type,
            hasChildren: hasChildren,
            opcode: 0,
          };

          childrenOfNodeId.push(cx);
        }
      })
      .catch((error) => {
        //alert( "Fatal Error-22: " + errInfo );
        let info = ExtensionUtil.getErrorText(
          ExtensionsError.ERROR_CONNECT_WEB_SERVER
        );
        this.setState({
          showAlert: true,
          alertType: SeverityType.ERROR,
          alertDuration: 8000,
          alertMessage: info,
        });
      });
    this._spinner.show(false);
    return childrenOfNodeId;
  }

  //=================================================================================================================

  async getModelDerivativeByEncodedId(selectedNode: TreeItemData) {
    const that = this;

    if (selectedNode !== null) {
      let id_encoded = null;

      await this.getDataManagment(selectedNode.id).then(function (
        result: string
      ) {
        if (result !== null) {
          id_encoded = result;
        } else {
          let info = ExtensionUtil.getErrorText(
            ExtensionsError.WARNING_NO_DATA_FOR_URN
          );
          alert(info);
          this.setState({
            showAlert: true,
            alertType: SeverityType.WARNING,
            alertDuration: 8000,
            alertMessage: info,
          });
        }
      });

      if (id_encoded !== null) {
        await this.getModelDerivative(id_encoded).then(function (
          result: string[]
        ) {
          if (result !== null && result.length > 0) {
            let childNodes: TreeItemData[] = [];

            for (let currUrn of result) {
              let modelNameShort = null;
              if (currUrn.endsWith(ExtensionUtil.getSvfFileExtension())) {
                // find last slash
                const index = currUrn.lastIndexOf("/");
                if (index >= 0) {
                  modelNameShort = currUrn.substring(index + 1);
                }
              }
              const modelNode: TreeItemData = {
                id: currUrn,
                text: modelNameShort,
                type: "urn",
                opcode: 0,
                hasChildren: false,
                children: [],
              };
              childNodes.push(modelNode);
            }

            if (childNodes.length > 0) {
              selectedNode.children = childNodes;
              that.setState({ needsUpdate: true });
            }
          }
        });
      }
    }
  }

  //=================================================================================================================

  async getDataManagment(id: string): Promise<string> {
    let id_encoded: string = null;

    const apiClient: Client = this.props.parentPanel.getApiClient();

    await apiClient
      .datamanagement(id)
      .then((result) => {
        if (result !== null && result.length > 0) {
          const node = result[0] as JsTreeNode;
          id_encoded = node.id;
        } else {
          let info = ExtensionUtil.getErrorText(
            ExtensionsError.WARNING_NO_DATA_FOR_URN
          );
          this.setState({
            showAlert: true,
            alertType: SeverityType.WARNING,
            alertDuration: 8000,
            alertMessage: info,
          });
        }
      })
      .catch((error) => {
        let info = ExtensionUtil.getErrorText(
          ExtensionsError.ERROR_CONNECT_BIM360
        );
        info += "    [ " + error + " ]";
        this.setState({
          showAlert: true,
          alertType: SeverityType.ERROR,
          alertDuration: 8000,
          alertMessage: info,
        });
      });
    return id_encoded;
  }

  //=================================================================================================================

  async getModelDerivative(path: string): Promise<string[]> {
    let modelUrnArray: string[] = [];

    const apiClient: Client = this.props.parentPanel.getApiClient();

    await apiClient
      .modelderivative(path, OutputType.Svf)
      .then((result) => {
        if (result !== null) {
          for (let currModelUrn of result) {
            modelUrnArray.push(currModelUrn);
          }
        } else {
          let info = ExtensionUtil.getErrorText(
            ExtensionsError.WARNING_NO_DATA_FOR_URN
          );
          this.setState({
            showAlert: true,
            alertType: SeverityType.WARNING,
            alertDuration: 8000,
            alertMessage: info,
          });
        }
      })
      .catch((error) => {
        let info = ExtensionUtil.getErrorText(
          ExtensionsError.ERROR_CONNECT_BIM360
        );
        info += "    [ " + error + " ]";
        this.setState({
          showAlert: true,
          alertType: SeverityType.ERROR,
          alertDuration: 8000,
          alertMessage: info,
        });
      });
    return modelUrnArray;
  }

  //=================================================================================================================

  private setChildrenNodesByParentId(
    treeItems: TreeItemData[],
    parentId: string,
    newItems: TreeItemData[]
  ) {
    treeItems.map((currentTreeItem) => {
      const that = this;
      let children = undefined;

      if (currentTreeItem.children && currentTreeItem.children.length > 0) {
        children = that.setChildrenNodesByParentId(
          currentTreeItem.children,
          parentId,
          newItems
        );
      }

      if (currentTreeItem.id == parentId) {
        currentTreeItem.children = newItems;
        this.setState({ needsUpdate: true });
      }
      return;
    });
  }

  //=================================================================================================================

  private getTreeNodeById(
    searchId: string,
    treeItems: TreeItemData[]
  ): TreeItemData {
    let foundTreeItem: TreeItemData = null;

    for (let i = 0; i < treeItems.length; i++) {
      const currentTreeItem = treeItems[i];

      //   alert( "i = "  + i + "\n" + "current is " + currentTreeItem.id + "\n" + "current hat children " + currentTreeItem.hasChildren + "\n" + "search = " + searchId );

      if (currentTreeItem.id == searchId) {
        //     alert("FOUND  id = " + currentTreeItem.id);
        return currentTreeItem;
      }

      if (currentTreeItem.children && currentTreeItem.children.length > 0) {
        foundTreeItem = this.getTreeNodeById(
          searchId,
          currentTreeItem.children
        );
      }

      if (foundTreeItem !== null) break;
    }
    return foundTreeItem;
  }

  //=================================================================================================================

  private getPseudoNodeArray(id: number): TreeItemData[] {
    const pseudoNodeArray: TreeItemData[] = [];
    const pseudoNode: TreeItemData = {
      id: id.toString(),
      text: "",
      type: "",
      opcode: 33,
      hasChildren: false,
    };
    pseudoNodeArray.push(pseudoNode);
    return pseudoNodeArray;
  }

  //=================================================================================================================

  async LoadNewModel() {
    const apiClient: Client = this.props.parentPanel.getApiClient();
    let token = await apiClient.getForgeToken();
    ModelLoader.loadModelFromWeb(
      this.props.parentPanel.getViewer(),
      this.state.dlgContentText,
      token.access_token
    );
  }

  //=================================================================================================================

  private onOpenMessageBox(modelNode: TreeItemData) {
    let info = ExtensionUtil.getInfoText(
      ExtensionsInfo.CAPTION_BIM360_GET_FILENAME
    );
    info = info.replace("%", modelNode.text);
    this.setState({
      showMessageBox: true,
      dlgTitle: info,
      dlgContentText: modelNode.id,
    });
  }

  //=================================================================================================================

  private getTreeItemsFromData = (treeItems: TreeItemData[]) => {
    return treeItems.map((currentTreeItem) => {
      const that = this;

      let children = undefined;
      if (currentTreeItem.children && currentTreeItem.children.length > 0) {
        children = that.getTreeItemsFromData(currentTreeItem.children);
      }

      let classX = "bim-tree-item";
      let classXY = "label-1";

      if (currentTreeItem.text.endsWith(ExtensionUtil.getRvtFileExtension())) {
        classX = "bim-tree-item-2";
        classXY = "label-2";
      }

      return (
        <div className={classX}>
          <TreeItem
            key={currentTreeItem.id}
            nodeId={currentTreeItem.id}
            label={
              <Typography variant="h6" className={classXY}>
                {currentTreeItem.text}
              </Typography>
            }
            children={children}
          />
        </div>
      );
    });
  };

  //=================================================================================================================

  private onCloseAlertMessage = () => {
    this.setState({ showAlert: false });
  };

  private onCancelMessageBox = () => {
    this.setState({ showMessageBox: false });
  };

  private onOKMessageBox = () => {
    this.setState({ showMessageBox: false });
    this.LoadNewModel();
  };

  private onCopyUrnToClipboard = () => {
    const that = this;
    navigator.clipboard.writeText(this.state.dlgContentText).then(
      function () {
        const info = ExtensionUtil.getInfoText(
          ExtensionsInfo.CAPTION_COPY_CLIPBOARD_SUCCESSFUL
        );
        that.setState({
          showAlert: true,
          alertType: SeverityType.SUCCESS,
          alertMessage: info,
        });
      },
      function (err) {
        const info = ExtensionUtil.getInfoText(
          ExtensionsInfo.CAPTION_COPY_CLIPBOARD_ERROR
        );
        that.setState({
          showAlert: true,
          alertType: SeverityType.ERROR,
          alertMessage: info,
        });
      }
    );
    this.setState({ showMessageBox: false });
  };

  //=================================================================================================================

  MinusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
    );
  }

  //=================================================================================================================

  PlusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
    );
  }

  //=================================================================================================================

  CloseSquare(props) {
    return (
      <SvgIcon className="close" fontSize="inherit" {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
      </SvgIcon>
    );
  }

  //=================================================================================================================

  render() {
    let useSeverity: any = DialogUtil.getSeverity(this.state.alertType);
    let useHorizontalOrigin: any = DialogUtil.getHorizontalOrigin(
      this.state.alertHOrigin
    );
    let useVerticalOrigin: any = DialogUtil.getVerticalOrigin(
      this.state.alertVOrigin
    );

    let info = "...loading";
    if (this.state.allTreeNodes == null) info = "no data found";

    let content_treeView =
      this.state.needsUpdate == false ? (
        <p>
          <em>{info}</em>
        </p>
      ) : (
        this.getTreeItemsFromData(this.state.allTreeNodes)
      );

    return (
      <div
        className="container m-3 p-0  flex-column d-flex"
        id="id-bim-treeview-container"
      >
        <div>
          <ThemeProvider theme={g_darkThemeDialog}>
            <Snackbar
              open={this.state.showAlert}
              TransitionComponent={this.state.alertTransition}
              autoHideDuration={this.state.alertDuration}
              anchorOrigin={{
                vertical: useVerticalOrigin,
                horizontal: useHorizontalOrigin,
              }}
              onClose={this.onCloseAlertMessage}
            >
              <Alert onClose={this.onCloseAlertMessage} severity={useSeverity}>
                {this.state.alertMessage}
              </Alert>
            </Snackbar>
          </ThemeProvider>
        </div>

        <div>
          <ThemeProvider theme={g_darkThemeDialog}>
            <Dialog
              open={this.state.showMessageBox}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.onCancelMessageBox}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dlg-title-bim">
                {this.state.dlgTitle}
              </DialogTitle>

              <DialogContent>
                <DialogContentText id="alert-dlg-bim">
                  <Typography display="block" noWrap>
                    {this.state.dlgContentText}
                  </Typography>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={this.onOKMessageBox} color="primary">
                  Load to Viewer
                </Button>
                <Button onClick={this.onCopyUrnToClipboard} color="primary">
                  Copy to Clipboard
                </Button>
                <Button onClick={this.onCancelMessageBox} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </ThemeProvider>
        </div>

        <div
          className="container m-0 p-0  flex-column d-flex"
          id="id-spinnerContainer-bim360"
        >
          <div className="row m-0 p-0" id="id-spinnerCaptionPanel-bim360" />
          <div className="col-xs-6 m-0 p-0" id="id-spinnerPanel-bim360" />
        </div>

        <div>
          <ThemeProvider theme={darkTheme_Tree}>
            <TreeView
              // className={classes.root}
              // defaultCollapseIcon={<ExpandMoreIcon />}
              // defaultExpandIcon={<ChevronRightIcon />}
              defaultCollapseIcon={<this.MinusSquare />}
              defaultExpandIcon={<this.PlusSquare />}
              // defaultEndIcon={<this.CloseSquare/>}
              onNodeToggle={this.handleNodeToggle}
              onNodeSelect={this.handleSelect}
            >
              {content_treeView}
            </TreeView>
          </ThemeProvider>
        </div>
      </div>
    );
  }
  //=================================================================================================================
}
