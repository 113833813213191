import "../../utils/GuiComponents/base.css";
import "../css/BimCadExtension.css";
import * as React from "react";
import {
  ExtensionsError,
  ExtensionsInfo,
  ExtensionUtil,
} from "../../utils/utils";
import SpinnerCore from "../../utils/GuiComponents/SpinnerCore";
import Tabulator from "tabulator-tables"; //import Tabulator library
import {
  AppBar,
  IconButton,
  Snackbar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  SeverityType,
  VerticalOrigin,
  HorizontalOrigin,
  DialogUtil,
  g_darkThemeDialog,
} from "../../utils/GuiComponents/ModalDialogs/BaseSnackbar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { HtmlUtils } from "../../utils/HtmlUtils";
import { BimCadDto } from "../../../../client";
import { readFileSync } from "fs";
import { parse } from "papaparse";

interface BimCadGUIPanelProps {
  parentPanel;
}

interface BimCadGUIPanelState {
  showMessageBox: boolean;
  dlgTitle: string;
  dlgContentText: string;
  canLoad: boolean;
  canSave: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class BimCadGUIPanel extends React.Component<
  BimCadGUIPanelProps,
  BimCadGUIPanelState
> {
  //============================================================
  private _bimCadGridControl = null;
  private _gridData: BimCadDto[] = [];
  private _storageIsEmpty = true;
  private _itemNumbersDataFileName = "BimCad.txt";
  private _itemNumbersDataPath = "content/data/";
  private _firstRowText = "itemnr";
  private _bimCadFileNextLineChar = "\n";
  private myInput_BimCad;
  private upload;
  file: File;
  //=============================================================

  constructor(prop: any) {
    super(prop);

    this.state = {
      canLoad: true,
      canSave: false,
      showMessageBox: false,
      dlgTitle: "",
      dlgContentText: "",
    };

    // this.props.parentPanel.setGUIPanel(this);
  }

  //=================================================================================================================

  componentDidMount = () => {
    const buttonElement = document.getElementById("btn_load_file_bimcad");
    if (buttonElement != null) {
      buttonElement.setAttribute("class", "btn btn-success btn-bimcad");
    }
    this.getBimCadItemNumbersFromStorage();
  };

  //=================================================================================================================

  componentWillUnmount = () => {};

  //=================================================================================================================

  private async getBimCadItemNumbersFromStorage() {
    this.startLongLastingProcess(true);

    let allBimCadItemNumbers: string[] = [];

    allBimCadItemNumbers = await this.props.parentPanel.props.parentPanel
      .getApiClient()
      .getBimCadItemNumbersFromStorage();

    if (allBimCadItemNumbers.length > 0) {
      let index = 0;
      for (let currItem of allBimCadItemNumbers) {
        let bimCadItem = new BimCadDto();
        bimCadItem.itemNumber = currItem;
        this._gridData.push(bimCadItem);
      }
    }
    this._storageIsEmpty = allBimCadItemNumbers.length > 0 ? false : true;
    this.finishLongLastingProcess(null, null, true);
  }

  //=================================================================================================================

  private async isBimCadItemNumbersStorageEmpty() {
    let allBimCadItemNumbers: string[] = [];
    allBimCadItemNumbers = await this.props.parentPanel.props.parentPanel
      .getApiClient()
      .getBimCadItemNumbersFromStorage();
    this._storageIsEmpty = allBimCadItemNumbers.length > 0 ? false : true;
  }

  //=================================================================================================================
  getBimDataFromCsv = (file: File) => {
    return new Promise<BimCadDto[]>((resolve, reject) => {
      const reader = new FileReader();

      this.file = file;

      reader.onload = function (e: any) {
        var csv = reader.result.toString();
        parse(csv, {
          header: false,
          complete: (result) => {
            if (result.errors.length > 0) {
              reject();
            }
            resolve(result.data);
          },
        });
      };
      // Make sure to handle error states
      reader.onerror = function (e: any) {
        reject(e);
      };
      reader.readAsText(file);
    });
  };

  private getConverted(data: any): BimCadDto[] {
    const mappedItems = data.map((item) => {
      let dto = new BimCadDto();

      dto.release = item[0];
      dto.itemNumber = item[1];
      dto.description = item[2];
      dto.level1 = item[3];
      dto.level2 = item[4];
      dto.level3 = item[5];
      dto.level4 = item[6];
      dto.level5 = item[7];
      dto.at = item[8] === "yes";
      dto.au = item[9] === "yes";
      dto.be = item[10] === "yes";
      dto.br = item[11] === "yes";
      dto.ch = item[12] === "yes";
      dto.cz = item[13] === "yes";
      dto.de = item[14] === "yes";
      dto.dk = item[15] === "yes";
      dto.es = item[16] === "yes";
      dto.fi = item[17] === "yes";
      dto.fr = item[18] === "yes";
      dto.gb = item[19] === "yes";
      dto.hk = item[20] === "yes";
      dto.hu = item[21] === "yes";
      dto.ie = item[22] === "yes";
      dto.in = item[23] === "yes";
      dto.it = item[24] === "yes";
      dto.jp = item[25] === "yes";
      dto.lu = item[26] === "yes";
      dto.mx = item[27] === "yes";
      dto.my = item[28] === "yes";
      dto.nl = item[29] === "yes";
      dto.pl = item[30] === "yes";
      dto.pt = item[31] === "yes";
      dto.ro = item[32] === "yes";
      dto.ru = item[33] === "yes";
      dto.se = item[34] === "yes";
      dto.sg = item[35] === "yes";
      dto.sk = item[36] === "yes";
      dto.sl = item[37] === "yes";
      dto.sp = item[38] === "yes";
      dto.tr = item[39] === "yes";
      dto.tw = item[40] === "yes";
      dto.uae = item[41] === "yes";
      dto.usa = item[42] === "yes";
      return dto;
    });
    let bimCadDtos = mappedItems;
    return bimCadDtos;
  }

  private async getBimCadRecords(file) {
    this.startLongLastingProcess(true);

    this.getBimDataFromCsv(file)
      .then((data) => {
        let mappedData = this.getConverted(data);
        let bimCadDtos = mappedData.slice(1); // remove haeder.

        let info = ExtensionUtil.getInfoText(
          ExtensionsInfo.CAPTION_ITEMNUMBER_RECORDS_LOADED_OK
        );
        const item_number_count = bimCadDtos.length;
        info = info.replace("%", item_number_count.toString());

        this._gridData = bimCadDtos;

        this.props.parentPanel.showSuccessMessage(info);
        this.setState({ canSave: true });
        this.finishLongLastingProcess(null, null, true);
      })
      .catch((reason) => {
        let info = ExtensionUtil.getErrorText(
          ExtensionsError.ERROR_INVALID_BIMCAD_FILE
        );
        this.props.parentPanel.showErrorMessage(info);
        this.finishLongLastingProcess(null, null, true);
      });
  }

  //=================================================================================================================

  public startLongLastingProcess(clearGrid: boolean) {
    if (clearGrid) this._gridData = [];

    HtmlUtils.showHtmlElement("bimcad-grid-container", false);
    HtmlUtils.showHtmlElement("bimcad-buttons-container", false);

    this.props.parentPanel.showAppBar(false);
    this.props.parentPanel.showSpinner(true);
  }

  //=================================================================================================================

  public finishLongLastingProcess(
    stateInfo: string,
    buttonText: string,
    setGridData: boolean
  ) {
    this.props.parentPanel.showSpinner(false);
    this.props.parentPanel.showAppBar(true);

    HtmlUtils.showHtmlElement("bimcad-grid-container", true);
    HtmlUtils.showHtmlElement("bimcad-buttons-container", true);

    if (setGridData) {
      this.fillBimCadDataGrid(this._gridData, true);
    }
  }

  //======================================================================================================================

  private fillBimCadDataGrid(data: BimCadDto[], groupStartOpenFlag: boolean) {
    this._bimCadGridControl = new Tabulator("#bimcad-datagrid", {
      //dataTreeStartExpanded:true, //start with an expanded tree
      data: data, // assign data to table
      layout: "fitColumns", // fit columns to width of table (optional)
      height: "620px",
      selectable: 1,
      groupStartOpen: groupStartOpenFlag,
      groupToggleElement: "header",

      columns: [
        {
          title: "BimCad-ItemNumber",
          field: "itemNumber",
          headerFilter: true,
          headerSort: true,
          width: "100%",
          widthGrow: 1,
          widthShrink: 1,
        },
      ],
    });
  }

  //======================================================================================================================

  private async onSaveToDatabase(event: any) {
    await this.isBimCadItemNumbersStorageEmpty();

    if (this._storageIsEmpty == true) {
      await this.saveBimCadRecords();
    } else {
      this.onOpenMessageBox(); // storage NOT EMPTY .., first ask, whether to save/overwrite data
    }
  }

  //======================================================================================================================

  private async saveBimCadRecords() {
    this.startLongLastingProcess(false);

    this.getBimDataFromCsv(this.file).then(async (data) => {
      let mappedData = this.getConverted(data);
      let bimCadDtos = mappedData.slice(1); // remove haeder.

      let bRetval = await this.props.parentPanel.props.parentPanel
        .getApiClient()
        .saveBimCadsToStorage(bimCadDtos);

      if (bRetval === true) {
        let info = ExtensionUtil.getInfoText(
          ExtensionsInfo.CAPTION_ITEMNUMBER_RECORDS_SAVED_OK
        );
        info = info.replace("%", (data.length - 1).toString());
        this.props.parentPanel.showSuccessMessage(info);
      } else {
        let info = ExtensionUtil.getErrorText(
          ExtensionsError.ERROR_RECORDS_COULD_NOT_BE_SAVED
        );
        this.props.parentPanel.showErrorMessage(info);
      }

      this.finishLongLastingProcess(null, null, false);
    });
  }

  //=================================================================================================================

  //=================================================================================================================

  private onClose = (event) => {
    this.props.parentPanel.onClose(event);
  };

  //=================================================================================================================

  private onCancelMessageBox = () => {
    this.setState({ showMessageBox: false });
  };

  //=================================================================================================================

  private onOKMessageBox = () => {
    this.setState({ showMessageBox: false });
    this.saveBimCadRecords();
  };

  //=================================================================================================================

  private onCloseMessageBox = () => {
    this.setState({ showMessageBox: false });
  };

  //=================================================================================================================

  private onOpenMessageBox() {
    let infoText = ExtensionUtil.getInfoText(
      ExtensionsInfo.CAPTION_ITEMNUMBER_CROWLER_SAVE_RECORDS_WARNING
    );
    const title = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_WARNING);
    this.setState({
      showMessageBox: true,
      dlgTitle: title,
      dlgContentText: infoText,
    });
  }

  //=================================================================================================================

  private onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();

    var file = event.target.files[0];

    if (file === undefined) {
      // User closed OpenFileDialog with cancel !!
    } else {
      if (file !== null) {
        this.getBimCadRecords(file);
        console.log(file);
      } else {
        let info = ExtensionUtil.getErrorText(
          ExtensionsError.ERROR_INVALID_BIMCAD_FILE
        );
        this.props.parentPanel.showErrorMessage(info);
      }
    }
  }

  //=================================================================================================================

  render() {
    return (
      <div>
        <div>
          <ThemeProvider theme={g_darkThemeDialog}>
            <Dialog
              open={this.state.showMessageBox}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.onCloseMessageBox}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dlg-title-ic">
                {this.state.dlgTitle}
              </DialogTitle>

              <DialogContent>
                <DialogContentText id="alert-dlg-ic">
                  <Typography display="block">
                    {this.state.dlgContentText}
                  </Typography>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={this.onOKMessageBox} color="primary">
                  OK
                </Button>
                <Button onClick={this.onCancelMessageBox} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </ThemeProvider>
        </div>

        <div className="bimcad-grid-container" id="bimcad-grid-container">
          <div className="bimcad-grid-content">
            <div id="bimcad-datagrid" />
          </div>
        </div>

        <div className="bimcad-buttons-container" id="bimcad-buttons-container">
          <div className="row ml-1 p0">
            <div className="xxx">
              <input
                id="myInput_BimCad"
                type="file"
                ref={(ref) => (this.upload = ref)}
                style={{ display: "none" }}
                onChange={this.onChangeFile.bind(this)}
              />

              <button
                className="btn  btn-bimcad"
                id="btn_load_file_bimcad"
                type="button"
                onClick={() => this.upload.click()}
              >
                Open File
              </button>

              <button
                className="btn  btn-secondary btn-bimcad"
                onClick={(e) => this.onSaveToDatabase(e)}
                disabled={!this.state.canSave}
                type="button"
              >
                Save
              </button>

              <button
                className="btn  btn-secondary btn-bimcad"
                onClick={(e) => this.onClose(e)}
                type="button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //=================================================================================================================
}
