//import THREE from "forge-viewer/node_modules/@types/three";

import { FilterTypes } from "./utils";

//import THREE from "forge-viewer/node_modules/@types/three";
declare var THREE: any;


export enum ModelDataTypes
{
    ALL_NODES                 = 1,
    ALL_NODES_BY_PARENT_DBID  = 2,
    ALL_FRAGMENTS             = 3,
    ALL_FRAGMENTS_BY_PARENT_DBID = 4,
    ALL_LEAF_NODES               = 5,
    ALL_LEAF_NODES_BY_PARENT_DBID = 6,
    ALL_LEAF_NODES_2             = 7
}


export interface IObjectProperties01
{
    DBID:number,
    ItemNumber:string,
    Description:string,
}

export interface IPropertiesResult
{
    DBID            :number,
    propertyName    :string
    propertyValue   :string
}



export default class ViewerToolkit {

    ///////////////////////////////////////////////////////////////////
    //
    //
    ///////////////////////////////////////////////////////////////////
    static guid(format = 'xxxxxxxxxxxx') {
  
      // var d = new Date().getTime();
  
      // var guid = format.replace(
      //   /[xy]/g,
      //   function (c) {
      //     var r = (d + Math.random() * 16) % 16 | 0;
      //     d = Math.floor(d / 16);
      //     return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
      //   });
  
      // return guid;
    }
  
    /////////////////////////////////////////////
    //mobile detection
    //
    /////////////////////////////////////////////
    // static get mobile() {
  
    //   return {
  
    //     getUserAgent: function () {
    //       return navigator.userAgent;
    //     },
    //     isAndroid: function () {
    //       return this.getUserAgent().match(/Android/i);
    //     },
    //     isBlackBerry: function () {
    //       return this.getUserAgent().match(/BlackBerry/i);
    //     },
    //     isIOS: function () {
    //       return this.getUserAgent().match(/iPhone|iPad|iPod/i);
    //     },
    //     isOpera: function () {
    //       return this.getUserAgent().match(/Opera Mini/i);
    //     },
    //     isWindows: function () {
    //       return this.isWindowsDesktop() || this.isWindowsMobile();
    //     },
    //     isWindowsMobile: function () {
    //       return this.getUserAgent().match(/IEMobile/i);
    //     },
    //     isWindowsDesktop: function () {
    //       return this.getUserAgent().match(/WPDesktop/i);
    //     },
    //     isAny: function () {
  
    //       return this.isAndroid() ||
    //         this.isBlackBerry() ||
    //         this.isIOS() ||
    //         this.isWindowsMobile();
    //     }
    //   }
    // }
  
    //////////////////////////////////////////////////////////////////////////
    // Return default viewable path: first 3d or 2d item
    //
    //////////////////////////////////////////////////////////////////////////
    // static getDefaultViewablePath (doc, roles = ['3d', '2d']) {
  
    //     var rootItem = doc.getRootItem()
  
    //     let roleArray = [...roles]
  
    //     let items = []
  
    //     roleArray.forEach((role) => {
  
    //         items = [ ...items, ...Autodesk.Viewing.Document.getSubItemsWithProperties( rootItem, { type: 'geometry', role }, true) ]  })
  
    //     return items.length ? doc.getViewablePath(items[0]) : null
    // }
  
    /////////////////////////////////////////////////////////////////
    // Toolbar button
    //
    /////////////////////////////////////////////////////////////////
  //   static createButton(id, className, tooltip, handler) {
  
  //     var button = new Autodesk.Viewing.UI.Button(id);
  
  //  //   button.icon.style.fontSize = '24px';
  //  //   button.icon.className = className;
  
  //     button.setToolTip(tooltip);
  
  //     button.onClick = handler;
  
  //     return button;
  //   }
  
    /////////////////////////////////////////////////////////////////
    // Control group
    //
    /////////////////////////////////////////////////////////////////
    // static createControlGroup(viewer, ctrlGroupName) 
    // {
  
    //     const viewerToolbar = viewer.getToolbar(true);
  
    //     if (viewerToolbar)
    //     {
    //         const ctrlGroup =  new Autodesk.Viewing.UI.ControlGroup( ctrlGroupName);
  
    //         viewerToolbar.addControl(ctrlGroup);
  
    //         return ctrlGroup;
    //     }
    // }
  
    /////////////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////////////
    static getLeafNodes (model, dbIds)  {
  
      return new Promise((resolve, reject)=>{
  
        try {
  
            const instanceTree = model.getData().instanceTree;
  
            if (dbIds == null)
            {
              dbIds = instanceTree.getRootId();
  
            }
//          dbIds = dbIds || instanceTree.getRootId();
  
            const dbIdArray = Array.isArray(dbIds) ? dbIds : [dbIds];
  
            const leafIds:Array<number> = [];
  
            const getLeafNodesRec = (id) => {
  
                let childCount = 0;
  
                instanceTree.enumNodeChildren(id, (childId) => {
  
                    getLeafNodesRec(childId);
  
                    ++childCount;
                })
  
                if (childCount === 0) 
                {
                    leafIds.push(id)
                }
            }
  
            for (var i = 0; i < dbIdArray.length; ++i) 
            {
                getLeafNodesRec(dbIdArray[i])
            }
  
            return resolve(leafIds)
  
        } 
        catch(ex)
        {
          return reject(  null);  //ex)
        }
    })
    }
  
    /////////////////////////////////////////////////////////////////
    // get node fragIds
    /////////////////////////////////////////////////////////////////

    // static getFragIds (model, dbIds) {
  
    //     return new Promise(async(resolve, reject) => {
  
    //         try{
    
    //             const dbIdArray = Array.isArray(dbIds) ? dbIds : [dbIds];
    
    //             const instanceTree = model.getData().instanceTree
    
    //             let leafIds:any[] = [];

    //          //  leafIds = await ViewerExtToolkit.getLeafNodes( model, dbIdArray);
    
    //             let fragIds = [];
    
    //             for( let currentLeaf of leafIds) 
    //             {
    //                 instanceTree.enumNodeFragments( currentLeaf, (fragId) => { fragIds.push(fragId) })
    //             }
    
    //             return resolve(fragIds)
    
    //         } 
    //         catch(ex)
    //         {
    //             return reject(ex)
    //         }
    //     })
    // }
  
    /////////////////////////////////////////////////////////////////
    // Node bounding box
    //
    /////////////////////////////////////////////////////////////////

    // static getWorldBoundingBox(model, dbId) {
  
    //     return new Promise(async(resolve, reject)=>{
  
    //         try{
    
    //             let fragIds:any[] = [];

    //             //fragIds = await ViewerExtToolkit.getFragIds( model, dbId);
        
    //             if (!fragIds.length)
    //             {
    //                 return reject('No geometry, invalid dbId?');
    //             }
        
    //             var fragList = model.getFragmentList();
        
    //             var fragbBox = new THREE.Box3();
    //             var nodebBox = new THREE.Box3();
        
    //             fragIds.forEach(function(fragId)  {
        
    //                 fragList.getWorldBounds(fragId, fragbBox);
    //                 nodebBox.union(fragbBox);
    //             });
        
    //             return resolve(nodebBox);
    //         }
    //         catch(ex) 
    //         {
    //             return reject(ex);
    //         }
    //     });
    // }
  
    /////////////////////////////////////////////////////////////////
    // Gets properties from component
    //
    /////////////////////////////////////////////////////////////////


    // static getXXX(viewer,dbId) {

    //     let id = viewer.getProperties(dbId, function (props) { 

    //         alert(props.externalId);

    //         return props.externalId;
    //     });
    // }
       


    static getProperties(model, dbId, requestedProps = null) {
  
        return new Promise((resolve, reject) => {
  
        try {
  
            if (requestedProps) 
            {
                const propTasks = requestedProps.map((displayName) => {
    
                    return ViewerToolkit.getProperty( model, dbId, displayName, 'Not Available'); 
                });
    
                Promise.all(propTasks).then((properties) => {
    
                    resolve(properties);
                });
            }
            else 
            {
                model.getProperties(dbId, function(result) {
  
                    if (result.properties)
                    {
                        return resolve( result.properties);
                    }
                    return reject('No Properties')
                 });
            }
        } 
        catch (ex)
        {
            console.log(ex)
            return reject(ex)
        }
        });
    }
  
    /////////////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////////////

    static getProperty(model, dbId, displayName, defaultValue)
    {
        return new Promise((resolve, reject) => {
  
            try
            {
                model.getProperties(dbId, function(result) {
    
                    if (result.properties) 
                    {
                        result.properties.forEach((prop) => {
        
                            if (typeof displayName === 'function') 
                            {
                                if (displayName(prop.displayName))
                                {
                                    resolve(prop)
                                }
                            } 
                            else if (displayName === prop.displayName) 
                            {
                                resolve(prop)
                            }
                        });
        
                        if (defaultValue) 
                        {
                            return resolve( { displayValue: defaultValue, displayName });
                        }
    
                        reject(new Error('Not Found'))
    
                    }
                    else 
                    {
                        reject(new Error('Error getting properties'));
                    }
                })
            }
            catch(ex)
            {
                return reject(ex);
            }
        });
    }

    /////////////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////////////
   
    static getProperty02(model, dbId, displayName)
    {
        return new Promise((resolve, reject) => {
  
            try
            {
                model.getProperties(dbId, function(result) {
    
                    if (result.properties) 
                    {
                        result.properties.forEach((currProperty) => {
                            
                            if (currProperty.displayName === displayName)
                            {
                                const resultData:IPropertiesResult = {DBID: dbId,  propertyName: displayName,  propertyValue: currProperty.displayValue};     
                                resolve(resultData)
                            }
                        });
                               
                        const resultData:IPropertiesResult = {DBID: dbId,  propertyName: "",  propertyValue: ""};     
                        reject( resultData );  //  new Error('not found'));
    
                    }
                    else 
                    {
                        const resultData:IPropertiesResult = {DBID: dbId,  propertyName: "",  propertyValue: ""};     
                        reject( resultData );  //  new Error('Error getting properties'));
                    }
                })
            }
            catch(ex)
            {
                const resultData:IPropertiesResult = {DBID: 0,  propertyName: "",  propertyValue: ""};     
                return reject(resultData);
            }
        });
    }


    static searchPropertiesByRestriction(model, dbId, dontAcceptDisplayName)
    {
        return new Promise((resolve, reject) => {
  
            try
            {
                model.getProperties(dbId, function(result) {
    
                    if (result.properties) 
                    {
                        let lastPropertyName  = "";
                        let lastDisplayValue = "";

                        result.properties.forEach((currProperty) => {
                            
                            lastPropertyName = currProperty.propertyName;
                            lastDisplayValue = currProperty.displayName;

                            if (currProperty.displayName.toLowerCase().startsWith(dontAcceptDisplayName.toLowerCase()))
                            {
                                // this dbId has at least 1 displayValue which starts with a name we dont accept ...
                                const resultData:IPropertiesResult = {DBID: dbId,  propertyName: "",  propertyValue: ""};     
                                reject( resultData );  //  new Error('not found'));
                            }
                        });

                        // check Name ( Name unfortunatly is NOT a property )
                        if (result.name.toLowerCase().startsWith(dontAcceptDisplayName.toLowerCase()))
                        {
                            const resultData:IPropertiesResult = {DBID: dbId,  propertyName: "",  propertyValue: ""};     
                            reject( resultData );  //  new Error('not found'));
                        }
                        else
                        {
                            const resultData:IPropertiesResult = {DBID: dbId,  propertyName: lastPropertyName,  propertyValue: lastDisplayValue};     
                            resolve(resultData)
                        }
                    }
                    else 
                    {
                        // this dbId has no properties at all
                        const resultData:IPropertiesResult = {DBID: dbId,  propertyName: "",  propertyValue: ""};     
                        reject( resultData );  //  new Error('Error getting properties'));
                    }
                })
            }
            catch(ex)
            {
                const resultData:IPropertiesResult = {DBID: 0,  propertyName: "",  propertyValue: ""};     
                return reject(resultData);
            }
        });
    }




    /////////////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////////////
   
    static getViewerProperty(viewer:any, dbId:number, displayName:string)
    {
        return new Promise((resolve, reject) => {
  
            try
            {
                viewer.getProperties(dbId, function(result) {
    
                    if (result.properties) 
                    {
                        result.properties.forEach((currProperty) => {
                            
                            if (currProperty.displayName === displayName)
                            {
                                const resultData:string = currProperty.displayValue;     
                                resolve(resultData)
                            }
                        });
                        reject( "the property " + displayName + "  does not exist" ); 
                    }
                    else 
                    {
                        reject( "err: getProps has no properties" );  //  new Error('Error getting properties'));
                    }
                })
            }
            catch(ex)
            {
                return reject( "err: getProps");
            }
        });
    }

    static getExternalDbIdAsync(viewer, dbId)
    {
        return new Promise((resolve, reject) => {
  
            try
            {
                viewer.getProperties(dbId, function(result) {
    
                    if (result.properties) 
                    {
                        resolve( result.externalId );
                    }
                    else 
                    {
                        reject( "0" );  //  new Error('Error getting properties'));
                    }
                })
            }
            catch(ex)
            {
                return reject( "0");
            }
        });
    }

    /////////////////////////////////////////////////////////////////
    // Gets all existing properties from component  dbIds
    //
    /////////////////////////////////////////////////////////////////
    static getPropertyList (model, dbIds)
    {
  
        return null;

    //   return new Promise(async(resolve, reject)=>{
  
    //     try {
  
    //         const propertyTasks = dbIds.map((dbId) => {
  
    //         return ViewerExtToolkit.getProperties(model, dbId);

    //         });
  
    //         const propertyResults = await Promise.all ( propertyTasks );
  
    //         var properties = [];
  
    //         propertyResults.forEach((propertyResult) => {
  
    //             propertyResult.forEach((prop) => {
  
    //                 if ( properties.indexOf(prop.displayName) < 0) 
    //                 {
    //                     properties.push(prop.displayName);
    //                 }
    //             });
    //         });
  
    //         return resolve(properties.sort());

    //     }
    //     catch(ex) 
    //     {
    //         return reject(ex);
    //     }
    //   });
    }
  
    /////////////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////////////

    // static getBulkPropertiesAsync (model, dbIds, propFilter) 
    // {
    //     return new Promise((resolve, reject) => {
  
    //         model.getBulkProperties(dbIds, propFilter, (result) => {
  
    //             resolve (result);
  
    //         }, (error) => { reject(error) })
    //     })
    // }
  
    /////////////////////////////////////////////////////////////////
    // Maps components by property
    //
    /////////////////////////////////////////////////////////////////
    static mapComponentsByProp (model, propName, components, defaultProp)
    {

        return null;

  
    //   return new Promise(async(resolve, reject) => {
  
    //     try {
  
    //       const results = await ViewerExtToolkit.getBulkPropertiesAsync(
    //         model, components, [propName])
  
    //       const propertyResults = results.map((result) => {
  
    //         return Object.assign({}, result.properties[0], {
    //           dbId: result.dbId
    //         })
    //       })
  
    //       var componentsMap = {};
  
    //       propertyResults.forEach((result) => {
  
    //         var value = result.displayValue;
  
    //         if(typeof value == 'string'){
  
    //           value = value.split(':')[0]
    //         }
  
    //         if (!componentsMap[value]) {
  
    //           componentsMap[value] = []
    //         }
  
    //         componentsMap[value].push(result.dbId)
    //       })
  
    //       return resolve(componentsMap)
  
    //     } catch(ex){
  
    //       return reject(ex);
    //     }
    //   })
    }
  
    /////////////////////////////////////////////////////////////
    // Runs recursively the argument task on each node
    // of the data tree
    //
    /////////////////////////////////////////////////////////////

    // static runTaskOnDataTree(root, taskFunc) {
  
    //   var tasks = [];
  
    //   var runTaskOnDataTreeRec = (node, parent=null)=> {
  
    //     if (node.children) {
  
    //       node.children.forEach((childNode)=> {
  
    //         runTaskOnDataTreeRec(childNode, node);
    //       });
    //     }
  
    //     var task = taskFunc(node, parent);
  
    //     tasks.push(task);
    //   }
  
    //   runTaskOnDataTreeRec(root);
  
    //   return Promise.all(tasks);
    // }

 
   //=================================================================================================================

   static updateIsolateFull (viewer, currentSelectedId:number, prevSelectedId:number) : void
   {
        viewer.impl.visibilityManager.setNodeOff( currentSelectedId,   true); 

        viewer.impl.visibilityManager.setNodeOff( prevSelectedId,      false); 
   }

    //=================================================================================================================

    static isolateFull (viewer, model = null, dbIds:Array<number>) {
  
        return new Promise(async(resolve, reject) => {
  
            try 
            {
    
                model = model || viewer.model
    
                let targetLeafIds = null;

                if (dbIds != null)
                {
                    let targetIds = null;
                    viewer.isolate(dbIds)
                    targetIds = Array.isArray(dbIds) ? dbIds : [dbIds]
                    targetLeafIds = await ViewerToolkit.getLeafNodes( model, targetIds);   // selected leaf-ids

                }
                const leafIds = await ViewerToolkit.getLeafNodes (model,null)

                let leafIds_array = leafIds as number[];

             
                const leafTasks = leafIds_array.map((dbId) => {
    
                    return new Promise((resolveLeaf) => {
    
                        let bShow = false;


                        if (targetLeafIds != null)
                        {
          
                            let targetLeafIds_array = targetLeafIds as number[];

                            bShow = !targetLeafIds_array.length  || targetLeafIds_array.indexOf(dbId) > -1;

                            viewer.impl.visibilityManager.setNodeOff( dbId,   !bShow); 

                        }
                        else
                        {
                            viewer.impl.visibilityManager.setNodeOff( dbId,   false); 
                        }
                        resolveLeaf(null);

                    })
                })
                return Promise.all(leafTasks)
            } 
            catch(ex)
            {
                return reject(ex)
            }
        })
    }


 

   //=================================================================================================================
    /**
     * Lists IDs of objects in the scene.
     * @param {number?} [parentId = undefined] ID of the parent object whose children
     * should be listed. If undefined, the list will include all scene object IDs.
     * @returns {Promise<number[]>} Promise that will be resolved with a list of IDs,
     * or rejected with an error message, for example, if there is no
     */
    //=================================================================================================================

    public static getNodes(theViewer:any, parentId = undefined) : Promise<number[]>
    {
        const viewer = theViewer;

        return new Promise(function(resolve, reject) {
        
            function onSuccess(tree) {
                
                if (typeof parentId === 'undefined') 
                {
                    parentId = tree.getRootId();
                }
                let ids = [];
                tree.enumNodeChildren(parentId, function(id) { ids.push(id); }, true);
                resolve(ids);
            }
            function onError(err) { reject(err); }
            viewer.getObjectTree(onSuccess, onError);
        });
    }

    //=================================================================================================================
    /**
     * Enumerates IDs of leaf objects in the scene.
     *
     * To make sure the method call is synchronous (i.e., it returns *after*
     * all objects have been enumerated), always wait until the object tree
     * has been loaded.
     *
     * @param {NodeCallback} callback Function called for each object.
     * @param {number?} [parent = undefined] ID of the parent object whose children
     * should be enumerated. If undefined, the enumeration includes all leaf objects.
     */
    //=================================================================================================================

    private enumerateLeafNodes(viewer, callback, parent = undefined) 
    {
        let tree = null;
        function onNode(id) { if (tree.getChildCount(id) === 0) callback(id); }
        function onSuccess(_tree) {
            tree = _tree;
            if (typeof parent === 'undefined') {
                parent = tree.getRootId();
            }
            tree.enumNodeChildren(parent, onNode, true);
        }
        function onError(err) { throw new Error(err); }
        viewer.getObjectTree(onSuccess, onError);
    }

    //=================================================================================================================
    /**
     * Lists IDs of leaf objects in the scene.
     * @param {number?} [parentId = undefined] ID of the parent object whose children
     * should be listed. If undefined, the list will include all leaf object IDs.
     * @returns {Promise<number[]>} Promise that will be resolved with a list of IDs,
     * or rejected with an error message, for example, if there is no
     */
    //=================================================================================================================

    public static getLeafNodes02(theViewer:any,parentId = undefined) : Promise<number[]>
    {  
        return new Promise(function(resolve, reject) {

            let tree = null;
            let ids = [];

            const thatViewer = theViewer;

            function onSuccess(_tree)
            {
                tree = _tree;

                if (typeof parentId === 'undefined') 
                {
                    parentId = tree.getRootId();
                }
                
                tree.enumNodeChildren(parentId, function(id) { 
                    
                    if (tree.getChildCount(id) === 0) 
                        ids.push(id);
                }, true);

                resolve(ids);
            }

            function onError(err)
            { 
                reject(err); 
            }
            
            thatViewer.getObjectTree(onSuccess, onError);
        });
    }

    //=================================================================================================================

    // getLeafNodes03(model, nodeId) {

    //     return new Promise((resolve, reject) => {
      
    //         try 
    //         {
      
    //             var leafIds = [];
      
    //             var instanceTree = model.getData().instanceTree
      
    //             nodeId = nodeId || instanceTree.getRootId()
      
    //             function _getLeafNodesRec(id) {
      
    //                 var childCount = 0;
      
    //                 instanceTree.enumNodeChildren(id, function(childId) { 
                        
    //                     _getLeafNodesRec(childId); 
                        
    //                     ++childCount;
    //                 })
      
    //                 if( childCount == 0) 
    //                 {
    //                     leafIds.push(id)
    //                 }
    //             }
      
    //             _getLeafNodesRec(nodeId);
      
    //             return resolve(leafIds);
      
    //         } 
    //         catch(ex)
    //         {
    //             return reject(ex)
    //         }
    //     })
    // }
  


    //=================================================================================================================
    /**
     * Enumerates fragment IDs of specific object or entire scene.
     *
     * To make sure the method call is synchronous (i.e., it returns *after*
     * all fragments have been enumerated), always wait until the object tree
     * has been loaded.
     *
     * @param {FragmentCallback} callback Function called for each fragment.
     * @param {number?} [parent = undefined] ID of the parent object whose fragments
     * should be enumerated. If undefined, the enumeration includes all scene fragments.
     */ 
    //=================================================================================================================

    private enumerateFragments(viewer:any,callback, parent = undefined) {
        function onSuccess(tree) {
            if (typeof parent === 'undefined') {
                parent = tree.getRootId();
            }
            tree.enumNodeFragments(parent, callback, true);
        }
        function onError(err) { throw new Error(err); }
        viewer.getObjectTree(onSuccess, onError);
    }

    //=================================================================================================================
    /**
     * Lists fragments IDs of specific scene object.
     * Should be called *after* the object tree has been loaded.
     * @param {number?} [parentId = undefined] ID of the parent object whose fragments
     * should be listed. If undefined, the list will include all fragment IDs.
     * @returns {Promise<number[]>} Promise that will be resolved with a list of IDs,
     * or rejected with an error message, for example, if there is no
     */
    //=================================================================================================================

    public static getFragments(theViewer:any,parentId = undefined)  : Promise<number[]>
    {
         const viewer = theViewer;
        return new Promise(function(resolve, reject) {
            function onSuccess(tree) {
                if (typeof parentId === 'undefined') {
                    parentId = tree.getRootId();
                }
                let ids = [];
                tree.enumNodeFragments(parentId, function(id) { ids.push(id); }, true);
                resolve(ids);
            }
            function onError(err) { reject(err); }
            viewer.getObjectTree(onSuccess, onError);
        });
    }

    //=================================================================================================================
    /**
     * Gets world bounding box of scene fragment.
     * @param {number} fragId Fragment ID.
     * @param {THREE.Box3} [bounds] {@link https://threejs.org/docs/#api/en/math/Box3|Box3}
     * to be populated with bounding box values and returned
     * (in case you want to avoid creating a new instance for performance reasons).
     * @returns {THREE.Box3} Transformation {@link https://threejs.org/docs/#api/en/math/Box3|Box3}.
     * @throws Exception when the fragments are not yet available.
     */
    //=================================================================================================================

    private getFragmentBounds(viewer:any,fragId, bounds = null) {
        if (!viewer.model) {
            throw new Error('Fragments not yet available. Wait for Autodesk.Viewing.FRAGMENTS_LOADED_EVENT event.');
        }
        const frags = viewer.model.getFragmentList();
        bounds = bounds || new THREE.Box3();
        frags.getWorldBounds(fragId, bounds);
        return bounds;
    }

    //=================================================================================================================

    public static getAllLeafComponents(viewer, callback) 
    {
        var cbCount = 0; // count pending callbacks
        var components = []; // store the results
        var tree; // the instance tree
    
        function getLeafComponentsRecursive(parent) 
        {
            cbCount++;

            if (tree.getChildCount(parent) !== 0) 
            {
                tree.enumNodeChildren(parent, function (children) 
                {
                    getLeafComponentsRecursive(children);
                }, false);
            }
            else 
            {
                components.push(parent);
            }
            if (--cbCount === 0) 
            {
                callback(components);
            }
        }

        viewer.getObjectTree(function (objectTree) {

            tree = objectTree;
            getLeafComponentsRecursive(tree.getRootId());
        });
    }

    //=================================================================================================================

    static changeTransparencyForAll(viewer,model)
    {
     
        const fragList = model.getFragmentList()

        ViewerToolkit.getLeafNodes02(model).then((dbIds) => {

            dbIds.forEach((dbId) => {

                const fragIds = ViewerToolkit.nodeIdToFragIds( model, dbId)

                fragIds.forEach((fragId) => {

                    var material = fragList.getMaterial(fragId)

                    if  (material) 
                    {
                        material.opacity = 0.5
                        material.transparent = true
                        material.needsUpdate = true
                    }
                })
            })

            viewer.impl.invalidate(true, true, true)
        })
        return true;
    }

    //===================================================================================================================

    static nodeIdToFragIds(model, nodeId)
    {
        const instanceTree = model.getData().instanceTree;
    
        var fragIds = [];

        instanceTree.enumNodeFragments(nodeId, (fragId) => {

            fragIds = [...fragIds, fragId];
        });
    
        return fragIds;
    } 
    
    //===================================================================================================================

    static isViewerObjectTreeCreated(viewer) : boolean
    {
        const model     = viewer.model;
        const modelData = model.getData();
        const it        = modelData.instanceTree;  

        return (modelData.instanceTree) ? true : false;
    }

    //===================================================================================================================

    static async processSearchPropertyByKey(viewer:any,propertyName:string,dbId:number,propertyValue:string,opcode:number) : Promise<boolean> 
    {
        let bRetval:boolean = false;
 
        await ViewerToolkit.getProperty02(viewer.model, dbId, propertyName).then(function(result:IPropertiesResult) {
 
            if (result !== null)
            {
                if (opcode == FilterTypes.STARTSWITH_EXCLUDE)
                {
                     const propValue = result.propertyValue.toLowerCase();    
 
                     if (propValue.startsWith(propertyValue.toLowerCase()) == false)
                     {
                         bRetval = true;
                     }
                }
            }
        })
        .catch((error) => { 
       
           // alert( "Error: 4566 " + dbId );  
        });    
        return bRetval;
     }

    //===================================================================================================================

    static async searchAllPropertiesByRestriction(viewer:any,dbId:number,dontAcceptPropertyValue:string,opcode:number) : Promise<boolean> 
    {
        let bRetval:boolean = false;
  
        await ViewerToolkit.searchPropertiesByRestriction(viewer.model, dbId, dontAcceptPropertyValue).then(function(result:IPropertiesResult) {
  
            if (result !== null)
            {
               bRetval = true;
            }
        })
        .catch((error) => { 
           // alert( "Error: 4566 " + dbId );  
        });    
        return bRetval;
    }
 
    //===================================================================================================================

  }