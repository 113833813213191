// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/button-ico.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.itemNumberCrawlerExtensionToolbarButton {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); \n    background-size: 24px;\n    background-repeat: no-repeat;\n    background-position: center;\n}\n\n\n\n\n#id-spinnerContainer-ic\n{\n    align-items:     center;\n    justify-content: center;\n}\n\n\n#id-spinnerCaptionPanel-ic\n{\n    color:             #a4b4a9;\n    margin-top:       12px;\n    display:          flex;\n    align-items:      center;\n    justify-content:  center;\n}\n\n#id-spinnerPanel-ic\n{\n  height:           auto;\n  padding:          0px;\n  margin-top:       0px;\n\n  display:          flex;\n  align-items:      center;\n  justify-content: center;\n}\n\n\n#currentLibContentTable-ic\n{\n  width: auto;\n}\n\n.some-space-ic\n{\n  height: 0px;\n}\n\n.btn-item-crawler\n{\n  margin:  0px 0px 0px 10px;              \n  background-color:  var(--base_button_bkcolor);\t\n  height:    42px;\n  width:     24px;\n}\n\n\n.itc-grid-container\n{\n    width: 100%;\n    margin-top: 0px;\n    padding-left: 32px;\n    padding-right:32px;    \n    box-sizing: border-box; \n}\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n", ""]);
// Exports
module.exports = exports;
