import ViewerModelToolkit from '../utils/ViewerModelToolkit';
import './css/TestExtension.css';
import { TestExtensionPanel } from "./TestExtensionPanel";

declare var THREE: any;

export class TestExtension extends Autodesk.Viewing.Extension {
    
    //=================================================================================================================
    _panel      :any = null;
    _subToolbar :any = null;
    _options    :any = null;
    _viewer     :any = null;
   // _allItems = null;
    //=================================================================================================================

    public constructor(viewer:any, options:any) {

        super(viewer, options);

        Autodesk.Viewing.Extension.call(this, viewer, options);

        this._options = options;
        this._viewer  = viewer;

        this._viewer.addEventListener( Autodesk.Viewing.SELECTION_CHANGED_EVENT,   this.onViewerSelectionChanged);
        this._viewer.addEventListener( Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onAggregateSelectionChanged.bind(this));


      //  this.onObjectTreeCreated = this.onObjectTreeCreated.bind(this)
    }
    
    //===================================================================================================================

    public load() : boolean {

        return true;
    }

    //===================================================================================================================

    public onToolbarCreated() : void 
    {
        this.createUI();
    }

    //===================================================================================================================

    private createUI() : void {

        if (this._subToolbar == null)
        {
            // button to show the docking panel
            const actionToolbarButton = new Autodesk.Viewing.UI.Button('Test');

            actionToolbarButton.addClass('testExtensionToolbarButton');
            actionToolbarButton.setToolTip('Test');

            actionToolbarButton.onClick = (e) => {  this.onToolbarButtonClicked(e);  }
            
            this._subToolbar = new Autodesk.Viewing.UI.ControlGroup('TestExtensionToolbar');
                
            this._subToolbar.addControl(actionToolbarButton);

            this._viewer.toolbar.addControl(this._subToolbar);

        }
    } 

    //===================================================================================================================

    public unload() : boolean 
    {
        this._viewer.removeEventListener( Autodesk.Viewing.SELECTION_CHANGED_EVENT,           this.onViewerSelectionChanged);
        this._viewer.removeEventListener( Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onAggregateSelectionChanged);
        this._viewer.toolbar.removeControl(this._subToolbar);
        return true;
    }
 
    //===================================================================================================================

    private onToolbarButtonClicked(e:any) : void {

        if (this._panel == null) 
        {
            this._panel = new TestExtensionPanel(this.viewer, this.viewer.container, 'TestExtensionPanel', 'Test', this.options);
        }                
        this._panel.setVisible(!this._panel.isVisible()); // toogle (show/hide) docking panel
    }

   //==================================================================================================================

   public onViewerSelectionChanged = (event:any) => { 

 
        if (event.dbIdArray.length) 
        {
            
            const dbId = event.dbIdArray[0];

            this._viewer.isolate(dbId);
        }    
        else
        {
            this._viewer.isolate( [] );
        }  
    }

    //=================================================================================================================

    public onObjectTreeCreated(event:any) : void 
    {
        // const that = this;

        // ViewerToolkit.getNodes(this._viewer).then(function(result) {
        //      that._allItems = [...result];
        //  });

        // ViewerToolkit.getFragments(this._viewer).then(function(result) {
        //     that._allItems = [...result];
        // });

        // ViewerToolkit.getLeafNodes02(this._viewer).then(function(result) {
        //     that._allItems = [...result];
        // });       

    }

    //===================================================================================================================

    public onAggregateSelectionChanged(event:any) : void 
    {
        if (event.selections.length == 0)
        {
            this._viewer.clearSelection();
        }

    }

    //===================================================================================================================

    public static loadExtension(viewer:any)
    {
        const init_options = {
            PANEL_INITIAL_POS:       "20,90,400,614",            // initial x1Pos,y1Pos,Width,Height of the panel
            WRITE_LOG_FILE:           1,                         // 1 => write to console.log  .., 0 => dont write    
        }
        viewer.loadExtension('Test.TestExtension',   { init_options } );
    }  
    
    //===================================================================================================================

}