import {AVEA_DescFilter,ExtensionsNames,Hilti_SearchMode, ExtensionsError,ExtensionInfoLevel,ExtensionUtil,ExtensionsInfo} from './utils'

export default class ViewerCore 
{
    //=================================================================================================================
    
    public static getElements(instanceTree:any, rootId:any, writeLogFile:boolean) : any[]
    {
        ViewerCore.writeToLogFile(rootId,writeLogFile);

        if (!ExtensionUtil.isValidContent(rootId))       // should NOT be undefined,null,empty-Array..or number with value 0
        {
            return null;
        }
        let allDbId:any[] = [];
        let queue:any[]   = [];

        queue.push(rootId);

        while (queue.length > 0) {

            let node = queue.shift();   // remove first element and returns it
            allDbId.push(node);         // append element
           
            instanceTree.enumNodeChildren(node, function (childrenIds)  // function(childrenIds) gets called for each child node.
            {
                queue.push(childrenIds);
            });
        }
        return allDbId;
    }
   
    //=====================================================================================================================
 
    public static getSubset(viewerModel:any, dbIds:any, name:string, value:string | number, writeLogFile:boolean, callback:Function) : void 
    {
        ViewerCore.writeToLogFile("getSubset, dbIds.length before = " + dbIds.length ,  writeLogFile);

        if (ExtensionUtil.isValidContent(name)) 
        {
           viewerModel.getBulkProperties(

            dbIds,
            {
               propFilter:     [name],
               ignoreHidden:   true,
            },
            function(data) {

                if (ExtensionUtil.isValidContent(data))
                {
                    let items:any[] = [];

                    for (let key in data) {

                        let item = data[key];

                        if (value) 
                        {
                            if (item.properties[0].displayValue === value)
                            {
                               items.push(item);
                            }
                        }
                        else 
                        {
                           items.push(item);
                        }
                    }
                    ViewerCore.writeToLogFile("getSubset, dbIds.length after = " + items.length, writeLogFile);
                    callback(items);
               }
            },
               function (error) { console.log(error); }
            );
        }
    }

    //======================================================================================================================

    public static writeToLogFile(info:any,writeLogFile:boolean) : void
    {
        if (writeLogFile)
            console.log(info);
    }
 
    //======================================================================================================================

    public static getAssembliesCodes(viewerModel:any,searchMode:Hilti_SearchMode,writeLogFile:boolean,dispValueSeparator:any) 
    {
        return new Promise(function (resolve, reject) {
            
            let instanceTree  = viewerModel.getData().instanceTree;
            let rootId        = instanceTree.getRootId();

            if (ExtensionUtil.isValidContent(rootId))
            {
                let dbIds         = ViewerCore.getElements(instanceTree, rootId, writeLogFile );
                let assemblyCodes = new Set<string>();
                let uniqueArray =[]; 
    
                if ( searchMode === Hilti_SearchMode.LOCATION_CODE)
                {
                    viewerModel.getBulkProperties(dbIds, [  ExtensionsNames.HILTI_LOCATION_CODE ], function (elements)
                    {
                        if (ExtensionUtil.isValidContent(elements))   // should NOT be undefined,null or empty array!!!
                        {
                            for (var i = 0; i < elements.length; i++) 
                            {
                                if (ExtensionUtil.isContentNotNull(elements[i].properties[0]))
                                {
                                    if (elements[i].properties[0].displayValue)
                                    {
                                        if (typeof(elements[i].properties[0].displayValue) == "number") 
                                        {
                                            let currNumber:number = elements[i].properties[0].displayValue as number;
                                            assemblyCodes.add( currNumber.toString());     
                                        }
                                        if (typeof(elements[i].properties[0].displayValue) == "string") 
                                        {
                                            assemblyCodes.add(elements[i].properties[0].displayValue);
                                        }
                                    } 
                                }
                            }
                            uniqueArray = Array.from(new Set(assemblyCodes));     // create Arrray from set with unique numbers
                            resolve(uniqueArray);                                      
                        }
                        else
                        {
                            resolve( null );   
                        }
                    });
                }
                else if ( searchMode === Hilti_SearchMode.AVEVA_DESCRRIPTION)
                {
                    viewerModel.getBulkProperties(dbIds, [ ExtensionsNames.DESCRIPTION ], function (elements) {

                        if (ExtensionUtil.isValidContent(elements))   // should NOT be undefined,null or empty array!!!
                        {
                            var aveaElements = ViewerCore.filterByAVEADesc(elements,AVEA_DescFilter.GET_NAMES,dispValueSeparator);

                            if (ExtensionUtil.isValidContent(aveaElements))
                            {
                                uniqueArray = Array.from(new Set(aveaElements));     // create Arrray from set with unique numbers
                            }
                        }
                        else
                        {
                            //reject(ExtensionsError.ERROR_INVALID_BULKPROPERTIES);
                        }
                        resolve(uniqueArray);
                    });
                }
                else
                {
                    resolve(null); // reject(ExtensionsError.ERROR_INVALID_HILTI_SEARCH_MODE);
                }
            }
            else
            {
                resolve(null); // ExtensionsError.ERROR_INVALID_ROOTID);
            }
        });
    }

    //======================================================================================================================

    public static filterByAVEADesc(elements:any[],searchFilter:AVEA_DescFilter,dispValueSeparator:any) : any[] 
    {
        let newElements:any[] = [];
 
        if (ExtensionUtil.isValidContent(elements))                                      // should NOT be undefined,null,empty-Array
        {
            for (let currentElement of elements) 
            {
                if (ExtensionUtil.isContentNotNull(currentElement.properties))           // should NOT be undefined,null
                {
                    for (let currrentProp of currentElement.properties) 
                    {
                        if (ExtensionUtil.isContentNotNull(currrentProp.displayValue))   // should NOT be undefined,null
                        {
                            // if (currrentProp.displayValue.startsWith( ExtensionsNames.AVEVA ))
                             {
                                 const truncated = currrentProp.displayValue.split( dispValueSeparator );
                                 if (ExtensionUtil.isValidContent( truncated ))   // should NOT be undefined or null
                                 {
                                     let  name = truncated[truncated.length - 1];  // last element in truncated-array
                                     name      = name.replace('.',''); 
                                     if (!isNaN(name))  // Include numbers only!!!!
                                     {  
                                         if (searchFilter === AVEA_DescFilter.GET_NAMES)
                                         {
                                             if (!newElements.includes(name))
                                             {         
                                                 newElements.push(name);
                                             }
                                         }
                                         else if (searchFilter === AVEA_DescFilter.GET_ELEMENTS)
                                         {
                                             if(!newElements.includes(currentElement))     
                                             {
                                                 newElements.push(currentElement);
                                             }
                                         }
                                     }
                                 }
                             }
                        }
                    }
                }
            }
        }
        return newElements;
    }
 
    //=====================================================================================================================

    public static getModelIdentification(viewerModel:any,writeLog:boolean)
    {
        return  new Promise(function(resolve, reject)  {

            let modelIdentification = "";
            let instanceTree = viewerModel.getData().instanceTree;
            let rootId       = instanceTree.getRootId();

            if (ExtensionUtil.isValidContent(rootId))
            {
                let dbIds = ViewerCore.getElements(instanceTree, rootId,writeLog);
    
                if (dbIds != null)
                {
                    viewerModel.getBulkProperties(dbIds, [null], function (elements) 
                    {
                        if (ExtensionUtil.isValidContent(elements))       
                        {
                            if (ExtensionUtil.isValidContent(elements[0].name))
                            {
                                modelIdentification = elements[0].name;  
                            }
                        }
                    });
                }
    
                viewerModel.getBulkProperties(dbIds, [ ExtensionsNames.PROJECT_NUMBER ], function (elements)
                {
                    if (ExtensionUtil.isContentNotNull(elements))  
                    {
                        for (var i = 0; i < elements.length; i++) 
                        {
                            if (ExtensionUtil.isValidContent(elements[i].properties[0]))
                            {
                                if (elements[i].properties[0].displayValue) 
                                {
                                    modelIdentification = elements[i].properties[0].displayValue;
                                }
                            }
                        }
                        resolve(modelIdentification);   // Task successfuly resolved
                    }
                });
            }
            else
            {
                reject(ExtensionsError.ERROR_INVALID_ROOTID);
            }
        });
    }

   //=====================================================================================================================
     
   public static search(viewer:any,propertyName:string, propertyValue:string | number, writeLog:boolean, callback: Function)  : void
   {
       ViewerCore.writeToLogFile(propertyName + " = " + propertyValue, writeLog);

       if ( ExtensionUtil.isContentNotNull(propertyName) && ExtensionUtil.isContentNotNull(propertyValue)  )  // propertyValue can be empty string or number with value 0
       {
           const viewerModel   = viewer.model;

           viewer.search( '"' + propertyValue + '"',

               function (dbIds) { 

                   ViewerCore.getSubset( viewerModel, dbIds, propertyName, propertyValue, writeLog, function (dbIds) {

                        callback(dbIds);

                   });
               },
               function (error) {}
           );
       }
   }

   //===================================================================================================================

   public static getNamesFromAVEAElement(element:any,dispValueSeparator:string) : string[]
   {
       let names:string[] = [];

       for (let index = 0; index < element.properties.length; index++)
       {
           const prop     = element.properties[index];
           const truncated = prop.displayValue.split( dispValueSeparator );

           if (ExtensionUtil.isValidContent(truncated))
           {
               let name = truncated[truncated.length - 1];
               name     = name.replace('.',''); 

               if (!isNaN(name))   // check if name is a value !!!
               {  
                   if(!names.includes(name)) {
                       names.push(name);
                   }
               }
           }
       }
       return names;
   }

   //===================================================================================================================

   public static getDbIdFromAVEAElement(element:any) 
   {
       let dbIds = [];

       for (let index = 0; index < element.properties.length; index++) 
       {
           const prop = element.properties[index];
           if (prop.displayValue.startsWith( ExtensionsNames.AVEVA ))
           {
               if(!dbIds.includes(element.dbId))
               {
                   dbIds.push(element.dbId);
               }
           }
       }
       return dbIds;
   }

   //===================================================================================================================
        


}
