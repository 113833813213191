import './css/AssemblyExtension.css';
import  AssemblyExtensionPanel  from "./AssemblyExtensionPanel";
import Config from '../../../Config';
import { ExtensionsError, ExtensionUtil } from '../utils/utils';
import ViewerToolkit from '../utils/ViewerToolkit';

/*=====================================================================================================================
DESCRIPTION:
=======================================================================================================================
AssemblyExtension

When the  AssemblyExtension-Panel gets opened for the first time,
it will inspect the current active model in the viewer and try 
to find the Model-Identification and all its related Assemblies.
If this procedure has been succesfully executed, 
in a second step, the extension will connect to a database-server
and try to retrieve ( GET ) data for the given model-id (model identification)
and update the grid and chart gui component with new retrieved data.

GUI elements:

Button:
connects to database-server, retrieves data for the given model-id (model identification)
and updates the grid and chart gui component with new retrieved data.

Grid:
shows all Assemblies of the model id grouped by status

Bar-Chart:
shows the amount of objects in the model grouped by status

QR-Code Display:
shows the QR-code of the cuurent selected assembly ( current selected row in the grid ) 

Parameter:

PANEL_INITIAL_POS:      initial x1 and y1 ( left-upper corner ) width and height of the ExtensionPanel
default-Value:          x1 = 30  y1 = 90  width = 326 pixel  height = 520 pixel

SPINNER_COLOR:          color of spinner bars
default-Value:          #FF0000 ( red )

SPINNER_SIZE:           size of spinner
default-Value:          60          30 small, 60 middle, 90 big

INFO_LEVEL:             1 = less detailed Error or warning-messages,  2 = more detailed Error or warning-messages
default-Value:          2    

HILTI_SEARCH_MODE:     defines how Assemblies will be searched. 1 => LOCATION_CODE, 2 => AVEVA_DESCRRIPTION  
default-Value:          1    

 GET_ASSEMBLIES_URL:    web-server url for GET transaction
 default-Value:         null 

 =====================================================================================================================*/

export class AssemblyExtension extends Autodesk.Viewing.Extension 
{

    //=================================================================================================================
    _panel      :any = null;
    _subToolbar :any = null;
    _viewer     :any = null;
    _options    :any = null;
    _viewerObjectTreeCreated:boolean = false;
    //=================================================================================================================

    
    constructor(viewer:any, options:any) {

        super(viewer, options);

        this._viewer  = viewer;
        this._options = options;

        this._viewer.addEventListener( Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT ,   this.onViewerObjectTreeCreated.bind(this));
    }

    //===================================================================================================================

    public load() : boolean {

        if(this._viewer.toolbar)
        {
            this.createUI(); // Toolbar is already available, create the UI
        }
        return true;
    }

    //===================================================================================================================

    public onToolbarCreated() : void {

        this.createUI();
    }

    //===================================================================================================================

    private createUI() : void {

        if (this._subToolbar === null)
        {
            //prepare to execute the button action
            const actionToolbarButton = new Autodesk.Viewing.UI.Button('Assembly');
            actionToolbarButton.addClass('assemblyExtensionToolbarButton');
            actionToolbarButton.setToolTip('Assembly');
            actionToolbarButton.onClick = (e) => {  this.onAssemblyToolbarButtonClicked(e);  }

            this._subToolbar = new Autodesk.Viewing.UI.ControlGroup("AssemblyExtensionToolbar");
            this._subToolbar.addControl(actionToolbarButton);
            this._viewer.toolbar.addControl(this._subToolbar);
        }
    } 

    //===================================================================================================================

    public unload() : boolean {
       
        this._viewer.removeEventListener( Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.onViewerObjectTreeCreated);
        if (this._subToolbar !== null)
            this._viewer.toolbar.removeControl(this._subToolbar);
        return true;
    }
 
    //===================================================================================================================

    private onAssemblyToolbarButtonClicked(e:any) : void {

        if (this._panel == null) 
        {
            if (this._viewerObjectTreeCreated)
            {
                this._panel = new AssemblyExtensionPanel(this._viewer, this._viewer.container, "AssemblyExtensionPanel", "Assembly", this._options);
            }
            else
            {
                alert(ExtensionUtil.getErrorText(ExtensionsError.ERROR_VIEWER_INSTANCE_TREE));
            }
        }
        if (this._panel !== null) 
            this._panel.setVisible(!this._panel.isVisible());
    }

    //===================================================================================================================

    public static loadExtension(viewer:any)
    {
        const getURL:string | undefined    = Config.LoadAssembExtGetAssemblyUrl;
        const separator:string | undefined = Config.LoadAssembExtDispValueSeparator;
        const spinnerColor : string | undefined = Config.SpinnerColor;
        const spinnerSize  : string | undefined = Config.SpinnerSize;
        const spinnerSizeValue = parseInt(spinnerSize,10);
        const hiltiSearchMode:string | undefined = Config.HiltiSearchMode;
        const hiltiSearchModeValue = parseInt(hiltiSearchMode,10);
    
        const init_options = {
          PANEL_INITIAL_POS:         "10,10,348,834",   // initial x1Pos,y1Pos,Width,Height of the panel
          SPINNER_COLOR:             spinnerColor,      // color of spinner bars
          SPINNER_SIZE:              spinnerSizeValue,  // size of spinner 90 -> very big 60 -> middle size  30 -> small
          INFO_LEVEL:                2,                 // 1 = more common Error or warning-text,  2= more detailed Error or Warning Text
          HILTI_SEARCH_MODE:         hiltiSearchModeValue, // LOCATION_CODE = 1,AVEVA_DESCRRIPTION = 2
          HILTI_DISPVALUE_SEPARATOR: separator,
          GET_ASSEMBLIES_URL:        getURL,            // web-server url for GET transaction
          WRITE_LOG_FILE:            1                  // 1 => write info to console.log ... 0 => dont write       
        }
     
        viewer.loadExtension('Hilti.AssemblyExtension',  {  init_options  });
    }  
  
    //===================================================================================================================

    private onViewerObjectTreeCreated() : void 
    {
        this._viewerObjectTreeCreated  = ViewerToolkit.isViewerObjectTreeCreated(this._viewer);
    }
  
    //===================================================================================================================


}