// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/viewer-settings-button-ico.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".viewerSettingsExtensionToolbarButton {\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); \r\n    background-size: 24px;\r\n    background-repeat: no-repeat;\r\n    background-position: center;\r\n  }\r\n\r\n\r\n  .btn-settings-button\r\n  {\r\n    width: 180px;\r\n    margin: 10px 10px 10px 10px;\r\n    background-color: var(--base_button_bkcolor);\t\r\n  }\r\n", ""]);
// Exports
module.exports = exports;
