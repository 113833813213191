// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/assembly-ico.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.assemblyExtensionToolbarButton {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 24px;\n    background-repeat: no-repeat;\n    background-position: center;\n  }\n\n\n  .spinnerContainer-assembly\n  {\n      align-items:     center;\n      justify-content: center;\n  }\n  \n  .assemblyPanelWrapper {\n   \n    height: auto;\n    width:  auto;\n    margin: 0px;\n    background-color: rgb(23, 85, 155);\n  }\n  \n  .barChartWrapper {\n   \n    width:  auto;\n    height: 100%;\n     margin: 0px;\n\n    /* margin: 10px;  */\n    background-color: #222222;  \n  }\n\n\n  .barChartCanvas {\n   \n    width:  auto;\n    height: 100%;\n     margin: 0px;\n\n    /* margin: 10px;  */\n    background-color: #222222;  \n  }\n\n\n  .assembliesTable {\n   \n    width:  auto;\n    height: auto;\n    \n    margin: 0px auto; \n    background-color: #885ebe; \n  }\n  \n \n", ""]);
// Exports
module.exports = exports;
