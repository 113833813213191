import './css/TestExtension.css';
import {ExtensionUtil} from '../utils/utils'
import ViewerToolkit from '../utils/ViewerToolkit';
import {ViewerMulti} from '../LEA-DE/ViewerMulti';
declare var THREE: any;

enum TRANSFORMATION_TYPE
{
    SCALE_X = 1,
    SCALE_Y = 2,
    SCALE_Z = 3,
    SCALE_XYZ = 4,

    POS_X = 5,
    POS_Y = 6,
    POS_Z = 7,
    POS_XYZ = 8,

    COLOR_MAT= 20,
    OPACITY_MAT = 50
}

enum VIEWER_MODE
{
    SELECTION_MODE = 1,
    ISOLATION_MODE = 2
}

enum ViewerObjectType
{
    OBJECT3D_CUBE   = 1,
    OBJECT3D_SPHERE = 2
}




interface DataRow
{
    id:number;
    itemNumber:string;
    extraField:string;
}


export class TestExtensionPanel extends Autodesk.Viewing.UI.DockingPanel 
{
    //=================================================================================================================
    protected _initialXPos   = "80";
    protected _initialYPos   = "140";
    protected _initialWidth  = "500";
    protected _initialHeight = "400";
    private   _viewer=null;
    private   _sceneBuilder=null;
    private   _modelBuilder=null;
    private   _grid = null;
    //=================================================================================================================
    private _currentAnimFrame:number=0.0;
    private _animStartTime:number=0.0;
    private _animLastTime:number=0.0;
    private _accuAnimTime:number = 0.0;
    private  _rotAnim360RotationDuration=10000.0;
    private  _seconds_pivot = null;
    private  _seconds_helper = null;
    private  _pin_Id:number = 0;
    private _rotateAnimRequestId = 0;
    private  _instanceTree = null;
    private _allHidden:number[]=[];
    private _allAddedMeshes:any[]=[];
    private _level01_dbIds:number[]=[];
    private _viewerMode:VIEWER_MODE = VIEWER_MODE.SELECTION_MODE;
    private _level01_redSpheres = true;
    private static DBID_START = 5000;

    constructor(viewer:any, container:any, id:any, title:any, options:any)  
    {
        super(viewer.container, id, title, options);
        
//        Autodesk.Viewing.UI.DockingPanel.call(this, container, id, title, options);

        this._viewer = viewer;


        this.onLevel1 = this.onLevel1.bind(this);

        this.createModelBuilder();

        this.readData();
      
 
        if (ExtensionUtil.isContentNotNull(options.init_options.PANEL_INITIAL_POS))
        {
            const posString = options.init_options.PANEL_INITIAL_POS;
            const xyCoords = posString.split(',');
            if (xyCoords.length === 4)
            {
              this._initialXPos   = xyCoords[0];
              this._initialYPos   = xyCoords[1];
              this._initialWidth  = xyCoords[2];
              this._initialHeight = xyCoords[3];
            }
        }   

        this.container.classList.add("docking-panel-container-solid-color-a");
         this.container.style.width       =  this._initialWidth +"px";  
         this.container.style.height      =  this._initialHeight +"px"; 
        this.container.style.minWidth    = "200px";
        this.container.style.minHeight   = "50px";
        this.container.style.resize      = "none";
        this.container.style.position    = "absolute";
         this.container.style.left        = this._initialXPos + "px";
         this.container.style.top         = this._initialYPos + "px";
 
        //================================================================================

      //  const wrapper = document.createElement('div');

       // this.container.appendChild( wrapper ); 

    //    this.container.appendChild(this.scrollContainer);




        // const button1   = document.createElement("button");
        // const button2   = document.createElement("button");
        // const button3   = document.createElement("button");
        // const button4   = document.createElement("button");
        // const button5   = document.createElement("button");
        // const button6   = document.createElement("button");
        // const button7   = document.createElement("button");
        // const button8   = document.createElement("button");
        // const button9   = document.createElement("button");
        // const button10  = document.createElement("button");
        // const button11  = document.createElement("button");
        // const button12  = document.createElement("button");
        // const button13  = document.createElement("button");
        // const button14  = document.createElement("button");
        // const button15  = document.createElement("button");
        // const button16  = document.createElement("button");
        // const button16b  = document.createElement("button");
        // const button17   = document.createElement("button");

        // const button18   = document.createElement("button");
        // const button19   = document.createElement("button");
        // const button20   = document.createElement("button");
        // const button21   = document.createElement("button");
        // const button22   = document.createElement("button");

    //     this.createScrollContainer({
    //         left: false,
    //         heightAdjustment: 45,
    //         marginTop:0
    //    });

       //=====================================================================



        // button1.setAttribute("class", "btn-sm btn-danger");
        // button1.setAttribute("id", "test-button");
        // button1.innerHTML      = "create GridMesh";

        // button2.setAttribute("class", "btn-sm btn-danger");
        // button2.setAttribute("id", "test-button");
        // button2.innerHTML    = "add Torus to ModelBuilder";

        // button3.setAttribute("class", "btn-sm btn-danger");
        // button3.setAttribute("id", "test-button");
        // button3.innerHTML    = " add 3 Cubes to ModelBuilder ";

        // button4.setAttribute("class", "btn-sm btn-danger");
        // button4.setAttribute("id", "test-button");
        // button4.innerHTML    = " add 3 Cubes to Scene ";

        // button5.setAttribute("class", "btn-sm btn-danger");
        // button5.setAttribute("id", "test-button");
        // button5.innerHTML    = " remove Model ";

        // button6.setAttribute("class", "btn btn-warning");
        // button6.setAttribute("id", "test-button");
        // button6.innerHTML    = " set background color ";

        // button7.setAttribute("class", "btn btn-danger");
        // button7.setAttribute("id", "test-button");
        // button7.innerHTML    = " rotate current selection ";

        // button8.setAttribute("class", "btn btn-danger");
        // button8.setAttribute("id", "test-button");
        // button8.innerHTML    = " ghost-isolate ";

        // button9.setAttribute("class", "btn btn-danger");
        // button9.setAttribute("id", "test-button");
        // button9.innerHTML    = " full isolate ";

        // //=======================================================
        // button10.setAttribute("class", "btn btn-info");
        // button10.setAttribute("id", "row-test-button");
        // button10.innerHTML    = " scale-X ";

        // button11.setAttribute("class", "btn btn-info");
        // button11.setAttribute("id", "row-test-button");
        // button11.innerHTML    = " scale-Y ";

        // button12.setAttribute("class", "btn btn-info");
        // button12.setAttribute("id", "row-test-button");
        // button12.innerHTML    = " scale-Z ";

        // button13.setAttribute("class", "btn btn-info");
        // button13.setAttribute("id", "row-test-button");
        // button13.innerHTML    = " scale-XYZ ";

        // //======================================================

        // button14.setAttribute("class", "btn btn-info");
        // button14.setAttribute("id", "test-button");
        // button14.innerHTML    = " move-X ";

        // button15.setAttribute("class", "btn btn-info");
        // button15.setAttribute("id", "test-button");
        // button15.innerHTML    = " move-Y ";

        // button16.setAttribute("class", "btn btn-info");
        // button16.setAttribute("id", "test-button");
        // button16.innerHTML    = " move-Z ";

        // button16b.setAttribute("class", "btn btn-info");
        // button16b.setAttribute("id", "test-button");
        // button16b.innerHTML    = " move-XYZ ";

        // //======================================================

        // button17.setAttribute("class", "btn btn-primary");
        // button17.setAttribute("id", "test-button");
        // button17.innerHTML    = " set ColorMat ";

        // button18.setAttribute("class", "btn btn-primary");
        // button18.setAttribute("id", "test-button");
        // button18.innerHTML    = " set TexMat ";

        // button19.setAttribute("class", "btn btn-primary");
        // button19.setAttribute("id", "test-button");
        // button19.innerHTML    = " set ColorMat ";

        // button20.setAttribute("class", "btn btn-primary");
        // button20.setAttribute("id", "test-button");
        // button20.innerHTML    = " set ShaderMat ";

        // button21.setAttribute("class", "btn btn-primary");
        // button21.setAttribute("id", "test-button");
        // button21.innerHTML    = " set opacity ";

        // button22.setAttribute("class", "btn btn-primary");
        // button22.setAttribute("id", "test-button");
        // button22.innerHTML    = " load LEA-Model into SceneBuilder.ModelBuilder ";


        // //=====================================================
 

        // //================================================================================
        // button1.onclick = (e) => {  this.onCreateGridMeshClicked(e);  }
        // button2.onclick = (e) => {  this.onCreateTorusClicked(e);  }
        // button3.onclick = (e) => {  this.onCreateCubesClicked(e);  }
        // button4.onclick = (e) => {  this.onCreateSceneCubesClicked(e);  }
        // button5.onclick = (e) => {  this.onUnLoadModelClicked(e);  }
        // button6.onclick = (e) => {  this.onSetBackgroundClicked(e);  }
        // button7.onclick = (e) => {  this.onRotateCurrentSelection(e);  }

        // button10.onclick = (e) => {  this.onScale_X_Clicked(e);  }
        // button11.onclick = (e) => {  this.onScale_Y_Clicked(e);  }
        // button12.onclick = (e) => {  this.onScale_Z_Clicked(e);  }
        // button13.onclick = (e) => {  this.onScale_XYZ_Clicked(e);  }

        // button14.onclick = (e) => {  this.onTranslate_X_Clicked(e);  }
        // button15.onclick = (e) => {  this.onTranslate_Y_Clicked(e);  }
        // button16.onclick = (e) => {  this.onTranslate_Z_Clicked(e);  }

        // button19.onclick = (e) => {  this.onSetColorMatClicked(e);  }
        // button22.onclick = (e) => {  this.onLoadLEAModel(e);  }

       //===================================================================================


    //    this.scrollContainer.appendChild( button1 );
    //    this.scrollContainer.appendChild( button2 );
    //    this.scrollContainer.appendChild( button3 );
    //    this.scrollContainer.appendChild( button4 );
    //    this.scrollContainer.appendChild( button5 );
    //    this.scrollContainer.appendChild( button6 );
    //    this.scrollContainer.appendChild( button7 );
    //    this.scrollContainer.appendChild( button8 );
    //    this.scrollContainer.appendChild( button9 );
    //    this.scrollContainer.appendChild( button10 );
    //    this.scrollContainer.appendChild( button11 );
    //    this.scrollContainer.appendChild( button12 );
    //    this.scrollContainer.appendChild( button13 );
    //    this.scrollContainer.appendChild( button14 );
    //    this.scrollContainer.appendChild( button15 );
    //    this.scrollContainer.appendChild( button16 );
    //    this.scrollContainer.appendChild( button16b );
    //    this.scrollContainer.appendChild( button17 );
    //    this.scrollContainer.appendChild( button18 );
    //    this.scrollContainer.appendChild( button19 );
    //    this.scrollContainer.appendChild( button20 );
    //    this.scrollContainer.appendChild( button21 );
    //    this.scrollContainer.appendChild( button22 );


   //    this.container.appendChild(this.scrollContainer);


     
    }



    // SimplePanel = function(parentContainer, id, title, content, x, y)
    // {
    //     this.content = content;
    //     Autodesk.Viewing.UI.DockingPanel.call(this, parentContainer, id, '');
    //     // Auto-fit to the content and don't allow resize.  Position at the coordinates given.
    //     //
    //     this.container.style.height = "auto";
    //     this.container.style.width = "auto";
    //     this.container.style.resize = "none";
    //     this.container.style.left = x + "px";
    //     this.container.style.top = y + "px";
    // };
    // SimplePanel.prototype = Object.create(Autodesk.Viewing.UI.DockingPanel.prototype);
    // SimplePanel.prototype.constructor = SimplePanel;
    // SimplePanel.prototype.initialize = function()
    // {
    //     // Override DockingPanel initialize() to:
    //     // - create a standard title bar
    //     // - click anywhere on the panel to move
    //     // - create a close element at the bottom right
    //     //
    //     this.title = this.createTitleBar(this.titleLabel || this.container.id);
    //     this.container.appendChild(this.title);
    //     this.container.appendChild(this.content);
    //     this.initializeMoveHandlers(this.container);
    //     this.closer = this.getDocument().createElement("div");
    //     this.closer.className = "simplePanelClose";
    //     this.closer.textContent = "Close";
    //     this.initializeCloseHandler(this.closer);
    //     this.container.appendChild(this.closer);
    // };








   //=================================================================================================================

   public initialize() : void 
   {

        this.title = this.createTitleBar(this.titleLabel || this.container.id);
        this.container.appendChild(this.title);

        this.initializeMoveHandlers(this.container);

        this.closer = this.createCloseButton();
        this.container.appendChild(this.closer);



//===========================

//const btnListDiv = 

this.createScrollContainer({
    left: false,
    heightAdjustment: 45,
    marginTop:0
});


const button0x1  = document.createElement("button");
const button0x2  = document.createElement("button");
const button0x3  = document.createElement("button");
const button0x4  = document.createElement("button");
const button0x5  = document.createElement("button");
const button0x6  = document.createElement("button");
const button0x7  = document.createElement("button");
const button0x8  = document.createElement("button");
const button0x9  = document.createElement("button");
const button0x10  = document.createElement("button");
const button0x11  = document.createElement("button");
const button0x12  = document.createElement("button");



const button0xx  = document.createElement("button");
const button0x  = document.createElement("button");



        const button1   = document.createElement("button");
        const button2   = document.createElement("button");
        const button3   = document.createElement("button");
        const button4   = document.createElement("button");
        const button5   = document.createElement("button");
        const button6   = document.createElement("button");
        const button7   = document.createElement("button");
        const button8   = document.createElement("button");
        const button9   = document.createElement("button");
        const button10  = document.createElement("button");
        const button11  = document.createElement("button");
        const button12  = document.createElement("button");
        const button13  = document.createElement("button");
        const button14  = document.createElement("button");
        const button15  = document.createElement("button");
        const button16  = document.createElement("button");
        const button16b  = document.createElement("button");
        const button17   = document.createElement("button");

        const button18   = document.createElement("button");
        const button19   = document.createElement("button");
        const button20   = document.createElement("button");
        const button21   = document.createElement("button");
        const button22   = document.createElement("button");




        button0x1.setAttribute("class", "btn btn-danger");
        button0x1.setAttribute("id", "test-button");
        button0x1.innerHTML     = "create usecase - 01";

        button0x2.setAttribute("class", "btn btn-danger");
        button0x2.setAttribute("id", "test-button");
        button0x2.innerHTML     = "create usecase - 02";

        button0x3.setAttribute("class", "btn btn-danger");
        button0x3.setAttribute("id", "test-button");
        button0x3.innerHTML     = "create usecase - 03";

        button0x4.setAttribute("class", "btn btn-danger");
        button0x4.setAttribute("id", "test-button");
        button0x4.innerHTML     = "show usecase - 01";

        button0x5.setAttribute("class", "btn btn-danger");
        button0x5.setAttribute("id", "test-button");
        button0x5.innerHTML     = "show usecase - 02";

        button0x6.setAttribute("class", "btn btn-danger");
        button0x6.setAttribute("id", "test-button");
        button0x6.innerHTML     = "show usecase - 03";

        button0x7.setAttribute("class", "btn btn-danger");
        button0x7.setAttribute("id", "test-button");
        button0x7.innerHTML     = "clear selection";

        button0x8.setAttribute("class", "btn btn-danger");
        button0x8.setAttribute("id", "test-button");
        button0x8.innerHTML     = "all Objects";

        button0x9.setAttribute("class", "btn btn-danger");
        button0x9.setAttribute("id", "test-button");
        button0x9.innerHTML     = "selection mode";

        button0x10.setAttribute("class", "btn btn-danger");
        button0x10.setAttribute("id", "test-button");
        button0x10.innerHTML     = "isolation mode";

        button0x11.setAttribute("class", "btn btn-info");
        button0x11.setAttribute("id", "test-level-1-button");
        button0x11.innerHTML     = "Level:  red spheres : ON";

        button0x12.setAttribute("class", "btn btn-info");
        button0x12.setAttribute("id", "test-button");
        button0x12.innerHTML     = "Group:  red spheres and red cubes: ON";







        button1.setAttribute("class", "btn-sm btn-danger");
        button1.setAttribute("id", "test-button");
        button1.innerHTML      = "create GridMesh";

        button2.setAttribute("class", "btn-sm btn-danger");
        button2.setAttribute("id", "test-button");
        button2.innerHTML    = "add Torus to ModelBuilder";

        button3.setAttribute("class", "btn-sm btn-danger");
        button3.setAttribute("id", "test-button");
        button3.innerHTML    = " add 3 Cubes to ModelBuilder ";

        button4.setAttribute("class", "btn-sm btn-danger");
        button4.setAttribute("id", "test-button");
        button4.innerHTML    = " add 3 Cubes to Scene ";

        button5.setAttribute("class", "btn-sm btn-danger");
        button5.setAttribute("id", "test-button");
        button5.innerHTML    = " remove Model ";

        button6.setAttribute("class", "btn btn-warning");
        button6.setAttribute("id", "test-button");
        button6.innerHTML    = " set background color ";

        button7.setAttribute("class", "btn btn-danger");
        button7.setAttribute("id", "test-button");
        button7.innerHTML    = " rotate current selection ";

        button8.setAttribute("class", "btn btn-danger");
        button8.setAttribute("id", "test-button");
        button8.innerHTML    = " ghost-isolate ";

        button9.setAttribute("class", "btn btn-danger");
        button9.setAttribute("id", "test-button");
        button9.innerHTML    = " full isolate ";

        //=======================================================
        button10.setAttribute("class", "btn btn-info");
        button10.setAttribute("id", "row-test-button");
        button10.innerHTML    = " scale-X ";

        button11.setAttribute("class", "btn btn-info");
        button11.setAttribute("id", "row-test-button");
        button11.innerHTML    = " scale-Y ";

        button12.setAttribute("class", "btn btn-info");
        button12.setAttribute("id", "row-test-button");
        button12.innerHTML    = " scale-Z ";

        button13.setAttribute("class", "btn btn-info");
        button13.setAttribute("id", "row-test-button");
        button13.innerHTML    = " scale-XYZ ";

        //======================================================

        button14.setAttribute("class", "btn btn-info");
        button14.setAttribute("id", "test-button");
        button14.innerHTML    = " move-X ";

        button15.setAttribute("class", "btn btn-info");
        button15.setAttribute("id", "test-button");
        button15.innerHTML    = " move-Y ";

        button16.setAttribute("class", "btn btn-info");
        button16.setAttribute("id", "test-button");
        button16.innerHTML    = " move-Z ";

        button16b.setAttribute("class", "btn btn-info");
        button16b.setAttribute("id", "test-button");
        button16b.innerHTML    = " move-XYZ ";

        //======================================================

        button17.setAttribute("class", "btn btn-primary");
        button17.setAttribute("id", "test-button");
        button17.innerHTML    = " set ColorMat ";

        button18.setAttribute("class", "btn btn-primary");
        button18.setAttribute("id", "test-button");
        button18.innerHTML    = " set TexMat ";

        button19.setAttribute("class", "btn btn-primary");
        button19.setAttribute("id", "test-button");
        button19.innerHTML    = " set ColorMat ";

        button20.setAttribute("class", "btn btn-primary");
        button20.setAttribute("id", "test-button");
        button20.innerHTML    = " set ShaderMat ";

        button21.setAttribute("class", "btn btn-primary");
        button21.setAttribute("id", "test-button");
        button21.innerHTML    = " set opacity ";

        button22.setAttribute("class", "btn btn-primary");
        button22.setAttribute("id", "test-button");
        button22.innerHTML    = " load LEA-Model into SceneBuilder.ModelBuilder ";




        button0x1.onclick = (e) => {  this.onCreateUsecase01(e);  }
        button0x2.onclick = (e) => {  this.onCreateUsecase02(e);  }
        button0x3.onclick = (e) => {  this.onCreateUsecase03(e);  }
        button0x4.onclick = (e) => {  this.onShowUsecase01(e);  }
        button0x5.onclick = (e) => {  this.onShowUsecase02(e);  }
        button0x6.onclick = (e) => {  this.onShowUsecase03(e);  }
        button0x7.onclick = (e) => {  this.onClearSelection(e);  }
        button0x8.onclick = (e) => {  this.onShowAllObjects(e);  }
        button0x9.onclick = (e) => {  this.onSelectionMode(e);  }
        button0x10.onclick = (e) => {  this.onIsolationMode(e);  }
        button0x11.onclick = (e) => {  this.onLevel1(e);  }
        button0x12.onclick = (e) => {  this.onGroup1(e);  }

        button1.onclick = (e) => {  this.onCreateGridMeshClicked(e);  }
        button2.onclick = (e) => {  this.onCreateTorusClicked(e);  }
        button3.onclick = (e) => {  this.onCreateCubesClicked(e);  }
        button4.onclick = (e) => {  this.onCreateSceneCubesClicked(e);  }
        button5.onclick = (e) => {  this.onUnLoadModelClicked(e);  }
        button6.onclick = (e) => {  this.onSetBackgroundClicked(e);  }
        button7.onclick = (e) => {  this.onRotateCurrentSelection(e);  }

        button10.onclick = (e) => {  this.onScale_X_Clicked(e);  }
        button11.onclick = (e) => {  this.onScale_Y_Clicked(e);  }
        button12.onclick = (e) => {  this.onScale_Z_Clicked(e);  }
        button13.onclick = (e) => {  this.onScale_XYZ_Clicked(e);  }

        button14.onclick = (e) => {  this.onTranslate_X_Clicked(e);  }
        button15.onclick = (e) => {  this.onTranslate_Y_Clicked(e);  }
        button16.onclick = (e) => {  this.onTranslate_Z_Clicked(e);  }

        button19.onclick = (e) => {  this.onSetColorMatClicked(e);  }
        button22.onclick = (e) => {  this.onLoadLEAModel(e);  }


        this.scrollContainer.appendChild( button0x1 );
        this.scrollContainer.appendChild( button0x2 );
        this.scrollContainer.appendChild( button0x3 );
        this.scrollContainer.appendChild( button0x4 );
        this.scrollContainer.appendChild( button0x5 );
        this.scrollContainer.appendChild( button0x6 );
        this.scrollContainer.appendChild( button0x7 );
        this.scrollContainer.appendChild( button0x8 );
        this.scrollContainer.appendChild( button0x9 );
        this.scrollContainer.appendChild( button0x10 );
        this.scrollContainer.appendChild( button0x11 );
        this.scrollContainer.appendChild( button0x12 );

        this.scrollContainer.appendChild( button1 );
        this.scrollContainer.appendChild( button2 );
        this.scrollContainer.appendChild( button3 );
        this.scrollContainer.appendChild( button4 );
        this.scrollContainer.appendChild( button5 );
        this.scrollContainer.appendChild( button6 );
       this.scrollContainer.appendChild( button7 );
       this.scrollContainer.appendChild( button8 );
       this.scrollContainer.appendChild( button9 );
       this.scrollContainer.appendChild( button10 );
       this.scrollContainer.appendChild( button11 );
       this.scrollContainer.appendChild( button12 );
       this.scrollContainer.appendChild( button13 );
       this.scrollContainer.appendChild( button14 );
       this.scrollContainer.appendChild( button15 );
       this.scrollContainer.appendChild( button16 );
       this.scrollContainer.appendChild( button16b );
       this.scrollContainer.appendChild( button17 );
       this.scrollContainer.appendChild( button18 );
       this.scrollContainer.appendChild( button19 );
       this.scrollContainer.appendChild( button20 );
       this.scrollContainer.appendChild( button21 );
       this.scrollContainer.appendChild( button22 );





        this.footer = this.createFooter();
        this.container.appendChild(this.footer);
    }

    //=================================================================================================================

    private onCreateUsecase01(e:any) : void 
    {
        for (let i=0; i < 8; ++i)
        {
            this.addCubeMesh(1); 
        }
    }

    private onCreateUsecase02(e:any) : void 
    {
        for (let i=0; i < 4; ++i)
        {
            this.addSphereMesh(null); 
        }   
        
        for (let i=0; i < 4; ++i)
        {
            const colorMat = new THREE.MeshBasicMaterial({ color: 0xff0000 });

            const dbId = this.addSphereMesh(colorMat); 

            this._level01_dbIds.push(dbId)
        }   
        

    }
    
    private onCreateUsecase03(e:any) : void 
    {
        for (let i=0; i < 8; ++i)
        {
            this.addCubeMesh(1); 
        }        
    }

    //=================================================================================================================

    private onShowUsecase01(e:any) : void 
    {

        this._viewer.clearSelection();
        this._viewer.isolate();


       const all_visible_dbIds:number[]  = [];
       const all_hidden_meshes:any[]     = [];
       const all_removed_meshes:any[]    = [];
       const all_visible_meshes:any[]    = [];


        let index = 0;
        for (let currMesh of this._allAddedMeshes)
        {
            if (index++ < 8)
            {
                all_visible_meshes.push(currMesh);
            }
            else
            {
                all_hidden_meshes.push(currMesh);
            }
        }     
   
        for (let currMesh of all_visible_meshes)
        {
            all_visible_dbIds.push(currMesh.dbId);

            if (currMesh.userData.isVisible == false)
            {
               currMesh.userData.isVisible = true;
               this._modelBuilder.addMesh(currMesh);
            }
            if (currMesh.userData.isVisible == true)
            {
                // is already added to the model...so all is fine we dont have to do anything here
            }

        }

        for (let currMesh of all_hidden_meshes)
        {
            if (currMesh.userData.isVisible == true)
            {
               currMesh.userData.isVisible = false;
               all_removed_meshes.push(currMesh);               
            }
            if (currMesh.userData.isVisible == false)
            {
               // is already removed from the model ...dont try to remove again
            }            
        }

        if (all_removed_meshes.length > 0)
        {
            this._modelBuilder.removeMesh( all_removed_meshes );
        }

        this._viewer.isolate(  all_visible_meshes[0].dbId );
    }

    //=================================================================================================================

    private onShowUsecase02(e:any) : void 
    {

        this._viewer.clearSelection();
        this._viewer.isolate();

        const all_visible_dbIds:number[] = [];
        const all_hidden_meshes:any[]    = [];
        const all_visible_meshes:any[]    = [];
        const all_removed_meshes:any[]    = [];
        const all_added_meshes:any[]      = [];

         let index = 0;
         for (let currMesh of this._allAddedMeshes)
         {
             if (index >= 8 && index <= 15)
             {
                 all_visible_meshes.push(currMesh);
             }
             else
             {
                 all_hidden_meshes.push(currMesh);
             }
             ++index;
         }     
               
      
    


        for (let currMesh of all_visible_meshes)
        {
            const xxx = this.existsInLevel(currMesh.dbId);   

            if (xxx == 2)
            {
                // dont show ( hide ) this mesh
                all_hidden_meshes.push(currMesh);
            }
            else
            {
                if (currMesh.userData.isVisible == false)
                {
                    all_added_meshes.push(currMesh);                    
                }
                if (currMesh.userData.isVisible == true)
                {
                    // is already added to the model...so all is fine we dont have to do anything here
                }
            }
 
        }

        for (let currMesh of all_added_meshes)
        {
            all_visible_dbIds.push(currMesh.dbId);
                
            if (currMesh.userData.isVisible == false)
            {
                currMesh.userData.isVisible = true;
                this._modelBuilder.addMesh(currMesh);
            }
        }


 
         for (let currMesh of all_hidden_meshes)
         {
             if (currMesh.userData.isVisible == true)
             {
                currMesh.userData.isVisible = false;
                all_removed_meshes.push(currMesh);               
             }
             if (currMesh.userData.isVisible == false)
             {
                // is already removed from the model ...dont try to remove again
             }            
         }
 
         if (all_removed_meshes.length > 0)
         {
             this._modelBuilder.removeMesh( all_removed_meshes );
         }
 
         this._viewer.isolate(  all_visible_meshes[0].dbId );


    }

    //=================================================================================================================

    private existsInLevel(dbId:number) : number
    {
        let retval = 0;  // does NOT exiust in any level

        if ( this._level01_redSpheres == true)   // active !!
        {
            for (let curr_level_01_dbId of this._level01_dbIds)
            {
                if (dbId == curr_level_01_dbId)
                {
                   retval = 1;   // exists in active level 
                   break;
                }
            }
        }
        else
        {
            // kein Object dieses lewels anzeigen
            for (let curr_level_01_dbId of this._level01_dbIds)
            {
                if (dbId == curr_level_01_dbId)
                {
                    retval = 2;  // exists in inactive level
                    break;
                }
            }                
        }  
        return retval;
    }

    //=================================================================================================================

    private onShowUsecase03(e:any) : void 
    {

    }

    private onClearSelection(e:any) : void 
    {
        this._viewer.clearSelection();
        this._viewer.isolate();
    }


    private onShowAllObjects(e:any) : void 
    {
        this._viewer.clearSelection();
        this._viewer.isolate();

        const all_visible_dbIds:number[] = [];
        const all_hidden_meshes:any[]    = [];
        const all_visible_meshes:any[]    = [];

         let index = 0;
         for (let currMesh of this._allAddedMeshes)
         {
            all_visible_meshes.push(currMesh);
             ++index;
         }     
                 

        for (let currMesh of all_visible_meshes)
        {
            if (currMesh.userData.isVisible == false)
            {
               currMesh.userData.isVisible = true;
               this._modelBuilder.addMesh(currMesh);
            }
        }


        // for (let currMesh of all_hidden_meshes)
        // {
        //     if (currMesh.userData.isVisible == true)
        //     {
        //        currMesh.userData.isVisible = false;
        //     }
        // }

        // if (all_hidden_meshes.length > 0)
        // {
        //     this._modelBuilder.removeMesh( all_hidden_meshes );
        // }


      //  this._viewer.isolate(  all_visible_meshes[0].dbId );





    }

    private onSelectionMode(e:any) : void 
    {
        this._viewerMode = VIEWER_MODE.SELECTION_MODE;

        //this._viewer.clearSelection();
        //this._viewer.isolate();
    }

    private onIsolationMode(e:any) : void 
    {
        this._viewerMode = VIEWER_MODE.ISOLATION_MODE;

        //this._viewer.clearSelection();
        //this._viewer.isolate();
    }

    //=================================================================================================================

    private onLevel1(e:any) : void 
    {
        this._level01_redSpheres = !this._level01_redSpheres;

        const htmlElement = document.getElementById(  "test-level-1-button"  );

        if (htmlElement)
        {
            if ( this._level01_redSpheres == false)
            {
                htmlElement.innerHTML     = "Level:  red spheres : OFF";
            }
            else
            {
                htmlElement.innerHTML     = "Level:  red spheres : ON";
            }
        }
    }

    //=================================================================================================================

    private onGroup1(e:any) : void 
    {
    }

    //=================================================================================================================

    private onCreateCubesClicked(e:any) : void 
    {
        this.addCubesToModelBuilder();
    }

    //=================================================================================================================

    private onCreateTorusClicked(e:any) : void 
    {
        this.addTorusToModelBuilder();
    }

    //=================================================================================================================

    private onCreateGridMeshClicked(e:any) : void 
    {
        this.createGridMesh();
    }
  
    //=================================================================================================================

    private onSetBackgroundClicked(e:any) : void 
    {
        let red    = Math.random() * 255;
        let green  = Math.random() * 255;
        let blue   = Math.random() * 255;

        let red2     = red;
        let green2   = green;
        let blue2    = blue;

        red   = ((red    - 95) > 0 ) ? red   - 95 : red;
        green = ((green  - 95) > 0 ) ? green - 95 : green;
        blue  = ((blue   - 95) > 0 ) ? blue  - 95 : blue;

        red2   = ((red2   + 75) < 255 ) ? red2   + 75 : red2;
        green2 = ((green2 + 75) < 255 ) ? green2 + 75 : green2;
        blue2  = ((blue2  + 75) < 255 ) ? blue2  + 75 : blue2;

        this._viewer.setBackgroundColor( red,green,blue, red2,green2,blue2);
    }
  
    //=================================================================================================================

    private onCreateSceneCubesClicked(e:any) : void 
    {
        this.addCubesToScene();
    }
  
    //=================================================================================================================
   
    private onRotateCurrentSelection(e:any) : void 
    {
        if (this._rotateAnimRequestId)
        {
            this.stopRotateAnim();

            this._viewer.clearSelection(); 

            this._viewer.isolate();   // reset isolation !!!
    
           //ViewerToolkit.isolateFull( this._viewer, null,  null );
    
            this._viewer.fitToView();
    
    

        }
        else
        {
            this.startRotateAnim();
        }
    }
  
    //=================================================================================================================
   
    private onUnLoadModelClicked(e:any) : void 
    {
        const models = this._viewer.impl.modelQueue().getModels();
        if (ExtensionUtil.isValidContent(models))
        {
            this._viewer.impl.unloadModel( models[0] );   // // has at least one model 
        }
    }
  
    //=================================================================================================================

    public async addTorusToModelBuilder() 
    {
 
        const color = Math.floor(Math.random() * 16777215);
        const material = this.createColorMaterial(color);
   
        let torus = new THREE.BufferGeometry().fromGeometry( new THREE.TorusGeometry(10, 3, 16, 64)  );
 
        const transform = new THREE.Matrix4().compose(
          new THREE.Vector3(0, 0, 0),
          new THREE.Quaternion(0, 0, 0, 1),
          new THREE.Vector3(1, 1, 1)
        );
   
        this._modelBuilder.addFragment(torus, material, transform);

        alert("... torus has been added by SceneBuilder.ModelBuilder")
    }

    //=================================================================================================================
 
    private onScale_XYZ_Clicked(e:any) : void 
    {
        this.doTransformation( TRANSFORMATION_TYPE.SCALE_XYZ );
    }

    //=================================================================================================================

    private onScale_X_Clicked(e:any) : void 
    {
        this.doTransformation(TRANSFORMATION_TYPE.SCALE_X);
    }    

    //=================================================================================================================

    private onScale_Y_Clicked(e:any) : void 
    {
        this.doTransformation(TRANSFORMATION_TYPE.SCALE_Y);
    }    

    //=================================================================================================================

    private onScale_Z_Clicked(e:any) : void 
    {
        this.doTransformation(TRANSFORMATION_TYPE.SCALE_Z);
    }    

    //=================================================================================================================
 
    private onTranslate_X_Clicked(e:any) : void 
    {
        this.doTransformation(TRANSFORMATION_TYPE.POS_X);
    }    

    //=================================================================================================================

    private onTranslate_Y_Clicked(e:any) : void 
    {
        this.doTransformation(TRANSFORMATION_TYPE.POS_Y);
    }    

    //=================================================================================================================

    private onTranslate_Z_Clicked(e:any) : void 
    {
        this.doTransformation(TRANSFORMATION_TYPE.POS_Z);
    }    

    //=================================================================================================================

    private onSetColorMatClicked(e:any) : void 
    {
        this.doTransformation(TRANSFORMATION_TYPE.COLOR_MAT);
    }    

    //=================================================================================================================

    private onLoadLEAModel(e:any) : void 
    {
       // const lea_viewer = new ViewerMulti(this._viewer,null);
       // lea_viewer.loadModelFromLocalFile();
    }    

    //=================================================================================================================

    createColorMaterial (color)
    {
           const newMaterial = new THREE.MeshPhongMaterial({
             specular:       new THREE.Color(color),
             side:           THREE.DoubleSide,
             reflectivity:   0.0, 
             color
         });
   
         const materials = this._viewer.impl.getMaterials();
   
         materials.addMaterial( color.toString(16), newMaterial, true);
   
         return newMaterial;
     }

    //=================================================================================================================

    private addCubeMesh(opcode:number) : void
    {
          const newdbId = this.randomIndex ( 1000, 1000000 ); 
  
         const width  = Math.random() * 10 + 5.0;
         const height = Math.random() * 10 + 5.0;
         const depth  = Math.random() * 10 + 5.0;

         const color = Math.floor(Math.random() * 16777215);
         const material = this.createColorMaterial(color);

         const boxGeometry    = new THREE.BufferGeometry().fromGeometry( new THREE.BoxGeometry(  width, height, depth ));
          
        const mesh = new THREE.Mesh(boxGeometry, material);
 
        mesh.name        = "Mesh-"    + newdbId;
        mesh.userData.isVisible = true;


        //mesh.dbId = newdbId;
        boxGeometry.name = "boxGeom-" + newdbId;

         const xPos  = -50 + Math.random() * 100;
         const yPos  = -50 + Math.random() * 100;
         const zPos  = -50 + Math.random() * 100;
    
         const position = new THREE.Vector3(  xPos, yPos, zPos );
         let rotation   = new THREE.Quaternion(0, 0, 0, 1);
 
         mesh.matrix = new THREE.Matrix4().compose(position,rotation,new THREE.Vector3(1, 1, 1));
  
         if (opcode === 1)
         {
            this._modelBuilder.addMesh(mesh);
            //this._modelBuilder.addFragment(mesh);
            this._allAddedMeshes.push(mesh);


         }
         else
         {
            this._viewer.impl.scene.add(mesh);
         }
   
     }
 
    //=================================================================================================================

    private addSphereMesh(useMaterial:any) : number
    {
        let   material = null;
        const newdbId = this.randomIndex ( 1000, 1000000 ); 
  
         const widthSegments  = 32;
         const heightSegments = 32;
         
         const radius         = Math.random() * 13;

         if (useMaterial == null)
         {
            const color = Math.floor(Math.random() * 16777215);
            material = this.createColorMaterial(color);
         }
         else
         {
            material = useMaterial; //this.createColorMaterial(useColor);
         }

         const sphereGeometry = new THREE.BufferGeometry().fromGeometry( new THREE.SphereGeometry(  radius, widthSegments, heightSegments ));
          
        const mesh = new THREE.Mesh(sphereGeometry, material);
 
        mesh.name        = "Mesh-"    + newdbId;
        mesh.userData.isVisible = true;

        //mesh.dbId = newdbId;
        sphereGeometry.name = "sphereGeom-" + newdbId;

         const xPos  = -50 + Math.random() * 100;
         const yPos  = -50 + Math.random() * 100;
         const zPos  = -50 + Math.random() * 100;
    
         const position = new THREE.Vector3(  xPos, yPos, zPos );
         let rotation   = new THREE.Quaternion(0, 0, 0, 1);
 
         mesh.matrix = new THREE.Matrix4().compose(position,rotation,new THREE.Vector3(1, 1, 1));
  
        this._modelBuilder.addMesh(mesh);
        this._allAddedMeshes.push(mesh);

        return mesh.dbId;
   
     }
     
    //=================================================================================================================

    private addFragment( objectType:ViewerObjectType, useMaterial:any, scaleFactor:number) : number 
    {
        // The addFragment method will return an id that you can use to delete or 
        // change the display of the fragment.      

        let objectGeometry  = null;
        let objectMaterial  = null;
        let objectTransform = null;

        if (objectType == ViewerObjectType.OBJECT3D_SPHERE)
        {
            const widthSegments  = 32;
            const heightSegments = 32;
            const radius         = Math.random() * 13;

            objectGeometry = new THREE.BufferGeometry().fromGeometry( new THREE.SphereGeometry(  radius, widthSegments, heightSegments ));

            const xPos  = -50 + Math.random() * 100;
            const yPos  = -50 + Math.random() * 100;
            const zPos  = -50 + Math.random() * 100;
          
            //mesh.matrix = new THREE.Matrix4().compose(position,rotation,new THREE.Vector3(1, 1, 1));

            objectTransform = new THREE.Matrix4().compose(
                new THREE.Vector3(  xPos, yPos, zPos),
                new THREE.Quaternion( 0, 0, 0, 1),
                new THREE.Vector3(scaleFactor,scaleFactor,scaleFactor)
            );
        }
        else if (objectType == ViewerObjectType.OBJECT3D_CUBE)
        {
            const width  = Math.random() * 10 + 5.0;
            const height = Math.random() * 10 + 5.0;
            const depth  = Math.random() * 10 + 5.0;

            objectGeometry    = new THREE.BufferGeometry().fromGeometry( new THREE.BoxGeometry(  width, height, depth ));

            const xPos  = -50 + Math.random() * 100;
            const yPos  = -50 + Math.random() * 100;
            const zPos  = -50 + Math.random() * 100;
          
            //mesh.matrix = new THREE.Matrix4().compose(position,rotation,new THREE.Vector3(1, 1, 1));

            objectTransform = new THREE.Matrix4().compose(
                new THREE.Vector3(  xPos, yPos, zPos),
                new THREE.Quaternion( 0, 0, 0, 1),
                new THREE.Vector3(scaleFactor,scaleFactor,scaleFactor)
            );
        }
        else
        {
            //objectGeometry = new THREE.BufferGeometry().fromGeometry( mesh.geometry );
            //objectMaterial = mesh.material; // as THREE.MeshPhongMaterial;    
            // const xPos = mesh.position.x;
            // const yPos = mesh.position.y;
            // const zPos = mesh.position.z;
      
            // const xQ = mesh.quaternion.x;
            // const yQ = mesh.quaternion.y;
            // const zQ = mesh.quaternion.z;
            // const wQ = mesh.quaternion.w;
    
            // const objectTransform = new THREE.Matrix4().compose(
            //     new THREE.Vector3(  xPos, yPos, zPos),
            //     new THREE.Quaternion(xQ, yQ, zQ, wQ),
            //     new THREE.Vector3(scaleFactor,scaleFactor,scaleFactor)
            // );
        }


        if (useMaterial == null)
        {
           const color    = Math.floor(Math.random() * 16777215);
           objectMaterial = this.createColorMaterial(color);
        }
        else
        {
           objectMaterial = useMaterial; //this.createColorMaterial(useColor);
        }

        //===================================================================================
        //this._modelBuilder.addMaterial(  mesh.material.name, mesh.material);
        //const mesh = new THREE.Mesh( objectGeometry,  objectMaterial);
        //===================================================================================

        const idFrag =this._modelBuilder.addFragment( objectGeometry , objectMaterial, objectTransform);       

        this._modelBuilder.changeFragmentsDbId( idFrag, ++TestExtensionPanel.DBID_START); 
        //===================================================================================

        return TestExtensionPanel.DBID_START;
    }

   //=================================================================================================================
 
    addCubesToModelBuilder() 
    {
        this.addCubeMesh(1); 

        this.addCubeMesh(1); 

        this.addCubeMesh(1); 

        alert( " .. 3 Cubes have been added to SceneBuilder.ModelBuilder");
     }
 
    //=================================================================================================================

    addCubesToScene() 
    {
        this.addCubeMesh(2); 

        this.addCubeMesh(2); 

        this.addCubeMesh(2); 

        this._viewer.impl.sceneUpdated(true);


        alert( " .. 3 Cubes have been added to Scene");    

     }
 
    //=================================================================================================================

    randomIndex (min, max) 
    {
       return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    //=================================================================================================================

    createGridMesh() 
    {
        //this._viewer.removeEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,  this.customize);

        // add grid
        this._grid = new THREE.GridHelper(300, 10);
        
        this._grid.material.opacity = 0.9;
        
        this._grid.material.transparent = true;

        //=========================================================

        if (!this._viewer.overlays.hasScene("grid")) 
        {
            this._viewer.overlays.addScene("grid")
        }

        this._viewer.overlays.addMesh(this._grid, "grid");

        //==========================================================

        this._grid.position.y = 20;

        this._viewer.impl.sceneUpdated(true);

    }

    //=================================================================================================================

    private createModelBuilder()
    {
        this._viewer.loadExtension("Autodesk.Viewing.SceneBuilder").then(() => {
             
            this._sceneBuilder = this._viewer.getExtension("Autodesk.Viewing.SceneBuilder");
    
            this._sceneBuilder.addNewModel({}).then((modelBuilder) => {
    
                this._modelBuilder = modelBuilder;
              
            });
        })
    }

    //=================================================================================================================

    public startRotateAnim() : void 
    {
        const currentSelection:any[] = this._viewer.getSelection(); // if selection is set, we cahnghe automaticly to 

        if (ExtensionUtil.isValidContent( currentSelection ))
        {
            const nodeId = currentSelection[0];

            this._viewer.clearSelection(); 

            this._viewer.isolate( nodeId );

            this._viewer.fitToView( nodeId );

            this.onInitRotateAnim(nodeId); 
        }
    }

  //=================================================================================================================

    public stopRotateAnim = () => {

        if (this._rotateAnimRequestId) 
        {
            //  this._guiPanel.setRotation(false);
            window.cancelAnimationFrame(this._rotateAnimRequestId);
            this._rotateAnimRequestId = 0;
        }
    };

    //=================================================================================================================

    public getFragmentWorldMatrixByNodeId = (nodeId:number) => {

        let result = {
              fragId: [],
             matrix: [],
        };

        const that = this;

        this._instanceTree.enumNodeFragments(nodeId, function  (frag)   {

            let fragProxy = that._viewer.impl.getFragmentProxy(that._viewer.model, frag);
            let matrix = new THREE.Matrix4();
            fragProxy.getWorldMatrix(matrix);

            result.fragId.push(frag);
            result.matrix.push(matrix);

        });

        return result;
    }    

    //=================================================================================================================

    public assignTransformations = (refererence_dummy, nodeId:number)  =>  {

        refererence_dummy.parent.updateMatrixWorld(true);  // wjx 3.12.20 ...parameter is neccessary now

        var position = new THREE.Vector3();
        var rotation = new THREE.Quaternion();
        var scale    = new THREE.Vector3();

        refererence_dummy.matrixWorld.decompose(position, rotation, scale);

        //  console.log("decomposed matrix into position = " + JSON.stringify(position));

        const that = this;

        this._instanceTree.enumNodeFragments(nodeId, function (frag) {

            var fragProxy = that._viewer.impl.getFragmentProxy(that._viewer.model, frag);
        
            fragProxy.getAnimTransform();
        
            fragProxy.position   = position;
            fragProxy.quaternion = rotation;
            fragProxy.updateAnimTransform();
        });
    }

    //=================================================================================================================

    private onInitRotateAnim(currentNodeId:number) : void
    {
       //alert("rotate-mode is ON"); 

       if ( this._instanceTree == null)
            this._instanceTree = this._viewer.model.getData().instanceTree;

       this._seconds_pivot  = new THREE.Mesh( new THREE.BoxGeometry(0.1, 0.1, 0.1),  new THREE.MeshBasicMaterial({ color: 0xff0000 }));
       this._seconds_helper = new THREE.Mesh( new THREE.BoxGeometry(0.5, 0.5, 2),    new THREE.MeshBasicMaterial({ color: 0x0000ff }));
       
       // viewer.impl.scene.add(seconds_pivot); // makes THREE geometry (boxes) visible in viewer
       /////////////////
       
       //some nodes might contain several fragments, but in our case we know it has one fragment
       // World position of Fragment Object to rotade

       this._pin_Id        = currentNodeId;
       const secondsArm_Id = this._pin_Id;
       //==========================================
       // check if can rotate with the current element ..if we can retain matrix and position
       //==========================================

        let bCanRotate = false;
        const frag_world_mat = this.getFragmentWorldMatrixByNodeId( this._pin_Id );

        if (frag_world_mat !== undefined)
        {
            if ( frag_world_mat.matrix !== undefined)
            {
                if ( frag_world_mat.matrix.length > 0)
                {
                    bCanRotate= true;
                }
            }
        }

        if ( !bCanRotate )
        {
            alert("cannot use rotation with element dbId = " + this._pin_Id);                
        }
        else
        {
            let pin_position = this.getFragmentWorldMatrixByNodeId(this._pin_Id).matrix[0].getPosition().clone();
            this._seconds_pivot.position.x = pin_position.x;
            this._seconds_pivot.position.y = pin_position.y;
            this._seconds_pivot.position.z = pin_position.z;
            ///////////////
            let secondsArm_position = this.getFragmentWorldMatrixByNodeId(secondsArm_Id).matrix[0].getPosition().clone();

            this._seconds_pivot.add(this._seconds_helper); // add helper to pivot not to scene directly

            this._seconds_helper.updateMatrixWorld(true);    // wjx 3.12.20 this has changed... parameter now os necessary true or false ?

            var seconds_helper_wold_position = new THREE.Vector3();
            this._seconds_helper.localToWorld(seconds_helper_wold_position);
            //console.log("Helper's World position = " + JSON.stringify(seconds_helper_wold_position));

            // calculate off set between Helper and Arm
            this._seconds_helper.position.x = - secondsArm_position.x - Math.abs(secondsArm_position.x - this._seconds_pivot.position.x);
            this._seconds_helper.position.y = - secondsArm_position.y - Math.abs(secondsArm_position.y - this._seconds_pivot.position.y);
            this._seconds_helper.position.z = - secondsArm_position.z - Math.abs(secondsArm_position.z - this._seconds_pivot.position.z);
            this._viewer.impl.sceneUpdated(true);

            this._rotateAnimRequestId = window.requestAnimationFrame(this.doRotateAnimation);
        }
    }

    //=================================================================================================================

    private doRotateAnimation = (animTime:number)  => {
       
        if (this._rotateAnimRequestId <= 0)
        {
            //  this._rotateAnimRequestId = window.requestAnimationFrame(this.doRotateAnimation);
            return;
        }

        if (animTime === 0)
        {
            this._currentAnimFrame = 0.0;
            this._animStartTime    = 0.0;
            this._animLastTime     = 0.0;
            this._accuAnimTime     = 0.0;
        }

       
        if (this._currentAnimFrame === 1)
        {
            this._animStartTime  = animTime;
        }

        const delta_time_in_ms = animTime - this._animLastTime;
        this._animLastTime     = animTime;
        this._accuAnimTime     += delta_time_in_ms;
        this._currentAnimFrame += 1.0;
        let currentRotationStep:number = 0.01;

        if (delta_time_in_ms > 0.0)
        {
            const PHI_2            = Math.PI * 2.0;
            const delta_in_ms      = delta_time_in_ms;
            const desired_angle    = 360.0;
            const time_for_360_degree_spin_in_ms = this._rotAnim360RotationDuration;   // we want to spin the object 360 degrees in a time duration of 5000 ms !!!!!

            let calls_to_get_360_degrees = time_for_360_degree_spin_in_ms / delta_in_ms; // 5000 / 10 ====>  500 calls to get 360 degrees

            let stepAngle_per_call_in_deg  = desired_angle / calls_to_get_360_degrees;   //  360 / 500  = 0.72 degrees per call     check: 0.72 * 500 = 360

            let stepAngle_per_call_in_rad  = PHI_2 * ( stepAngle_per_call_in_deg / desired_angle) ;   // 6.28 * (0.72 / 360)  ====> 0.01256

            currentRotationStep   = stepAngle_per_call_in_rad;     // 0.01256;   da nach 500 calls ein spin von 6.28 ( what is 360 degrees )

            //console.log("delta-in-ms = " + delta_in_ms + " ")
            //========================================================
            this._seconds_pivot.rotation.x += currentRotationStep; 
            this._seconds_pivot.rotation.y += currentRotationStep; 
            this._seconds_pivot.rotation.z += currentRotationStep; 
            this.assignTransformations(this._seconds_helper, this._pin_Id);
            this._viewer.impl.sceneUpdated();
            //========================================================
        }

        // 0.017463 ==> <= 1 degree rotate     0.0087315  ==> <= 0.5 degree rotate

        if ( currentRotationStep >  0.0087315)
        {
            // dont call renderloop 
        }    

        window.requestAnimationFrame(this.doRotateAnimation);
    };    


    private doTransformation( transType:TRANSFORMATION_TYPE)
    {
        const that = this;
      
        const fragList = this._viewer.model.getFragmentList()

        ViewerToolkit.getLeafNodes02(this._viewer.model).then((dbIds) => {

            dbIds.forEach((dbId) => {

                const fragIds = ViewerToolkit.nodeIdToFragIds( this._viewer.model, dbId)

                fragIds.forEach((fragId) => {

                    if (transType == TRANSFORMATION_TYPE.COLOR_MAT )
                    {
                        const color       = Math.floor(Math.random() * 16777215);
                        const material    = that.createColorMaterial(color);
                        const renderProxy = that._viewer.impl.getRenderProxy( that._viewer.model, fragId);   
    

                        var meshProxy = new THREE.Mesh( renderProxy.geometry, renderProxy.material);

                        meshProxy.matrix.copy(renderProxy.matrixWorld);
                
                        meshProxy.matrixWorldNeedsUpdate = true;
                
                        meshProxy.matrixAutoUpdate = false;
                
                        meshProxy.frustumCulled = false;
           
                        that._viewer.impl.addOverlay(material.name, meshProxy);
              
    
                    }  
                    else if (transType == TRANSFORMATION_TYPE.OPACITY_MAT )
                    {
                        var oldMaterial   = fragList.getMaterial(fragId);
              
                        const color       = Math.floor(Math.random() * 16777215);
                        const newMaterial = that.createColorMaterial(color);

                        fragList.setMaterial( fragId, newMaterial );


                        // if  (material) 
                        // {
                        //     material.opacity = 0.2;
                        //     material.transparent = true;
                        //     material.needsUpdate = true;
                        // }
                    }                       
                    else
                    {
                        //======================================================
                        // transform all fragIds of fragList
                        //======================================================

                        const fragProxy = that._viewer.impl.getFragmentProxy(that._viewer.model, fragId);

                        fragProxy.getAnimTransform();


                        let  xPos = fragProxy.position.x;
                        let  yPos = fragProxy.position.y;
                        let  zPos = fragProxy.position.z;

                        let  xScale = fragProxy.scale.x;
                        let  yScale = fragProxy.scale.y;
                        let  zScale = fragProxy.scale.z;

                        if (transType == TRANSFORMATION_TYPE.POS_X )
                        {
                            xPos += 1.0;    
                        }   
                        if (transType == TRANSFORMATION_TYPE.POS_Y )
                        {
                            yPos += 1.0;    
                        }    
                        if (transType == TRANSFORMATION_TYPE.POS_Z )
                        {
                            zPos += 1.0;    
                        }                                                           
                        if (transType == TRANSFORMATION_TYPE.SCALE_X )
                        {
                            xScale += 0.5;    
                        }                    
                        if (transType == TRANSFORMATION_TYPE.SCALE_Y )
                        {
                            yScale += 0.5;    
                        }
                        if (transType == TRANSFORMATION_TYPE.SCALE_Z )
                        {
                            zScale += 0.5;    
                        }                    
                        if (transType == TRANSFORMATION_TYPE.SCALE_XYZ )
                        {
                            xScale += 0.5;    
                            yScale += 0.5;    
                            zScale += 0.5;    
                        }                    

                        //======================================================
                        fragProxy.scale    = new THREE.Vector3(  xScale, yScale, zScale);   
                        fragProxy.position = new THREE.Vector3(  xPos, yPos, zPos);   
                        //======================================================
                        fragProxy.updateAnimTransform()
                        //======================================================
                    }
                })
            })

            this._viewer.impl.invalidate(true, true, true)
        })
    }


    //=================================================================================================================

    // Promise.all([
    //     fetch('sample.txt'),
    //     fetch('sample2.txt')
    //     ]).then(allResp => {
    //       let sampleResp = allResp[0];
    //       let sample2Resp = allResp[1];
    //       console.log(sampleResp);
    //       console.log(sample2Resp);
    //     })


    private readData()
    {


        Promise.all([
            fetch('content/data/xdata.txt').then(x => x.text()),
            fetch('content/data/xdata.txt').then(x => x.text())
          ]).then(([sampleResp, sample2Resp]) => {
            console.log(sampleResp);
            console.log(sample2Resp);
          });



        const gridData:DataRow[] = [];

        fetch('content/data/xdata.txt').then( result_data => {

            if (result_data != null)
            {

            }
            // for (let currItem of result_data)
            // {
            //     const d1:DataRow = { id: currItem.Id, itemNumber: "asasasa" , extraField: "asas" };
                
            //     gridData.push(d1);
            // }
          //  this.fillConfigurationTable(this._GridData,true)
        });
    }

    //=================================================================================================================





}


