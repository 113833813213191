// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/button2-ico.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.BIM360DataManagerToolbarButton {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); \n    background-size: 24px;\n    background-repeat: no-repeat;\n    background-position: center;\n  }\n \n  #id-spinnerContainer-bim360\n  {\n      align-items:     center;\n      justify-content: center;\n  }\n  \n  #id-spinnerCaptionPanel-bim360\n  {\n      color:             #a4b4a9;\n      /* margin-top:       12px; */\n      display:          flex;\n      align-items:      center;\n      justify-content:  center;\n  }\n  \n  #id-spinnerPanel-bim360\n  {\n    color:  #FF0000;\n    height:           auto;\n    padding:          0px;\n    margin-top:       0px;\n  \n    display:          flex;\n    align-items:      center;\n    justify-content: center;\n  }\n\n  .bim-tree-item\n  {\n    color:  #e3e3e4;\n    padding: 2px 10px 2px 10px;    /* top right bottom left */\n    margin-top: 2px;\n  }\n \n  .bim-tree-item-2\n  {\n    /* color:  #ce4f64; */\n    color:  #e3e3e4;\n    padding: 2px 10px 2px 10px;    /* top right bottom left */\n    margin-top: 6px;\n  }\n\n  .label-1\n  {\n    font-size:  18px;\n    color: #CCCCCC;\n  }\n\n  .label-2\n  {\n    font-size:  18px;\n    /* color: #3ab93a; */\n    color: #CCCCCC;\n  }\n\n  .MuiTypography-h6\n  {\n    padding: 4px 10px 2px 10px;\n  } \n\n  #id-bim-manage-content\n  {\n    background:  #353535;\n  }", ""]);
// Exports
module.exports = exports;
