import React from 'react';
import './App.css';
import './client';
import Loading, {  } from './components/Loading';
import Logo from './components/Logo';
import Authentication, {  } from "./components/Authentication";
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Switch } from 'react-router-dom';
import ForgeViewer from './components/ForgeViewer';
import Overlay from './components/Overlay';
import ToForgeViewer from './components/ToForgeViewer';

const App = () => {
  const { isLoading } = useAuth0();
  
  if(isLoading){
    return <Loading/>
  }

  return (
    
    <div id="App">
      <Switch>
        <Route path="/forgeviewer">
          <div id="forgeviewer">
            <Overlay/>
            <ForgeViewer/>
          </div>
        </Route>
        <Route path="/">
          <div className="login-container">
            <div className="loginContent">
              <Logo/>
              <Authentication/>
              <ToForgeViewer/>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
}

export default App;